import { EXP_DATE, HEADER_NAME, MANF_DATE, STATUS } from "../../../textConstant";
import moment from "moment";
import Columnchip from "../../../components/Table/Columnchip";
import LinkRedirect from "./LinkRedirect";
import UnitConverterWeight from "../../../utils/UnitConverter";
export const USAGE_LOT_HEADERS = (translate) => [
  {
    field: "targetBatch.batchNo",
    headerName: translate('batchNumber'),
    // maxWidth: 180,
    flex:1,
    hide: false,
    cellRendererFramework: (params) =><LinkRedirect params={params.data} target="batch"/>,
  },
  {
    field: "manufacturerBatchNumber",
    headerName: translate('manufacturerBatchNo'),
    // maxWidth: 170,
    flex:1,
    hide: false,
  },
  {
    field: "targetBatch.dateOfManufacture",
    headerName: translate('manufacturingDate'),
    // maxWidth: 170,
    flex:1,
    hide: false,
    valueFormatter: (params) => {
      if(params.data.dateOfManufacture){
        return moment.utc(params.data.dateOfManufacture).format("DD-MMM-YYYY");
      } else{
          return ""
      }
    },
  },
  {
    field: "targetBatch.dateOfExpiry",
    headerName: translate('expiryDate'),
    // maxWidth: 170,
    flex:1,
    hide: false,
    valueFormatter: (params) => {
      if(params.data.dateOfExpiry){
        return moment.utc(params.data.dateOfExpiry).format("DD-MMM-YYYY");
      } else {
          return ""
      }
    },
  },
  {
    field: "targetBatch.totalQuantity",
    headerName: "Quantity Used",
    // maxWidth: 170,
    flex:1,
    hide: false,
    valueFormatter: (params) => {
      return UnitConverterWeight(params.data.totalQuantity);
  },
  },
  // {
  //   field: "targetBatch.remainingQuantity",
  //   headerName: "Remaining Quantity",
  //   maxWidth: 170,
  //   hide: false,
  //   valueFormatter: (params) => {
  //     return `${params.data.remainingQuantity || 0} ${params.data.uom}`;
  //   },
  // },
  {
    field: "targetBatch.state",
    headerName: translate('stage'),
    // maxWidth: 170,
    flex:1,
    hide: false,
    cellRendererFramework: (params) => <Columnchip data={params.data} />,
  },
];
