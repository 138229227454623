import moment from "moment";
import React from "react";
import Columnchip from "../../../components/Table/Columnchip";
import { DISPATCH_DATE, HEADER_NAME, QUANTITY_USED, REMAINING_QUANTITY, STATUS, TOTAL_QUANTITY } from "../../../textConstant";
import UnitConverterWeight from "../../../utils/UnitConverter";
import LinkRedirect from "./LinkRedirect";

export const SOURCE_LOTS_HEADERS = [
  {
    field: "lotNo",
    headerName: `${HEADER_NAME}`,
    hide: false,
    cellRendererFramework: (params) => <LinkRedirect params={params.data} target ="lot"/> ,

  },
  {
    field: "dateOfManufacture",
    headerName: `${DISPATCH_DATE}`,
    hide: false,
    valueFormatter: (params) => {
      if(params.data.dateOfManufacture){
        return moment.utc(params.data.dateOfManufacture).format("DD-MMM-YYYY");
      } else{
          return ""
      }
    },
  },

  {
    field: "totalQuantity",
    headerName: `${TOTAL_QUANTITY}`,
    hide: false,
    valueFormatter: (params) => {
      return UnitConverterWeight(params.data.totalQuantity);
  },
  },
  {
    field: "remainingQuantity",
    headerName: `${REMAINING_QUANTITY}`,
    hide: false,
    valueFormatter: (params) => {
      return UnitConverterWeight(params.data.remainingQuantity);
  },
  },
  {
    field: "state",
    headerName: `${STATUS}`,
    hide: false,
    cellRendererFramework: (params) => <Columnchip data={params.data} />,
  },
];
