import moment from 'moment';
import { EXP_DATE, HEADER_NAME, MANF_DATE, REMAINING_QUANTITY, STATUS, TOTAL_QUANTITY, LOT_NUMBER, LOT_NAME } from "../../textConstant";
import Columnchip from "../../components/Table/Columnchip";
import UnitConverterWeight from '../../utils/UnitConverter';



export const LISTING_LOTS_HEADER = (translate, independentBatch)=> [
    {
        field: "lotNo",
        headerName: translate('lotNumber'),
        minWidth: 170,
        hide: false,
        filter: "agTextColumnFilter",
        floatingFilterComponentParams: {
            suppressFilterButton: true,
        },
        filterParams: {
            buttons: ["apply", "clear"],
        },
    },
    {
        field: "batchName",
        headerName: translate('lotName'),
        minWidth: 170,
        tooltipValueGetter: params => {return translate(params.value)},
        hide: !independentBatch,
        filter: "agTextColumnFilter",
        floatingFilterComponentParams: {
            suppressFilterButton: true,
        },
        filterParams: {
            buttons: ["apply", "clear"],
        },
        valueFormatter: params => {
                return translate(params.data.batchName)
        }
    },
    {
        field: "manufacturerLotNumber",
        tooltipField: "manufacturerLotNumber",
        headerName: translate('manufacturerLotNo'),
        minWidth: 170,
        hide: false,
        filter: "agTextColumnFilter",
        floatingFilterComponentParams: {
            suppressFilterButton: true,
        },
        filterParams: {
            buttons: ["apply", "clear"],
        },
        valueFormatter: params => {
            // debugger;
            if(params?.data?.lotProperties && params?.data?.lotProperties?.length != 0){
                const manufacturerLotNumber = params?.data?.lotProperties?.find(item=>item.name=='manufacture_lotNumber')?.value;
                return (manufacturerLotNumber ? manufacturerLotNumber : "" )
            } else{
                return (params.data.manufacturerBatchNumber || '')
            }
        },
    },

    {
        field: "dateOfManufacture",
        headerName: translate('manufacturingDate'),
        minWidth: 150,
        hide: false,
        filter: "agTextColumnFilter",
        floatingFilterComponentParams: {
            suppressFilterButton: true,
        },
        filterParams: {
            buttons: ["apply", "clear"],
        },
        valueFormatter: params => {
            if(params.data.dateOfManufacture){
                return moment.utc(params.data.dateOfManufacture).format("DD-MMM-YYYY");
            } else{
                return ""
            }
        },


    },
    {
        field: "dateOfExpiry",
        headerName: translate('expiryDate'),
        minWidth: 150,
        hide: false,
        filter: "agTextColumnFilter",
        floatingFilterComponentParams: {
            suppressFilterButton: true,
        },
        filterParams: {
            buttons: ["apply", "clear"],
        },

        valueFormatter: params => {
            if(params.data.dateOfExpiry){
                return moment.utc(params.data.dateOfExpiry).format("DD-MMM-YYYY");
            } else {
                return ""
            }
        },

    },
    {
        field: "totalQuantity",
        headerName: translate('totalQuantity'),
        minWidth: 170,
        hide: false,
        filter: "agTextColumnFilter",
        floatingFilterComponentParams: {
            suppressFilterButton: true,
        },
        filterParams: {
            buttons: ["apply", "clear"],
        },
        valueFormatter: (params) => {
            return UnitConverterWeight(params.data.totalQuantity);
        },
    },
    {
        field: "remainingQuantity",
        headerName: translate('remainingQuantity'),
        minWidth: 170,
        hide: false,
        filter: "agTextColumnFilter",
        floatingFilterComponentParams: {
            suppressFilterButton: true,
        },
        filterParams: {
            buttons: ["apply", "clear"],
        },
        valueFormatter: (params) => {
            return UnitConverterWeight(params.data.remainingQuantity);
        },
    },
    {
        field: "state",
        headerName: translate('stage'),
        minWidth: 170,
        hide: false,
        filter: "agTextColumnFilter",
        floatingFilterComponentParams: {
            suppressFilterButton: true,
        },
        filterParams: {
            buttons: ["apply", "clear"],
        },
        cellRendererFramework: (params) =>
            (<Columnchip data={params.data} type="lots" />)
    },
    {
        field: "labName",
        headerName: translate('labName'),
        minWidth: 200,
        hide: false,
        tooltipField: "labName",
        filter: "agTextColumnFilter",
        floatingFilterComponentParams: {
            suppressFilterButton: true,
        },
        filterParams: {
            buttons: ["apply", "clear"],
        },
        // valueFormatter: params => {
        //     return (params.data.manufacturerBatchNumber ? params.data.manufacturerBatchNumber : 
        //         (params?.data?.lotProperties?.find(item=>item.name=='manufacture_lotNumber')?.value || "")  )
        // },
    },
    {
        field: "labCertificateNumber",
        headerName: translate('tcNumber'),
        minWidth: 200,
        hide: false,
        tooltipField: "labCertificateNumber",
        filter: "agTextColumnFilter",
        floatingFilterComponentParams: {
            suppressFilterButton: true,
        },
        filterParams: {
            buttons: ["apply", "clear"],
        },
        valueFormatter: (params) => {
            return (params.data.labCertificateNumber ? params.data.labCertificateNumber : params.data.licenseNumber);
        },
    },
    {
        field: "createdBy",
        headerName: translate('createdBy'),
        minWidth: 200,
        hide: false,
        tooltipField: "createdBy",
        filter: "agTextColumnFilter",
        floatingFilterComponentParams: {
            suppressFilterButton: true,
        },
        filterParams: {
            buttons: ["apply", "clear"],
        }
    },

];

