import { Modal, Box, Typography, TextField, Button } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { MAIN_BLUE } from "../../../color";
import { getPremixManufacturerByLicenseNumber, getVendorFromLicense } from "../../../services/FortificationService";
import { useAppDispatch, useAppSelector } from "../../../services/StoreHooks";
import useTranslatewrapper from "../../../components/useTranslateWrapper";

function VendorLicenseDetails(props) {
  const {translate} = useTranslatewrapper()
  const categoryNameToIdSelectorRedux = useAppSelector((state) =>
    state?.categoriesData?.categories
  );

  const categoryNameToIdMapRedux = useMemo(()=>{
    return (
      categoryNameToIdSelectorRedux.reduce(
      (acc, current) => {
        acc[current.name] = current.id.toString();
        return acc;
      },
      {}
    ))
  }, [categoryNameToIdSelectorRedux])

    const [licenseNo, setLicenseNo] = useState('')
    const [manufName, setManufName] = useState('')
    const [manufId, setManufId] = useState()
    const [error, setError] = useState(false)
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    overflow: "scroll",
    width: "50%",
  };
  const dispatch = useAppDispatch();
  const dispatchShowLoader = (showLoader) => {
    dispatch({
      type: "SHOW_LOADER",
      data: {
        showLoader
      },
    });
  };
  const searchByLicenseNo = async() =>{
    if(!licenseNo){
        setError(true)
        return
    }
    //call api here
    dispatchShowLoader(true)

    let manufData
    if(!props.isPremixSelfAdd){
      manufData = await getVendorFromLicense(licenseNo, props.categoryId)
    } else{
      manufData = await getPremixManufacturerByLicenseNumber(licenseNo, categoryNameToIdMapRedux['PREMIX'])
    }

    // debugger;

    if(manufData?.data){

      if(!props.isPremixSelfAdd){
        await props.fetchVendorList()
      } else{
        const tempArr = props.vendorList.map(item=>item)
        tempArr.push(manufData.data)

        props.setVendorList(tempArr)

        if(props.setTempVendorData){
          props.setTempVendorData(manufData.data)
        }


      }
        setManufName(manufData?.data['name'])
        setManufId(manufData?.data['id'])
    }
    dispatchShowLoader(false)

  }

  return (
    <Modal 
    open={props.open}
    onClose={()=>{
        props.setOpenSearchByLicenseNumber(false)
        setLicenseNo('');
        setManufName('');
        setManufId('');
        setError(false)
    }}
    >
      <Box sx={style}>
        <Typography
          align="left"
          mt={2}
          mb={4}
          variant="h6"
          sx={{ color: MAIN_BLUE, fontWeight: "600" }}
        >
          {"Search Vendor by License Number"}
        </Typography>
        {/* <form>
          <label>
            License Number:
            <input 
            type="text" 
            value={licenseNo}
            onChange={(e) => setLicenseNo(e.target.value)}
            />
          </label>
        </form> */}
        <TextField
        id="outlined-basic" 
        error={error}
        type="string"
        helperText={error && "Please enter a valid 14 digit license number"}
        label={translate('licenseNo')}
        variant="outlined"
        value={licenseNo}
        sx={{ width: "50%" }}
        onChange={(e) => {
            if(!(/^\d*$/.test(e.target.value))){
                return;
            }
            if(e.target.value.toString().length != 14){
                setError(true)
            } else{
                setError(false)
            }
            if(manufId){
                setManufId(null)
                setManufName('')
            }
            setLicenseNo(e.target.value)
        }
        }
         />

        <Typography mt={2} mb={2}>
        {manufName ? `Vendor name: ` : ''} {manufName}
        </Typography>

        <Button variant="contained" style={{marginRight:'10px'}} onClick={searchByLicenseNo} disabled={error} >{translate('search')}</Button>
        {/* {manufId && ( */}
        <Button variant="contained" disabled={!manufId} onClick={async()=>{
            await props.handleClickOk(manufId,manufName)
            props.setOpenSearchByLicenseNumber(false)
            setLicenseNo('');
            setManufName('');
            setManufId('');
            setError(false)
        }}
        >Ok</Button>
            {/*  ) */}
        {/*  } */}

      </Box>
    </Modal>
  );
}

export default VendorLicenseDetails;
