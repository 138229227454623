import { ThemeProvider, createTheme } from '@mui/material/styles';
import { MAIN_BLUE } from './color';

const MainTheme = createTheme({
  palette: {
    primary: {
      main: MAIN_BLUE,
    },
  },
});

export default MainTheme;