export const transformedArray = (data)=>{
   const resultedArr= data?.map(item => {
    const id=item.id;
    const categoryName = item.attributeCategory.category;
    const categoryId = item.attributeCategory.id;
    

    const overallRating = item.attributeScore.reduce((total, attribute) => total + attribute.score, 0);
    const items = item.attributeScore.map(attribute => ({
      id:attribute.id,
      attributeId:attribute.attribute.id,
      name: attribute.attribute.name,
      value: attribute.value,
      totalStars: attribute.attribute.totalScore,
      type: attribute?.attribute?.type,
      compliance: attribute?.compliance
    }));
    return {
      id,
      categoryName,
      categoryId,
      overallRating,
      items
    };
  });

  return resultedArr;
}

export const transformAddRating=(data)=>{
  const resultedArr = data.map((item) => ({
    categoryId: item.id,
    categoryName: item.category,
    id: item.attributes[0].id,
    items: item.attributes.map((attribute) => ({
      attributeId: attribute.id,
      id: attribute.id,
      name: attribute.name,
      totalStars: attribute.totalScore,
      // value: attribute.defaultScore,
      type: attribute.type,
      compliance: attribute?.compliance
    }))
  }));

  return resultedArr

}



export const updatedRatingData=(addRatingData,ratingData)=>{

  const updatedFirstArray = addRatingData.map((firstItem) => {
    const secondItem = ratingData.find((item) => item.categoryId === firstItem.categoryId);

      const updatedItems = firstItem?.items?.map((item) => {

        const matchingSecondItem = secondItem?.items?.find((secondItem) => secondItem.attributeId === item.attributeId);
          return { ...item, value: matchingSecondItem?.value || item?.value,compliance:matchingSecondItem?.compliance };
        
      });
  
      return { ...firstItem, items: updatedItems,id: secondItem?.id };


  });

  return updatedFirstArray;
}
