import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import PremixSections from "../Form/PremixSections/PremixSections";
import { getDataFromQRCode } from "../../services/FortificationService";

export default function PremixDetails({isInspector}) {
    const routeParams = useParams();
    const [requiredID, setRequiredID] = React.useState("");
    const [QRBatchDetails, setQRBatchDetails] = React.useState();

    useEffect(() => {
        if(routeParams.uuid){
            getUUIDData()
        }
    }, [])

    const getUUIDData = async () => {
        const QRCodeData = await getDataFromQRCode(routeParams?.categoryId, routeParams?.type, routeParams?.uuid);
        setRequiredID(QRCodeData?.data?.id);
        setQRBatchDetails(QRCodeData?.data);
    }

    return (
        <>
            {
                (!routeParams?.uuid || requiredID) ?
                <PremixSections 
                    categoryId={routeParams?.categoryId}
                    generatedId={requiredID}
                    QRBatchDetails={QRBatchDetails}
                    isInspector={isInspector}
                /> : null
            }
       </>
    )
}
