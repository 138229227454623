import Highcharts from "highcharts/highmaps";
import HighchartsReact from "highcharts-react-official";
import drilldown from "highcharts/modules/drilldown";
import dataModule from "highcharts/modules/data";
import { useEffect, useMemo, useRef, useState } from "react";
import { CircularProgress, Paper } from "@mui/material";
import Box from "@mui/material/Box";
import { getMapData } from "../../services/DashBoardService";
import UnitConverterWeight from "../../utils/UnitConverter";
import KeycloakService from "../../services/KeycloakService";
import { useAppDispatch, useAppSelector } from "../../services/StoreHooks";
import { MILLER_UI_TEXT } from "../../textConstant";

drilldown(Highcharts);
dataModule(Highcharts);

const globaloptions = {
  chart: {
    events: {},
  },

  legend: {
    layout: "horizontal",
    align: "center",
    verticalAlign: "bottom",
  },

  colorAxis: {
    min: 0,
    minColor: "#F1E0DE",
    maxColor: "#73FED1",
    stops: [
      [0, "#F1E0DE"],   
      [0.25, "#F6E8CA"],  
      [0.5, "#FEF9B3"],  
      [0.75, "#C5FCBD"],  
      [1, "#73FED1"]  
    ]
  },

  mapNavigation: {
    enabled: true,
    buttonOptions: {
      verticalAlign: "bottom",
    },
  },

  series: [],

  drilldown: {
    activeDataLabelStyle: {
      color: "#000",
      textDecoration: "none",
      // textOutline: "1px #000000",
    },
    drillUpButton: {
      relativeTo: "spacingBox",
      position: {
        x: 0,
        y: 60,
      },
    },
  },
};

export default function Mapcomponent(props) {

  const categoryNameToIdSelectorRedux = useAppSelector((state) =>
    state?.categoriesData?.categories
  );

  const categoryNameToIdMapRedux = useMemo(()=>{
    return (
      categoryNameToIdSelectorRedux.reduce(
      (acc, current) => {
        acc[current.name] = current.id.toString();
        return acc;
      },
      {}
    ))
  }, [categoryNameToIdSelectorRedux])

  const userDetails = KeycloakService.getUserDetail();
  if((userDetails.stateGeoId || userDetails.districtGeoId) && props.selectedTab == '1' ){
    globaloptions.drilldown.breadcrumbs = {
      style: {
        display: 'none'
      },
      separator: {
        style: {
          display: 'none'
        }
      }
    }
  } else{
    globaloptions.drilldown.breadcrumbs = {
      style: {
        display: 'block'
      },
      separator: {
        style: {
          display: 'block'
        }
      }
    }
  }
  const { dashboardType, categoryname, selectedCategoryId, setGridlevel,geoId, 
    // year, 
    gridlevel,
    //  formattedfromDate, formattedtoDate, 
     selectedTab
   , selectedDates 
  } = props;

  const dispatch = useAppDispatch();
  const dispatchShowLoader = (showLoader) => {
      dispatch({
        type: "SHOW_LOADER",
        data: {
          showLoader
        },
      });
    };

  const pointRef = useRef(null);
  const chartRef = useRef(null);
  const [options, setOptions] = useState({
    ...globaloptions,
    chart: {},
    series: [],
    title: {
      text: "",
    },
  });
  const [allowChartUpdate, setAllowChartUpdate] = useState(true);
  const [isDrilledDown, setIsDrilledDown] = useState(false);
  const [loader, setLoader] = useState(true);
  const labels = {
    masters: "total",
    // production: "producedQuantity",
    production:'totalProduction',
    testing: (selectedTab == '2') ? "lotApproved" : "batchTested",
    samples: "testedCount",
  };
  const paramtexts = {
    totalTestedQuantity: "Total Tested",
    underTestingQuantity: "Under Testing",
    batchTestApprovedQuantity: "Batches Approved quantity",
    batchTestRejectedQuantity: "Batches Rejected quantity",
    lotTestApprovedQuantity: "Lots Approved quantity",
    lotTestRejectedQuantity: "Lots Rejected quantity" ,
    producedQuantity: "Produced",
    inProductionQuantity: "In Production",
    inTransitCount: "In Transit",
    underTestingCount: "Under Testing",
    testedCount: "Tested",
    rejectedCount: "Rejected",
    total: "Total",
    totalProduction: 'Total Production',
    sampleInTransit: 'Sample in Transit for testing',
    // approvedQuantity: 'Approved Quantity',
    // availableNotTested: 'Available Non Tested',
    availableTested: dashboardType == "testing" ? "Not Dispatched" : 'Available Tested',
    batchTested: 'Batch Tested',
    lotApproved: 'Lot Approved',
    lotRejected: 'Lot Rejected',
    lotInTransit: 'Lot In Transit',
    batchApproved: 'Batch Approved',
    batchRejected: 'Batch Rejected',
    totalDispatched: 'Total Dispatched',
    usedQuantity: 'Quantity Consumed',
    accepted: 'accepted',
    rejected: 'rejected',
    dispatched: 'dispatched',
    available: 'available',
    batchNotTested:'Batch Not Tested'
  };
  const maplabel = labels[dashboardType];
  const maptitles = (dashboardType, categoryname, regionName)=>{
    if(regionName.toLowerCase() == 'india'){
      regionName = 'Sri Lanka'
    }
    let title = "";
    if(dashboardType=="masters"){
      if(categoryname.toLowerCase()=="all"){
        categoryname = "";
      }
      title = `Total ${categoryname=="MILLER" ? MILLER_UI_TEXT : categoryname}${categoryname !== 'LABS' ?' manufacturers':''} in ${regionName}`;
    }
    else if(dashboardType == "production"){
      if(categoryname === 'WAREHOUSE'){
        title = `Total ${selectedCategoryId==categoryNameToIdMapRedux['FRK']?"FRK":"Fortified Rice"} available in ${(selectedTab == '2') ? 'Empaneled' : '' } Warehouses in ${regionName}`
      }else{
        title = `Total ${categoryname=="MILLER"? "Fortified Rice" : categoryname} Production in ${regionName}`;
      }
    }
    else if(dashboardType == "testing"){
      title =  `${selectedTab == '2' ? 'Empaneled' : 'Tested'} ${(categoryname=="MILLER" || categoryname=="WAREHOUSE")? "Fortified Rice" : categoryname} in ${regionName}`;
    }
    else{
      title = `Tested ${categoryname=="MILLER"? "Fortified Rice" : categoryname} Samples in ${regionName}`;
    }
    return title;
  }
  const getparams = (data) => {
    if (!data) {
      return [], [];
    }
    if (data.length == 0) {
      return [], [];
    }
    let params = Object.keys(data[0]);
    // let params = Object.keys(data[0] || data['data']);
    params = params.filter((item) => {
      if(categoryname === 'WAREHOUSE' ){
        paramtexts['inProductionQuantity'] = 'Total Available'
        return !['id', 'producedQuantity'].includes(item)
      }
      return item !== "id" && item !== 'categoryId' && item !== 'geoId' 
    });
    
    const paramnames = params.map((item) => paramtexts[item]);
    return { params, paramnames };
  };
  let defaultstatsobj = {};

  const setStats = (data, params) => {
    if(!params){
      return {};
    }
    defaultstatsobj = {
      label: "",
      data: params.map(() => "N/A"),
      enableDrilldown: false,
    };
    let statsobj = {};
    for (let i of data) {
      if(!i["id"]){continue}
      statsobj[i["id"].toString()] = {
        data: params.map((param) =>{
          if(param == 'totalTestedQuantity'){
            return UnitConverterWeight(i[param] + i['totalLotTestedQuantity']) || 0
          }
          return  ["production", "testing"].includes(dashboardType)
          ? UnitConverterWeight(i[param]) || 0 : i[param] || 0
        }
         
        ),
        label: getLabel(i),
        enableDrilldown: true,
        value: getValue(i[maplabel], i),
      };
    }
    return statsobj;
  };
  
  const getLabel = (i)=>{
    
    if(dashboardType === 'production'){
      // if(categoryname === 'WAREHOUSE'){
      //   return UnitConverterWeight(i["accepted"]);
      // }
      let calculatedTotalProduction = 0;
      if(i['data']){
        for(let element of i['data']){
          if(categoryname === "WAREHOUSE"){
          calculatedTotalProduction = calculatedTotalProduction + element['available']
          } else{
            calculatedTotalProduction = calculatedTotalProduction + element['totalProduction']
          }
        }
      } else{
        if(categoryname==="WAREHOUSE"){
          calculatedTotalProduction = calculatedTotalProduction + i['available']
        } else{
          calculatedTotalProduction = calculatedTotalProduction + i['totalProduction']
        }
      }
      return UnitConverterWeight(calculatedTotalProduction)
      // return UnitConverterWeight(i[maplabel] + i["inProductionQuantity"]);
    } else if(dashboardType === 'testing'){
      // let testedQuant = (i[maplabel] || 0) + (i["totalLotTestedQuantity"] || 0);
      // return UnitConverterWeight(testedQuant)
      let calculatedTotalTested = 0;
      if(i['data']){
        if(selectedTab == '2'){
          for(let element of i['data']){
            calculatedTotalTested = calculatedTotalTested + element['lotApproved']
          }
        } else{
          for(let element of i['data']){
            calculatedTotalTested = calculatedTotalTested + element['batchTested']
          }
        }

      } else{
        if(selectedTab == '2'){
          calculatedTotalTested = calculatedTotalTested + i['lotApproved']
        } else {
          calculatedTotalTested = calculatedTotalTested + i['batchTested']
        }
      }
      return UnitConverterWeight(calculatedTotalTested)
    }
    return i[maplabel]
  }

  const getValue = (v, i)=>{
    
    if(dashboardType === 'production'){
      if(categoryname === 'WAREHOUSE'){
        return  Number((i["available"])) || 0;
      }
      return Number(
        // UnitConverterWeight
        (
        // v +
         i["totalProduction"])
        //  .split(" ")[0]
         ) || 0;
    } else if(dashboardType === 'testing'){
      return Number(
        // UnitConverterWeight
        (v)
        // .split(" ")[0]
        ) || 0
    }
    return v || 0;

  }
  const getToolTipPointFormat = (paramnames) => {
    if (!paramnames || paramnames.length==0){
      return `<div style="display: block; color:#0075ff">
      <div>{point.name}</div><br/>
      </div>`;
    }
    let htmlstring = "";
    for (let i = 0; i < paramnames.length ; i++) {
      if(paramnames[i]){

        htmlstring += `<div style="display: block; color:black ;"> ${paramnames[i]}: {point.stats.data.${i}}</div><br/>`;

      }
    }
    return `<div style="display: block; color:#0075ff">
    <div>{point.name}</div><br/>
    ${htmlstring}
  </div>`;
  };

  const fetchMapData = async (mapKeys, selectedCategoryId, type, geoId
    //, selectedDates
    ) => {
    if (mapKeys[0] == "lk") {
      setLoader(true);
    }
    return Promise.all([
      fetch(`/${mapKeys.join("/")}.geojson`).then((res) => res.json()),
      getMapData(dashboardType, ((dashboardType === 'masters') && (categoryname === 'WAREHOUSE')) ? categoryNameToIdMapRedux['WAREHOUSE'] : selectedCategoryId, type, geoId, categoryname, null,
      //  formattedfromDate, formattedtoDate, 
       selectedTab
      , selectedDates
        ),
    ]).then(([mapData, res]) => {

      const stats = res.data;

      // let newStats = stats;
      // if(selectedCategoryId && (dashboardType == "production" || dashboardType == 'testing')){
      //   for(let item of stats){
      //     item['id'] = item['geoId']
      //   }
      //   newStats = {
      //     'data': stats,
      //     'producedQuantity' : 1000,
      //     'inProductionQuantity' : 1000

      //   }
      // }

      // stats.data.forEach(x=> {
      //   x.totalTestedQuantity = x.totalTestedQuantity || 10000
      // })
      if(stats["data"]){

        const { params, paramnames } = getparams(stats["data"]);
        setLoader(false)
        return { mapData, stats, params, paramnames };
      } else{
        const { params, paramnames } = getparams(stats);
        setLoader(false)
        return { mapData, stats, params, paramnames };

      }
      // const { params, paramnames } = getparams(stats);
      // setLoader(false)
      // return { mapData, stats, params, paramnames };
    });
  };

  const loadDrilledDownMap = () => {
    const pointIndex = pointRef.current;

    const chart = chartRef.current;
    const point = chart.get("india_series").points[pointIndex];
    if (!chart || !point) {
      return;
    }
    chart.showLoading(<CircularProgress />);
    fetchMapData(
      point.drilldown,
      selectedCategoryId,
      "state",
      point.properties.id,
      // year,
      //selectedDates
    )
      .then(({ mapData, stats, params, paramnames }) => ({
        map: Highcharts.geojson(mapData),
        stats,
        params,
        paramnames,
      }))
      .then(({ map, stats, params, paramnames }) => {
        // alter data to have name and value
        const data = map;
        const statsfinal = (stats["data"] ? setStats(stats["data"], params) : setStats(stats, params)) || {};
        data.forEach(function (el, i) {
          el.geoId = el.properties.DISTRICT_L;
          el.type = "village";
          const districtStatistics =
            statsfinal[el.properties.DISTRICT_L] || defaultstatsobj;
          el.stats = districtStatistics;
          el.name = toTitleCase(el.properties.District);
          el.value = el.stats.value || 0;
        });
        chart.hideLoading();
        try{

          const point = chart?.get("india_series")?.points[pointIndex];
          chart.addSeriesAsDrilldown(point, {
            name: point.name,
            data: data,
            dataLabels: {
              enabled: true,
              allowOverlap: true,
              format: "{point.name}",
            },
            tooltip: {
              pointFormat: getToolTipPointFormat(paramnames),
            },
          });
        }catch(e){
          console.log(e)
        }
      });
      //
      if(props.setStateName){
        props.setStateName(point.name)
      }
    chart.setTitle(null, {
      text: `${maptitles(dashboardType,categoryname, point.name)} : ${ point.stats.label || 'N/A'}`,
      style: { fontWeight: "bold", fontSize: "14px", marginRight: "5px" },
      align: "right",
    });
  };

  const fetchConsolidatedMapData = async (mapKeys) => {
    const { mapData, stats, params, paramnames } = await fetchMapData(
      mapKeys,
      selectedCategoryId,
      "country",
      1,
      // year,
      //selectedDates
    );
    const india_title = getLabel(stats)|| "N/A";
    let statsfinal = setStats((stats["data"] ? stats['data'] : stats), params) || {};
    const map = Highcharts.geojson(mapData);
    const separators = Highcharts.geojson(mapData, "mapline");

    map.forEach(function (el, i) {
      el.geoId = el.properties.id;
      el.drilldown = ["states", `State_${el.properties.id}`];
      const stateStatistics =
        statsfinal[el.properties.id] || defaultstatsobj;
      el.stats = stateStatistics;
      el.name = toTitleCase(el.properties.name);
      el.value = el.stats.value || 0;
    });
    return { map, params, paramnames, india_title, separators };
  };

  const loadmap = async (mapKeys) => {
    const { map, params, paramnames, india_title, separators } =
      await fetchConsolidatedMapData(mapKeys);
      
    setOptions({
      ...globaloptions,
      chart: {
        events: {
          drilldown: function (e,chart) {
            if (!e.point.drilldown 
              // || !e.point.stats.enableDrilldown
              ) {
              return;
            }
            setIsDrilledDown(true);
            if(!chart){

              chart = this

            }
            setGridlevel({
              level: "state",
              geoId: e.point.geoId,
              name: e.point.name,
            });
            chartRef.current = chart;
            pointRef.current = e.point.index;
            loadDrilledDownMap();
          },
          drillup: function (e) {
            setIsDrilledDown(false);
            this.setTitle(null, { text: `` });
            setGridlevel({
              level: "country",
              geoId: 1,
              name: "India",
            });
            setAllowChartUpdate(true);
          },
          load: function (e){
            if(gridlevel && (gridlevel.level=='state' || gridlevel.level == 'district') && geoId
            //  && (selectedTab == '1')
            ){
              const chart = this

              chart.eventOptions.drilldown({
                // point:  chart.series[0].data?.filter((item)=>item.geoId==geoId)[0]
                point:  chart.series[0].data?.filter((item)=>item.geoId==gridlevel.geoId)[0]
              }, chart)
            }
           
          }
        },
        animation:false
      },

      
      series: [
        {
          data: map,
          id: "india_series",
          name: toTitleCase(mapKeys.join("/")),
          dataLabels: {
            enabled: true,
            format: "{point.name}",
           //style is at line 41
          },
          tooltip: {
            pointFormat: getToolTipPointFormat(paramnames),
          },
        },
        {
          type: "mapline",
          data: separators,
          color: "silver",
          enableMouseTracking: false,
          animation: false,
        },
      ],
      title: {
        text: `${maptitles(dashboardType, categoryname, "India")} : ${india_title}`,
        align: "right",
        style: { fontWeight: "bold" },
        x:-20
      },
      plotOptions: {
        map: {
          states: {
            hover: {
              color: "#EEDD66",
            },
          },
        },
        series: {
          point: {
            events: {
              click: function (e) {
                if (e.point.type == "village") {
                  // setGridlevel({
                  //   level: "district",
                  //   geoId: e.point.geoId,
                  //   name: e.point.name,
                  // });

                  setAllowChartUpdate(false);
                }
              },
            },
          },
        },
      },
    });
    setLoader(false);
  };

  function toTitleCase(str) {
    return str?.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
  useEffect(() => {
    // if((userDetails.stateGeoId || userDetails.districtGeoId) && props.selectedTab == '1' ){
      
    // } else if (isDrilledDown) {
    //   chartRef.current.drillUp();
    // }

    if(!(userDetails.stateGeoId || userDetails.districtGeoId) || props.selectedTab != '1' ){
      if(isDrilledDown){
        chartRef.current.drillUp();
      }
    }


    loadmap(["lk"]);
  }, [dashboardType, categoryname, selectedCategoryId, selectedTab, selectedDates
    // , year
  ]);

  return (loader) ? (
    <Box className="main-content-overlay" sx={{ height: "100vh" }}>
      <CircularProgress />
    </Box>
  ) : (
    <Box component={Paper} m={1}>
      <HighchartsReact
        containerProps={{
          style: { height: "calc(100vh - 170px)" },
        }}
        highcharts={Highcharts}
        options={options}
        constructorType={"mapChart"}
        allowChartUpdate={allowChartUpdate}
      />
    </Box>
  );
}
