import { Tree } from "react-tree-graph";
import { Paper } from "@mui/material";
import Box from "@mui/material/Box";
import "./HorizontalTree.css";

export default function Horizontal_Tree(props) {
  let { treeData } = props;
  return (
    <Box component={Paper}>
      <Tree
        data={treeData}
        animated
        height={400}
        width={900}
        gProps={{
          onClick: (event, node) => {
            props.clickHandler(event, node.props.children.props.children.props.title);
          },
        }}
        svgProps={{
          className: "custom",
        }}
        nodeRadius={15} 
        nodeProps={{ rx: 10, ry: 10 }} 
      ></Tree>
    </Box>
  );
}