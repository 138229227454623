export const ACTION_STATE_DATE_LABELS = {
    "sentBatchSampleToLabTest": "Sample Dispatch Date",
    "lotReceived": "Lot Received Date",
    "approved": "Approved Date",
    "toSendBackRejected": "Lot Rejected Date",
    "sentLotSampleToLabTest": "Sample Dispatch Date",
    "batchToDispatch": "Approved Date",
    "sentBackRejected": "Lot Sent Date",
    "rejected": "Rejected Date",
    "receivedRejected": "Rejected Date",

}
export const ACTION_CONFIRMATION_MESSAGES = {
    "sentBatchSampleToLabTest":"mark the sample as sent to lab?",
     "selfTestedBatch":"self attest the batch?",
     "batchToDispatch":"mark the batch as ready to dispatch?",
     "lotReceived":"mark the lot as Received?",
     "approved":"approve the Lot?",
     "toSendBackRejected":"reject the lot and send Back to the manufacturer?",
     "sentBackRejected":"mark the lot as sent back to the manufacturer?",
     "selfTestedLot":"self attest the lot?",
     "rejected":"mark the batch as Rejected?",
     "sentLotSampleToLabTest":"mark the sample as sent to lab?",
     "receivedRejected": "mark the rejected lot has received?",
     "sendSampleForInspection":"send sample for inspection?"
}