import axios from "axios";
import HttpService from "./HttpService";
const _axios = HttpService.getAxiosClient();

const FORTIFICATION_URL = process.env.REACT_APP_BASE_FORTIFICATION_URL;
const IAM_URL = process.env.REACT_APP_BASE_IAM_URL;
const LAB_URL = process.env.REACT_APP_BASE_LAB_URL;
const IMMUDB_URL = process.env.REACT_APP_BASE_IMMUDB_URL;

const FORTIFICATION_URL_RICE = process.env.REACT_APP_BASE_FORTIFICATION_URL_RICE;
const IAM_URL_RICE = process.env.REACT_APP_BASE_IAM_URL_RICE;
const IMMUDB_URL_RICE = process.env.REACT_APP_BASE_IMMUDB_URL_RICE;


var data = {
  roleCategoryIds: [29, 30],
  roleCategoryType: "MODULE",
};

export const getAllSubSections = async (
  categoryId,
  searchCriteriaList,
  subSection,
  pageNumber,
  pageSize
) => {
  return _axios
    .post(
      `${FORTIFICATION_URL}${categoryId}/${subSection}/list?pageNumber=${pageNumber}&pageSize=${pageSize}`,
      searchCriteriaList
    )
    .catch((error) => {});
};

export const getAllLotsOrBatches = async (
  searchCriteriaList,
  subSection,
  pageNumber,
  pageSize
) => {
  return _axios
    .post(
      `${FORTIFICATION_URL}admin/${subSection}?pageNumber=${pageNumber}&pageSize=${pageSize}`,
      searchCriteriaList
    )
    .catch((error) => {});
};
export const getAllInspectionSubSections = async (
  categoryId,
  searchCriteriaList,
  subSection,
  pageNumber,
  pageSize
) => {
  return _axios
    .post(
      `${FORTIFICATION_URL}inspection/${categoryId}/${subSection}/list?pageNumber=${pageNumber}&pageSize=${pageSize}`,
      searchCriteriaList
    )
    .catch((error) => {});
};

export const createBatch = async (categoryId, body) => {
  return _axios
    .post(`${FORTIFICATION_URL}${categoryId}/batch`, body)
    .catch((error) => {});
};
export const updateBatchDetails = async (categoryId, id, body) => {
  return _axios
    .put(`${FORTIFICATION_URL_RICE}${categoryId}/batch/${id}`, body)//
    .catch((error) => {});
};

export const putBatch = async (categoryId, batchId, data) => {
  return _axios
    .put(`${FORTIFICATION_URL}${categoryId}/batch/${batchId}`, data)
    .catch((error) => {});
};

export const getUomData = async () => {
  return _axios.get(`${FORTIFICATION_URL}uom`).catch((error) => {});
};

export const getUomDatabyId = async (id) => {
  return _axios.get(`${FORTIFICATION_URL}uom/${id}`).catch((error) => {});
};

export const updateUom = async (body) => {
  return _axios
    .put(`${FORTIFICATION_URL}uom/${body.id}`, body)
    .catch((error) => {});
};

export const createUom = async (body) => {
  return _axios.post(`${FORTIFICATION_URL}uom`, body).catch((error) => {});
};

export const getSizeUnit = async () => {
  return _axios.get(`${FORTIFICATION_URL}sizeUnit`).catch((error) => {});
};
export const    getCategoryDocs = async (categoryId) => {
  return _axios
    .get(`${FORTIFICATION_URL}category/${categoryId}/document`)
    .catch((error) => {});
};
export const    getCategoryManuDocs = async (categoryId) => {
  return _axios
    .get(`${IAM_URL}category-document/${categoryId}/documents`)
    .catch((error) => {});
};

export const    getCategoryLabDocs = async (categoryId) => {
  return _axios
    .get(`${LAB_URL}document/category/type/LAB`)
    .catch((error) => {});
};

export const getLabAggregates = (body, isEmpaneledData,empanelledStateGeoId)=>{

  const newKey = (body['type'] == 'country') ? 'sourceCountryGeoId' : (body['type'] == 'state') ? 'sourceStateGeoId' : 'sourceDistrictGeoId'
  let newBody = {
    ...body,
    [newKey]: body['geoId']
  }

  const {geoId, ...updatedNewBody} = newBody;

  let bodyWithEmpaneledStateGeoId = updatedNewBody
  let isEmpaneledUrl = ''
  if(isEmpaneledData){
    isEmpaneledUrl = '/empanel'
    bodyWithEmpaneledStateGeoId = {
      ...updatedNewBody,
      empanelledStateGeoId : empanelledStateGeoId
    }
  }

  return _axios
  .post(`${LAB_URL}labs/aggregate`, bodyWithEmpaneledStateGeoId)
  .catch((error) => {
    console.log(error);
  });
}

export const getLabSamplesList = (body,labId, labDataType, isEmpaneledData,empanelledStateGeoId)=>{

  const newKey = (body['type'] == 'country') ? 'sourceCountryGeoId' : (body['type'] == 'state') ? 'sourceStateGeoId' : 'sourceDistrictGeoId'
  let newBody = {
    ...body,
    [newKey]: body['geoId']
  }

  const {geoId, ...updatedNewBody} = newBody;

  let bodyWithEmpaneledStateGeoId = updatedNewBody
  // let isEmpaneledUrl = ''
  // if(isEmpaneledData){
  //   isEmpaneledUrl = '/empanel'
  //   bodyWithEmpaneledStateGeoId = {
  //     ...updatedNewBody,
  //     empanelledStateGeoId : empanelledStateGeoId
  //   }
  // }

  return _axios
  .post(`${LAB_URL}labSamples/${labId}/${labDataType}`, bodyWithEmpaneledStateGeoId)
  .catch((error) => {
    console.log(error);
  });
}


export const getUsageDetailsExcel = async(catId, id, type, batchNo)=>{
  const newBatchNo = batchNo || ''
  return _axios
    .get(`${FORTIFICATION_URL_RICE}${catId}/${type}/${id}/usage-excel`, { responseType: 'blob' })
    .then((response=>{
      const blob = new Blob([response.data],
        { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${newBatchNo}_usage_details.xlsx`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    }))
    .catch((error) => {
      console.log(error);
    });
}

export const getInventoryExcel = async(catId, batchOrLot, payload, title)=>{
  // const newBatchNo = batchNo || ''
  return _axios
    .post(`${FORTIFICATION_URL_RICE}${catId}/${batchOrLot}/inventory/excel`, payload, { responseType: 'blob' })
    .then((response=>{
      const blob = new Blob([response.data],
        { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${title}_Inventory_details.xlsx`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    }))
    .catch((error) => {
      console.log(error);
    });
}


export const getAutopopulatedData = async (manufacturerId) => {
  let url = `${IAM_URL}manufacturer/manufacturer-details`;
  if (manufacturerId) {
    url += `?manufacturerId=${manufacturerId}`;
  }
  return _axios
    .get(url)
    .catch((error) => {});
};

export const getQRCode = async (img, type) => {
  return _axios
    .get(`${FORTIFICATION_URL}QrCode/view?key=${img}${".png"}&type=${type}`, {
      responseType: "blob",
    })
    .catch((error) => {});
};
export const downloadQRCode = async (img, type) => {
  return _axios
    .get(
      `${FORTIFICATION_URL}QrCode/download?key=${img}${".png"}&type=${type}`,
      {
        responseType: "blob",
      }
    )
    .catch((error) => {});
};

export const createPremixBatchByFrkUser = async(body, categoryNameToIdMapRedux) => {
  return _axios
  .post(`${FORTIFICATION_URL_RICE}${categoryNameToIdMapRedux['FRK']}/batch/self-declared/premix`, body)
  .catch((error) => {
    console.log(error);
  });
}

export const getPremixManufacturersList = async(categoryIds, type) => {
  return _axios
  .get(`${IAM_URL}manufacturer/self-declared/list?categoryId=${categoryIds}&type=${type}`)
  .catch((error) => {
    console.log(error);
  });
}

export const getPremixManufacturerByLicenseNumber = async(licenseNo,categoryId) => {
  return _axios
  .get(`${IAM_URL_RICE}manufacturer/self-declared/vendor/licenseNo?licenseNo=${licenseNo}&categoryId=${categoryId}`)
  .catch((error) => {
    console.log(error);
  });
}



export const UploadFile = async (body) => {
  return _axios
    .post(
      `${FORTIFICATION_URL}storage/upload?type=FORTIFICATION_FILES&id=1`,
      body,
      { headers: { "Content-Type": "multipart/form-data" } }
    )
    .catch((error) => {});
};

export const DownloadFile = async (body) => {
  return _axios
    .get(
      `${FORTIFICATION_URL}storage/files/Screenshot_20230211-113924_2023_03_06_04_58_58_690.png`,
      body,
      { headers: { "Content-Type": "multipart/form-data" } },
      { responseType: "blob" }
    )
    .catch((error) => {});
};

export const getTypeDetailsById = async (categoryId, type, typeId) => {
  return _axios
    .get(
      `${FORTIFICATION_URL_RICE}${categoryId}/${type}/${typeId}`
      // config
    )
    .catch((error) => {});
};

export const getStateList = async (type) => {
  return _axios
    .get(`${FORTIFICATION_URL}state/?type=${type}`)
    .catch((error) => {});
};

export const updateBatchStatus = async (categoryId, batchId, body, type , result) => {
  return _axios
    .post(`${FORTIFICATION_URL_RICE}${categoryId}/${type}/${batchId}/state/module?sampleTestResult=${result? result:""}`, body)
    .catch((error) => {});
};

export const getSourceCategory = async (categoryId) => {
  return _axios
    .get(`${FORTIFICATION_URL}category/${categoryId}/batch/requirements`)
    .catch((error) => {});
};

export const getSourceCategoryDropdown = async (
  categoryId,
  sourceCategoryId,
  searchString,
  pageNumber,
  pageSize
) => {
  return _axios
    .get(
      `${FORTIFICATION_URL}${categoryId}/lot/source/${sourceCategoryId}?search=${searchString}&pageNumber=${pageNumber}&pageSize=${pageSize}`
    )
    .catch((error) => {});
};

export const getManufacturerCategoryDetails = async (frkType, searchString,categoryId , pageNumber , pageSize, manufacturerId, dispatchType) => {
  return _axios
    .get(`${IAM_URL_RICE}manufacturer/${frkType}/list?search=${searchString}&category_id=${categoryId}&pageNumber=${pageNumber}&pageSize=${pageSize}&manufacturerId=${manufacturerId}&categoryAction=${dispatchType}`)
    .catch((error) => {});
};

export const createLot = async (categoryId, payloadData) => {
  return _axios
    .post(`${FORTIFICATION_URL}${categoryId}/lot`, payloadData)
    .catch((err) => {});
};

export const getLotDetailsData = async (categoryId, lotId) => {
  return _axios
    .get(`${FORTIFICATION_URL}${categoryId}/lot/${lotId}`)
    .catch((error) => {});
};

export const postInspection = async (data) => {
  return _axios
    .post(`${LAB_URL}inspection`,data)
    .catch((error) => {});
};



export const getAllActionData = async (categoryId, type, lotId) => {
  return _axios
    .get(`${FORTIFICATION_URL}${categoryId}/${type}/${lotId}/actions/module`)
    .catch((error) => {});
};

export const getHistory = async (type, batchId) => {
  return _axios
    .get(`${IMMUDB_URL_RICE}batchHistory/${type}/entity/?entityIds=${batchId}`)
    .catch((error) => {});
};

export const UpdateLabSamples = async (body) => {
  return _axios
    .post(`${LAB_URL}17/sample?self=true`, body)
    .catch((error) => {});
};

export const getDownloadedDocument = async (filename) => {
  return _axios
    .get(
      `${FORTIFICATION_URL}storage/get-file/?key=${filename}&type=FORTIFICATION_FILES`,
      { responseType: "blob" }
    )

    .catch((error) => {});
};
export const getLabDownloadedDocument = async (filename, id) => {
  return _axios
    .get(
      `${FORTIFICATION_URL}storage/get-file/?key=${filename}&type=LAB_FILES`,
      { responseType: "blob" }
    )

    .catch((error) => {});
};
export const getWastageInfo = async (categoryId, type,batchId) => {
  return _axios
    .get(
      `${FORTIFICATION_URL}${categoryId}/${type}/${batchId}`
      // config
    )
    .catch((error) => {});
};
export const addWastageInfo = async (categoryId, type, typeId, body) => {
  return _axios
    .post(
      `${FORTIFICATION_URL}${categoryId}/${type}/${typeId}/wastage`,
      body
      // config
    )
    .catch((error) => {});
};
export const getPackagingInfo = async (categoryId, batchId) => {
  return _axios
    .get(
      `${FORTIFICATION_URL}${categoryId}/batch/${batchId}/size-unit`
      // config
    )
    .catch((error) => {});
};
export const addPackagingInfo = async (categoryId, type, batchId, body) => {
  return _axios
    .post(
      `${FORTIFICATION_URL}${categoryId}/batch/${batchId}/size-unit/add/list`,
      body
      // config
    )
    .catch((error) => {});
};
export const editPackagingInfo = async (categoryId, type, batchId, body) => {
  return _axios
    .put(
      `${FORTIFICATION_URL}${categoryId}/batch/${batchId}/size-unit/update/list`,
      body
      // config
    )
    .catch((error) => {});
};

export const getDataFromQRCode = async (categoryId, type, uuid) => {
  try {
    return _axios.get(
      `${FORTIFICATION_URL}${categoryId}/${type}/code/${uuid}/details`
    );
  } catch (error) {}
};

export const getBatchTree = async (caetegoryId, batchId) => {
  return _axios
    .get(`${FORTIFICATION_URL_RICE}${caetegoryId}/batch/history/${batchId}`)
    .catch((error) => {});
};

export const getNodeModalData = async (body) => {
  return _axios.post(`${IMMUDB_URL_RICE}batchHistory`, body).catch((error) => {});
};

export const updateMixes = async (categoryId, batchId, body) => {
  return _axios
    .put(
      `${FORTIFICATION_URL}${categoryId}/batch/${batchId}/usage/update-mixes`,
      body
    )
    .catch((error) => {});
};

export const getMixes = async (categoryId, batchId) => {
  return _axios
    .get(`${FORTIFICATION_URL}${categoryId}/batch/${batchId}/usage`)
    .catch((error) => {});
};

export const getDashboardCount = async () => {
  const body = {
    "fromDate" : null,
    "toDate": null 
  }
  return _axios
    .post(`${FORTIFICATION_URL}dashboard/count`, body)
    .catch((error) => {
    });
};

export const getVendorList = async (categoryId) => {
  return _axios
    .get(`${IAM_URL}manufacturer/material-manufacturers/${categoryId}`)
    .catch((error) => {});
};

export const getTargetManufacturerFromLicense = async (licenseNo, categoryId)=> {
  return _axios
  .get(`${IAM_URL_RICE}manufacturer/fssai/licenseNo?licenseNo=${licenseNo}&categoryId=${categoryId}`)
  .catch((error) => {});
}

export const getVendorFromLicense = async(licenseNo, categoryId)=>{
  return _axios
  .get(`${IAM_URL_RICE}manufacturer/material-manufacturers/licenseNo?licenseNo=${licenseNo}&categoryId=${categoryId}`)
  .catch((error) => {});
}

export const addnewVendor = async (body) => {
  return _axios
    .post(`${IAM_URL}manufacturer/material-manufacturers`, body)
    .catch((error) => {});
};

export const lotDispatch = async (body, categoryId) => {
  return _axios
    .post(`${FORTIFICATION_URL}${categoryId}/lot/target?externalDispatch=${body.externalDispatch}`, body)
    .catch((error) => {});
};

export const fetchDashboardMasterData = async (params, selectedTab) => {
  let empanelStr = ''
  empanelStr = (selectedTab == '1')? 'manufacturers' : 'empanel'
  return _axios
    .get(`${IAM_URL}${empanelStr}/categories/count`, {params})
    .catch((error) => {});
};

export const fetchLabMasterData = async (params) => {
  return _axios
    .get(`${LAB_URL}dashboard/labs/count`, {params})
    .catch((error) => {});
};
export const fetchProductionMasterData = async (params) => {
  const body = {
    "fromDate" : null,
    "toDate": null 
  }
  return _axios
    .post(`${FORTIFICATION_URL_RICE}dashboard/masters/categories/quantity`,body, {params})
    .catch((error) => {});
};

export const fetchProductionMasterDataNew = async (responseType, fromDate, toDate, categoryId, type, geoId, categoryNameToIdMapRedux) => {
  let responseType1 = ''
  const body = {
    'fromDate' : fromDate,
    'toDate': toDate, 
    'categoryId': categoryId,
    'type': type,
    'geoId': geoId
  }

  responseType1 = ((categoryNameToIdMapRedux['WAREHOUSE'] == categoryId) && !!categoryNameToIdMapRedux['WAREHOUSE']) ? 'warehouse' : responseType

  return _axios
  .post(`${FORTIFICATION_URL_RICE}dashboard/geography/quantity/${responseType1}`,body)
  .catch((error) => {console.log(error)});
}

export const fetchWarehouseAggregateData = async (fromDate, toDate, categoryId, type, geoId, selectedTab) => {
  let idKey = (type == 'state') ? 'targetStateGeoId' : 'geoId'
  let body = {}
  let urlStr = ''
  if(selectedTab == '1'){
    urlStr = 'geography'
    body = {
      'fromDate' : fromDate,
      'toDate': toDate, 
      'categoryId': categoryId,
      'type': type,
      'geoId': geoId
    }
  } else if(selectedTab == '2'){
    urlStr = 'empanel'
    body = {
      'fromDate' : fromDate,
      'toDate': toDate, 
      'categoryId': categoryId,
      'type': type,
      [idKey]: geoId
    }
  }
  return _axios
  .post(`${FORTIFICATION_URL_RICE}dashboard/${urlStr}/aggregate/warehouse`,body)
  .catch((error) => {console.log(error)});
}

export const fetchEmpaneledData = async (fromDate, toDate, sourceStateGeoId, responseType, geoId, type) => {
  const body = {
    'fromDate' : fromDate,
    'toDate': toDate, 
    'empanelledStateGeoId': sourceStateGeoId,
    'type': type,
    'geoId': geoId
  }
  return _axios
  .post(`${FORTIFICATION_URL_RICE}dashboard/empanel/aggregate/${responseType}`,body)
  .catch((error) => {console.log(error)});
}

export const fetchLabTestingQuantities = async (params) => {
  const body = {
    "fromDate" : null,
    "toDate": null 
  }
  return _axios
    .post(`${FORTIFICATION_URL_RICE}dashboard/masters/categories/testing-quantities`, body , {params})
    .catch((error) => {});
};
export const getCanSkipRawMaterial = async (manufacturerId,categoryId) => {
  return _axios
  .get(`${IAM_URL}manufacturer-category/can-skip-raw-materials?manufacturerId=${manufacturerId}&categoryId=${categoryId}`)
    .catch((error) => {});
};

export const getManufacturerFilterData = async (
  searchCriteriaList,
  pageNumber,
  pageSize
) => {
  return _axios
    .post(
      `${IAM_URL}manufacturer/filter/list?pageNumber=${pageNumber}&pageSize=${pageSize}`,
      searchCriteriaList
    )
    .catch((error) => {});
};
export const getPurchaseOrderDetails = async (sourceManufacturerId, targetManufacturerId) => {
  return _axios
    .get(`${IAM_URL}purchase-order/get-by-mapping?sourceManufacturerId=${sourceManufacturerId}&targetManufacturerId=${targetManufacturerId}`)
    .catch((error) => {});
};

export const getLotTree = async (caetegoryId, lotId) => {
  return _axios
    .get(`${FORTIFICATION_URL_RICE}${caetegoryId}/lot/history/${lotId}`)
    .catch((error) => {});
};
