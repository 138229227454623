import { EXP_DATE, HEADER_NAME, MANF_DATE, QUANTITY_USED, STATUS } from "../../../textConstant";
import moment from "moment";
export const INFO_HEADER = [
  {
    field: "size",
    headerName: "Size",
    hide: false,
    flex:1,

  },

  {
    field:"quantity",
    headerName: "Quantity",
    maxWidth: 300,
    hide: false,
    flex:3,

  },
  {
    field: "uom",
    headerName: "uom",
    maxWidth: 300,
    hide: false,
    flex:3,

  },
 
];
