import React, { useState } from "react";
import Box from "@mui/material/Box";
import { CircularProgress } from "@mui/material";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import GridItem from "../../GridItem/GridItem";
import Paper from "@mui/material/Paper";
import "../../../App.css";
import { AddWastageForm } from "./AddWastage";
import AddButton from "../../../components/CommonFilterCreateButton/AddButton";
import useTranslatewrapper from "../../../components/useTranslateWrapper";

const Wastage = (props) => {
  const {translate} = useTranslatewrapper()
  const {remainingQuantity, rowWastageData, columnDefs, refreshWastageInfo, showButton,isInspector,isMonitor, isSuperAdmin, inspectModalData, disableVendorData } = props;
  const [loader, setLoader] = React.useState(false);

  const [gridApi, setGridApi] = useState(null);

  const RowSelectionType = "single";

  function onGridReady(params) {
    setGridApi(params.api);
  }

  const [openForm, setopenForm] = React.useState(false);

  const handleClickOpen = () => {
    setopenForm(true);
  };
  const handleClose = (value) => {
    setopenForm(false);
  };

  return (
    <div>
      {loader ? (
        <Box className="main-content-overlay" sx={{ height: "100vh" }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box component={Paper} className="form-div ">
          <Box>
            <Box sx={{ width: "100%", display: "table" }}>
              {openForm ? (
                <div>
                  <AddWastageForm open={openForm}
                    remainingQuantity={remainingQuantity}
                    onClose={handleClose} refreshWastageInfo={refreshWastageInfo}
                    inspectModalData={inspectModalData}
                     />
                </div>
              ) : (
                <AddButton onClick={handleClickOpen} disabled={!showButton || isInspector || isSuperAdmin || isMonitor}
                  text={translate('addWastage')} />
              )}
            </Box>
            <Box>
              <GridItem
                onGridReady={onGridReady}
                rowData={rowWastageData}
                rowSelection={RowSelectionType}
                columnDefs={columnDefs}
                pagination={false}
                localeText={{noRowsToShow : translate('noRowsToShow')}}
              />
            </Box>
          </Box>
        </Box>
      )}
    </div>
  );
};

export default Wastage;
