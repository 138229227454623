import HttpService from "./HttpService";
const _axios = HttpService.getAxiosClient();

const FORTIFICATION_URL = process.env.REACT_APP_BASE_FORTIFICATION_URL;
const IAM_URL = process.env.REACT_APP_BASE_IAM_URL;
const LAB_URL = process.env.REACT_APP_BASE_LAB_URL;

// var data = {
//     "roleCategoryIds": [29, 30],
//     "roleCategoryType": "MODULE"
// }

export const getAllBatchesForSerach = async (categoryId, searchString) => {
  return (
    _axios.get(
      `${FORTIFICATION_URL}${categoryId}/batch/batch-search?search=${searchString}`
    )
    .catch((error) => {
       
    })
  )
}

export const getAllBatches = async (
  categoryId,
  manufacturedId,
  searchCriteriaList
) => {
  return (
    _axios
      
      .post(
        `${FORTIFICATION_URL}${categoryId}/batch/list?manufacturedId=${manufacturedId}`,
        searchCriteriaList
      )
      .catch((error) => {
         
      })
  );
};

export const createBatch = async (categoryId, body) => {
  return _axios
    .post(`${FORTIFICATION_URL}${categoryId}/batch`, body)
    .catch((error) => {
       
    });
};

export const getUomData = async () => {
  return _axios.get(`${FORTIFICATION_URL}uom`).catch((error) => {
     
  });
};
export const getSizeUnit = async () => {
  return _axios.get(`${FORTIFICATION_URL}sizeUnit`).catch((error) => {
     
  });
};
export const getCategoryDocs = async (categoryId) => {
  return _axios
    .get(`${FORTIFICATION_URL}category/${categoryId}/document`)
    .catch((error) => {
       
    });
};

export const getStateList = async () => {
  return _axios.get(`${FORTIFICATION_URL}state`).catch((error) => {
     
  });
};

export const labTestDetails = (batchId, type, labId) => {
  return (
    _axios
      .get(`${LAB_URL}${labId}/sample/${type || "batch"}/${batchId}`)
      // .get(`/mock_jsonx_data/data.json`)
      .catch((error) => {
         
      })
  );
};

export const selfTestedMethods = async (categoryId) => {
  return _axios
    .get(
      `${LAB_URL}document/${categoryId}/sample/requirements?type=PHYSICAL`
    )
    .catch((error) => {
       
    });
};
export const labTestStatus = (batchId, type, labId) => {
  return (
    _axios
      .get(`${LAB_URL}inspection/sample/status?sampleType=${type || "batch"}&id=${batchId}`)
      // .get(`/mock_jsonx_data/data.json`)
      .catch((error) => {
         
      })
  );
};