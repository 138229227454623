import React, { useEffect, useState } from "react";
// import userDetailsSchema from "./UserDetailsSchema.json";
import useUserDetailsSchemaFn from "./UserDetailsSchema";
import UserDetailsUiSchema from "./UserDetailsUiSchema.json";
// import UserDetailsEditSchema from "./UserDetailsEditSchema.json";
import useUserDetailsEditSchemaFn from "./UserDetailsEditSchema";
import validator from "@rjsf/validator-ajv8";
import { Form } from "@rjsf/mui";
import { useAppDispatch } from "../../../services/StoreHooks";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "./UserDetails.css";
import {
  getAllCategory,
  manufacturerDetails,
  createUser,
  getUserDetails,
  editUserDetails,
  getAllStatus,
} from "../../../services/Manage_service";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Typography, Box, Stack, Modal } from "@mui/material";
import RoleCategoryMap from "../RoleCategoryMap/RoleCategoryMap";
import PasswordWidget from "../PasswordWidget/PasswordWidget";
import { BACK_BUTTON, CREATE_NEW_BUTTON_TEXT, DETAILS_PAGE } from "../../../textConstant";
import { MAIN_BLUE } from "../../../color";
import { useSelector } from "react-redux";
import ResetPasswordModal from "../ResetPasswordModal/ResetPasswordModal";
import UserExistCheck from "../UserExistCheck/UserExistCheck";
import { set } from "lodash/fp";
import useTranslatewrapper from "../../../components/useTranslateWrapper";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
};

function UserDetails() {
  const { translate } = useTranslatewrapper();

  const userDetailsSchema = useUserDetailsSchemaFn()

  const UserDetailsEditSchema = useUserDetailsEditSchemaFn()

  const [formData, setFormData] = useState({});
  const[Names,setNames] = useState({});
  const filterDataRedux = useSelector((state) => state.filterUserData);
  const [categories, setCategories] = useState();
  const [schema, setSchema] = useState(null);
  const [errorArray, setErrorArray] = useState([]);
  const [manufacturerId, setManufacturerId] = useState("");
  const [userValidation, setUserValidation]=useState();

  const [autoPopulateData, setAutoPopulateData] = useState({});
  const [createOrSaveDisabled, setCreateOrSaveDisable] = useState(false);
  const [roleCategoryMap, setRoleCategoryMap] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [updateStatus,setUpdateStatus]= useState(false)
  const [roleCategoryData, setRoleCategoryData] = useState([]);;
  const [passwordObject, setPasswordObject] = useState({
    Password: "",
    ConfirmPassword: "",
    isValidPassword: false
  });
  const [checkNameChange, setCheckNameChange] = useState(false);
  const location=useLocation();
  const pathNameCapitalize = location.pathname.split("/")[2];
  const isInspectionUser = location.pathname.split("/")[3]==="inspectionusers";
  const categoriesRedux = useSelector((state) => state.categoriesData);

  const [userName, setUserName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [statusData, setStatusData] = useState([]);
  const navigate = useNavigate();
  const setModalOpen = (value) => {
    setOpenModal(value);
  };
  const setPasswordData = (value) => {
    setPasswordObject(value);
  };
  const setUserNameData = (value) => {
    setUserName(value);
  };
  const setEmailData = (value) => {
    setEmailId(value);
  };

  const setTableData = (value) => {
    setRoleCategoryMap(value);
  };
  const params = useParams();
  const dispatch = useAppDispatch();
  const dispatchNotification = (typ, msg) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      data: {
        open: true,
        type: typ,
        message: msg,
      },
    });
  };

useEffect(() => {
  fetchStatusData();
}, []);
  useEffect(() => {
    getData();
  }, [statusData]);
   useEffect(() => {

    if (params?.id && statusData.length>0) {
      fetchUserData();
    }
  }, [categories,statusData]);
  useEffect(()=>{},[checkNameChange])

  const roleCategoryMapValidate = (arrErr) => {
    if (roleCategoryMap.length === 0) {
      arrErr.push(`Atleast one Role must be assigned to User`);
    } else {
      roleCategoryMap.forEach((map) => {
        if (!map.category) arrErr.push(`Please Select Category`);
        if (map.role.length === 0) arrErr.push(`Please Select Role`);
      });
    }
  };

  const handleSubmit = () => {
    let arrErr = [];
    roleCategoryMapValidate(arrErr);
    if (formData.FirstName.trim().length === 0)
      arrErr.push(`First Name can not be Empty`);
    if (formData.LastName.trim().length === 0)
      arrErr.push(`Last Name can not be Empty`);
    setErrorArray(arrErr);
    let msg = "";
    arrErr.map((err) => (msg += err + "\n"));
    if (arrErr && arrErr.length > 0) {
      dispatchNotification("error", arrErr);
    } else {
      if (params?.id) {
        updateUser();
      } else {
        createNewUser();
      }
    }
  };

  const createNewUser = async () => {
    let mapArray = [];
    roleCategoryMap.map((item) =>
      mapArray.push({
        roleName: item.role,
        categoryName: item.category.toUpperCase(),
        categoryId: item.categoryId,
        roleCategoryType: "MODULE",
      })
    );

    const data = {
      firstName: formData.FirstName,
      lastName: formData.LastName,
      email: emailId,
       manufacturerId: isInspectionUser?null: manufacturerId,
      labId: isInspectionUser?null:0,
      userName: userName,
      password: passwordObject.Password,
      rolesMap: mapArray,
      statusId: formData.status?.id,
    };
    if(!passwordObject.isValidPassword){
      return
    }
    const Response = await createUser(data);
    if (Response.status === 201) {
      navigate(-1);
      dispatchNotification("success", [translate('userCreatedSuccessfully')]);
      setCreateOrSaveDisable(true);
    }
  };
  const fetchStatusData=async () =>{
    const statusApi=await getAllStatus();
    statusApi?.data?.data && setStatusData(statusApi?.data?.data.filter(d=> d.name !== 'Testing'));
  }

  const fetchUserData = async () => {
    const userDetails = await getUserDetails(params?.id);
    const userData = {};
    const userCredentials = {};
    if (userDetails.data && statusData.length>0) {
      userData["FirstName"] = userDetails.data?.firstName;
      userData["LastName"] = userDetails.data?.lastName;
      userCredentials["UserName"] = userDetails.data?.userName;
      userCredentials["EmailId"] = userDetails.data?.email;
      userData["status"]= userDetails.data?.status;
    }
    setUserName(userDetails.data?.userName);
    setEmailId(userDetails.data?.email);
    let dataArray = [];

    userDetails.data?.roleCategory.map((item) => {
      dataArray.push({
        category: item.category,
        role: item.role.name,
        rolesArray: categories[item.category]?.roles,
        categoryId: categories[item.category]?.id,
        roleCategoryId: item.id,
        categoryArray: Object.keys(categories).map((element) =>
          element.toUpperCase()
        ),
      });
    });
      let newSchema = set(
        ["properties", "status"],
        {
          type: 'string',
        enumNames: statusData?.map((item) => translate(item.name)),
        enum: statusData?.map((item) => ({id:item.id,name:item.name})),
        title: translate('status')
          // default: statusData?.map((item) => ({id:item.id,name:item.name})).filter(item=>item.name===userDetails.data?.status.name)[0],
        },
        UserDetailsEditSchema
      );
    setRoleCategoryData(dataArray);
    setRoleCategoryMap(dataArray);
    setSchema(newSchema);
    setFormData(userData);
    setNames(userData);
    setAutoPopulateData(userCredentials);
  };

  const updateUser = () => {
    let arrErr = [];
    if (formData.FirstName.trim().length === 0)
      arrErr.push(`First Name can not be Empty`);
    if (formData.LastName.trim().length === 0)
      arrErr.push(`Last Name can not be Empty`);
    setErrorArray(arrErr);
    let msg = "";
    arrErr.map((err) => (msg += err + "\n"));
    if (arrErr && arrErr.length > 0) {
      dispatchNotification("error", arrErr);
    } else {
      userDetailsUpdate();
    }
  };

  const userDetailsUpdate = async () => {
    let mapArray = [];
    roleCategoryMap.map((item) =>
      mapArray.push({
        roleName: item.role,
        categoryName: item.category.toUpperCase(),
        categoryId: item.categoryId,
        roleCategoryType: "MODULE",
      })
    );
    const data = {
      id: params?.id,
      firstName: formData.FirstName,
      lastName: formData.LastName,
      manufacturerId: manufacturerId,
      labId: 0,
      statusId: formData.status?.id,
    };
    const Response = await editUserDetails(params?.id, data);
    if (Response.status === 202 || Response.status === 200) {
      navigate(-1);
      dispatchNotification("success", ["User Updated Successfully"]);
    }
  };

  const getData = async () => {
   const categoryData= categoriesRedux.categories?.filter((item)=>item?.independentBatch===false);
  
  const convertArrays = () => {
    const convertedArray = {};

    categoryData.slice(1).forEach(item => {
      convertedArray[item.name] = {
        id: item.id,
        roles: ["INSPECTION"]
      };
    });

    return convertedArray;
  };
 const inspectionData= convertArrays();
    const getCategories = await getAllCategory();
    const categoryList =  getCategories.data;
isInspectionUser?setCategories(inspectionData):
    (getCategories.data && setCategories(categoryList));

    if (!params?.id) {
      let newSchema = set(
        ["properties", "status"],
        {
          type: 'string',
          enumNames: statusData?.map((item) => translate(item.name)),
          enum: statusData?.map((item) => ({id:item.id,name:item.name})),
          default: statusData?.map((item) => ({id:item.id,name:item.name})).filter(item=>item.name==="Active")[0],
          title: translate('status')
        },
        userDetailsSchema
      );
      setSchema(newSchema);
    }

    const manufacturerData = await manufacturerDetails();
    manufacturerData.data && setManufacturerId(manufacturerData.data?.id);
  };

  const roleCategoryWidget = () => {
    return (
      roleCategoryData && (
        <RoleCategoryMap
          setMap={setTableData}
          id={params?.id}
          categoryMapData={categories}
          roleCategoryData={roleCategoryData}
          autoPopulateData={autoPopulateData}
        />
      )
    );
  };

  const passwordWidget = () => {
    return <PasswordWidget setPasswordData={setPasswordData} />;
  };

  const UserExistCheckWidget = () => {
    return (
      <UserExistCheck
        setUserNameData={setUserNameData}
        setEmailData={setEmailData}
        autoPopulateData={autoPopulateData}
        setUserValidation={setUserValidation}

      />
    );
  };

  const widgets = { roleCategoryWidget, passwordWidget, UserExistCheckWidget };
  const setFilters = (filterData) => {
    var json = filterDataRedux.searchCriteriaList;
    var json1 = structuredClone(json);

    json1[0].value = "";
    json1[1].value = "";
    json1[2].value = "";

    dispatch({
      type: "UPDATE_USER_FILTER",
      data: json1,
    });
  };

 const checkName =(e)=>{
  if(e.formData.FirstName !== Names.FirstName || e.formData.LastName !== Names.LastName ){setCheckNameChange(true) }
  else{setCheckNameChange(false)}
 }


  return (
    schema &&
    categories && (
      <div className="user-details-form">
        {params?.id ? (
          <Typography
            align="center"
            mt={2}
            variant="h6"
            sx={{ color: MAIN_BLUE, fontWeight: "600" }}         >
            {translate(pathNameCapitalize.toUpperCase() + DETAILS_PAGE)}
          </Typography>
        ) : (
          <Typography
            align="center"
            mt={2}
            variant="h6"
            sx={{ color: MAIN_BLUE, fontWeight: "600" }}         >
            {translate(pathNameCapitalize.toUpperCase() + " " + CREATE_NEW_BUTTON_TEXT.toUpperCase())}
          </Typography>
        )}
        <Box marginLeft={3} className="main-content-div" style={{ position: "relative" }}>
          <Button
            onClick={(e) => {
              navigate(-1);
              setFilters();
            }}
          >
            <ArrowBackIcon />
            <Typography
              sx={{
                fontSize: "13px",
                textDecoration: "underline",
                fontWeight: "600",
                fontSize: "12px",
                lineHeight: "15px",
                color: "#0075FF",
              }}
            >
              {BACK_BUTTON}
            </Typography>
          </Button>

          <Form
            schema={schema}
            validator={validator}
            uiSchema={UserDetailsUiSchema}
            formData={formData}
            widgets={widgets}
            noValidate={true}
            className="batch-form-styling"
            onChange={(e) => {
              setFormData(e.formData);
              checkName(e);
              setUpdateStatus(true)
            }}
            onSubmit={() => {
              handleSubmit();
            }}
          >
            {params.id ? (
              <div>
                <Stack direction="row" spacing={2}>
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={!(createOrSaveDisabled || checkNameChange ||updateStatus)}
                  >
                    {translate('saveChanges')}
                  </Button>
                  <Button
                    variant="contained"
                    disabled={createOrSaveDisabled}
                    onClick={() => setOpenModal(true)}
                  >
                    {translate('resetPassword')}
                  </Button>
                </Stack>
                <Modal
                  open={openModal}
                  onClose={() => {
                    setOpenModal(false);
                  }}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <ResetPasswordModal
                      id={params?.id}
                      setModalOpen={setModalOpen}
                    />
                  </Box>
                </Modal>
              </div>
            ) : (
              <div className="save-button-div">
                <Button
                  type="submit"
                  variant="contained"
                  disabled={createOrSaveDisabled}
                >
                  {translate('addUser')}
                </Button>
              </div>
            )}
          </Form>
        </Box>
      </div>
    )
  );
}

export default UserDetails;
