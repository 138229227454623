import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { Stack } from "@mui/system";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Box, Button, Grid, Typography, Divider } from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import "./DialogElements.css";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { getDistrict, getManufacturerGeoList } from "../../services/adminService";
import { useLocation } from "react-router-dom";
import {
  APPLY_BUTTON_TEXT,
  ENTITY_TYPE,
  EXP_DATE,
  MANF_DATE,
  SELECT_FILTER,
  STATUS,
  TEST_TYPE
} from "../../textConstant";
import moment from "moment";
import useTranslatewrapper from "../useTranslateWrapper";
export const SimpleDialog = (props) => {
  const {translate} = useTranslatewrapper()
  const { chipsState, onClose, selectedValue, isInspector, open, options, filterStates, testTypeOptions, hideDateFilter, categories, manufactureOptions, targetmanufactureOptions, isLot, entityTypeOptions, hideState, state, district, setSearchCriteria, searchCriteria, setDistrict, manufacturerTypeData, vendorTypeData } = props;
  const dispatch = useDispatch();
  let defaultValues;
  // const [district, setDistrict] = useState([]);
  const location = useLocation();
  const showManagePageFilter = location.pathname.split("/")[2] === "manage";

  const isAdminManufList = location.pathname==="/admin/iam/manufacturer"

  const isAdminLabList = (location.pathname === '/admin/lab/labs')

  const stateData = state?.map((item) => ({ label: item.name, value: item.id }));

  const dateValue = (date) => {
    if (date) {
      return moment.utc(date).local().format("yyyy/MM/DD");
    } else {
      return "";
    }
  };

  const dispatchNotification = (typ, msg) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      data: {
        open: true,
        type: typ,
        message: msg,
      },
    });
  };

  const handleApply = () => {

    if (filterDataDate.mnfStartDate > filterDataDate.mnfEndDate && filterDataDate.mnfEndDate !== null) {
      dispatchNotification("error", ["Manufacturing Start Date cannot be less than End Date"]);
    }
    else if (filterDataDate.expStartDate > filterDataDate.expEndDate && filterDataDate.expEndDate !== null) {
      dispatchNotification("error", ["Expiry Start Date cannot be less than End Date"]);
    }
    else {
      onClose(selectedValue);
      setFilters();
      setDistrictDataArr([])
    }

  };
  const handleClear = () => {
    setSelectedDistrict("");
    setSelectedState("");

    setDefaultStates([null]);
    setDistrictDataArr([])
    handleSelectChange([]);

    setDefaultTestTypeStates([null]);
    setDefaultCategoryIds([null]);
    setDefaultEntityTypestates([null]);
    handleTestType([]);
    handleEntityType([]);

    setdefaultManufacturer([null]);
    handleManufacturerIds([]);
    setdefaultTargetManufacturer([null]);
    handleTargetManufacturerIds([]);
    setFilterDataDate({
      expStartDate: "",
      expEndDate: "",
      mnfEndDate: "",
      mnfStartDate: "",
    });
  };



  defaultValues = [];
  chipsState?.stateIds?.map((dataId) =>
    defaultValues.push(options.filter((elem) => elem.id === dataId)[0])
  );
  const defaultTestTypeValues = [];
  chipsState?.testTypes?.map((dataId) =>
    defaultTestTypeValues.push(testTypeOptions.filter((elem) => elem.id === dataId)[0])
  );

  const defaultCategoryValues = [];
  chipsState?.categoryIds?.map((dataId) =>
    defaultCategoryValues.push(categories.filter((elem) => elem.id === dataId)[0])
  );
  const defaultmanufacturerValues = [];
  chipsState?.manufacturerIds?.map((dataId) =>
    defaultmanufacturerValues.push(manufactureOptions?.filter((elem) => elem.id === dataId)[0])
  );
  const defaultStateFilter = [];
  chipsState?.stateData?.map((dataId) =>
    defaultStateFilter.push(state.filter((elem) => elem.id === dataId)[0])

  )

  const defaultTargetmanufacturerValues = [];
  chipsState?.targetManufacturerIds?.map((dataId) =>
    defaultTargetmanufacturerValues.push(targetmanufactureOptions?.filter((elem) => elem.id === dataId)[0])
  );
  const defaultEntityTypeValues = [];
  chipsState?.entityTypes?.map((dataId) =>
    defaultEntityTypeValues.push(entityTypeOptions.filter((elem) => elem.id === dataId)[0])
  );
  const [defaultStates, setDefaultStates] = React.useState(
    chipsState?.stateIds ? chipsState?.stateIds : []
  );

  const [defaultTestTypeStates, setDefaultTestTypeStates] = React.useState(
    chipsState?.testTypes ? chipsState?.testTypes : []
  );
  const [defaultEntityTypestates, setDefaultEntityTypestates] = React.useState(
    chipsState?.entityTypes ? chipsState?.entityTypes : []
  );

  const [defaultCategoryIds, setDefaultCategoryIds] = React.useState(
    chipsState?.categoryIds ? chipsState?.categoryIds : []
  );
  
  const [defaultDistrictIds, setDefaultDistrictIds] = React.useState(chipsState?.districtId || []);

  const [defaultmanufacturer, setdefaultManufacturer] = React.useState(
    chipsState?.manufacturerIds ? chipsState?.manufacturerIds : []
  );
  const [defaultManufacturerType,setdefaultManufacturerType]=useState('');
  const [defaultVendorType,setdefaultVendorType]=useState('');

  const [defaultTargetManufacturer, setdefaultTargetManufacturer] = React.useState(
    chipsState?.targetManufacturerIds ? chipsState?.targetManufacturerIds : []
  );
  const [selectedValueNew, setSelectedValueNew] = React.useState(defaultValues);
  const [getList, setGetList] = React.useState(manufactureOptions);

  const [selectedTestType, setSelectedTestType] = React.useState(defaultTestTypeValues);
  const [selectedEntityType, setSelectedEntityType] = React.useState(defaultEntityTypeValues);
  const [selectedCategoryIds, setSelectedCategoryIds] = React.useState(defaultCategoryValues);
  const [selectedManufacturerNew, setSelectedManufacturerNew] = React.useState(defaultmanufacturerValues);
  const [selectedTargetManufacturerNew, setSelectedTargetManufacturerNew] = React.useState(defaultTargetmanufacturerValues);
  const [selectedState, setSelectedState] = React.useState(defaultStateFilter);
  const [selectedDistrict, setSelectedDistrict] = React.useState("");
  const [selectedManufacturerType,setSelectedManufacturerType]=useState();
  const [selectedVendorType,setSelectedVendorType]=useState();
  const [districtDataArr, setDistrictDataArr] = useState([])




  const handleSelectChange = (values) => {
    setSelectedValueNew(values);
    let arrId = [];
    values &&
      values?.length != 0 &&
      values?.map((value) => arrId.push(value.id));
    setDefaultStates(arrId);
  };

  const handleTestType = (values) => {
    setSelectedTestType(values);
    let arrId = [values.id];

    // values &&
    //   values?.length != 0 &&
    //   values?.map((value) => arrId.push(value.id));
    setDefaultTestTypeStates(arrId);

  }
  const handleEntityType = (values) => {
    setSelectedEntityType(values);
    let arrId = [];
    values &&
      values?.length != 0 &&
      values?.map((value) => arrId.push(value.id));
    setDefaultEntityTypestates(arrId);
  }

  const handleCategoryIds = (values) => {
    setSelectedCategoryIds(values);
    let arrId = [];

    values &&
      values?.length != 0 &&
      values?.map((value) => arrId.push(value.id));
    setDefaultCategoryIds(arrId);

  }
  const handleManufacturerIds = (values) => {
    setSelectedManufacturerNew(values);
    let arrId = [];

    values &&
      values?.length != 0 &&
      values?.map((value) => arrId.push(value.id));
    setdefaultManufacturer(arrId);

  }
  
  const handleManufacturerTypeId = (values) => {
    setSelectedManufacturerType(values);
    let arrId = [];

    // values &&
    //   values?.length != 0 &&
    //   values?.map((value) => arrId.push(value.label));
    setdefaultManufacturerType(values.label.toUpperCase());

  }
  const handleVendorTypeId = (values) => {
    setSelectedVendorType(values);
    let arrId = [];
    // values &&
    //   values?.length != 0 &&
    //   values?.map((value) => arrId.push(value.label));
    setdefaultVendorType(values["label"]);
    
  }
  
  const getDistrictData = async (id) => {
    const DistrictData = await getDistrict(id);
    DistrictData && setDistrict(DistrictData.data.data.map((item) => ({ label: item.name, value: item.geoId })));
    DistrictData && setDistrictDataArr(DistrictData.data.data.map((item) => ({ label: item.name, value: item.geoId })))

  };
  const handleStateId = (values) => {
    setSelectedState(values);
    setSelectedDistrict("");
    setSelectedManufacturerNew("")

    handlemanufacturers(values.value)
    let arrId = [];
    
    getDistrictData(values.value);


  }
  const handleDistrictId = (values) => {
    setSelectedDistrict(values);
    handlemanufacturers(selectedState.value, values.value);
    let arrId = [];
    setSelectedManufacturerNew("")

    values &&
      values?.length > 0 &&
    values?.map((value) => arrId.push(Number(value.value)));
  setDefaultDistrictIds(arrId);



  }
  const handlemanufacturers = async (stateId, distId) => {
    const list = await getManufacturerGeoList(stateId, distId);
    setGetList(list.data.data.map((list) => {
      return {
        value: list.name,
        label: list.name,
        id: list.id,
      };
    }))
  }




  const handleTargetManufacturerIds = (values) => {
    setSelectedTargetManufacturerNew(values);
    let arrId = [];

    values &&
      values?.length != 0 &&
      values?.map((value) => arrId.push(value.id));
    setdefaultTargetManufacturer(arrId);

  }
  const [filterDataDate, setFilterDataDate] = React.useState({
    expStartDate: chipsState?.dateOfExpiry
      ? Date.parse(chipsState?.dateOfExpiry?.split("-")[0])
      : null,
    expEndDate: chipsState?.dateOfExpiry
      ? Date.parse(chipsState?.dateOfExpiry?.split("-")[1])
      : null,
    mnfEndDate: chipsState?.dateOfManufacture
      ? Date.parse(chipsState?.dateOfManufacture?.split("-")[1])
      : null,
    mnfStartDate: chipsState?.dateOfManufacture
      ? Date.parse(chipsState?.dateOfManufacture?.split("-")[0])
      : null,
  });


  const filterDataRedux = useSelector((state) => state.filterData);

  const setFilters = () => {

    // const newState = { ...searchCriteria };
    // const stateIdIndex = newState.searchCriteriaList.findIndex(item => item.key === 'stateId');
    // const districtIdIndex = newState.searchCriteriaList.findIndex(item => item.key === 'districtId');

    // if (stateIdIndex !== -1) {
    //   newState.searchCriteriaList[stateIdIndex].value = selectedState.value;
    //   setSearchCriteria(newState);
    // }
    // if (districtIdIndex !== -1) {
    //   newState.searchCriteriaList[districtIdIndex].value = selectedDistrict.value;
    //   setSearchCriteria(newState);
    // }

    var json = filterDataRedux.searchCriteriaList;

    var json1 = structuredClone(json);

    if(showManagePageFilter){
      if (defaultCategoryIds.length>0) {
        json1[1].key = "categories";

        json1[1].value = defaultCategoryIds;
  
      } else {
        // json1[2].value = [null];
      }

      if (selectedState) {
        json1[2].key = "states";

        json1[2].value = [selectedState.value];
  
      } else {
        // json1[2].value = [null];
      }
      json1[3].key = "districts";

      if (defaultDistrictIds.length>0) {

        json1[3].value = defaultDistrictIds;
  
      } else {
        // json1[3].value = [null];
      }
      if (defaultManufacturerType!="") {
        json1[4].key = "manufacturerType";

        json1[4].value = defaultManufacturerType;
  
      } else {
        // json1[2].value = [null];
      }
      if (defaultVendorType!="") {
        json1[5].key = "vendorType";

        json1[5].value = defaultVendorType;
  
      } else {
        // json1[2].value = [null];
      }
      // if (category.length != 0) {
      //   json1[3].value = selectedDistrict.value;
  
      // } else {
      //   // json1[3].value = [null];
      // }


    } else if(isAdminManufList){

      if (defaultStates.length != 0) {
        json1[3].value = defaultStates;
  
      } else {
        json1[3].value = [null];
      }
  
      if (defaultTestTypeStates.length > 0) {
        json1[4].value = defaultTestTypeStates
      } else {
        json1[4].value = [null];
      }
      
      if (selectedState) {
        let foundState = false
        for (let obj of json1 ){
          if(obj.key == "states"){
            obj.value = [selectedState.value]
            foundState = true
          }
        }  
        if(!foundState){
          json1.push({
            key:"states",
            value:[selectedState.value]
          })
        }
  
      }

      if (defaultManufacturerType!="") {
        // json1[4].key = "manufacturerType";

        // json1[4].value = defaultManufacturerType;

        let foundState = false
        for (let obj of json1 ){
          if(obj.key == "manufacturerType"){
            obj.value = defaultManufacturerType.toUpperCase()
            foundState = true
          }
        }  
        if(!foundState){
          json1.push({
            key:"manufacturerType",
            value: defaultManufacturerType.toUpperCase()
          })
        }        
      }

      if (defaultDistrictIds.length>0) {
        // json1[3].value = defaultDistrictIds;
        let foundKey = false
        for (let obj of json1 ){
          if(obj.key == "districts"){
            obj.value = defaultDistrictIds
            foundKey = true;
          }
        }
        if(foundKey == false){
        json1.push({
          key:"districts",
          value:defaultDistrictIds
        })
        }
      }

      if (defaultVendorType!="") {

        let foundKey = false
        for (let obj of json1 ){
          if(obj.key == "vendorType"){
            obj.value = defaultVendorType
            foundKey = true;
          }
        }
        if(foundKey == false){
        json1.push({
          key:"vendorType",
          value:defaultVendorType
        })
        }
  
      }
      
  
  
  if (defaultCategoryIds.length > 0) {
    json1[5].value = defaultCategoryIds

    let foundKey = false
    for (let obj of json1 ){
      if(obj.key == "categories"){
        obj.value = defaultCategoryIds
        foundKey = true;
      }
    }
    if(foundKey == false){
    json1.push({
      key:"categories",
      value:defaultCategoryIds
    })
    }
  } else {
    json1[5].value = [null];
  }
  if (defaultmanufacturer.length > 0) {
    json1[7].value = defaultmanufacturer
  } else {
    json1[7].value = [null];
  }
  
  if (defaultTargetManufacturer.length > 0) {
    json1[8].value = defaultTargetManufacturer
  } else {
    json1[8].value = [null];
  }
  if (defaultEntityTypestates.length > 0) {
    json1[9].value = defaultEntityTypestates
  } else {
    json1[9].value = [null];
  }
  
  
  json1[1].value = `${dateValue(
    filterDataDate.mnfStartDate
  )}${"-"}${dateValue(filterDataDate.mnfEndDate)}`;
  json1[2].value = `${dateValue(
    filterDataDate.expStartDate
  )}${"-"}${dateValue(filterDataDate.expEndDate)}`;
  
    
    } else if(isAdminLabList){
      if (defaultStates.length != 0) {
        json1[3].value = defaultStates;
      } else {
        json1[3].value = [null];
      }

      if (defaultTestTypeStates.length > 0) {
        json1[4].value = defaultTestTypeStates;
      } else {
        json1[4].value = [null];
      }

      if (selectedState) {
        let foundState = false;
        for (let obj of json1) {
          if (obj.key == "states") {
            obj.value = [selectedState.value];
            foundState = true;
          }
        }
        if (!foundState) {
          json1.push({
            key: "states",
            value: [selectedState.value],
          });
        }
      }

      if (defaultManufacturerType != "") {

        let foundState = false;
        for (let obj of json1) {
          if (obj.key == "manufacturerType") {
            obj.value = defaultManufacturerType.toUpperCase();
            foundState = true;
          }
        }
        if (!foundState) {
          json1.push({
            key: "manufacturerType",
            value: defaultManufacturerType.toUpperCase(),
          });
        }
      }

      if (defaultDistrictIds.length > 0) {
        // json1[3].value = defaultDistrictIds;
        let foundKey = false;
        for (let obj of json1) {
          if (obj.key == "districts") {
            obj.value = defaultDistrictIds;
            foundKey = true;
          }
        }
        if (foundKey == false) {
          json1.push({
            key: "districts",
            value: defaultDistrictIds,
          });
        }
      }

      if (defaultCategoryIds.length > 0) {
        json1[5].value = defaultCategoryIds;

        let foundKey = false;
        for (let obj of json1) {
          if (obj.key == "categories") {
            obj.value = defaultCategoryIds;
            foundKey = true;
          }
        }
        if (foundKey == false) {
          json1.push({
            key: "categories",
            value: defaultCategoryIds,
          });
        }
      } else {
        json1[5].value = [null];
      }
      if (defaultmanufacturer.length > 0) {
        json1[7].value = defaultmanufacturer;
      } else {
        json1[7].value = [null];
      }

      if (defaultEntityTypestates.length > 0) {
        json1[9].value = defaultEntityTypestates;
      } else {
        json1[9].value = [null];
      }
  
    
    } else{
      if (defaultStates.length != 0) {
        json1[3].value = defaultStates;
  
      } else {
        json1[3].value = [null];
      }
  
      if (defaultTestTypeStates.length > 0) {
        json1[4].value = defaultTestTypeStates
      } else {
        json1[4].value = [null];
      }
      
     
      
  
  
  if (defaultCategoryIds.length > 0) {
    json1[5].value = defaultCategoryIds
  } else {
    json1[5].value = [null];
  }
  if (defaultmanufacturer.length > 0) {
    json1[7].value = defaultmanufacturer
  } else {
    json1[7].value = [null];
  }
  
  if (defaultTargetManufacturer.length > 0) {
    json1[8].value = defaultTargetManufacturer
  } else {
    json1[8].value = [null];
  }
  if (defaultEntityTypestates.length > 0) {
    json1[9].value = defaultEntityTypestates
  } else {
    json1[9].value = [null];
  }
  
  
  json1[1].value = `${dateValue(
    filterDataDate.mnfStartDate
  )}${"-"}${dateValue(filterDataDate.mnfEndDate)}`;
  json1[2].value = `${dateValue(
    filterDataDate.expStartDate
  )}${"-"}${dateValue(filterDataDate.expEndDate)}`;
  
    }

    dispatch({
      type: "UPDATE_FILTER",
      data: json1,
    });
  };

return (
  <Dialog
    onClose={onClose}
    open={open}
    PaperProps={{
      sx: { minWidth: "350px", minHeight: "50%" },
    }}
  >
    <Stack direction="row" justifyContent={"space-between"} alignItems={"center"}>
      <Typography p={2} sx={{ fontWeight: "bold", fontFamily: "outfit" }}>
        {translate('filterData')}
      </Typography>
      <CloseIcon onClick={onClose} sx={{ marginRight: "16px" }} />
    </Stack>
    <Divider sx={{ marginBottom: "1rem" }} />
    <Stack
      direction="column"
      spacing={2}
      sx={{ marginRight: "2rem", marginLeft: "2rem" }}
    >
      {!hideDateFilter && (
        <>
          <Box>
            <Typography style={{ fontWeight: "400", fontFamily: "outfit", marginBottom: "1rem" }}>{translate('manufacturingDate')}</Typography>
            <Grid container md={12} spacing={1}>
              <Grid item md={6} xs={12} sm={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDatePicker
                    value={filterDataDate.mnfStartDate}
                    label={translate('startDate')}
                    inputFormat="DD/MM/YYYY"
                    renderInput={(params) => <TextField {...params} inputProps={{ ...params.inputProps, readOnly: true }} />}
                    onChange={(date) =>
                      setFilterDataDate({
                        ...filterDataDate,
                        mnfStartDate: date?.$d,
                      })
                    }
                    maxDate={filterDataDate.mnfEndDate && new Date(filterDataDate.mnfEndDate)}
                    className="input-date-picker"
                    sx={{ mb: "1", mt: "1" }}
                  />
                </LocalizationProvider>

              </Grid>
              <Grid item md={6} xs={12} sm={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDatePicker
                    value={filterDataDate.mnfEndDate}
                    label={translate('endDate')}
                    className="input-date-picker"
                    inputFormat="DD/MM/YYYY"
                    renderInput={(params) => <TextField {...params} inputProps={{ ...params.inputProps, readOnly: true }} />}
                    minDate={new Date(filterDataDate.mnfStartDate)}
                    onChange={(date) =>
                      setFilterDataDate({
                        ...filterDataDate,
                        mnfEndDate: date?.$d,
                      })
                    }
                    sx={{ mb: "1" }}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <Typography style={{ fontWeight: "400", fontFamily: "outfit", marginBottom: "1rem" }}>{translate('expiryDate')}</Typography>
            <Grid container md={12} spacing={1}>
              <Grid item md={6} xs={12} sm={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDatePicker
                    value={filterDataDate.expStartDate}
                    label={translate('startDate')}
                    className="input-date-picker"
                    inputFormat="DD/MM/YYYY"
                    renderInput={(params) => <TextField {...params} inputProps={{ ...params.inputProps, readOnly: true }} />}
                    onChange={(date) =>
                      setFilterDataDate({
                        ...filterDataDate,
                        expStartDate: date?.$d,
                      })
                    }
                    maxDate={filterDataDate.expEndDate && new Date(filterDataDate.expEndDate)}
                    sx={{ mb: "1" }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item md={6} xs={12} sm={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDatePicker
                    value={filterDataDate.expEndDate}
                    label={translate('endDate')}
                    inputFormat="DD/MM/YYYY"
                    className="input-date-picker"
                    renderInput={(params) => <TextField {...params} inputProps={{ ...params.inputProps, readOnly: true }} />}
                    onChange={(date) =>
                      setFilterDataDate({
                        ...filterDataDate,
                        expEndDate: date?.$d,
                      })
                    }
                    minDate={new Date(filterDataDate.expStartDate)}
                    sx={{ mb: "1" }}
                  />
                </LocalizationProvider>

              </Grid>
            </Grid>
          </Box>
        </>
      )}
      {
        options.length > 0 &&
        <>
          <Typography style={{ fontWeight: "400", fontFamily: "outfit" }}>{hideState ? translate('category') : translate('stage')}</Typography>
          <Select
            onChange={handleSelectChange}
            isMulti
            options={options.map(item=>({...item, 'label': translate(item.label.toLowerCase()) }))}
            value={selectedValueNew}
          />
        </>
      }


      {categories?.length > 0 && (
        <>
          <Typography style={{ fontWeight: "400", fontFamily: "outfit" }}>{translate('category')}</Typography>
          <Select
            isMulti
            onChange={handleCategoryIds}
            options={categories}
            value={selectedCategoryIds}
          />
        </>
      )}

      {testTypeOptions?.length > 0 && (
        <>
          <Typography style={{ fontWeight: "400", fontFamily: "outfit" }}>{translate('testType')}</Typography>
          <Select
            onChange={handleTestType}
            options={testTypeOptions}
            value={selectedTestType}
          />
        </>
      )}
      {isInspector && (
        <>
                
          <>



          <Typography style={{ fontWeight: "400", fontFamily: "outfit" }}>{translate('manufacturerState')}</Typography>
          <Select
            onChange={handleStateId}
// isMulti={showManagePageFilter}
            options={stateData}
            value={selectedState}
          />
          {
            !showManagePageFilter &&
            selectedState!="" &&
            !isAdminManufList &&
            !isAdminLabList &&
            <div>
              <p>Please select a manufacture to filter</p>
            </div>
          }

          <>


          <Typography style={{ fontWeight: "400", fontFamily: "outfit" }}>{translate('manufacturerDistrict')}</Typography>
          <Select
            onChange={handleDistrictId}
isMulti={showManagePageFilter || isAdminManufList || isAdminLabList}

            
            // options={district}
            options={districtDataArr}
            value={selectedDistrict}
          />

          </>

</>



          {
            !showManagePageFilter &&
            selectedDistrict!="" &&
            !isAdminManufList &&
            !isAdminLabList &&
            <div>
              <p>Please select a manufacture to filter</p>
            </div>
          }
         {(showManagePageFilter || isAdminManufList)  &&
          <>
           <><Typography style={{ fontWeight: "400", fontFamily: "outfit" }}>{translate('vendorType')}</Typography>
          <Select
            onChange={handleVendorTypeId}
            options={vendorTypeData}
            value={selectedVendorType}
          /></>
          <Typography style={{ fontWeight: "400", fontFamily: "outfit" }}>{translate('manufacturerType')}</Typography>
          <Select
            onChange={handleManufacturerTypeId}
            options={manufacturerTypeData}
            value={selectedManufacturerType}
          />
          </>}


          {!showManagePageFilter && !isAdminManufList && !isAdminLabList &&
            <>
              <Typography style={{ fontWeight: "400", fontFamily: "outfit" }}>{translate('manufacturer')}</Typography>
              <Select
                onChange={handleManufacturerIds}
                isMulti
                options={getList}
                value={selectedManufacturerNew}
              />
            </>
          }
          {isLot && (
            <>
              <Typography style={{ fontWeight: "400", fontFamily: "outfit" }}> Target Manufacturer</Typography>
              <Select
                onChange={handleTargetManufacturerIds}
                isMulti
                options={targetmanufactureOptions}
                value={selectedTargetManufacturerNew}
              />
            </>
          )}
        </>
      )}

      {entityTypeOptions?.length > 0 && (
        <>
          <Typography style={{ fontWeight: "400", fontFamily: "outfit" }}>{ENTITY_TYPE}</Typography>
          <Select
            onChange={handleEntityType}
            isMulti
            options={entityTypeOptions}
            value={selectedEntityType}
          />
        </>
      )}
    </Stack>
    <Divider sx={{ marginTop: "1rem", marginBottom: "1rem" }} />
    <Stack direction="row" className="mb-4" justifyContent={"space-between"} spacing={2}
      sx={{ marginRight: "2rem", marginLeft: "2rem" }}>

      <Button onClick={handleClear} sx={{ width: "49%" }} variant="contained">
        {translate('clear')}
      </Button>
      <Button onClick={handleApply} sx={{ width: "49%" }} variant="contained">
        {translate('apply')}
      </Button>

    </Stack>

  </Dialog>
);
};
