import React, {
    useEffect,
    useState,
    useRef,
    useCallback,
  } from "react";
  import Box from "@mui/material/Box";
  import Stack from "@mui/material/Stack";
  import "../../../../App.css";
  import { CircularProgress, Grid, Typography } from "@mui/material";
  import { useSelector, } from "react-redux";
  import { useNavigate, } from "react-router-dom";
  import "ag-grid-community/dist/styles/ag-grid.css";
  import "ag-grid-community/dist/styles/ag-theme-alpine.css";
  import _ from "lodash";
  import { getAllTestTypes } from "../../../../services/adminService";
  import { MAIN_BLUE } from "../../../../color";
  import GridItem from "../../../GridItem/GridItem";
  import CreateButton from "../../../../components/CommonFilterCreateButton/CreateButton";
  import { PAGE_SIZE_ARRAY } from "../../../../constants";
  import { adminCategoryDocDropDown } from "../../../../services/adminService";
  import { useAppDispatch } from "../../../../services/StoreHooks";
  import moment from 'moment';
  import DeletableChips from "../../../../components/Chips/DeletableChip";
  import SearchBar from "../../../../components/CommonSearchBar/Search";
  import FilterButton from "../../../../components/CommonFilterCreateButton/FilterButton";
  import SimpleDialogDemo from '../../../../components/Filter/FilterPopup'
import { MILLER_UI_TEXT } from "../../../../textConstant";
import useTranslatewrapper from "../../../../components/useTranslateWrapper";

  const TestTypesGrid = () => {
    const {translate} = useTranslatewrapper()
    // const routeParams = useParams();
    const dispatch = useAppDispatch();
    const [loader, setLoader] = React.useState(false);
    const [rowData, setRowData] = useState([]);
    const [gridApi, setGridApi] = useState(null);
    const [chipsState, setChipsState] = React.useState({});
    const [chip, setChip] = useState("");
    const [clear, setclear] = useState(false);
    const [activeFilters, setActiveFilters] = React.useState({});
    
    const [filterStates, setFilterStates] = React.useState([]);
    const [openFilter, setOpenFilter] = React.useState(false);
    const [stateId, setStateId] = React.useState([]);
    const [selected, setSelected] = React.useState([]);
    const [options, setOptions] = React.useState([]);
    const [testTypeOptions, setTestTypeOptions] = React.useState([]);
    let chipMap = {};


    const [columnDefsUser, setColumnDefsUser] = React.useState([{
      field: "name",
      headerName: translate('testName'),
      hide: false,
      filter: "agTextColumnFilter",
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      flex: 2,
      filterParams: {
        buttons: ["apply", "clear"],
      },
    }, {
      field: "type",
      headerName: translate('testType'),
      hide: false,
      filter: "agTextColumnFilter",
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      flex: 2,
      filterParams: {
        buttons: ["apply", "clear"],
      },
    },
    {
      field: "categoryId",
      headerName: translate('category'),
      hide: false,
      filter: "agTextColumnFilter",
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      flex: 2,
      filterParams: {
        buttons: ["apply", "clear"],
      },
      // cellRendererFramework: (params)=> {
      //   if(!params.data.categoryId) {
      //     return ''
      //   }
      //   return categoryMap[params.data.categoryId];
      // }
      valueFormatter: (params) => {
        return (params.data.categoryId?.replace('MILLER',MILLER_UI_TEXT));
    },
    }]);
    const gridRef = useRef();
  
    const [pageSize, setPageSize] = React.useState(12);
    const [pageNo, setPageNo] = React.useState(1);
    const [count, setCount] = React.useState(1);
    const RowSelectionType = "single";
    const filterDataRedux = useSelector((state) => state.filterData);
    const categoriesRedux = useSelector((state) => state.categoriesData);
    const [filterData, setFilterData] = React.useState({
      state: [],
      expStartDate: "",
      expEndDate: "",
      mnmfEndDate: "",
      mnfStartDate: "",
    });
    const [searchValue, setSearchValue] = React.useState("");
    const dateValue = (date) => {
      if (date) {
        return moment.utc(date).local().format("yyyy/MM/DD");
      } else {
        return "";
      }
    };
    useEffect(()=>{
      const options = []
      categoriesRedux?.categories?.forEach((category)=> {
        if(!category.independentBatch){

          options.push({
            value: category.name,
            label: category.name,
            id: category.id,
          });
        }
      });
      setOptions([...options]);
      const testTypes = [
        {
          value: 'PHYSICAL',
          label: 'PHYSICAL',
          id: 'PHYSICAL',
        },
        {
          value: 'MICRONUTRIENT',
          label: 'MICRONUTRIENT',
          id: 'MICRONUTRIENT',
        }
      ]
      setTestTypeOptions(testTypes)
    }, [categoriesRedux]);

    const searchCriteriaList = [
      {
        key: "search",
        value: "",
      },
      {
        key: "categoryIds",
        value: [],
      },
      {
        key: "type",
        value: "",
      },
    ];
      
   
    useEffect(() => {
      updateChips();
      // setPageNo(page);
      // fetchAllTypes(1,pageSize);
      setPage(1)
    }, [filterDataRedux]);

    const navigate = useNavigate();



    const handleClickOpen = () => {
      setOpenFilter(true);
    };
    const handleClose = (value) => {
      setOpenFilter(false);
    };

    const setFiltersSearch = (data) => {
      var json = filterDataRedux.searchCriteriaList;
      var json1 = structuredClone(json);
  
      if (data == null) {
        json1[0].value = "";
      } else {
        json1[0].value = data.label;
        setChip(data.label);
      }
      dispatch({
        type: "UPDATE_FILTER",
        data: json1,
      });
    };

    const removeSearch = (data) => {
      var json = filterDataRedux.searchCriteriaList;
      var json1 = structuredClone(json);
      if (data != null) {
        json1[0].value = "";
        setChip("");
        setclear(true);
      }
  
      dispatch({
        type: "UPDATE_FILTER",
        data: json1,
      });
    };

    const setFilters = (filterData) => {
      var json = filterDataRedux.searchCriteriaList;
      var json1 = structuredClone(json);
      if (filterStates.length != 0) {
        setFilterData({ ...filterData, state: filterStates });
        json1[3].value = filterStates;
      } else {
        json1[3].value = [null];
      }


      json1[0].value = searchValue;
      json1[1].value = `${dateValue(filterData.mnfStartDate)}${"-"}${dateValue(
        filterData.mnfEndDate
      )}`;
      json1[2].value = `${dateValue(filterData.expStartDate)}${"-"}${dateValue(
        filterData.expEndDate
      )}`;
      dispatch({
        type: "UPDATE_FILTER",
        data: json1,
      });
    };


    const handleSelectChange = (values) => {
      setSelected(values);
    };

  
 
    const updateChips = () => {
      filterDataRedux.searchCriteriaList.map((entry) => {
        switch (entry.key) {
          case "batchNo":
            if (entry.value) chipMap["batchNo"] = entry.value;
            // code block
            break;
          case "dateOfManufacture":
            if (entry.value !== "" && entry.value)
              chipMap["dateOfManufacture"] = entry.value;
            break;
          case "dateOfExpiry":
            if (entry.value !== "" && entry.value)
              chipMap["dateOfExpiry"] = entry.value;
            break;
          case "stateIds":
            if (entry.value !== "" && entry.value)
              chipMap["stateIds"] = entry.value;
            break;
          case "testTypes":
            if (entry.value !== "" && entry.value)
              chipMap["testTypes"] = entry.value;
            break;
        }
      });
      setChipsState(chipMap);
    };
  
  
    const deleteChip = (chipKey, chipValue) => {
      var json = filterDataRedux.searchCriteriaList;
      var json1 = structuredClone(json);
  
      if (chipKey === "batchNo") {
        json1[0].value = "";
      }
      if (chipKey === "dateOfManufacture") {
        setFilterData({ ...filterData, mnfStartDate: "", mnfEndDate: "" });
  
        json1[1].value = "";
      }
      if (chipKey === "dateOfExpiry") {
        setFilterData({ ...filterData, expStartDate: "", expEndDate: "" });
  
        json1[2].value = "";
      }
      if (chipKey === "stateIds") {
        const index = json1[3].value.indexOf(chipValue);
        if (index > -1) {
          json1[3].value.splice(index, 1);
        }
        setFilterStates(json1[3].value);
      }
  
      if (json1[3].value.length == 0) json1[3].value = [null];
      if (chipKey === "testTypes") {
        const index = json1[4].value.indexOf(chipValue);
        if (index > -1) {
          json1[4].value.splice(index, 1);
        }
        // setFilterStates(json1[3].value);
      }
  
      if (json1[4].value.length == 0) json1[4].value = [null];
  
      dispatch({
        type: "UPDATE_FILTER",
        data: json1,
      });
    };

   

    useEffect(() => {
      setLoader(true);
      fetchAllTypes(1, pageSize)      
    }, []);
  
    const setPage = (page) => {
      setPageNo(page);
      fetchAllTypes(page, pageSize);
    };
    const counter = () => {
      return count % pageSize === 0
        ? Math.floor(count / pageSize)
        : Math.floor(count / pageSize) + 1;
    };
  
    const fetchAllTypes = async (pageNo, pageSize) => {
      if(filterDataRedux.searchCriteriaList){
        if(filterDataRedux.searchCriteriaList[0].value){
          searchCriteriaList[0].value = filterDataRedux.searchCriteriaList[0].value
        }
        if(filterDataRedux.searchCriteriaList[3].value && filterDataRedux.searchCriteriaList[3].value.length>0){
          searchCriteriaList[1].value = filterDataRedux.searchCriteriaList[3].value
        }
        if(filterDataRedux.searchCriteriaList[4].value && filterDataRedux.searchCriteriaList[4].value.length>0){
          searchCriteriaList[2].value = filterDataRedux.searchCriteriaList[4].value[0]
        }
      }
      const searchCriteria = {
        searchCriteriaList
      }
      const fetchData = await getAllTestTypes(searchCriteria,pageNo, pageSize);
      const arr = []
      const categoryMap = {}
      categoriesRedux?.categories?.forEach((category)=> {
        
        categoryMap[category.id] = category.name
      });
      if (fetchData?.data?.data) {
        setCount(fetchData?.data?.count);
        fetchData?.data?.data.map((data) =>
          arr.push({
            name: data.name,
            id: data?.id,
            type: data?.type,
            categoryId: categoryMap[data?.categoryId]
          })
        )

        setRowData(arr)
      }
      setLoader(false)
    };
  
    function onGridReady(params) {
      // console.log(rowData)
      setGridApi(params.api);
    }
  
    const onRowClicked = useCallback(() => {
      const selectedRows = gridRef.current.api.getSelectedRows();
      // console.log(selectedRows)
      navigate(`/admin/lab/labtesttype/details/${selectedRows[0].id}`)
  
    }, []);
  
    return (
      <div>
        {loader ? (
          <Box className="main-content-overlay" sx={{ height: "100vh" }}>
            <CircularProgress />
          </Box>
        ) : (
          <div>
            <Box className="main-content-overlay">
              <div className="main-content-div">
                <Box m={2}>
                  <Grid container>
                    <Grid item md={3} sd={12} p={1}>
                      <Typography variant="h6" sx={{ color: MAIN_BLUE, mx: 1 }}>
                        Lab Test Types
                      </Typography>
                    </Grid>
                    <Grid item md={5}>
                      <Stack direction="row" sx={{ my: 1.5, flexWrap: "wrap" }}>
                        {Object.keys(chipsState).map((key) => (
                          <DeletableChips
                            chipValue={chipsState[key]}
                            chipKey={key}
                            deleteChip={deleteChip}
                            options={options}
                            sx={{ maxHeight: "2rem !important" }}
                          />
                        ))}
                      </Stack>
                    </Grid>
                    <Grid item md={4} sx={{ display: 'flex', alignItems: 'center' }}>
                    <SearchBar
                      onChange={(e) => {
                        if (e !== "") {
                          setFiltersSearch(e);
                        } else {
                          removeSearch();
                        }
                      }}
                    />
                    {openFilter ? (
                      <div>
                        <SimpleDialogDemo
                          chipsState={chipsState}
                          open={openFilter}
                          onClose={handleClose}
                          filterData={filterData}
                          setFilterData={setFilterData}
                          setFilters={setFilters}
                          filterStates={filterStates}
                          setActiveFilters={setActiveFilters}
                          activeFilters={activeFilters}
                          stateId={stateId}
                          setStateId={setStateId}
                          handleSelectChange={handleSelectChange}
                          options={options}
                          testTypeOptions={testTypeOptions}
                          hideDateFilter={true}
                          // fetchStateList={fetchStateList}
                          hideState={true}

                        />
                      </div>
                    ) : (
                      <FilterButton handleClickOpen={handleClickOpen} />
                    )}

                  </Grid>
                    <Grid item md={4} sx={{ alignItems: 'center', display: 'flex', }}>
                      <CreateButton
                        onClick={() => {
                          navigate(`/admin/lab/labtesttype/details`)
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Box sx={{ m: 1 }}>
                    <GridItem
                      onGridReady={onGridReady}
                      rowData={rowData}
                      gridRef={gridRef}
                      rowSelection={RowSelectionType}
                      columnDefs={columnDefsUser}
                      pageSize={pageSize}
                      pageSizeArray={PAGE_SIZE_ARRAY}
                      count={counter()}
                      setPage={setPage}
                      page={pageNo}
                      onSelectionChanged={onRowClicked}
                      height="main"
                    />
                  </Box>
                </Box>
              </div>
            </Box>
          </div>
        )}
      </div>
    );
  };
  export default TestTypesGrid;