import useTranslatewrapper from "../../../components/useTranslateWrapper";

export default function useUserDetailsSchemaFn() {
  // const {t, i18n} = useTranslation();
  const { translate } = useTranslatewrapper();

  return {
    properties: {
      FirstName: {
        type: "string",
        title: translate('firstName'),
      },
      LastName: {
        type: "string",
        title: translate('lastName'),
      },
      User: {
        type: "string",
        title: "User",
      },
      Password: {
        type: "string",
        title: "Password",
      },
      RoleCategory: {
        type: "string",
        title: "Role category",
      },
      status: {
        type: "string",
        title: translate('status'),
      },
    },
    required: ["FirstName", "LastName"],
  };
}
