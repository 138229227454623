import React, { useState, useEffect } from "react";
import GetAllFiles from "../../../Form/GetAllFiles/GetAllFiles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import {
  Paper,
  TextField,
  MenuItem,
  Button,
  Stack,
  Chip,
  Avatar,
  Checkbox
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ManufacturingMappingModal from "./ManufacturingMappingModal.js";
import { useAppDispatch } from "../../../../services/StoreHooks";
import {
  getManufacturerByCategory,
  adminCategoryDocDropDown,
  getNextCategoryId,
} from "../../../../services/adminService.js";
import { getCategoryManuDocs,
  getCategoryLabDocs,

} from "../../../../services/FortificationService";
import { useParams,useLocation } from "react-router-dom";
import _ from "lodash";

import { useSelector } from "react-redux";
import { MILLER_UI_TEXT } from "../../../../textConstant.js";
import useTranslatewrapper from "../../../../components/useTranslateWrapper.js";


function ManufacturingMapping({
  widgetData,
  setWidgetData,
  setDocsData,
  setUploadData,
}) {
  const {translate} = useTranslatewrapper()
  const getId = useParams();
  const location=useLocation();
  const pageHeading=location.pathname.split("/")[2]==="lab";


const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
const categoriesRedux = useSelector((state) => state.categoriesData);


  const [category, setCategory] = useState([]);
  const [chipArr, setChipArr] = useState({});

  const [manufacturers, setManufactureres] = useState([]);
  const [selectedManufacturers, setSelectedManufactureres] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [fileBody, setFileBody] = React.useState([]);
  const [removedNames, setRemovedNames] = useState([]);
  const [deleteFiles, setDeleteFiles] = React.useState([]);
  const [createFileUploadFields, setCreateFileUploadFields] = React.useState(
    []
  );
const [idModal,setIdModal]=useState()
  const [tableData, setTableData] = useState([]);
  const [upload, setUpload] = useState([]);
  const [widgetDataDocs, setWidgetDataDocs] = useState( widgetData?.docs || [] );


  const [uploads, setUploads] = useState([]);
  const [open, setOpen] = React.useState(false);
useEffect(()=>{

},[])


  const handleOpen = (idx) => {


    pageHeading?
    setChipArr({id:idx,data:tableData[idx]?.manufactureDropDown.filter(item=>tableData[idx]?.selectedManufacturers.some(obj=>obj?.manufacturerId===item.id || obj?.id ===item.id))}):

    setChipArr({id:idx,data:tableData[idx]?.selectedManufacturers});

    setOpen(true)};
  const handleClose = () => setOpen(false);

  const dispatch = useAppDispatch();

  const dispatchNotification = (typ, msg) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      data: {
        open: true,
        type: typ,
        message: msg,
      },
    });
  };

  const docReq = [];

  const addPath = () => {};
  const getFileData = () => {};

;
  const setShowButton = () => {};
  const disableVendorData = false;
  const sourceCategoryData = () => {};
  const GetCategoryName=(categoryId)=> {
     return categoriesRedux.categories?.filter((item)=>item.id===categoryId)[0].name;
   
}
  const prepareSourceCategoryExtraDocs = (extraDocs, requiredDocs) => {
    if (
      (extraDocs && extraDocs.length > 0) ||
      (requiredDocs && requiredDocs.length > 0)
    ) {
     

      const docCategories = _.groupBy(extraDocs, (data) => {
        if (data?.categoryDoc?.docType?.name && data?.categoryDoc?.categoryId) {
          return `${GetCategoryName(data.categoryDoc.categoryId)}-${data.categoryDoc.docType.name}`;
        }
        return data.name || data.categoryDoc?.docType?.name;
      });
      const formattedExtras = [];
      Object.entries(docCategories).forEach((item) => {
        const formattedObject = {
          paths: item[1],
        };

        if (item[1][0]?.categoryDoc) {
          formattedObject.id = item[1][0]?.categoryDoc?.id;
          formattedObject.name = item[0];

          formattedObject.isMandatory = item[1][0]?.categoryDoc?.isMandatory;
          formattedObject.paths = item[1].map((docItem) => {
            return { ...docItem, categoryDocId: docItem.categoryDoc?.id,categoryId:docItem?.categoryDoc?.categoryId };
          });
        } else {
          formattedObject.name = item[0];
        }
        formattedExtras.push(formattedObject);
      });
      return customSortDocuments(
        addRequiredDocs(formattedExtras, requiredDocs)
      );
    }
    return [];
  };
  const addRequiredDocs = (currentDocs, requiredDocs) => {
    requiredDocs?.forEach((doc) => {
      if (currentDocs.findIndex((elem) => elem.name === doc.name) === -1) {
        currentDocs.push({ ...doc, categoryId: doc.id, paths: [] });
      }
    });
    return currentDocs;
  };

  const customSortDocuments = (initialArray) => {
    return initialArray.sort((a, b) => {
      if (a.id && !b.id) {
        return -1; // a comes first
      } else if (!a.id && b.id) {
        return 1; // b comes first
      } else {
        return 0; // no change in order
      }
    });
  };

  const removeDeletedFiles = () => {
    let tempFileBody = [
      ...fileBody.filter((obj) => !(obj.name == "" && obj.categoryDocId == "")),
    ];
    removedNames.forEach((removedName) => {
      const deleteFileIndexesArray = fileBody.filter(
        (file) => !file?.id && file.name === removedName
      );
      deleteFileIndexesArray.forEach((deleteItem) => {
        const deleteFileIndex = fileBody.findIndex(
          (file) => file.name === deleteItem.name
        );
        if (deleteFileIndex !== -1) {
          tempFileBody.splice(deleteFileIndex, 1);
        }
      });
    });

    deleteFiles.forEach((deleteItem) => { 
      const deleteFileIndex = tempFileBody?.findIndex(
        (file) =>
          (file.docPath === deleteItem.path || file.path === deleteItem.path) &&
          (file.docName === deleteItem.name || file.name === deleteItem.name) &&
          (file.categoryDocId === deleteItem.categoryDocId || file.categoryDocId === deleteItem?.categoryDoc?.id)
      );
      if (deleteFileIndex !== -1) {

        tempFileBody.splice(deleteFileIndex, 1);
      }

    });
    
    return tempFileBody;
  };

  useEffect(()=>{
    categoriesRedux?.categories?.length>0 && setCategory(categoriesRedux.categories?.filter((item)=>item?.independentBatch===false));

  },[categoriesRedux])

  
  const fetchNextCategory = async (id) => {
    if(id){
      const categories = await getNextCategoryId(id);

      return categories.data;
    }
   
  };

  const targetToRemove = [];


  useEffect(() => {
    if (Array.isArray(category) && category.length > 0) {
      const fetchData = async () => {
        const updatedUpload = [];
        const updatedTableData = [];
        
        if(widgetData){

        for (const item of widgetData?.category) {
          if (item?.isEnabled) {
            const docs = await fetchCategoryDocs(Number(item.categoryId));
            updatedUpload.push(...docs?.filter((item)=>item?.isEnabled!=false));
  
            const selectedManufacturersArr = [];
            
  const num = await fetchNextCategory(item.categoryId)
            for (const items of widgetData.targetManufacturers) {
              
  
              if (

!targetToRemove.some(data=>data.id===items.id) &&
(


                (pageHeading && items.categoryId === item.categoryId) ||
                (!pageHeading &&
                  items.manufacturerCategory.some(
                    (item2) =>
                      item2.categoryId ===  num
                  ) )
                  )
              ) {
                selectedManufacturersArr.push(items);
                targetToRemove.push(items);

              }
            }
  
  
            updatedTableData.push({
              categoryArray: category,
              categoryValId: item.categoryId,
              isEnabled: item.isEnabled,
              canSkipRawMaterials:item.canSkipRawMaterials,
              selectedManufacturers: selectedManufacturersArr,
              // manufactureDropDown: await fetchCategoryManufactureArr(item?.categoryId, updatedTableData?.length),

            });
  
            selectedCategory.push(item.categoryId);
          }
          else{
            const selectedManufacturersArr = [];

            for (const items of widgetData.targetManufacturers) {
              const num =
                await fetchNextCategory(item.categoryId)
  
              if (
                !targetToRemove.some(data=>data.id===items.id) &&
(

                (pageHeading && items.categoryId === item.categoryId) ||
                (!pageHeading &&
                  items.manufacturerCategory.some(
                    (item2) =>
                      item2.categoryId ===  num
                  )))
              ) {
                selectedManufacturersArr.push(items);
                targetToRemove.push(items);


              }
            }
  
  
            updatedTableData.push({
              categoryArray: category,
              categoryValId: item.categoryId,
              isEnabled: item.isEnabled,
              selectedManufacturers: selectedManufacturersArr,
              // manufactureDropDown: await fetchCategoryManufactureArr(item?.categoryId, updatedTableData?.length),
            });
  
            selectedCategory.push(item.categoryId);

          }
          // fetchCategoryManufacture(item.categoryId, updatedTableData.length);
          
        }
      }

  
        setUpload((prevUpload) => [...prevUpload, ...updatedUpload]);
        setTableData(updatedTableData);
      };
  
      fetchData();

    }
  }, [category]);

  useEffect(() => {
    getId.id &&
      setFileBody(
        widgetData?.docs?.map((item) => {
          return {
            docName: item?.categoryDoc?.id ? "" : item?.name,
            categoryDocId: item.categoryDoc?.id
              ? item.categoryDoc?.id
              : undefined,
            // isMandatory: item.isMandate,
            categoryId: item.categoryDoc?.categoryId,

            docPath: item.path,
          };
        })
      );
  }, [widgetData]);

  const removeDuplicates = (arr, idKey) =>
   Object.values(arr.reduce((unique, item) => {
    if (!unique[item[idKey]]) {
      unique[item[idKey]] = item;
    }
    return unique;
  }, {}));
  

  useEffect(() => {
    if (getId.id) {
      setCreateFileUploadFields(() => [
        ...prepareSourceCategoryExtraDocs(
          widgetDataDocs,
           upload.map((item) => {

            const { docType, isMandatory, isEnabled } = item;
            return {
              ...docType,
              id:item.id,
              name: `${GetCategoryName(item.categoryId)}-${docType.name}`,
              isMandatory,
              isEnabled,
            };


           
          })
        ),
      ]);
    } else {
      setCreateFileUploadFields(
        upload?.map((item) => {
          return {
            ...item,
            docType: {
              ...item.docType,
              id:item.id,
              name: `${GetCategoryName(item.categoryId)}-${item.docType.name}`,
            },
          };
        }))
        
        
        
    }

    setUploadData(upload);
  }, [upload, widgetData]);
  useEffect(() => {
    setWidgetData(tableData);
  }, [tableData]);

  useEffect(() => {
    
    setDocsData(
      removeDeletedFiles()?.map(
        ({ name: docName, path: docPath, ...rest }) => ({
          docName,
          docPath,
          ...rest,
        })
      )
    );
  }, [fileBody, widgetData, deleteFiles]);

  const fetchCategoryManufacture = async (id, index) => {
    const manufacturer =  pageHeading?await getManufacturerByCategory(id,"LAB"):
    await getManufacturerByCategory(id,"MANUFACTURER")
    ;

    if (manufacturer) {
      tableData[index].manufactureDropDown.push(...manufacturer?.data?.data);
      setTableData([...tableData]);
    }

    // debugger;
  };
  const fetchCategoryManufactureArr = async (id, index) => {
if(id){

    const manufacturer =  pageHeading?await getManufacturerByCategory(id,"LAB"):
    await getManufacturerByCategory(id,"MANUFACTURER")
    ;

   return manufacturer?.data?.data;

}
  };

  // const fetchCategoryManufactureArr = async (id, index) => {
  //   const manufacturer = await getManufacturerByCategory(id);

  //  return manufacturer?.data?.data;

  //   // debugger;
  // };

  const fetchCategoryDocs = async (id) => {
    
    const files = pageHeading?await getCategoryLabDocs(id): await getCategoryManuDocs(id);
    // files && setUpload([...upload, ...files?.data?.data]);
    return pageHeading?(files?.data?.data?.filter((item)=>item.categoryId===id)):(files?.data?.data);
    // return pageHeading?[]:(files?.data?.data);

  };

  const handleChipDelete = (item, index, idx) => {

    const newItems = [...tableData];
    const itemIndex = newItems[index]?.selectedManufacturers.findIndex(
      (items) => (items.manufacturerId == item.id || items.id == item.id)
    );
    newItems[index]?.selectedManufacturers.splice(itemIndex, 1);
    setTableData(newItems);
    handleOpen(index);
  };

 

  const handleCategoryChange = async (event, index) => {
    const categoryArr = event.target.value;
    // tableData[index].categoryObj=categoryArr;
    // tableData[index].categoryVal=categoryArr.name;
    tableData[index].categoryValId = categoryArr;

    // setTableData(prevRows =>
    //   prevRows.map(row =>
    //     row.id === index
    //       ? { ...row, categoryObj: categoryArr }
    //       : { ...row, categoryArray: row.categoryArray.filter(option => option !== categoryArr) }
    //   )
    // );

    // fetchCategoryManufacture(categoryArr, index);
    setSelectedCategory([...selectedCategory, categoryArr]);

    const docs = await fetchCategoryDocs(categoryArr);
    setUpload([...upload, ...docs?.filter((item)=>item?.isEnabled!=false)]);
    // debugger;
    // debugger;
  };
  const handleManufacturerChange = (event, index) => {
    const selManu = event.target.value;
    tableData[index].selectedManufacturers.push(selManu);
    setSelectedManufactureres([...selectedManufacturers, selManu]);
  };


  const addRows = () => {
    if (tableData?.length > 0) {
      tableData?.length > 0 &&
      tableData[tableData.length - 1].categoryValId == undefined
        ? dispatchNotification("error", ["Select existing category first"])
        : setTableData([
            ...tableData,
            {
              categoryArray: category,
              // ?.filter((data) => !selectedCategory?.includes(data?.id))
              Manufactures: [],
              manufactureDropDown: [],
              selectedManufacturers: [],
              isEnabled:true,
              canSkipRawMaterials:false,
            },
          ]);
    } else {
      setTableData([
        {
          categoryArray: category,
          // ?.filter(data=>!(selectedCategory?.includes(data?.id)))
          Manufactures: [],
          manufactureDropDown: [],
          selectedManufacturers: [],
          isEnabled:true,

        },
      ]);
    }
  };
  const handleDisableRow = async (e,index)=>{

const updatedTableData = [...tableData];
  
  updatedTableData[index] = { ...updatedTableData[index], isEnabled: e.target.checked };
  
  setTableData(updatedTableData);

  if(tableData[index].categoryValId){
    const docs = await fetchCategoryDocs(tableData[index].categoryValId);
    if(e.target.checked){
      setUpload([...upload, ...docs?.filter((item)=>item?.isEnabled!=false)]);

    }
    else{
      setUpload(upload.filter((item) => !docs.some((docs) => docs.id === item.id)));
      setWidgetDataDocs(widgetDataDocs?.filter((item) => !docs.some((docs) => (docs.categoryId === item.categoryDoc?.categoryId && docs.id===item.categoryDoc?.id))));
      setDeleteFiles((prevDeleteFiles) => [...prevDeleteFiles,...widgetDataDocs?.filter((item) =>docs?.some((doc) => doc?.categoryId === item?.categoryDoc?.categoryId && doc?.id === item?.categoryDoc?.id)),]);
      setUploadData((data)=>data.filter((item)=>item.categoryId!==tableData[index].categoryValId));
    };
    
   
        
        // setSelectedCategory(
        //   selectedCategory.filter((item) => item != tableData[index].categoryValId)
        // );
    
        const files = await getCategoryManuDocs(tableData[index].categoryValId);
        if (files.data.data) {
          const idsToRemove = new Set(files.data.data.map((item) => item.uuid));
    
          const filteredArray1 = uploads.filter(
            (item) => !idsToRemove.has(item.uuid)
          );
    
          setUploads(filteredArray1);
        }
    
    
  }
 

  }
  const handleDisableRawMaterial=async (e,index)=>{
const updatedTableData = [...tableData];
  
updatedTableData[index] = { ...updatedTableData[index], canSkipRawMaterials: !e.target.checked };

setTableData(updatedTableData);


 
      
    
  
   
  }

  const deleteRows = async (index) => {
    // debugger;
    const docs = await fetchCategoryDocs(tableData[index].categoryValId);
    setUpload(
      upload.filter((item) => !docs.some((docs) => docs.id === item.id))
    );
    setSelectedCategory(
      selectedCategory.filter((item) => item != tableData[index].categoryValId)
    );
    const newItems = [...tableData];

    // for (let i = index; i < tableData.length; i++) {
    //   tableData[i].categoryArray.push(tableData[i].categoryObj);
    // }

    newItems.splice(index, 1);
    setTableData(newItems);
    const files = await getCategoryManuDocs(tableData[index].categoryValId);
    if (files.data.data) {
      const idsToRemove = new Set(files.data.data.map((item) => item.uuid));

      const filteredArray1 = uploads.filter(
        (item) => !idsToRemove.has(item.uuid)
      );

      setUploads(filteredArray1);
    }
  };

  // useEffect(() => {
  //   fetchCategoryData();
  // }, []);

  return (
    <div>
      <div>
        <TableContainer component={Paper} className="lab-details-table">
          <Table sx={{ width: "70vw" }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">S.No</TableCell>
                <TableCell align="left">{translate('category')}</TableCell>
                {/* <TableCell align="left">Manufactures</TableCell> */}
                {!pageHeading &&   <TableCell align="left">Raw Materials Mandatory</TableCell>}

                <TableCell align="left">Action(Enable/Disable)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData?.map((row, index) => (
                <TableRow>
                  <TableCell align="left">{index + 1}</TableCell>
                  <TableCell align="left">
                    <TextField
                      sx={{ width: "100%", backgroundColor: "#fff" }}
                      name="category"
                      id="outlined-basic"
                      value={row.categoryValId}
                      variant="standard"
                      select
                      // required
                      disabled={row.categoryValId}
                      onChange={(event) => {
                        handleCategoryChange(event, index);
                      }}
                    >
                      {(row?.categoryValId
                        ? row.categoryArray
                        : row.categoryArray?.filter(
                            (data) => !selectedCategory?.includes(data?.id)
                          )
                      )?.map((option, idx) => (
                        <MenuItem key={idx} value={option?.id}>
                          {option?.name.replace('MILLER',MILLER_UI_TEXT)}
                        </MenuItem>
                      ))}
                    </TextField>
                  </TableCell>


                  {

                  // <TableCell sx={{ maxWidth: "20vw" }} align="left">
                  //   <div>
                  //     {row?.selectedManufacturers?.length > 0 && (
                  //      pageHeading? 
                  //      <div
                  //         style={{
                  //           display: "flex",
                  //           justifyContent: "space-between",
                  //         }}
                  //       >
                  //         {/* {row?.selectedManufacturers?.map((item, idx) => ( */}

                  //         <Chip
                  //           label={row.manufactureDropDown?.filter(item=>item.id===row?.selectedManufacturers[0]?.manufacturerId || item.id===row?.selectedManufacturers[0]?.id )[0]?.name}
                  //           variant="outlined"
                  //           // onDelete={() => handleChipDelete(item, index, idx)}
                  //         />
                  //         {row?.selectedManufacturers.length > 1 && (
                  //           <>
                  //             <Avatar
                  //               onClick={()=>handleOpen(index)}
                  //               sx={{ width: 28, height: 28 }}

                  //               // alt="Remy Sharp"
                  //             >
                  //               +{row?.selectedManufacturers.length - 1}
                  //             </Avatar>
                  //             <ManufacturingMappingModal
                  //               open={open}
                  //               handleClose={handleClose}
                  //               chipData={chipArr}
                  //               index={row.categoryValId}
                  //               handleChipDelete={handleChipDelete}
                  //             />
                  //           </>
                  //         )}
                  //         {/* ))} */}
                  //       </div>
                  //       : 
                  //       <div
                  //       style={{
                  //         display: "flex",
                  //         justifyContent: "space-between",
                  //       }}
                  //     >
                  //       {/* {row?.selectedManufacturers?.map((item, idx) => ( */}

                  //       <Chip
                  //         label={row?.selectedManufacturers[0]?.name}
                  //         variant="outlined"
                  //         // onDelete={() => handleChipDelete(item, index, idx)}
                  //       />
                  //       {row?.selectedManufacturers.length > 1 && (
                  //         <>
                  //           <Avatar
                  //             onClick={()=>handleOpen(index)}
                  //             sx={{ width: 28, height: 28 }}

                  //             // alt="Remy Sharp"
                  //           >
                  //             +{row?.selectedManufacturers.length - 1}
                  //           </Avatar>
                  //           <ManufacturingMappingModal
                  //             open={open}
                  //             handleClose={handleClose}
                  //             chipData={chipArr}
                  //             handleChipDelete={handleChipDelete}
                  //           />
                  //         </>
                  //       )}
                  //       {/* ))} */}
                  //     </div>

                  //     )}

                  //     <TextField
                  //       sx={{ width: "100%", backgroundColor: "#fff" }}
                  //       name="role"
                  //       // disabled={roleDropDownDisable(index) || row.role!==""}
                  //       id="outlined-basic"
                  //       variant="standard"
                  //       select
                  //       value={""}
                  //       onChange={(event) =>
                  //         handleManufacturerChange(event, index)
                  //       }
                  //     >
                  //       {(pageHeading?
                  //       (row?.manufactureDropDown
                  //         ?.filter(
                  //           (obj) =>
                            
                  //             !row?.selectedManufacturers
                  //               ?.map((item) => item.manufacturerId || item.id )
                  //               ?.includes(obj.id)
                  //         )):
                  //         row?.manufactureDropDown
                  //         ?.filter(
                  //           (obj) =>
                  //             !row?.selectedManufacturers
                  //               ?.map((item) => item.id )
                  //               ?.includes(obj.id)
                  //         ))

                  //         ?.map((option, index) => (
                  //           <MenuItem key={index} value={option}>
                  //             {option.name}
                  //           </MenuItem>
                  //         ))}
                  //     </TextField>
                  //   </div>
                  // </TableCell>
                  }
                  
                  

              {!pageHeading &&
                 <TableCell>
                    <Stack direction="row">
                    <Checkbox {...label} defaultChecked checked={!row.canSkipRawMaterials} onChange={(e)=>handleDisableRawMaterial(e,index)}/>

                      {/* <Button onClick={() => deleteRows(index)}>Delete</Button> */}
                    </Stack>
                  </TableCell>
                  }

                  <TableCell>

                    <Stack direction="row">
                    <Checkbox {...label} defaultChecked checked={row.isEnabled} onChange={(e)=>handleDisableRow(e,index)}/>
                     
                      {/* <Button onClick={() => deleteRows(index)}>Delete</Button> */}
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Button
          onClick={addRows}
          sx={{
            mt: 1,
            color: "#0075FF !important",
            width: "25%",
            textDecoration: "underline",
          }}
          // disabled={
          //   tableData?.length > 0 &&
          //   tableData[tableData.length - 1].categoryValId == undefined
          // }
        >
          <AddCircleOutlineIcon />
          Add Categories
        </Button>
      </div>

      <GetAllFiles
        getId={getId}
        addPath={addPath}
        createFileUploadFields={createFileUploadFields}
        getFileData={setFileBody}
        handleFileDelete={setDeleteFiles}
        setRemovedNames={setRemovedNames}
        setShowButton={setShowButton}
        disableVendorData={disableVendorData}
        sourceCategoryData={sourceCategoryData}
      />
    </div>
  );
}

export default ManufacturingMapping;
