import { combineReducers } from "redux";
import notificationAlert from "./notificationState";
import qrImageState from "./qrImageState";
import actionState from "./actionStates";
import showQrState from "./showQrState";
import filterData from "./filterData";
import stateData from "./stateData";
import rowData from "./rowData";
import menuDetails from "./menuSlice"
import loaderStatus from "./loaderState";
import filterUserData from "./filterUserData";
import categoriesData from "./categories"
import notificationCount from './notificationCount'
export default combineReducers({
  notificationAlert,
  qrImageState,
  showQrState,
  actionState,
  filterData,
  stateData,
  rowData,
  menuDetails,
  loaderStatus,
  filterUserData,
  categoriesData,
  notificationCount
});
