import React from 'react'

function UnitConverterWeight(props) {
if(!props){
    return('N/A')
}
    if(props/1000000000.00 >= 1){
    return (
        ((props/1000000000.00) %1 !== 0 ? (props/1000000000.00).toFixed(3) :  (props/1000000000.00))+" MT"
        )
}
if(props/1000000000.00 < 1 && props/1000.00 >= 1){
    return (
        ((props/1000.00) %1 !==0 ? (props/1000.00).toFixed(3) : (props/1000.00))+" Ton"
        )
}
if(props/1000.00 < 1 && props/100.00 >= 1){
    return (
        ((props/100.00) %1 !==0 ? (props/100.00).toFixed(3) : (props/100.00))+" Quintal"
        )
}
if(props/100.00 < 1 ){
    return (
        props+" Kg"
        )
}
  
}

export default UnitConverterWeight
