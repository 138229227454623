const initial = {
    categories: [],
  };
  export default (state = initial, event) => {
    switch (event.type) {
      case "STORE_CATEGORIES":
        return {
          ...state,
          categories: [...event.data],
        };
  
      default:
        return state;
    }
  };
  