import useTranslatewrapper from "../../../components/useTranslateWrapper";

export default function useVendorDetailsSchemaEditFn() {
  const { translate } = useTranslatewrapper();
  return {
    properties: {
      name: {
        type: "string",
        title: translate("nameOfVendor"),
      },
      licenseNumber: {
        type: "string",
        title: translate("fssaiLicenseNo"),
      },
      vendorType: {
        title: translate("vendorType"),
        enum: ["Manufacturer", "Trader", "Broker"],
        enumNames: [
          translate("manufacturer"),
          translate("trader"),
          translate("broker"),
        ],
      },
      completeAddress: {
        type: "string",
        title: translate("address"),
      },
      accreditedByAgency: {
        type: "boolean",
        title: translate("manufAccreditedByAgency"),
      },

      QR: {
        type: "string",
        title: true,
      },
    },
    required: [],
  };
}
