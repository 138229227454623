import React, { useEffect, useMemo, useState } from "react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import UnitConverterWeight from "../../utils/UnitConverter";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import DescriptionIcon from "@mui/icons-material/Description";
import { useParams } from "react-router-dom";
import LinkRedirect from "../../pages/Form/UsageGrid/LinkRedirect";
import exportFromJSON from 'export-from-json';
import { IconButton, InputAdornment, TextField } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import * as XLSX from 'xlsx';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
export default function MapPageAgencyGrid(props) {
  const routeParams = useParams();
  const [columnDefs, setColumnDefs] = useState([]);


  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
    };
  }, []);

  useEffect(()=>{
    if(props.query['type'] == 'agency-all'){
      setColumnDefs([
        //columns for source manufacturer grid
        {
          headerName: "S.No",
          valueGetter: "node.rowIndex + 1",
          width: 100,
          flex:0.5,
        },
        {
          headerName: "Agency Name",
          field: "manufacturerName",
          tooltipField: "manufacturerName",
          width: "auto",
          flex:2,
        },
        {
          headerName: "License Number",
          field: "licenseNo",
          tooltipField: "licenseNo",
          width: "auto",
          flex:1,
        },
        {
          headerName: "State",
          field: "stateName",
          tooltipField: "stateName",
          width: "auto",
          flex:1,
        },
        {
          headerName: "District",
          field: "districtName",
          tooltipField: "districtName",
          width: "auto",
          flex:1,
        },
        {
          headerName: "Data",
          field: "data",
          width: "auto",
          valueFormatter: (params) => {
            return UnitConverterWeight(params.data.data);
          },
          flex:1,
        },
        {
          headerName: "No. Of Batches",
          field: "numberOfBatches",
          width: "auto",
          hide: !((props.query["cellType"]==='totalProduction') || ((props.query['dataType'] === 'testing') && (props.query['type'] === 'agency-all'))),
          cellRendererFramework: (params) => (params.data.numberOfBatches === 0) ? params.data.numberOfBatches : <a href ={'javascript:void(0)'} style={{ textDecoration: 'none' }}>{params.data.numberOfBatches}</a>
        },

      ])
    } else if((props.query['type'] == 'agency-targetManufacturers') || (props.query['type'] == 'agency-sourceManufacturers')){
      setColumnDefs([
        {
          headerName: "S.No",
          valueGetter: "node.rowIndex + 1",
          width: 100,
          // flex:1,
        },
        {
          headerName: "Agency Name",
          field: "agencyName",
          tooltipField: "agencyName",
          width: "auto",
          
          // flex:2,
        },
        {
          headerName: "License Number",
          field: "licenseNumber",
          tooltipField: "licenseNumber",
          width: "auto",
          // flex:2,
        },
        {
          headerName: "State",
          field: "stateName",
          tooltipField: "stateName",
          width: "auto",
          minWidth: 150
          // flex: 2,
        },
        {
          headerName: "District",
          field: "districtName",
          tooltipField: "districtName",
          width: "auto",
          minWidth: 150
          // flex:1,
        },
        {
          headerName: "Lot Approved Quantity",
          field: "lotApprovedQuantity",
          width: "auto",
          valueFormatter: (params) => {
            return UnitConverterWeight(params?.data?.lotApprovedQuantity);
          },
          // flex:1,
        },
        {
          headerName: "Lot Rejected Quantity",
          field: "lotRejectedQuantity",
          width: "auto",
          valueFormatter: (params) => {
            return UnitConverterWeight(params?.data?.lotRejectedQuantity);
          },
          // flex:1,
        },
        {
          headerName: "Lot Pending For Approval",
          field: "lotApprovalPending",
          width: "auto",
          valueFormatter: (params) => {
            return UnitConverterWeight(params?.data?.lotApprovalPending);
          },
          // flex:1,
        },
        {
          headerName: "available quantity",
          field: "totalAvailable",
          width: "auto",
          valueFormatter: (params) => {
            return UnitConverterWeight(params?.data?.totalAvailable);
          },
          // flex:1,
        },

      ]);
    }
  },[props.query])



  const getRowStyle = (params) => {
    if (params.node.rowIndex % 2 != 0) {
      return { background: "#F8F8F8" };
    }
  };

  const [columnHeaderMap, setColumnHeaderMap] = useState({});

  useEffect(() => {
    const tempColumnHeaderMap = columnDefs.reduce((acc, column) => {
      if (column.field && !column.hide) {
        acc[column.field] = column.headerName;
      }
      return acc;
    }, {});
    setColumnHeaderMap(tempColumnHeaderMap);
  }, [columnDefs])

  const valueFormaterKeys = ['totalQuantity', 'remainingQuantity', 'data', 'lotApprovedQuantity', 'lotRejectedQuantity', 'lotApprovalPending', 'totalAvailable' ];

  let fData = []
  const generateExcelData = ()=> {
    fData = []
    let count = 1;
    props.rowData.forEach((x)=> {
      const obj = {}
      obj['S.No'] = count;
      count++;
      for(const key in x){
        if(columnHeaderMap[key]){
  
          if(!(valueFormaterKeys.includes(key))){
            obj[columnHeaderMap[key]] = x[key];
          } else {
            obj[columnHeaderMap[key]] = UnitConverterWeight( x[key])
          }
        }
      }

      fData.push(obj)
    })
  }
  // let fileName;
  // if(props.title){
  //   fileName =props.title + "_"+Date.now()
  // }else if(window.location.pathname.includes("inventory")){
  //   fileName = routeParams.type + " Inventory" +"_"+ Date.now()
  // } else{
  //   fileName = routeParams.categoryname+"_"+props.query['cellType'] + "_"+ Date.now()
  // }

  const getFileNameForExcel = () =>{
    let cellTypeToTitleMap = {
      "totalProduction": "totalProduction",
      "availableTested": "availableQuantity",
      "transitQuantity":"in-transit-quantity",
      "approvedQuantity":"acceptedQuantity",
      "lotRejected":"rejectedQuantity",
      "usedQuantity":"consumedQuantity",
      "sampleInTransit":"inTransitForTesting",
      "batchTestApprovedQuantity":"testedApprovedQuantity",
      "batchTestRejectedQuantity":"testedRejectedQuantity",
      "batchTestedQuantity":"testedQuantity",
      "batchNotTestedQuantity":"notTestedQuantity"

    }

    let cellTypeToTitleText = cellTypeToTitleMap[props.query['cellType']] || ""

    const now = new Date();
  const formattedDate = now.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  }).replaceAll('/','-');
  
  const formattedTime = now.toLocaleTimeString('en-GB', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  })
  .replaceAll(':','-');
    
    let fileName;
    fileName = routeParams.categoryname.replace("MILLER",'MILLER(FR)')+"_"+cellTypeToTitleText + "_"+ formattedDate+"T"+formattedTime

    return fileName;
  }

  const formatDateToDescriptive = (dateStr)=>{
    if(!dateStr){
      return ""
    }
    const date = new Date(dateStr);

    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);

    return formattedDate;
  }

  const getHeaderForExcel = () =>{

    const targetMap = {
      'PREMIX':'FRK',
      'FRK':'MILLER(FR)'
    }

    let cellTypeToExcelHeaderMap = {
      "totalProduction": "total production",
      "availableTested": "available quantity",
      "transitQuantity":"in-transit lots quantity",
      "approvedQuantity":"accepted lots quantity",
      "lotRejected":"rejected lots quantity",
      "usedQuantity":`consumed by ${targetMap[routeParams.categoryname] || ""} quantity`,
      "sampleInTransit":"in-Transit for testing",
      "batchTestApprovedQuantity":"tested approved quantity",
      "batchTestRejectedQuantity":"tested rejected quantity",
      "batchTestedQuantity":"tested quantity",
      "batchNotTestedQuantity":"not tested quantity"
    }

    if(props.query['type']!= "agency-all"){
      return (props.heading
        + `(${formatDateToDescriptive(props.query['fromDate'])} to ${formatDateToDescriptive(props.query['toDate'])})`
      )
    } else{
      return routeParams.categoryname.replace("MILLER",'MILLER(FR)')
      + " "
      + (cellTypeToExcelHeaderMap[props.query['cellType']] || " agencies")
      + `(${formatDateToDescriptive(props.query['fromDate'])} to ${formatDateToDescriptive(props.query['toDate'])})`
    }
  }

  const exportToExcel = async () => {
    generateExcelData()

    const fileNameForExcel = getFileNameForExcel();
    const headerForExcel = getHeaderForExcel();

    //   fileName = 'excel'+"_"+ Date.now()
    // const exportType = 'xls';
    // exportFromJSON({ data: fData, fileName, exportType});
    // const worksheet = XLSX.utils.json_to_sheet(fData, { skipHeader: false });
    // XLSX.utils.sheet_add_aoa(worksheet, [["custom document title"]], { origin: 'A1' });

  // const headers = Object.keys(fData[0]);
  const headers = Array.from(new Set(fData.flatMap(Object.keys)));

  const numberOfColumns = headers.length;
  
  const endColumn = String.fromCharCode(65 + numberOfColumns - 1);
  const mergeRange = `A1:${endColumn}1`;

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Sheet1');

  worksheet.mergeCells(mergeRange);
  worksheet.getCell('A1').value = headerForExcel;
  worksheet.getCell('A1').alignment = { horizontal: 'left' };
  worksheet.getCell('A1').font = { bold: true, size: 12 }; // Title font style

  worksheet.getRow(3).values = headers;

  worksheet.getRow(3).font = { bold: true };

  fData.forEach((item) => {
    const rowValues = headers.map(header => item[header] !== undefined ? item[header] : '');
    worksheet.addRow(rowValues);
  });

  const buffer = await workbook.xlsx.writeBuffer();

  const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  saveAs(blob, `${fileNameForExcel}.xlsx`);

  };

  // let overlayNoRowsTemplate = (props.query['level']!='country') ? '<span style="padding: 10px; display: block; text-align: center;">No Data to show</span>' : '<span style="padding: 10px; display: block; text-align: center;">Please apply filters to view data.</span>';

  // const gridOptions = {
    // overlayNoRowsTemplate: overlayNoRowsTemplate,
  // };

  return (
    <>
      <div
        style={{ display: "flex", justifyContent: "space-between", margin:"10px", alignItems:"end" }}
      >
        <div style={{width:"40%"}}>
          <TextField
            id="outlined-required"
            label="Search by name"
            value={props.searchKeyWord}
            onChange={(e)=>{props.setSearchKeyWord(e.target.value)}}
            InputProps={{
              style: {
                borderRadius: 40,
                height: '45px',
                textAlign:"center"
              },
              endAdornment: 
                (<InputAdornment position='end'>
                  <IconButton onClick={()=>{props.setSearchKeyWord("")}}>
                    <ClearIcon/>
                  </IconButton>
                </InputAdornment>)
            }}
          />
        </div>
        <a
          onClick={exportToExcel}
          href="javascript:void(0)"
          hidden={['labs-all','labsForParticularData'].includes(props.query['type'])}
        >
          <span style={{ fontSize: "1rem" }}>Download</span>
          <FileDownloadOutlinedIcon sx={{ fontSize: "1.2rem" }} m={1} />
        </a>
      </div>
      <div className="ag-theme-alpine" style={{  height: 430}}>
        <AgGridReact
          rowData={props?.rowData?.filter(item=>((item?.manufacturerName?.toLowerCase().includes(props.searchKeyWord.toLowerCase())) || (item?.agencyName?.toLowerCase().includes(props.searchKeyWord.toLowerCase())) || (item?.stateName?.toLowerCase().includes(props.searchKeyWord.toLowerCase()) ) || (item?.districtName?.toLowerCase().includes(props.searchKeyWord.toLowerCase()) )))}
          columnDefs={columnDefs}
          animateRows={true}
          getRowStyle={getRowStyle}
          onRowClicked={props.onRowClick}
          onCellClicked={props.onCellClicked}
          defaultColDef={defaultColDef}
          tooltipShowDelay={0}
          tooltipHideDelay={2000}
          enableBrowserTooltips={true}
          // gridOptions={gridOptions}
        />
      </div>
    </>
  );
}
