import React, { useEffect, useState } from "react";
import { Form } from "@rjsf/mui";
import validator from "@rjsf/validator-ajv8";
import { Box, Button, Typography } from "@mui/material";
import "../../../../../src/App.css";
import { useNavigate, useParams } from "react-router-dom";
import { BACK_BUTTON } from "../../../../textConstant";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  createUom,
  getUomDatabyId,
  updateUom,
} from "../../../../services/FortificationService";
import { useAppDispatch } from "../../../../services/StoreHooks";
import { width } from "@mui/system";
import useTranslatewrapper from "../../../../components/useTranslateWrapper";
const UomForm = () => {
  const {translate} = useTranslatewrapper()

  const params = useParams();
  const [uomData, setUomData] = useState({});
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  let uomSchema = {
    properties: {
      name: {
        title: translate('unitName'),
        type: "string",
      },
      conversionFactorKg: {
        title: "Conversion Factor",
        type: "integer",
      },
    },
    required: ["name", "conversionFactorKg"],
  };
  const dispatchNotification = (typ, msg) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      data: {
        open: true,
        type: typ,
        message: msg,
      },
    });
  };
  const fetchUomList = async () => {
    if (!params.id) {
      return;
    }
    const response = await getUomDatabyId(params.id);
    if (!response.data.id) {
      return;
    }
    setUomData(response.data);
  };
  const submitForm = async (body) => {
    let response = null;
    if (body?.id) {
      response = await updateUom(body);
    } else {
      response = await createUom(body);
    }
    if (response.data.toLowerCase() == "success") {
      navigate(-1);
      dispatchNotification("success", [
        body?.id ? "Updated Successfully" : "Added new UOM Successfully",
      ]);
    }
  };
  useEffect(() => {
    fetchUomList();
  }, []);

  return (
    <div>
      <div className="main-content-overlay">
        {`${
          uomData?.id ? "Update" : "Add New"
        } Unit Of Measurement (UOM)`}
        <Box className="main-content-div" style={{ position: "relative" }}>
          <Box id="VendorDetails" className="form-div">
            <Box p={2} id="rjsf">
              <Button
                onClick={(e) => {
                  navigate(-1);
                }}
              >
                <ArrowBackIcon />
                <Typography
                  sx={{
                    fontSize: "13px",
                    textDecoration: "underline",
                    fontWeight: "600",
                    fontSize: "12px",
                    lineHeight: "15px",
                    color: "#0075FF",
                  }}
                >
                  {BACK_BUTTON}
                </Typography>
              </Button>
              {
                <Form
                  schema={uomSchema}
                  validator={validator}
                  uiSchema={{}}
                  formData={uomData}
                  className="batch-form-styling"
                  onChange={(e) => {
                    console.log("e", e);
                  }}
                  onSubmit={(e) => {
                    submitForm(e.formData);
                  }}
                ></Form>
              }
            </Box>
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default UomForm;
