import React, { useState, useEffect } from 'react'
import { Box, Rating, Typography, Button, Accordion, AccordionSummary, AccordionDetails, TextField, Modal, } from "@mui/material"
import { MAIN_BLUE } from "../../../../color";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import { BACK_BUTTON } from "../../../../textConstant.js";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RatingAccordion from './RatingAccordion';
import ModalEditRating from './ModalEditRating';

import { transformedArray, transformAddRating, updatedRatingData } from '../../../../utils/inspectionManufactureToComponent';
import {
  getManufacturerByID,
  editManufacturerRating,
  getManufacturerRating,
  addManufacturerRating,

} from "../../../../services/adminService"


import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';
import { useAppDispatch } from '../../../../services/StoreHooks';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


function ManufacturerPage() {
  const [isExpanded1, setIsExpanded1] = useState(true)
  const [isExpanded2, setIsExpanded2] = useState(true)
  const [manufacturerRatings, setManufacturerRatings] = useState([])
  const [formData, setFormData] = useState({
    name: "",
    licenseNumber: "",
    agencyName: "",
    vendorType: "",
    type: "",
    manufacturerType: "",
    completeAddress: "",
    totalScore: "",
    applicableCategory: ""
  });


  const [clicked, setClicked] = useState(false);
  const [ratingData, setRatingData] = useState([]);
  const [ratingDataView, setRatingDataView] = useState([]);


  const navigate = useNavigate();
  const params = useParams();

  const [open, setOpen] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [addRatingData, setAddRatingData] = React.useState([]);

  const dispatch = useAppDispatch();

  const dispatchNotification = (typ, msg) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      data: {
        open: true,
        type: typ,
        message: msg,
      },
    });
  };

  const handleOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
    setClicked(false);

  }

  const handleClose = () => setOpen(false);
  const categoriesRedux = useSelector((state) => state.categoriesData);

  const handleCategoryCsv = (data) => {


    const filteredCategories = data
      .filter((filterItem) => filterItem.isEnabled)
      .map((filterItem) => {
        const category = categoriesRedux?.categories?.find((item) => item.id === filterItem.categoryId);
        return category?.name;
      })
      .filter((name) => name !== undefined)
      .join(',');

    return filteredCategories


  }
  const fetchFormData = async () => {
    const formDataApi = await getManufacturerByID(params?.id);
    formDataApi && setFormData({ ...formDataApi?.data, "applicableCategory": handleCategoryCsv(formDataApi?.data?.manufacturerCategory) })
    const addRatingDataApi = await getManufacturerRating();

    addRatingDataApi && setAddRatingData(transformAddRating(addRatingDataApi?.data?.data))

    formDataApi && setRatingData(updatedRatingData(transformAddRating(addRatingDataApi?.data?.data), transformedArray(formDataApi?.data?.manufacturerAttributes[0]?.attributeCategoryScores)))
    setRatingDataView([]);
    formDataApi && setRatingDataView(cloneDeep(transformedArray(formDataApi?.data?.manufacturerAttributes[0]?.attributeCategoryScores)))
    const isEditTrue = formDataApi?.data?.manufacturerAttributes.length > 0;
    isEditTrue && setIsEdit(true);



  };



  const dispatchShowLoader = (showLoader) => {
    dispatch({
      type: "SHOW_LOADER",
      data: {
        showLoader
      },
    });
  };


  const editFormRating = async (ratings) => {
    dispatchShowLoader(true);

    const postFormBody = formData?.manufacturerAttributes ? formData?.manufacturerAttributes[0] : {};
    const editBody = {
      "id": postFormBody?.id,
      "manufacturerId": Number(params?.id),
      "userId": 10,
      "attributeCategoryScores":

        ratings.map((item) => {
          return {
            manufacturerAttributeScores: item.items.map((attribute) => {
              return {
                attributeId: attribute.attributeId,
                value: attribute.value,
                compliance: attribute.compliance,
              };
            }),
            attributeCategoryId: item.categoryId,
          };
        })




    }
    const addBody =
    {

      "manufacturerId": Number(params?.id),
      "userId": 10,
      "attributeCategoryScores":

        ratings.map((item) => {
          return {

            manufacturerAttributeScores: item.items.map((attribute) => {
              return {

                attributeId: attribute.attributeId,
                value: attribute.value,
                compliance: attribute.compliance,
              };
            }),
            attributeCategoryId: item.categoryId,
          };
        })




    };
    const formValidation = ratings.some((item) => {
      return item.items.some((attribute) => {
        if (attribute.value === "" || attribute.value === undefined || attribute.compliance === undefined || attribute.compliance === "") {
          document.getElementById(attribute.id).scrollIntoView({ behaviour: "smooth", block: "center" })
        }
        return attribute.value === "" || attribute.value === undefined || attribute.compliance === undefined || attribute.compliance === ""
      });
    });



    if (formValidation || ratings.length == 0) {
      dispatchNotification('error', ["Please fill all mandatory fields"])
      dispatchShowLoader(false);


    }
    else {

      const formDataApi = isEdit ? await editManufacturerRating(postFormBody?.id, editBody) : await addManufacturerRating(addBody);
      dispatchShowLoader(false);

      if (formDataApi.status === 200 || formDataApi.status === 201) {
        if (isEdit) {
          dispatchNotification("success", ["Ratings updated successfully"]);
        } else {
          dispatchNotification("success", ["Ratings submitted successfully"]);
        }

        fetchFormData();
        handleClose(true);


      }
    }
  };

  useEffect(() => {
    fetchFormData();

  }, [])



























  return (
    <div className="main-content-overlay">
      <Typography
        align="center"
        mt={2}
        variant="h6"
        sx={{ color: MAIN_BLUE, fontWeight: "600" }}
      >
        Details Page
      </Typography>
      <Box sx={{ width: "100%" }}>


        {/* <div className="main-content-overlay"> */}
        {/* <Box className="main-content-div" style={{ position: "relative" }}> */}
        <Box id="VendorDetails" className="form-div ">
          <Box p={2} id="rjsf">
            <Button
              onClick={(e) => {
                navigate(-1);
              }}
            >
              <ArrowBackIcon />
              <Typography
                sx={{
                  fontSize: "13px",
                  textDecoration: "underline",
                  fontWeight: "600",
                  fontSize: "12px",
                  lineHeight: "15px",
                  color: "#0075FF",
                }}
              >
                {BACK_BUTTON}
              </Typography>
            </Button>

            <Accordion
              onChange={(e, expanded) => {
                setIsExpanded1(expanded);
              }}
              defaultExpanded={true}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{ color: "#fff", background: "#0075FF", height: "35px" }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Box className="formTitle">

                  <Typography className="formBox-text"> Manufacturer Detail</Typography>
                  {isExpanded1 ? (
                    <Typography className="formBox-helperText">
                      (Click To Collapse)
                    </Typography>
                  ) : (
                    <Typography className="formBox-helperText">
                      (Click To Expand)
                    </Typography>
                  )}

                </Box>
              </AccordionSummary>
              <AccordionDetails>

                <div
                  style={{ display: "flex", gap: "20px", flexWrap: "wrap", width: "75vw" }}
                >





                  <TextField
                    sx={{ width: "31%", backgroundColor: "#fff" }}
                    name="category"
                    id="outlined-basic"
                    value={formData.name} // Ensure this is the correct value from the formData state
                    variant="outlined"
                    label="Name"
                    required
                    disabled
                    readOnly
                    // disabled
                    onChange={(event) => {
                      // Handle onChange event if necessary
                    }}
                  />
                  <TextField
                    sx={{ width: "31%", backgroundColor: "#fff" }}
                    name="category"
                    id="outlined-basic"
                    value={formData.licenseNumber}
                    variant="outlined"
                    label="License Number"
                    required
                    disabled
                  // onChange={(event) => {

                  // }}
                  />
                  <TextField
                    sx={{ width: "31%", backgroundColor: "#fff" }}
                    name="category"
                    id="outlined-basic"
                    value={formData.agencyName}
                    variant="outlined"
                    label="Agency Name"
                    required
                    disabled

                  // onChange={(event) => {

                  // }}
                  />
                  <TextField
                    sx={{ width: "31%", backgroundColor: "#fff" }}
                    name="category"
                    id="outlined-basic"
                    value={formData.vendorType}
                    variant="outlined"
                    label="Vendor Type"
                    required
                    disabled

                  // onChange={(event) => {

                  // }}
                  />
                  <TextField
                    sx={{ width: "31%", backgroundColor: "#fff" }}
                    name="category"
                    id="outlined-basic"
                    value={formData.type}
                    variant="outlined"
                    label="Type"
                    required
                    disabled

                  // onChange={(event) => {

                  // }}
                  />
                  <TextField
                    sx={{ width: "31%", backgroundColor: "#fff" }}
                    name="category"
                    id="outlined-basic"
                    value={formData.manufacturerType}
                    variant="outlined"
                    label="Manufacturer Type"
                    required
                    disabled

                  // onChange={(event) => {

                  // }}
                  />
                  <TextField

                    sx={{ width: "31%", backgroundColor: "#fff" }}
                    name="category"
                    id="outlined-basic"
                    value={formData.applicableCategory}
                    variant="outlined"
                    label="Listed Categories"
                    required
                    disabled
                  />
                  <TextField
                    multiline
                    rows={4}
                    sx={{ width: "31%", backgroundColor: "#fff" }}
                    name="category"
                    id="outlined-basic"
                    value={formData.completeAddress}
                    variant="outlined"
                    label="Address"
                    required
                    disabled

                  // onChange={(event) => {

                  // }}
                  />
                  {
                    formData.totalScore ?
                      <div style={{ display: "flex", gap: "10px" }}>
                        <Typography>Overall Rating :</Typography>
                        <Typography>{formData.totalScore}/100</Typography>

                      </div> : null
                  }


                </div>


              </AccordionDetails>
            </Accordion>
            {/* {ratingData?.length>0 && */}

            <Accordion
              onChange={(e, expanded) => {
                setIsExpanded2(expanded);
              }}
              defaultExpanded={true}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{ color: "#fff", background: "#0075FF", height: "35px" }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Box className="formTitle"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ display: "flex" }}>

                    <Typography className="formBox-text"> Manufacturer Rating</Typography>
                    {isExpanded2 ? (
                      <Typography className="formBox-helperText">
                        (Click To Collapse)
                      </Typography>
                    ) : (
                      <Typography className="formBox-helperText">
                        (Click To Expand)
                      </Typography>
                    )}
                  </div>
                  {/* {
isEdit &&
<Button sx={{color:"#fff"}} onClick={handleOpen}>Edit Rating</Button>

} */}


                </Box>
              </AccordionSummary>
              <AccordionDetails>
                {isEdit ?

                  (ratingDataView?.map((item, idx) =>
                    <RatingAccordion key={idx} data={item} />

                  ))
                  :

                  <Button onClick={handleOpen}>Add Rating</Button>}



              </AccordionDetails>
            </Accordion>

            {/* } */}





          </Box>
        </Box>
        {/* </Box> */}
        {/* </div> */}













      </Box>


      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >

          <ModalEditRating data={isEdit ? ratingData : addRatingData} isEdit={isEdit} open={open} handleClose={handleClose} setManufacturerRatings={setManufacturerRatings} setClicked={setClicked} clicked={clicked} editFormRating={editFormRating} />
        </Modal>
      </div>







    </div>
  )
}

export default ManufacturerPage