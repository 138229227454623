import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { Stack } from "@mui/system";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Box, Button, Typography, MenuItem, Divider } from "@mui/material";
import "react-date-range/dist/theme/default.css"; // theme css file
import TextField from "@mui/material/TextField";
import {
  ADD_BUTTON_TEXT,
  COMMENTS,
  DATE,
  SUBMIT_BUTTON_TEXT,
  WASTAGE_QUANTITY,
} from "../../../textConstant";
import {
  addWastageInfo,
  getUomData,
} from "../../../services/FortificationService";
import { useParams } from "react-router-dom";
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useAppDispatch } from "../../../services/StoreHooks";
import { useLocation, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { convert } from "../Packaging Info/TotalQuantityValidatew";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import KeycloakService from "../../../services/KeycloakService";
import useTranslatewrapper from "../../../components/useTranslateWrapper";

export const AddWastageForm = (props) => {
  const {translate} = useTranslatewrapper()
  const navigate = useNavigate();
  const param = useParams();
  const { onClose, open, refreshWastageInfo, remainingQuantity,inspectModalData } = props;
  const [unit, setUnit] = React.useState("");
  const [comments, setComments] = React.useState("");
  const [reportedDate, setReportedDate] = React.useState(moment().local());
  const pathNameCapitalize = useLocation().pathname.split("/")[1];
  const userdetails = KeycloakService.getUserDetail();

  const isSuper = userdetails?.isSuperAdmin;
  let currentPath="";
  if(isSuper){
    currentPath = `/${pathNameCapitalize}/fortification/${param.type}/${param.categoryId}`
  }else{
    currentPath = `/${pathNameCapitalize}/${param.categoryId}/${param.type}`;
  }
  const [quantity, setQuantity] = React.useState("");
  const [openConfirmation, setConfirmationOpen] = React.useState(false);
  const [uomData, setUomData] = React.useState();
  const dispatch = useAppDispatch();
  const [factor, setFactor] = React.useState()
  let arrSucc = [];
  const [dateValidated, setDateValidated] = React.useState(false);

  const checkValidate = (data) => {
    let arrErr = [];
    let sum = 0;

    if (quantity == "") {
      arrErr.push("Please select the Quantity");
    }
    if (unit == "") {
      arrErr.push("Please select the unit");
    }
    if (reportedDate == "") {
      arrErr.push("Date is not selected");
    }
    if (comments == "") {
      arrErr.push("Please add a comment");

    }
    if (remainingQuantity < convert(quantity, 1, factor)) {
      arrErr.push("Quantity exceeds remaining quantity");

    }
    if (arrErr.length > 0) {
      dispatchNotification("error", arrErr);
      return;
    }
    else {
      setConfirmationOpen(true);
    }

  };
  const validateDate =(e)=>{
    let arrErr =[];
      if(new Date(e.$d) > moment().local()){
        arrErr.push(`Lot Recived Date can not be after Current date`)
      }    
       let msg = "";
    arrErr.map((err) => (msg += err + "\n"));

    if (arrErr && arrErr.length > 0) {
      // debugger;
    //  setGlobalErr(arrErr)
      dispatchNotification("error", arrErr);
      return false;
    } else {
      // setGlobalErr([])
      return true;
    }

  }

  const handleApply = () => {
    onClose();
  };
  const dateValue = (date) => {
    if (date) {
      return moment.utc(date).local().format("yyyy-MM-DD");
    } else {
      return "";
    }
  };
  const addWastage = async () => {
    const data = {
      batchId: param.type!="lot"?param.id:null,
      lotId:param.type=="lot" ? param.id : null,
      wastageQuantity: quantity,
      comments: comments,
      reportedDate: dateValue(reportedDate),
      uomId: uomData.filter((e) => e.name == unit)[0].id,
      conversionFactorKg: uomData.filter((e) => e.name == unit)[0].conversionFactorKg
    };
    const res = await addWastageInfo(
      param.categoryId,
      param.type,
      param.id,
      data
    );


    if (res?.status === 201) {
      refreshWastageInfo();
      arrSucc.push("Successfully added wastage info");
      navigate(`${currentPath}/details/${param.id}`);
      if(arrSucc.length>0){
        dispatchNotification("success", arrSucc);

      }
    }

  };
  const handleClickCloseDialog = () => {
    setConfirmationOpen(false);
  };
  const handleClickYes = () => {
    setConfirmationOpen(false);
    addWastage();
    handleApply();
  };
  const dispatchNotification = (typ, msg) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      data: {
        open: true,
        type: typ,
        message: msg,
      },
    });
  };
  React.useEffect(() => {
    const fetchData = async () => {
      const SizeUnit = await getUomData();
      setUomData(SizeUnit.data.data);
    };
    fetchData();
  }, []);

  React.useEffect(()=>{
    setDateValidated(validateDate(reportedDate))
  },[reportedDate])
  return (
    <Box>
      <Dialog
        open={open}
        onClose={handleApply}
        PaperProps={{
          sx: { width: "auto", height: 500 },
        }}
      >
        <Stack direction="row" justifyContent={"space-between"} alignItems={"center"}>

          <Typography p={2} sx={{ fontWeight: "bold", fontFamily: "outfit" }}>
            {translate('addWastage')}
          </Typography>
          <CloseIcon onClick={onClose} sx={{ marginRight: "16px" }} />
        </Stack>

        <Divider sx={{ marginBottom: "1rem" }} />

        <Stack
          direction="column"
          spacing={2}
          sx={{ marginRight: "2rem", marginLeft: "2rem" }}
        >

          <Box>
            <Typography style={{ fontWeight: "400", fontFamily: "outfit" }}>

              {translate('wastageDate')}</Typography>
            <Box sx={{ m: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDatePicker
                  value={reportedDate}
                  disableFuture="true"
                  minDate={new Date(inspectModalData?.dateOfManufacture)}
                  label={translate('reportedDate')}
                  inputFormat="DD/MM/YYYY"
                  renderInput={(params) => <TextField {...params} inputProps={{...params.inputProps, readOnly: true}}  />}
                  onChange={(date)=>{
                    setReportedDate(date.$d)
                  }}
                  className="input-date-picker"
                  sx={{ mb: "1", mt: "1",width: "97%" }}
                />
              </LocalizationProvider>
            </Box>
          </Box>
          <Box>
            <Typography style={{ fontWeight: "400", fontFamily: "outfit" }}>
              {translate('wastageQuantity')}</Typography>
            <TextField
              sx={{
                width: "18ch",
                backgroundColor: "#fff",
                marginRight: "2.5rem",
                m: 1,
              }}
              name="quantity"
              type="number"
              variant="standard"
              InputProps={{ inputProps: { min: 1 } }}
              id="outlined-basic"
              value={quantity}
              label={translate('quantity')}
              onChange={(event) => setQuantity(event.target.value)}
            />
            <TextField
              sx={{ width: "18ch", m: 1, backgroundColor: "#fff" }}
              name="unit"
              id="outlined-basic"
              value={unit}
              variant="standard"
              label={translate('units')}
              select
              onChange={(event) => {
                setUnit(event.target.value);
                setFactor(uomData.filter((e) => e.name === event.target.value)[0].conversionFactorKg)
              }}
            >
              {Array.isArray(uomData) &&
                uomData?.map((uom, idx) => {
                  return <MenuItem value={uom.name}> {uom.name}</MenuItem>;
                })}
            </TextField>
          </Box>

          <Box>
            <Typography style={{ fontWeight: "400", fontFamily: "outfit" }}>

              {translate('comments')}*</Typography>
            <TextField
              id="outlined-multiline-static"
              multiline
              rows={3}
              sx={{ m: 1, width: "40ch" }}
              onChange={(event) => setComments(event.target.value)}
            />
          </Box>

          <Button onClick={checkValidate} variant="contained">
            {translate('submit')}
          </Button>
        </Stack>
      </Dialog>
      <Box>
        <Dialog
          open={openConfirmation}
          onClose={handleClickCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
          {translate('confirmationMsg')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to save wastage info ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClickCloseDialog} variant="contained">
            {translate('no')}
            </Button>
            <Button
              type="submit"
              variant="contained"
              onClick={handleClickYes}
              autoFocus
            >
              {translate('yes')}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
};
