import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Paper, TextField, MenuItem, Button, Stack } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useAppDispatch } from "../../../services/StoreHooks";
import { addRole, removeRole } from "../../../services/Manage_service";
import { MILLER_UI_TEXT } from "../../../textConstant";
import useTranslatewrapper from "../../../components/useTranslateWrapper";

function RoleCategoryMap(props) {
  const { translate } = useTranslatewrapper();

  const { id, setMap, categoryMapData, roleCategoryData, autoPopulateData } =
    props;

  const [categories, setCategories] = useState(Object.keys(categoryMapData));
  const [tableData, setTableData] = useState(roleCategoryData || []);

  const selectedRoles = tableData.map((ele) => ({
    category: ele.category,
    role: ele.role,
  }));

  const usedRoles = {};
  const allCategories = Object.keys(categoryMapData);
  allCategories.map((item) => (usedRoles[item] = []));
  tableData.forEach((element) => {
    allCategories.forEach((cat) => {
      if (element.category === cat) {
        usedRoles[cat].push(element.role);
      }
    });
  });
  const fullyUsedCategories = allCategories.filter((cat) => {
    return usedRoles[cat].length === categoryMapData[cat].roles.length;
  });

  const [UsedCategories, setUsedCategories] = useState(
    fullyUsedCategories || []
  );
  const [selected, setSelected] = useState(selectedRoles || []);

  useEffect(() => {}, [selected, UsedCategories]);

  useEffect(() => {}, [tableData]);

  const dispatch = useAppDispatch();
  const dispatchNotification = (typ, msg) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      data: {
        open: true,
        type: typ,
        message: msg,
      },
    });
  };

  const handleCategoryChange = (e, index) => {
    let refData = [...tableData];
    refData[index].rolesArray = [];
    refData[index]["category"] = e.target.value;
    refData[index]["role"] = "";
    let filterData = selected.filter((element) => {
      return element?.category === e.target.value;
    });
    let usedRoles = [];
    if (filterData.length > 0) {
      usedRoles = filterData.map((element) => element.role);
    }
    refData[index].rolesArray.push(
      ...categoryMapData[e.target.value].roles.filter(
        (element) => !usedRoles.includes(element)
      )
    );
    refData[index]["categoryId"] = categoryMapData[e.target.value].id;
    const selectedArray = [...selected];
    selectedArray[index]["category"] = e.target.value;
    selectedArray[index]["role"] = '';
    setSelected(selectedArray);
    setTableData(refData);
    setMap(refData);
  };

  const roleClickHandler = (index) => {
    let arrErr = [];
    if (!tableData[index].category) {
      arrErr.push("Please Select category First");
    }
    let msg = "";
    arrErr.map((err) => (msg += err + "\n"));
    if (arrErr && arrErr.length > 0) {
      dispatchNotification("error", arrErr);
    }
  };

  const handleRoleChange = (e, index) => {
    let refData = [...tableData];
    refData[index]["role"] = e.target.value;
    
    const selectedArray = [...selected];
    selectedArray[index]["role"] = e.target.value;
    refData.forEach((eR, i)=> {
      if(i !==index && eR.category === selectedArray[index]["category"]){
        eR.rolesArray = eR.rolesArray.filter(eRole=> eRole !== e.target.value)
      }
    })

    let filterData = selected.filter((element) => {
      return element?.category === refData[index].category;
    });

    let usedRoles = [];
    if (filterData.length > 0) {
      usedRoles = filterData.map((element) => element.role);
    }
    if (
      categoryMapData[refData[index].category].roles.filter(
        (element) => !usedRoles.includes(element)
      ).length === 0
    ) {
      setUsedCategories([...UsedCategories, refData[index].category]);
    }
    setSelected(selectedArray);
    setTableData(refData);
    setMap(refData);
  };

  const addRows = () => {
    const refData = [...tableData];
    if (refData[refData.length - 1]?.roleCategoryId !== 0 || refData[0].roleCategoryId === 0) {
      refData.push({
        category: "",
        role: "",
        rolesArray: [],
        categoryId: 0,
        roleCategoryId: 0,
        categoryArray: categories.filter((category) => {
          return !UsedCategories.includes(category);
        }),
      });
      const selectedArray = [...selected];
      selectedArray.push({ category: "", role: "" });
      setSelected(selectedArray);
      setTableData(refData);
      setMap(refData);
    } else {
      dispatchNotification("error", ["Save the above row to proceed"]);
    }
  };

  const saveRow = async (index) => {
    const refData = [...tableData];
    let emptyRows = refData.filter((element) => {
      return element.role === "";
    });
    if (emptyRows.length > 0) {
      dispatchNotification("error", [
        "Please select both Role and Category to proceed",
      ]);
    } else {
      const data = [
        {
          roleName: tableData[index].role,
          categoryName: tableData[index].category,
          categoryId: tableData[index].categoryId,
          roleCategoryType: "MODULE",
        },
      ];

      const result = await addRole(autoPopulateData.UserName, data);

      if (result.status === 202 || result.status === 200) {
        const refData = [...tableData];
        refData[index].roleCategoryId = result.data[0];
        setTableData(refData);
        dispatchNotification("success", ["Role added Successfully"]);
      }
    }
  };

  const deleteRows = async (index) => {
    let rows = [...tableData];
    let selectedData = [...selected];
    if (rows.filter((item) => {return item.roleCategoryId !== 0}).length > 1 || rows.filter((item) => {return item.roleCategoryId === 0}).length > 0 ) {
      if (rows[index].roleCategoryId) {
        const result = await removeRole(
          autoPopulateData.UserName,
          rows[index].roleCategoryId
        );
        if (result.status === 200) {
          setUsedCategories(
            UsedCategories.filter((cat) => cat !== rows[index].category)
          );
          selectedData.splice(index, 1);
          setSelected(selectedData);
          const deletedRole = rows[index].role
          rows.forEach((eR) => {
            if (eR.category === rows[index]["category"]) {
              if (!eR.rolesArray.includes(deletedRole) && deletedRole) {
                eR.rolesArray.push(rows[index]["role"])
              }
            }
          })
          rows.splice(index, 1);
          setTableData(rows);
          setMap(rows);
          dispatchNotification("success", [result.data]);
        } else {
          dispatchNotification("error", ["something went wrong"]);
        }
      } else {
        setUsedCategories(
          UsedCategories.filter((cat) => cat !== rows[index].category)
        );
        const deletedRole = rows[index].role
        rows.forEach((eR) => {
          if (eR.category === rows[index]["category"]) {
            if (!eR.rolesArray.includes(deletedRole) && deletedRole) {
              eR.rolesArray.push(rows[index]["role"])
            }
          }
        })
        rows.splice(index, 1);
        selectedData.splice(index, 1);
        setSelected(selectedData);
        setTableData(rows);
        setMap(rows);
      }
    } else {
      dispatchNotification("error", [
        "Atleast one Role must be assigned to User",
      ]);
    }
  };

  const disableRow =(index) =>{
    
  }
  const roleDropDownDisable = (index) => {
    if (!tableData[index].roleCategoryId) {
      if (tableData[index].category) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  return (
    <div>
      <TableContainer component={Paper} className="lab-details-table">
        <Table sx={{ width: "70vw" }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">S.No</TableCell>
              <TableCell align="left">{translate('category')}</TableCell>
              <TableCell align="left">{translate('role')}</TableCell>
              <TableCell align="left">{translate('action')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row, index) => (
              <TableRow>
                <TableCell align="left">{index + 1}</TableCell>
                <TableCell align="left">
                  <TextField
                    sx={{ width: "100%", backgroundColor: "#fff" }}
                    name="category"
                    id="outlined-basic"
                    value={row.category}
                    variant="standard"
                    select
                    disabled={row.roleCategoryId ? true : false}
                    onChange={(event) => {
                      handleCategoryChange(event, index);
                    }}
                  >
                    {row.categoryArray?.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {translate(option.replace('MILLER',MILLER_UI_TEXT))}
                      </MenuItem>
                    ))}
                  </TextField>
                </TableCell>
                <TableCell align="left">
                  <TextField
                    sx={{ width: "100%", backgroundColor: "#fff" }}
                    name="role"
                    disabled={roleDropDownDisable(index) || row.role!==""}
                    id="outlined-basic"
                    variant="standard"
                    select
                    value={row.role}
                    onClick={(event) => roleClickHandler(index)}
                    onChange={(event) => {
                      handleRoleChange(event, index);
                    }}
                  >
                    {row.rolesArray?.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {translate(option)}
                      </MenuItem>
                    ))}
                  </TextField>
                </TableCell>
                <TableCell align="left">
                  {row.roleCategoryId ? (
                    <Stack direction="row">
                      <Button onClick={() => deleteRows(index)}>{translate('delete')}</Button>
                      <Button
                        onClick={() => saveRow(index)}
                        disabled={row.roleCategoryId ? true : false}
                      >
                        {translate('save')}
                      </Button>
                    </Stack>
                  ) : !id ? (
                    <Button onClick={() => deleteRows(index)}> {translate('delete')}</Button>
                  ) : (
                    <Stack direction="row">
                      <Button onClick={() => deleteRows(index)}>{translate('delete')}</Button>
                      <Button
                        onClick={() => saveRow(index)}
                        disabled={row.roleCategoryId ? true : false}
                      >
                        {translate('save')}
                      </Button>
                    </Stack>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        onClick={addRows}
        sx={{
          mt: 1,
          color: "#0075FF !important",
          width: "15%",
          textDecoration: "underline",
        }}
        disabled={UsedCategories.length === categories.length ? true : false}
      >
        <AddCircleOutlineIcon />
        {translate('addRole')}
      </Button>
    </div>
  );
}

export default RoleCategoryMap;
