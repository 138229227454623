import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { firebaseConfig } from "./FireBaseConfig";
import { firebaseREgistrationToken } from "./NotifcationServices";
import KeycloakService from "./KeycloakService";

// Initialize the Firebase app using the provided configuration
const firebaseApp = process.env.REACT_APP_FIREBASE_VAPID_KEY && initializeApp(firebaseConfig);

// Get the Firebase messaging instance
export const messaging = process.env.REACT_APP_FIREBASE_VAPID_KEY && getMessaging(firebaseApp);

// Function to request and retrieve the registration token for Firebase Cloud Messaging
export const getRegistrationToken = async () => {
  // Request permission from the user for notifications
  if (firebaseApp?.messaging?.isSupported()) {
    // Use Firebase Cloud Messaging
    const permission = await Notification.requestPermission();

  if (permission === "granted") {
    // Generate a registration token for the current user
    const token = await getToken(messaging, {
      vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
    });

    // If the token is generated and not already stored in local storage
    if (localStorage.getItem("firebase-reg-token") == null) {
      const body = {
        "statusId": 0,
        userName: KeycloakService.getUserDetail().userName,
        registrationToken: token
      }

      // Send the registration token to the server for storage
      await firebaseREgistrationToken(body);
    }

    if (token) {
      // Store the registration token in local storage
      localStorage.setItem("firebase-reg-token", token || "no");
    }
  } else if (permission === "denied") {
    // Alert the user if they denied notification permission
    alert("You denied permission for notifications.");
  }
  }
  
}

// Function to listen for incoming messages from Firebase Cloud Messaging
export const onMessageListener = () =>
new Promise((resolve) => {
    // Set up a listener for incoming messages
    onMessage(messaging, (payload) => {
      resolve(payload); // Resolve the promise with the received payload
    });
  });
