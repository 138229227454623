import React, { useEffect, useState } from 'react'
import { Form } from '@rjsf/mui'
import validator from "@rjsf/validator-ajv8";
import {  createLabDocumentTypeByID,  editLabDocumentTypeByID,  getLabDocumentTypeByID } from "../../../../services/adminService.js"
import { Box, Button, Typography } from '@mui/material';
import "../../../../../src/App.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from 'react-router-dom';
import { BACK_BUTTON } from '../../../../textConstant.js';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../../services/StoreHooks.js';
import "../../../../App.css";
import useTranslatewrapper from '../../../../components/useTranslateWrapper.js';

function DoctypeFormLab() {
    const {translate} = useTranslatewrapper()
    const [dropdown, setDropdown] = useState([]);

    const navigate = useNavigate()
    const [formData, setFormData] = useState({});
    const params = useParams()

    const fetchFormData = async () => {
        const formDataApi = await getLabDocumentTypeByID(params?.id);
        formDataApi && setFormData(formDataApi?.data)
    }
    useEffect(() => {
        params.id && fetchFormData();
    }, [params])

    const dispatch = useAppDispatch();
    const dispatchNotification = (typ, msg) => {
        dispatch({
            type: "SHOW_NOTIFICATION",
            data: {
                open: true,
                type: typ,
                message: msg,
            },
        });
    };

    const createForm = async (data) => {
        let Response;
        if (formData.id) {
            Response = await editLabDocumentTypeByID(formData.id, {
                id: Number(formData.id),
                name: data.name,

            });
            
        } else {
            Response = await createLabDocumentTypeByID({
                name: data.name,
            });
        }
        if (Response.status === 201) {
            navigate(-1);
            dispatchNotification("success", ["Created Successfully"]);
        }
        if (Response.status === 202 || Response.status === 200) {
            navigate(-1);
            dispatchNotification("success", ["Updated Successfully"]);
        }

    };
    const DocTypeSchema = {

        "properties": {
            "name": {
                "type": "string",
                "title": translate('nameOfTheFile')
            },

        },

        required: ["name"],

    };
    const CategoryDocTypeUiSchema = {};
    const CategoryDocTypeData = {
        "name": formData?.name,
    };
    return (
        <div>
            <div className="main-content-overlay">
                Iam Doc Type
                <Box className="main-content-div" style={{ position: "relative" }}>

                    <Box id="VendorDetails" className="form-div ">
                        <Box p={2} id="rjsf">
                            <Button
                                onClick={(e) => {
                                    navigate(-1);
                                }}
                            >
                                <ArrowBackIcon />
                                <Typography
                                    sx={{
                                        fontSize: "13px",
                                        textDecoration: "underline",
                                        fontWeight: "600",
                                        fontSize: "12px",
                                        lineHeight: "15px",
                                        color: "#0075FF",
                                    }}
                                >
                                    {BACK_BUTTON}
                                </Typography>
                            </Button>
                            <Form
                                schema={DocTypeSchema}
                                validator={validator}
                                uiSchema={CategoryDocTypeUiSchema}
                                formData={CategoryDocTypeData}
                                className="batch-form-styling"
                                onChange={(e) => {
                                    console.log("e", e)
                                }}
                                onSubmit={(e) => {
                                    createForm(e.formData);
                                }}
                            ></Form>


                        </Box>
                    </Box>
                </Box>
            </div>


        </div>
    )
}

export default DoctypeFormLab