import { useTranslation } from "react-i18next";
import useTranslatewrapper from "../../../components/useTranslateWrapper";

export default function useBatchDetailsSchemaFn() {
  // const {t, i18n} = useTranslation();
  const { translate } = useTranslatewrapper();

  return {
    properties: {
      BatchName: {
        type: "string",
        title: translate('name'),
      },
      RawMaterialName: {
        title: translate("rawMaterialName"),
        enum: ["Premix Base", "Iron", "Vitamin B9"],
        enumNames: [translate("premixBase"), translate("iron"), translate("vitaminB9")]
      },
      RawMaterialType: {
        title: translate("rawMaterialType"),
        enum: [""],
      },
      RawMaterialTypeOther: {
        title: "Raw Material Type used",
        type: "string",
      },
      UserDefinedBatchNo: {
        type: "string",
        title: "Manufacturer Batch Number",
      },
      BatchNoOfPremixManufracturer: {
        type: "string",
        title: "Batch Number(Auto Generated)",
        readOnly: true,
      },
      ManufacturingDate: {
        type: "string",
        title: translate("manufacturingDate"),
      },
      DispatchDate: {
        type: "string",
        title: "Date Of Dispatch",
      },
      ExpiryDate: {
        title: translate("expiryDate"),
        type: "string",
      },
      PremixPercentageNeededForFRK: {
        type: "number",
        title: translate("percentInMix"),
        maximum: 100,
        minimum: 0,
      },
      TotalQuantity: {
        title: translate("totalQuantityInKgs"),
        type: "number",
        minimum: 0.01,
      },
      RemainingQuantity: {
        title: translate("remainingQuantityInKgs"),
        type: "number",
        minimum: 0,
        readOnly: true,
      },
      BatchSize: {
        type: "string",
        title: "Bag Sizes",
      },

      CropYear: {
        title: "Crop Year",
        type: "number",
        minimum: 0,
      },
      RiceType: {
        title: translate("riceType"),
        enum: ["Steam rice naadu variety", "Raw", "Boil"],
        enumNames: ["Steam rice naadu variety", translate('raw'),translate('boil')]
      },
      Grade: {
        title: "Grade",
        type: "string",
      },
      Variety: {
        title: "Variety",
        type: "string",
      },
      GetAllFiles: {
        title: "Upload File",
        type: "string",
      },
    },
    required: [
      "BatchName",
      // "PremixPercentageNeededForFRK",
      "TotalQuantity",
      "CropYear",
      "RiceType",
      "Variety",
      "Grade",
    ],
  };
}
