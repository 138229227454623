
import * as React from "react";
import { FILTER_BUTTON_TEXT } from "../../textConstant";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Button } from "@mui/material";
import { buttonStyles } from "./buttonstyle";
export default function FilterButton(props) {
    const { handleClickOpen } = props;
    return (
        <Button
            variant="outlined"
            style={buttonStyles}
            onClick={handleClickOpen}
        >
            <FilterAltIcon />
        </Button>

    );
}


