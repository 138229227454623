import React, { useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import PremixSections from "../Form/PremixSections/PremixSections";

export default function RawMaterialDetails() {

    const routeParams = useParams();

    return (
        <PremixSections categoryId={routeParams.categoryId} />
    )
}
