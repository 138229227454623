import React, { useState } from "react";
import Box from "@mui/material/Box";
import { CircularProgress } from "@mui/material";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import GridItem from "../../GridItem/GridItem";
import Paper from "@mui/material/Paper";
import "../../../App.css";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { useParams } from "react-router-dom";
import { getUsageDetailsExcel } from "../../../services/FortificationService";

const Usage = (props) => {
  const { rowData, columnDefs } = props;
  const [loader, setLoader] = React.useState(false);

  const [gridApi, setGridApi] = useState(null);

  const RowSelectionType = "single";
  const getParams = useParams();

  function onGridReady(params) {
    setGridApi(params.api);
  }

  const getExcel = ()=>{
    getUsageDetailsExcel(getParams.categoryId, getParams.id, getParams.type, props.batchNo)
  }

  return (
    <div>
      {loader ? (
        <Box className="main-content-overlay" sx={{ height: "100vh" }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box component={Paper} className="form-div " >
          <div>
        <a
          onClick={getExcel}
          href="javascript:void(0)"
        >
          <span style={{ fontSize: "1rem" }}>Download</span>
          <FileDownloadOutlinedIcon sx={{ fontSize: "1.2rem" }} m={1} />
        </a>
      </div>
          <div>
            <GridItem
              onGridReady={onGridReady}
              rowData={rowData}
              rowSelection={RowSelectionType}
              columnDefs={columnDefs}
              pagination={false}
              defaultColDef={{resizable: true}}
            />
          </div>
        </Box>
      )}
    </div>
  );
};

export default Usage;
