import React, {
    useEffect,
    useState,
    useRef,
    useCallback,
    useMemo,
} from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import "../../../../App.css";
import { CircularProgress, Grid, Typography } from "@mui/material";
import { useSelector, } from "react-redux";
import { useNavigate, } from "react-router-dom";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import _ from "lodash";
import { getLabPageGrid, getState} from "../../../../services/adminService";
import { MAIN_BLUE } from "../../../../color";
import GridItem from "../../../GridItem/GridItem";
import CreateButton from "../../../../components/CommonFilterCreateButton/CreateButton";
import { PAGE_SIZE_ARRAY } from "../../../../constants";
import SearchBar from "../../../../components/CommonSearchBar/Search";
import FilterButton from "../../../../components/CommonFilterCreateButton/FilterButton";
import SimpleDialogDemo from "../../../../components/Filter/FilterPopup";
import DeletableChips from "../../../../components/Chips/DeletableChip";
import { useAppDispatch, useAppSelector } from "../../../../services/StoreHooks";
import { getManufacturerFilterData } from "../../../../services/FortificationService";
import moment from "moment";
import KeycloakService from "../../../../services/KeycloakService";
import useTranslatewrapper from "../../../../components/useTranslateWrapper";

const LabPageGrid = () => {
  const {translate} = useTranslatewrapper()

  const categoryNameToIdSelectorRedux = useAppSelector((state) =>
    state?.categoriesData?.categories
  );

  const categoryNameToIdMapRedux = useMemo(()=>{
    return (
      categoryNameToIdSelectorRedux.reduce(
      (acc, current) => {
        acc[current.name] = current.id.toString();
        return acc;
      },
      {}
    ))
  }, [categoryNameToIdSelectorRedux])

    // const routeParams = useParams();
    const [loader, setLoader] = React.useState(false);
    const [gridApi, setGridApi] = useState(null);
    const [options, setOptions] = React.useState([]);
    const [clear, setclear] = useState(false);
    const [categories, setCategories] = useState([])
    const dispatch = useAppDispatch();
    const [manufactureOptions, setManufacturerOptions] = React.useState([]);
    const [targetmanufactureOptions, setTagretManufacturerOptions] = React.useState([]);
    const [filterStates, setFilterStates] = React.useState([]);
    const [activeFilters, setActiveFilters] = React.useState({});
    const [searchValue, setSearchValue] = React.useState("");
    // const isInspector = true
    const dateValue = (date) => {
        if (date) {
          return moment.utc(date).local().format("yyyy/MM/DD");
        } else {
            return "";
        }
    };
    const categoriesData = useSelector((state) => state.categoriesData);


    useEffect(() => {
      setCategories(categoriesData?.categories?.filter(item=>!item.independentBatch)?.map(cat => { return { id: cat.id, value: cat.name, label: cat.name } }))
    }, [categoriesData])
    const [manufacturerId, setmanufacturerId] = React.useState([]);
    const [filterManufacturer, setFilterManufacturer] = React.useState([]);
    const [filterTargetManuf, setFilterTargetManuf] = React.useState([]);
    const [tagetManufacturerId, setTargetManufacturerId] = React.useState([]);
    const [state, setState] = useState([])
    const [stateId, setStateId] = React.useState([]);
    const [district, setDistrict] = useState([])
    const [openFilter, setOpenFilter] = React.useState(false);
    const [chip, setChip] = useState("");
    const [filterData, setFilterData] = React.useState({
        state: [],
        manufacturerIds: [],
        targetManufacturerIds: [],
    });
    const [searchCriteria, setSearchCriteria] = useState(
        { searchCriteriaList: [{ key: "stateId", value: "" }, 
                               { key: "districtId", value: '' }, 
                               { key: "categories", value: "" }, 
                               { key: "search", value: chip }] }
    )
    const [vendorTypeData, setVendorTypeData] = React.useState([
        {id:"1",label:"Manufacturer",value:"Manufacturer"},
        {id:"2",label:"Trader",value:"Trader"},
        {id:"3",label:"Broker",value:"Broker"},
    ]);
      const [manufacturerTypeData, setManufacturerTypeData] = React.useState([
        {id:"1",label:"Public",value:"Public"},
        {id:"2",label:"Private",value:"Private"},
      ]);
    const [columnDefsUser, setColumnDefsUser] = React.useState([
        {
        field: "name",
        headerName: translate('name'),
        hide: false,
        filter: "agTextColumnFilter",
        floatingFilterComponentParams: {
            suppressFilterButton: true,
        },
        flex: 3,
        filterParams: {
            buttons: ["apply", "clear"],
        },
        },
        {
          field: "certificateNo",
          headerName: translate('tcNumber'),
          minWidth: 200,
          hide: false,
          tooltipField: "certificateNo",
          filter: "agTextColumnFilter",
          floatingFilterComponentParams: {
              suppressFilterButton: true,
          },
          filterParams: {
              buttons: ["apply", "clear"],
          },
      },
        {
            field: "isMandate",
            headerName: "Description",
            hide: true,
            valueFormatter: (params) => {
                return params.data.isMandate;
            },
            filter: "agTextColumnFilter",
            floatingFilterComponentParams: {
                suppressFilterButton: true,
            },
            flex: 2,
            filterParams: {
                buttons: ["apply", "clear"],
            },
            },
            {
                field: "isPremixEnabled",
                headerName: translate('premix'),
                hide: false,
                filter: "agTextColumnFilter",
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
                flex: 1,
                filterParams: {
                    buttons: ["apply", "clear"],
                },
            },
            {
                field: "isFrkEnabled",
                headerName: translate('frk'),
                hide: false,
                filter: "agTextColumnFilter",
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
                flex: 1,
                filterParams: {
                    buttons: ["apply", "clear"],
                },
            },
            {
                field: "isMillerEnabled",
                headerName: translate('miller(FR)'),
                hide: false,
                filter: "agTextColumnFilter",
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
                flex: 1,
                filterParams: {
                    buttons: ["apply", "clear"],
                },
            },
           
]);

const getStateData = async () => {
  const StateData = await getState(1);
  StateData && setState(StateData.data.data);
};


useEffect(() => {
  getStateData();
}, [])


let arrFilterStates = [];
    const gridRef = useRef();
    const [rowData, setRowData] = useState([]);
    const [pageSize, setPageSize] = React.useState(12);
    const [pageNo, setPageNo] = React.useState(1);
    const [count, setCount] = React.useState(1);
    const [chipsState, setChipsState] = React.useState({});
    const filterUserDataRedux = useSelector((state) => state.filterUserData);
    const filterDataRedux = useSelector((state) => state.filterData);

    const RowSelectionType = "single";
    // const filterDataRedux = useSelector((state) => state.filterUserData);
    const navigate = useNavigate();

    let chipMap = {};
    const updateChips = () => {
      filterDataRedux.searchCriteriaList.map((entry) => {
        switch (entry.key) {
          case "search":
            if (entry.value) {
              chipMap["search"] = entry.value;
            }
            break;
          case "categories":
            if (entry.value !== "" && entry.value)
              chipMap["categories"] = entry.value;

            break;

          case "states":
            if (entry.value !== "" && entry.value)
              chipMap["states"] = entry.value;

            break;
          case "districts":
            if (entry.value !== "" && entry.value)
              chipMap["districts"] = entry.value;

            break;
          case "categories":
            if (entry.value !== "" && entry.value) {
              chipMap["categories"] = entry.value;
            }

            break;
          case "manufacturerType":
            if (entry.value !== "" && entry.value) {
              chipMap["manufacturerType"] = entry.value;
            }

            break;
          case "vendorType":
            if (entry.value !== "" && entry.value) {
              chipMap["vendorType"] = entry.value;
            }

            break;
        }
      });

      setChipsState(chipMap);
    };


    useEffect(() => {
        setLoader(true);
        setPage(1);
        setRowData([]);
        // fetchIamDocTypeData(pageNo, pageSize, filterDataRedux.searchCriteriaList[0].value, filterDataRedux);
        setCount(0);
        updateChips();
    }, [filterDataRedux, filterUserDataRedux]);

    const setPage = (page) => {
        setPageNo(page);
        fetchIamDocTypeData(page, pageSize,filterDataRedux);
    };
    const counter = () => {
        return count % pageSize === 0
            ? Math.floor(count / pageSize)
            : Math.floor(count / pageSize) + 1;
    };

    const fetchIamDocTypeData = async (pageNo, pageSize, body) => {
        const fetchData = await getLabPageGrid(pageNo, pageSize, body);
        const arr = []
        if (fetchData?.data?.data) {
            setCount(fetchData?.data?.count);
            fetchData?.data?.data.map((data) => 
            arr.push({
                name : data?.name,
                id :data?.id ,
                certificateNo: data?.certificateNo,
                isMandate: data?.description,
                isPremixEnabled : (data?.labCategories?.filter(obj => obj.categoryId?.toString() == categoryNameToIdMapRedux["PREMIX"])[0]?.isEnabled ? "Yes" : "No"),
                isFrkEnabled : (data?.labCategories?.filter(obj => obj.categoryId?.toString() == categoryNameToIdMapRedux["FRK"])[0]?.isEnabled ? "Yes" : "No"),
                isMillerEnabled : (data?.labCategories?.filter(obj => obj.categoryId?.toString() == categoryNameToIdMapRedux["MILLER"])[0]?.isEnabled ? "Yes" : "No")
            })
            )
            setRowData(arr)
        }
        setLoader(false)
    };


    const handleClose = () => {
        setOpenFilter(false);
      };
      const handleClickOpen = () => {
        setOpenFilter(true);
      };
  
      const handleSelectChange = (values) => {
        if (arrFilterStates.length == 0) {
          values &&
            values.length != 0 &&
            values?.map((value) => {
              arrFilterStates.push(value.id);
            });
          setFilterStates(arrFilterStates);
        }
      };
  
      const deleteChip = (chipKey, chipValue) => {
          var json = filterDataRedux.searchCriteriaList;
          var json1 = structuredClone(json);
    
          if (chipKey === "categories") {
            for (let obj of json1 ){
              if(obj.key == chipKey){
                const index = obj.value.indexOf(chipValue);
                if (index > -1) {
                  obj.value.splice(index, 1);
                }
              }
            }
          }
          if (chipKey === "search") {
            for (let obj of json1 ){
              if(obj.key == chipKey){
                obj.value = ""
                const index = obj.value.indexOf(chipValue);
                if (index > -1) {
                  json1[1].value.splice(index, 1);
                }
              }
            }
          }
          if (chipKey === "states") {
            for (let obj of json1 ){
              if(obj.key == chipKey){
                obj.value = ""
              }
            }
          }
          if (chipKey === "manufacturerType") {
            for (let obj of json1 ){
              if(obj.key == chipKey){
                obj.value = ""
              }
            }
          }
          if (chipKey === "vendorType") {
            json1[5].value = "";
          }
          if (chipKey === "districts") {
  
            for (let obj of json1 ){
              if(obj.key == chipKey){
                const index = obj.value.indexOf(chipValue);
                if (index > -1) {
                  obj.value.splice(index, 1);
                  if (obj.value.length == 0) obj.value = [null];
                }
              }
            }
          }
    
          if (json1[6].value.length == 0) json1[6].value = [null];
          if (json1[3].value.length == 0) json1[3].value = [null];
          if (json1[7].value.length == 0) json1[7].value = [null];
          if (json1[8].value.length == 0) json1[8].value = [null];
          dispatch({
            type: "UPDATE_FILTER",
            data: json1,
          });
        };
  
    function onGridReady(params) {
        setGridApi(params.api);
    }

    const onRowClicked = useCallback(() => {
        const selectedRows = gridRef.current.api.getSelectedRows();
        navigate(`/admin/lab/labs/details/${selectedRows[0].id}`)

    }, []);

    const setFiltersSearch = (data) => {
        var json = filterDataRedux.searchCriteriaList;
        var json1 = structuredClone(json);
        json1[0].key = "search";
        if (data == null) {
          json1[0].value = "";
        } else {
          json1[0].value = data.label;
          setChip(data.label);
        }
        dispatch({
          type: "UPDATE_FILTER",
          data: json1,
        });
      };
  
      const removeSearch = (data) => {
        var json = filterDataRedux.searchCriteriaList;
        var json1 = structuredClone(json);
        if (data != null) {
          json1[0].value = "";
          setChip("");
          setclear(true);
        }
  
        dispatch({
          type: "UPDATE_FILTER",
          data: json1,
        });
      };

      const setFilters = (filterData) => {
        var json = filterDataRedux.searchCriteriaList;
        var json1 = structuredClone(json);
        if (filterStates.length != 0) {
          setFilterData({ ...filterData, state: filterStates });
          json1[3].value = filterStates;
          json1[6].value = filterStates;
        } else {
          json1[3].value = [null];
          json1[6].value = [null];
        }
        if (filterManufacturer.length != 0) {
          setFilterData({ ...filterData, manufacturerIds: filterManufacturer });
          json1[7].value = filterManufacturer;
        } else {
          json1[7].value = [null];
        }
        if (filterTargetManuf.length != 0) {
          setFilterData({
            ...filterData,
            targetManufacturerIds: filterTargetManuf,
          });
          json1[7].value = filterTargetManuf;
        } else {
          json1[8].value = [null];
        }
  
        json1[0].value = searchValue;
        json1[1].value = `${dateValue(filterData.mnfStartDate)}${"-"}${dateValue(
          filterData.mnfEndDate
        )}`;
          json1[2].value = `${dateValue(filterData.expStartDate)}${"-"}${dateValue(
            filterData.expEndDate
            )}`;
        dispatch({
          type: "UPDATE_FILTER",
          data: json1,
        });
      };


    return (
        <div>
            {loader ? (
                <Box className="main-content-overlay" sx={{ height: "100vh" }}>
                    <CircularProgress />
                </Box>
            ) : (
                <div>
                    <Box className="main-content-overlay">
                        <div className="main-content-div">
                            <Box m={2}>
                                <Grid container>
                                    <Grid item md={3} sd={12} p={1}>
                                        <Typography variant="h6" sx={{ color: MAIN_BLUE, mx: 1 }}>
                                            Labs
                                        </Typography>
                                    </Grid>
                                    <Grid item md={5}>
                      <Stack direction="row" sx={{ my: 1.5, flexWrap: "wrap" }}>
                        {Object.keys(chipsState).map((key) => (
                          <DeletableChips
                            chipValue={chipsState[key]}
                            chipKey={key}
                            deleteChip={deleteChip}
                            options={options}
                            categories={categories}
                            manufactureOptions={manufactureOptions}
                            targetmanufactureOptions={targetmanufactureOptions}
                            sx={{ maxHeight: "2rem !important" }}
                            state={state}
                            district={district}
                          />
                        ))}
                      </Stack>
                    </Grid>
                                    <Grid item md={4} sx={{ alignItems: 'center', display: 'flex', }}>
                                    <SearchBar
                        onChange={(e) => {
                          if (e !== "") {
                            setFiltersSearch(e);
                          } else {
                            removeSearch();
                          }
                        }}
                      />

{openFilter ? (
                        <div>
                          <SimpleDialogDemo
                            chipsState={chipsState}
                            open={openFilter}
                            onClose={handleClose}
                            filterData={filterData}
                            setFilterData={setFilterData}
                            setFilters={setFilters}
                            filterStates={filterStates}
                            setActiveFilters={setActiveFilters}
                            activeFilters={activeFilters}
                            stateId={stateId}
                            setStateId={setStateId}
                            handleSelectChange={handleSelectChange}
                            options={options}
                            categories={categories}
                            isInspector={true}
                            manufacturerId={manufacturerId}
                            tagetManufacturerId={tagetManufacturerId}
                            manufactureOptions={manufactureOptions}
                            targetmanufactureOptions={targetmanufactureOptions}
                            state={state}
                            district={district}
                            setDistrict={setDistrict}
                            hideDateFilter={true}
                            setSearchCriteria={setSearchCriteria}
                            searchCriteria={searchCriteria}
                            manufacturerTypeData={manufacturerTypeData}
                            vendorTypeData={vendorTypeData}
                          />
                        </div>
                      ) : (
                        <FilterButton handleClickOpen={handleClickOpen} />
                      )}

                    </Grid>

                                    <Grid
                                      item
                                      md={4}
                                      sx={{ alignItems: "center", display: "flex" }}
                                    >
                                        <CreateButton
                                            onClick={() => {
                                                navigate(`/admin/lab/labs/details`)
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Box sx={{ m: 1 }}>
                                    <GridItem
                                        onGridReady={onGridReady}
                                        rowData={rowData}
                                        gridRef={gridRef}
                                        rowSelection={RowSelectionType}
                                        columnDefs={columnDefsUser}
                                        pageSize={pageSize}
                                        pageSizeArray={PAGE_SIZE_ARRAY}
                                        count={counter()}
                                        setPage={setPage}
                                        page={pageNo}
                                        onSelectionChanged={onRowClicked}
                                        height="main"
                                    />
                                </Box>
                            </Box>
                        </div>
                    </Box>
                </div>
            )}
        </div>
    );
};

export default LabPageGrid;
