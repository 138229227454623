
import * as React from "react";
import AddIcon from '@mui/icons-material/Add';
import { Fab } from "@mui/material";

export default function CreateButton(props) {
  const { onClick } = props;
  return (
    <>
      {
        <Fab
          color="primary"
          style={{
            right: 40,
            bottom: 50,
            position: "fixed",
          }}
          onClick={onClick}

        >

          <AddIcon />
        </Fab>
      }
    </>
  );
}