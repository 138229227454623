export const buttonStyles = {
    padding: "4px 4px",border:"1px solid #0075ff",
    float: "right", margin: "8px", width: "auto",
    maxHeight: "2rem", borderRadius: "100px",
    color: "#0075ff"  ,minWidth:"0px"
  }
  export const buttonStyles1 = {
    padding: "10px 10px",border:"1px solid ",
    float: "right", margin: "8px", width: "auto",
    maxHeight: "2rem", borderRadius: "100px",
    color: "white"  ,minWidth:"0px"
  }  