import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { Stack } from "@mui/system";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Typography, Divider } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./DialogElements.css";
import { useSelector, useDispatch } from "react-redux";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import {
  APPLY_BUTTON_TEXT,
  ROLE,
  SELECT_FILTER,
  CATEGORY,
  CLEAR_BUTTON
} from "../../textConstant";
import Select from "react-select";
import { useParams,useLocation } from "react-router-dom";
import useTranslatewrapper from "../useTranslateWrapper";
export const FilterUser = (props) => {
  const {translate} = useTranslatewrapper()

  const { chipsState, onClose, selectedValue, open, categoryoptions, roleoptions, status ,hideRoleFilter} =
    props;
  categoryoptions.forEach(element => {
    element.label = translate(element.label)
  });

  roleoptions.forEach(element => {
    element.label = translate(element.label)
  });

  status.forEach(element => {
    element.label = translate(element.label)
  });

  const filterDataRedux = useSelector((state) => state.filterUserData);
  const dispatch = useDispatch();
  let defaultValuesCat;
  let defaultValuesRole;
  let defaultValuesStatus;
  defaultValuesCat = [];
  defaultValuesRole = [];
  defaultValuesStatus=[];
  chipsState?.categories?.map((data) => {
    defaultValuesCat.push(categoryoptions.filter((elem) => elem.value === data)[0])
  }
  );

  const location=useLocation();
  chipsState?.roles?.map((data) => {
    defaultValuesRole.push(roleoptions.filter((elem) => elem.value === data)[0])
  }
  );
  chipsState?.status?.map((data) => {
    defaultValuesStatus.push(status.filter((elem) => elem.value === data)[0])
  }
  );
  const [defaultCategory, setDefaultCategory] = React.useState(
    chipsState?.categories ? chipsState?.categories : []
  );
  const [defaultRole, setDefaultRole] = React.useState(
    chipsState?.roles ? chipsState?.roles : []
  );
  const [defaultStatus, setDefaultStatus] = React.useState(
    chipsState?.status ? chipsState?.status : []
  );
  const [selectedValueNewCat, setSelectedValueNewCat] = React.useState(defaultValuesCat);
  const [selectedValueNewRole, setSelectedValueNewRole] = React.useState(defaultValuesRole);
  const [selectedValueNewStatus, setSelectedValueNewStatus] = React.useState(defaultValuesStatus);


  const handleCategoryChange = (values) => {
    setSelectedValueNewCat(values);
    let arr = [];
    values &&
      values?.length != 0 &&
      values?.map((value) => arr.push(value.value));
    setDefaultCategory(arr);
  };
  const handleRoleChange = (values) => {
    setSelectedValueNewRole(values);
    let arr = [];
    values &&
      values?.length != 0 &&
      values?.map((value) => arr.push(value.value));
    setDefaultRole(arr);
  };
  const handleStatusChange = (values) => {
    setSelectedValueNewStatus(values);
    let arr = [];
    values &&
      values?.length != 0 &&
      values?.map((value) => arr.push(value.value));
    setDefaultStatus(arr);
  };
  const setFilters = () => {

    var json = filterDataRedux.searchCriteriaList;
    var json1 = structuredClone(json);
    if (defaultCategory.length != 0) {
      json1[1].value = defaultCategory;
    }
    else {
      json1[1].value = [null];
    }
    if (defaultRole.length != 0) {
      json1[2].value = defaultRole;
    }
    else {
      json1[2].value = [null];
    }
    if(defaultStatus.length != 0){
      json1[3].value = defaultStatus;
    }
    else {
      json1[3].value = [null];
    }
    dispatch({
      type: "UPDATE_USER_FILTER",
      data: json1,
    });
  };


  const handleApply = () => {
    onClose(selectedValue);
    setFilters()
  };
  const handleClear = () => {
    handleCategoryChange([]);
    handleRoleChange([]);
    handleStatusChange([]);
    setDefaultCategory([null]);
    setDefaultRole([null]);
    setDefaultStatus([null]);

  };
  return (
    <Dialog
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: { width: 420, height: 420 },
      }}
    >
      <Stack direction="row" justifyContent={"space-between"} alignItems={"center"}>
        <Typography p={2} sx={{ fontWeight: "bold", fontFamily: "outfit" }}>
          {translate('filterData')}
        </Typography>
        <CloseIcon onClick={onClose} sx={{ marginRight: "16px" }} />
      </Stack>
      <Divider sx={{ marginBottom: "1rem" }} />
      <Stack
        direction="column"
        spacing={2}
        sx={{ marginRight: "2rem", marginLeft: "2rem" }}
      >
        <Typography style={{ fontWeight: "400", fontFamily: "outfit", width: "50%" }}>
          {translate('category')}
        </Typography>

        <Select
          onChange={handleCategoryChange}
          isMulti
          options={categoryoptions.map(item=>({...item, 'label': translate(item.label) }))}
          value={selectedValueNewCat}
        />
        { !hideRoleFilter && <>
        <Typography style={{ fontWeight: "400", fontFamily: "outfit", width: "50%" }}>
          {translate('role')}
        </Typography>
        <Select
          onChange={handleRoleChange}
          isMulti
          options={roleoptions.map(item=>({...item, 'label': translate(item.label) }))}
          value={selectedValueNewRole}
        />
        </>
}
        
        <Typography style={{ fontWeight: "400", fontFamily: "outfit", width: "50%" }}>
        {translate('status')}
        </Typography>

        <Select
          onChange={handleStatusChange}
          isMulti
          options={status.map(item=>({...item, 'label': translate(item.label) }))}
          value={selectedValueNewStatus}
        />
      </Stack>

      <Divider sx={{ marginTop: "1rem", marginBottom: "1rem" }} />
      <Stack direction="row" justifyContent={"space-between"} spacing={2}
        sx={{ marginRight: "2rem", marginLeft: "2rem" }}>
        <Button onClick={handleClear} sx={{ width: "49%" }} variant="contained">
          {translate('clear')}
        </Button>
        <Button onClick={handleApply} sx={{ width: "49%" }} variant="contained">
        {translate('apply')}
        </Button>
      </Stack>
    </Dialog>
  );
};
