import React, { Fragment, useEffect } from "react";
import { ApplicationRoutes } from "./routes";
import { Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import StoreService from "./services/StoreService";
import ProtectedRouteComponent from "./components/ProtectedRouteComponent";
import ScrollToTop from "./utils/ScrollToTop";
import Sidemenu from "./components/BarsOverlay/BarsOverlayComponent";
import NotificationSelector from "./NotificationSelector";
import { getMenuItems } from "./services/MenuItems";
import LoaderComponent from "../src/components/Loader/LoaderComponent";
import { useDispatch } from "react-redux";
import { getMenuDetails, saveMenuDetails } from "./modules/menuSlice";
import { MENU_DETAILS, MILLER_UI_TEXT } from "../src/textConstant";
import { useAppSelector, useAppDispatch } from "./services/StoreHooks";
import KeycloakService from "./services/KeycloakService";
import { adminCategoryDocDropDown } from "./services/adminService";
import { useTranslation } from "react-i18next";
const store = StoreService;

function App() {

  const { t : translate, i18n } = useTranslation();
  // const [language, setLanguage] = React.useState(localStorage.getItem('preferredLang') || 'en')
  const [language, setLanguage] = React.useState('en')
  
  useEffect(()=>{    
    i18n.changeLanguage(language);
  }, [language])

  const loaderStatus = useAppSelector((state) => state.loaderStatus);
  const storeMenuDetails = useAppSelector(getMenuDetails);
  const [menuDetails, setMenuDetails] = React.useState([]);
  const [loader, setLoader] = React.useState(false);
  const userdetails = KeycloakService.getUserDetail();
  const searchAdmin = userdetails
    ?.role.slice(",")
    .slice(" ")
    .includes("ADMIN");
  const isSuperAdmin = userdetails?.isSuperAdmin;
  const isMonitor = userdetails?.isMonitor;
  const userrole = userdetails?.role;
  const isInspector = userdetails?.isInspector;
  const assignNodeId = (nodeArr, initialId, categoryDetail) => {
    let currId;
    if (initialId) currId = initialId;
    else currId = 0;
    return {
      entityStates: nodeArr.map((ele) => {
        if (ele.entityStates) {
          const { entityStates, id } = assignNodeId(ele.entityStates, currId, {
            categoryId: ele.categoryId,
            categoryName: (ele.categoryName.toLowerCase() == 'miller') ? 'Miller(FR)' : ele.categoryName,
          });
          ele.entityStates = entityStates;
          currId = id;
        }
        if (ele.states) {
          const { entityStates, id } = assignNodeId(ele.states, currId);
          ele.states = entityStates;
          currId = id;
        }
        currId++;
        return {
          ...ele,
          nodeId: currId.toString(),
          categoryId: ele?.categoryId || categoryDetail?.categoryId,
        };
      }),
      id: currId,
    };
  };
  const dispatch = useDispatch();

  const fetchData = async () => {

  let categoryNameToIdMapRedux = {};

    const categories = await adminCategoryDocDropDown();
    if (categories.status === 200) {
        if (categories.data.data) {
            dispatch({
                type: "STORE_CATEGORIES",
                data: categories.data.data,
            });

            categoryNameToIdMapRedux = categories.data.data.reduce(
              (acc, current) => {
                acc[current.name] = current.id.toString();
                return acc;
              },
              {}
            )
         
        }
    }
    const menuData = await getMenuItems(isSuperAdmin, isMonitor, userrole, categoryNameToIdMapRedux);
    if (menuData?.data) {
      // if((menuData.data.find(ele=>(ele.categoryName=='FRK'))?.entityStates?.some(ele=>(ele.name == 'BATCH'))) && !isInspector){

        // const indexForReplacing = menuData.data.findIndex(ele=>(ele.categoryName=='Rice Mix'))
        // if(indexForReplacing && indexForReplacing != -1){

          
          // menuData.data.splice(indexForReplacing+1,0,{
          //   categoryName: 'Self Declared Premix Batch',
          //   entityStates: [{}],
          // })
        // }
      // }

      const refactoredMenuData = assignNodeId(menuData.data);

      let listData = [];
      if(isInspector){
        listData.push(generateManageMenu(refactoredMenuData.entityStates)
        // ,...refactoredMenuData.entityStates
        );
      } 
      else{
        listData.push(...refactoredMenuData.entityStates);

      }
      if (!(isSuperAdmin || isMonitor || isInspector)) {
        const inventoryMenuItem = generateInventoryMenu(
          refactoredMenuData.entityStates
        );
        listData.push(inventoryMenuItem);
        if (searchAdmin) {
          listData.push(generateManageMenu(inventoryMenuItem.entityStates));
        }
      }

      if((menuData.data.find(ele=>(ele.categoryName=='FRK'))?.entityStates?.some(ele=>(ele.name == 'BATCH'))) && !isInspector){

        listData.splice(2,0,{
          categoryName: 'Self Declared Premix Batch',
          entityStates: [{
            categoryId: 2,
            name: 'BATCH',
            nodeId: '91'
          }],
          nodeId: '92'
        })
      }

      setMenuDetails(listData);
    }
    setLoader(false);
  };

  useEffect(() => {
    dispatch(saveMenuDetails(menuDetails));
  }, [menuDetails]);

  useEffect(() => {
    setLoader(true);
    fetchData();
  }, []);

  const generateInventoryMenu = (menuItem) => {
     let currentNodeId = Number(menuItem[menuItem.length - 1]?.nodeId);
         currentNodeId = currentNodeId + 1;
         const inventoryParent = {
         entityStates: [],
         nodeId: currentNodeId.toString(),
         categoryName: "Inventory",
         categoryId: menuItem[0].categoryId,
         };
         const childItem = [];
    const convert = (isIndependent,result, str) => {
      result = ((result === 'MILLER') ? MILLER_UI_TEXT : result)
      const obj = {
        lot: ' lots',
        batch: ' batches'
      }
      if (!str) {
        return ""
      }
      else if (isIndependent){
        return result
      }
      else if (str.toLowerCase().includes("batch")) {
        return result + obj.batch
      }
      else if (str.toLowerCase().includes("lot")) {
        return result + obj.lot
      }
      else{
        return result
      }
    }
     menuItem.forEach((item) => {
         item.entityStates.forEach((eState=> {
         if(eState.inventory){
         currentNodeId = currentNodeId + 1;
         const currentItem = { ...item };
         delete currentItem.entityStates;
         currentItem.name = convert(currentItem?.independentBatch ,currentItem?.categoryName ,eState?.name )
         delete currentItem.categoryName;
         currentItem.nodeId = currentNodeId.toString();
         currentItem.states = [];
         currentItem.wildCardEntry = "1";
         childItem.push(currentItem);}
        }))
      });
      inventoryParent.entityStates = [...childItem];
      return inventoryParent;
    };
  const generateManageMenu = (menuItem) => {
    let currentNodeId = Number(menuItem[menuItem.length - 1]?.nodeId);
    currentNodeId = currentNodeId + 1;
    const manage = {
      entityStates: [],
      nodeId: currentNodeId.toString(),
      categoryName: "Manage",
      categoryId: Number(menuItem[0].categoryId) + 1,
    };
    const childItem = [];
    currentNodeId = currentNodeId + 1;
    let currentItem ={};
    if(isInspector){
      currentItem = {
       name: "Manufacturer",
       nodeId: currentNodeId.toString(),
       states: [],
       wildCardEntry: "4",
     };
    }
    else{
      currentItem = {
        name: "User",
        nodeId: currentNodeId.toString(),
        states: [],
        wildCardEntry: "2",
      };
    }
    childItem.push(currentItem);
    manage.entityStates = [...childItem];
    return manage;
  };

  return (
    <>
      {loader || !storeMenuDetails?.length ? (
        <LoaderComponent />
      ) : (
        <Fragment>
          <Sidemenu menuDetails={menuDetails} isSuperAdmin={isSuperAdmin} isMonitor={isMonitor} isInspector={isInspector} searchAdmin={searchAdmin} language={language} setLanguage={setLanguage}  />
          <div
            className={`all-content-backdrop ${
              loaderStatus.showLoader ? "no-overflow" : ""
            }`}
          >
            <div style={{paddingLeft: `${isMonitor ? "12.5%" :'18.5%'}`}} className="main-content-container">
              {loaderStatus.showLoader ? <LoaderComponent loaderType="spinner" /> : ''}
              <NotificationSelector>
                <ScrollToTop>
                  {/* TODO: category id to be changes made generic */}
                  <Routes>
                    {ApplicationRoutes.map((route) => (
                      <Route
                        key={route.key}
                        path={route.path}
                        element={
                          <ProtectedRouteComponent menuDetails={menuDetails} isSuperAdmin= {isSuperAdmin} isMonitor={isMonitor} language={language}  >
                            <route.component isInspector={isInspector} language={language}/>
                          </ProtectedRouteComponent>
                        }
                      />
                    ))}
                  </Routes>
                </ScrollToTop>
              </NotificationSelector>
            </div>
          </div>
        </Fragment>
      )}
    </>
  );
}

export default App;
