export const ChipColorSelector = (type) => {
  switch (type) {
    case "Created":
      return "#ffb780";
    case "Sample sent to Lab":
      return "#ffe6ff";
    case "Sample in Lab":
      return "#ffff99";
    case "Sample tested":
      return "#ccccff";
    case "Rejected":
      return "rgb(251 91 91 / 84%)";
    case "Sample Rejected":
      return "#db7693c7";
    case "Batch ready to be dispatched":
      return "#e6fffa";
    case "Lot dispatched":
      return "#d4d4aa";
    case "Lot received":
      return "#ffb780";
    case "Self tested":
      return "#f1ffc4";
    case "Accepted":
      return "#b3ffb3";
    case "Lot to send back":
      return "#d1b59ecc";
    case "Lot sent back":
      return "#ffa69a";
    case "Rejected lot received":
      return "#fb5b5bd6";
    case "Partially Dispatched":
      return "#9dcd3d96";
    case "Fully Dispatched":
      return "#3d77cd85";
    default:
      return "white";
  }
};
