import React, { useEffect,useState } from 'react'
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import {Box,Rating,Typography,Button,CircularProgress,CircularProgressWithLabel} from "@mui/material"
import RatingComponent from '../../../../components/RatingComponent/RatingComponent';
import RatingTable from './RatingTable';


const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  }));
  
  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));
  
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(1),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));
  









function RatingAccordion({key,data}) {
  const [ratingArr,setRatingArr]=useState(data);
  useEffect(()=>{
    setRatingArr(data);
  },[data])

const readOnly=true;
    const [expanded, setExpanded] = React.useState('panel');

    const handleChange = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };
    
    
  return (
    <div key={key}>




<Accordion 
// expanded={expanded === 'panel1'} 
          defaultExpanded={true}

onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" >
            <div style={{width:"100%",display:"flex",justifyContent:"space-between",paddingRight:"10%"}}>
          <Typography>{ratingArr?.categoryName}</Typography>





          <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
        {/* <Typography>{data.overallRating}</Typography> */}

        </Typography>
      </Box>
    </Box>





            </div>
        </AccordionSummary>
        <AccordionDetails>
        <Box>
    <ul>
        { ratingArr?.items?.map((item,idx)=>{
            return(
<li>

            <div key={idx} style={{display:"flex",justifyContent:"space-between",gap:"20px" ,paddingRight:"20px"}}> 
                <h6>{item.name}</h6>

      <RatingTable key={item.id} item={item} Val={item.value} toEdit={false}/>

        {/* <RatingComponent totalStars={item.totalStars} starsVal={item.value} readOnly={readOnly} /> */}


            </div>
        </li>
            )

        
        })

}
    </ul>


</Box>
        </AccordionDetails>
      </Accordion>








    </div>
  )
}

export default RatingAccordion