import moment from "moment";
import {CATEGORY, EMAIL, MILLER_UI_TEXT, NAME, ROLE } from "../../../../textConstant";

export const USER_LISTING_HEADER =(translate)=> [
  {
    field: "email",
    headerName: translate('emailId'),
    hide: false,
    filter: "agTextColumnFilter",
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    flex:2,
    filterParams: {
      buttons: ["apply", "clear"],
    },
  },

  {
    field: "userName",
    headerName: translate('userName'),
    hide: false,
    flex:3,
    filter: "agTextColumnFilter",
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    filterParams: {
      buttons: ["apply", "clear"],
    },

  },
  {
    field: "role",
    headerName: translate('role'),
    flex:2,
    hide: false,
    filter: "agTextColumnFilter",
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    filterParams: {
      buttons: ["apply", "clear"],
    },
  },
  {
    field: "category",
    headerName: translate('category'),
    flex:2,
    hide: false,
    filter: "agTextColumnFilter",
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    filterParams: {
      buttons: ["apply", "clear"],
    },
    valueFormatter: (params) => {
      return (params.data.category.replace('MILLER',MILLER_UI_TEXT));
    },
  },
  {
    field: "status",
    headerName: translate('status'),
    flex:2,
    hide: false,
    filter: "agTextColumnFilter",
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    filterParams: {
      buttons: ["apply", "clear"],
    },
  },
 
];
