import React, { useState, useEffect, useRef, Fragment } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import {
  Button,
  TextField,
  MenuItem,
  Typography,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import InfoIcon from "@mui/icons-material/Info";
import Chip from "@mui/material/Chip";
import {
  getUomData,
  getSourceCategoryDropdown,
} from "../../../services/FortificationService";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Autocomplete from "@mui/material/Autocomplete";
import { List, ListItem } from "@mui/material";
import { useParams } from "react-router-dom";
import _, { drop } from "lodash";
import "./SourceCategory.css";
import { updateMixes, getMixes } from "../../../services/FortificationService";
import { useAppDispatch } from "../../../services/StoreHooks";
import { isEqual } from "lodash";
import { ListboxComponent } from "../../../components/InfiniteScroll/ListboxComponent";
import KeycloakService from "../../../services/KeycloakService";
import BatchDetailsSchema from "../BatchDetails/BatchDetailsSchema.json";
import useTranslatewrapper from "../../../components/useTranslateWrapper";

function useDebounce(value, wait = 500) {
  const [debounceValue, setDebounceValue] = useState(value);
  useEffect(() => {
    const timer = setTimeout(() => {
      setDebounceValue(value);
    }, wait);
    return () => clearTimeout(timer);
  }, [value, wait]);
  return debounceValue;
}

function StackComponent({
  data,
  lotSize,
  getBatchSizeLot,
  getBatchDetailLot,
  setComments,
  MaterialData,
  categoryId,
  showButton,
  setShowButton,
  comments,
  mixComments,
  disableVendorData,
  sendFormData,
  setHandleMixDelete,
  chipList,
  setShowButtonAction,
  isInspector,
  setSaveButtonCondition,
  getBatchTreeDataById,
  disableQuantityEdit
}) {
  const {translate} = useTranslatewrapper()
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);

  const [uomData, setUomData] = useState();
  const [chipData, setChipData] = useState([]);
  const [initialMixes, setInitialMixes] = useState([]);
  const [mixUpdated, setMixUpdated] = useState({});
  const [chipDeleteData, setChipDeleteData] = useState([]);

  const [dropDown, setDropDown] = useState([]);
  const [dropDownArray, setdropDownArray] = useState([]);
  const [id, setId] = useState();
  const [size, setSize] = React.useState([]);
  const [sizea, setSizea] = React.useState();

  const userDetails = KeycloakService.getUserDetail();

  const [unit, setUnit] = React.useState("");
  const [quantity, setQuantity] = React.useState("");
  const [instructions, setInstructions] = React.useState(comments);
  const [initialComments, setInitialComments] = React.useState(comments);
  const [hideSaveButton, setHideSaveButton] = React.useState(true);
  const [commentsChanged, setCommentsChanged] = React.useState(false);

  const [fields, setFields] = React.useState({
    sourceLotId: "",
    quantityUsed: "",
    uomId: "",
  });
  const [dropData, setDropData] = useState([]);
  const [sizeData, setSizeData] = useState([]);
  const [deletedId, setDeleteId] = useState();
  const [searchString, setSearchString] = useState("");
  const [categoryDropdownData, setCategoryDropdownData] = useState({});
  const [sourceCategoryId, setSourceCategoryId] = useState(null);
  const [sizeLoading, setSizeLoading] = useState(false);
  const debounceInput = useDebounce(searchString);
  const pageSize = 10;
  const [pageNumber, setPageNumber] = useState(1);
  const [activeRow, setActiveRow] = useState(null);
  const [mixesValidated, setMixesValidated] = useState(false);
  const dispatch = useAppDispatch();
  const [hover, setHover] = useState(false);


  const getId = useParams();

  const saveButtonCondition=getId.id &&
  (!hideSaveButton || commentsChanged ||
    chipData?.filter(
      (data) =>
        data.id == "" &&
        data.size !== "" &&
        data.sizeChipData !== "" &&
        data.unit !== "" &&
        data.quantity !== ""
    )?.length > 0)


  setSaveButtonCondition(saveButtonCondition);

  const onHover = () => {
    setHover(true);
  };

  const onLeave = () => {
    setHover(false);
  };

  useEffect(() => {
    if (sourceCategoryId) {
      fetchData(sourceCategoryId, 1);
    }
  }, [debounceInput]);
  //mixes Validation
  const dispatchNotification = (typ, msg) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      data: {
        open: true,
        type: typ,
        message: msg,
      },
    });
  };

  const checkValidations = () => {
    let newArrMixes;
    const mixesValidation = () => {
      let arrMixes = [];
      data?.map((item) => {
        if (item.name) {
          if (
            chipData.length === 0 ||
            chipData.findIndex((elem) => item.name === elem.chipName) === -1
          ) {
            arrMixes.push({
              name: item.name,
              value: false,
            });
          } else {
            if (
              chipData
                .filter((items) => item.name === items.chipName)
                ?.findIndex(
                  (elem) =>
                    elem.quantity === "" ||
                    elem.size === "" ||
                    elem.uomId === "" ||
                    elem.unit === ""
                ) !== -1
            ) {
              arrMixes.push({
                name: item.name,
                value: false,
              });
            } else {
              if (
                chipData.findIndex(
                  (elem) => item.name === elem.chipName && elem.quantity < 0
                ) !== -1
              ) {
                arrMixes.push({
                  name: `${item.name}, Quantity cannot be less than zero`,
                  value: false,
                });
              }
              arrMixes.push({
                name: item.name,
                value: true,
              });
            }
          }
        }
      });

      newArrMixes = arrMixes.filter((ele) => ele.value == false);
      if (newArrMixes.length == 0) {
        setMixesValidated(true);
        return true;
      } else {
        setMixesValidated(false);
        return false;
      }
    };
    let arrErr = [];
    if (!mixesValidation()) {
      // arrErr.push("Mixes is not selected");

      newArrMixes.map((ele) => arrErr.push(`${ele.name} is not selected`));
    }
    arrErr.length != 0 && dispatchNotification("error", arrErr);

    return arrErr;
  };
  useEffect(() => {
    fetchUom();
  }, []);
  useEffect(() => {
    getBatchDetailLot([...chipData]);
  }, [chipData]);

  useEffect(() => {
    let mixNotChanged = true;
    initialMixes.forEach((data) => {
      const getMix = chipData.find((item) => item.id === data.id);
      if (getMix) {
        mixNotChanged =
          mixNotChanged &&
          getMix.quantity == data.quantity &&
          getMix.unit === data.unit;
      }
    });
    // (chipData.filter((data,idx)=> data.id!="" && (data.quantity!==initialMixes[idx].quantity || data.unit !==initialMixes[idx].unit)).length>0) ?
    setHideSaveButton(mixNotChanged);
    // setHideSaveButton(true);

    // const compare= isEqual(
    //   chipData.filter((data)=> data.id!=""),initialMixes)
    //   setHideSaveButton(compare);
  }, [mixUpdated]);

  // useEffect(()=>{

  // },[chipData])

  useEffect(() => {

    if (MaterialData.length > 0) {
      const MixesMapData = MaterialData.map((data) => ({
        id: data?.id,
        comment: data?.sourceLot?.comments
          ? ` ${data?.sourceLot?.batch?.comments}, ${data?.sourceLot?.prefetchedInstructions}`
          : data?.sourceLot?.prefetchedInstructions,
        size: data?.sourceLot?.id,
        batchName: data?.sourceLot?.batchName,
        unit: data.uom.name,
        quantity: data.quantityUsed,
        uomId: data.uom.id,
        chipName: data?.sourceCategory?.name,
        chipId: data?.sourceCategory?.id,
        categoryId: data?.sourceCategory?.id,
        readOnly: true,
      }));
      setChipData(MixesMapData);
      setInitialMixes(
        MaterialData.map((data) => ({
          id: data?.id,
          comment: data?.sourceLot?.comments
            ? ` ${data?.sourceLot?.batch?.comments}, ${data?.sourceLot?.prefetchedInstructions}`
            : data?.sourceLot?.prefetchedInstructions,
          batchName: data?.sourceLot?.batchName,
          size: data?.sourceLot?.id,
          unit: data.uom.name,
          quantity: data.quantityUsed,
          uomId: data.uom.id,
          chipName: data?.sourceCategory?.name,
          chipId: data?.sourceCategory?.id,
          categoryId: data?.sourceCategory?.id,
          readOnly: true,
        }))
      );
    }

    // MaterialData.map((data) => {
    //   let comment = "";
    //   if (data?.sourceLot?.batch?.comments)
    //     comment = data?.sourceLot?.batch?.comments;
    //   if (data?.sourceLot?.batch?.prefetchedInstructions)
    //     comment += ", " + data?.sourceLot?.batch?.prefetchedInstructions;

    //   chipData.filter(
    //     (chip) =>
    //       (!chip.id || chip.id === data?.id) &&
    //       chip.size === data?.sourceLot?.id
    //   ).length === 0 &&
    //     chipData.push({
    //       id: data?.id,
    //       comment: comment,
    //       size: data?.sourceLot?.id,
    //       unit: data.uom.name,
    //       quantity: data.quantityUsed,
    //       uomId: data.uom.id,
    //       chipName: data?.sourceCategory?.name,
    //       chipId: data?.sourceCategory?.id,
    //       categoryId: data?.sourceCategory?.id,
    //       readOnly: true,
    //     });
    //   getBatchDetailLot([...chipData]);
    // });
  }, [MaterialData]);
  useEffect(()=>{
   setShowButtonAction( !hideSaveButton || commentsChanged);

  },[hideSaveButton,commentsChanged])

  const fetchData = async (id, pageNumber1) => {
    setSizeLoading(true);
    setPageNumber(pageNumber1);
    const prev = [...categoryDropdownData[id]?.data];
    const dropdownData = await getSourceCategoryDropdown(
      categoryId,
      id,
      searchString || "",
      pageNumber1,
      pageSize
    );
    const latestDropdownData = { ...categoryDropdownData };
    const currentUsed = prev?.filter(
      (d) =>
        chipData.some((cd) => cd.size === d.id) &&
        !dropdownData?.data?.data?.some((dd) => dd.id === d.id)
    );

    latestDropdownData[id] = {
      pageNumber: pageNumber1,
      pageSize,
      count: dropdownData?.data?.count,
      data: [
        ...(pageNumber1 !== 1 ? prev : [...currentUsed]),
        ...dropdownData?.data?.data,
      ],
    };
    setCategoryDropdownData(latestDropdownData);
    setSizeLoading(false);
    return latestDropdownData;
  };

  useEffect(() => {
   updateCategoryDropDownData();
  }, [data, categoryId]);

  const updateCategoryDropDownData = ()=> {
    const latestDropdownData = {};
    data &&
      Promise.all(
        data.map((d) =>
          getSourceCategoryDropdown(
            categoryId,
            d.id,
            searchString || "",
            1,
            10
          ).then((result) => {
            const prev = MaterialData?.filter(
              (d1) => d1?.sourceCategory?.id === d.id
            )
              ?.map((d1) => d1?.sourceLot)
              ?.filter(
                (d1) => !result?.data?.data?.some((d2) => d2.id === d1?.id)
              )
              ?.reduce((res, item) => {
                if (!res.find((d1) => d1.id === item.id)) {
                  return [...res, item];
                }
                return res;
              }, []);
            latestDropdownData[d.id] = {
              pageNumber: 1,
              pageSize: 10,
              count: result?.data?.count,
              data: [...prev, ...result?.data?.data],
            };
          })
        )
      ).then((results) => {
        setCategoryDropdownData(latestDropdownData);
      });
  }

  const searchResults = (e, data) => {
    setSourceCategoryId(data?.categoryId);
    setSearchString(e?.target?.value || "");
  };

  const getSourceLotComment = (data) => {
    let comment = "";
    if (data?.sourceLot?.batch?.comments)
      comment = data?.sourceLot?.batch?.comments + ", " ;
    if (data?.sourceLot?.prefetchedInstructions)
      comment += data?.sourceLot?.prefetchedInstructions;
    return comment;
  };

  const handleSaveMixes = () => {
    setOpen1(true);
  };

  const getBatchComment = (data) => {
    let comment = "";
    if (data?.comments) comment = data?.comments + ", " ;
    if (data?.prefetchedInstructions)
      comment +=  data?.prefetchedInstructions;
      // comment = comment.trim() ? (comment + ", " + data?.prefetchedInstructions) : data?.prefetchedInstructions
    return comment;
  };

  const fetchUom = async (id) => {
    const uomData = await getUomData();
    if(uomData?.data?.data){
      setUomData(uomData.data.data.filter(d=> d.name === 'Kg'))
    }
    // uomData && setUomData(uomData.data.data);
  };
  useEffect(() => {}, [sizeData]);

  const handleAddItem = async (id, name) => {
    // setHideSaveButton(false);
    // setHideSaveButton(false);

    setSearchString("");
    setId(id);
    setChipData([
      ...chipData,
      {
        id: "",
        size: "",
        unit: uomData[0].name,
        quantity: "",
        uomId: uomData[0].id,
        remaining: "",
        chipName: name,
        chipId: id,
        categoryId: id,
        readOnly: false,
      },
    ]);
    setDropDown([]);
  };

  const handleDeleteRow = (idx) => {
    // debugger;
    if (
      (!hideSaveButton ||
        chipData?.filter((data) => data.id == "")?.length > 0) &&
      chipData[idx].id
    ) {
      dispatchNotification("error", [
        "Please save the unsaved material detail changes to proceed",
      ]);
    } else {
      setDeleteId(idx);
      let errMessage = checkValidated(idx);

      errMessage.length > 0
        ? dispatchNotification("error", errMessage)
        : setOpen(true);
    }
  };

  const handleClickCloseDialog = () => {
    setOpen(false);
  };
  const handleClickSaveCloseDialog = () => {
    setOpen1(false);
  };

  const checkValidated = (idx) => {
    // debugger;
    let validated = [];

    let dataNew = [...chipData];
    if (dataNew[idx]?.id === "") return validated;
    dataNew = dataNew.filter(
      (ele) =>
        ele.id != "" &&
        ele.size !== "" &&
        ele.quantity !== "" &&
        ele.uomId !== "" &&
        ele.unit !== ""
    );
    dataNew.splice(idx, 1);

    data?.map((dataValue) => {

      if(dataValue.name == 'Raw Material'){
        let listOfRawMaterialsRequired = BatchDetailsSchema.properties.RawMaterialName.enum
        let setOfRawMaterialsInSelectedMixes = new Set()
        dataNew.forEach((ele)=>{
          setOfRawMaterialsInSelectedMixes.add(ele?.batchName)
        })
  
        const elementsNotInSet = listOfRawMaterialsRequired.filter(item=> !setOfRawMaterialsInSelectedMixes.has(item))
  
        if(elementsNotInSet.length > 0){
          elementsNotInSet.map((ele) => validated.push(`Raw Material:${ele} cannot be empty`));
        }
      }

      if (
        dataNew.findIndex((elem) => dataValue.name === elem.chipName) === -1
      ) {
        validated.push(`${dataValue.name} cannot be empty`);
      }
    });
    if (getId.id && validated.length > 0) {
      return validated;
    }
    return [];
  };
  const fetchSaveData = async () => {
    const getMix = await getMixes(getId.categoryId, getId.id);
    // debugger;
    getMix?.data?.data &&
      setChipData(
        getMix?.data?.data?.map((data) => ({
          id: data?.id,
          comment: data?.sourceLot?.comments
            ? ` ${data?.sourceLot?.batch?.comments}, ${data?.sourceLot?.prefetchedInstructions}`
            : data?.sourceLot?.batch?.prefetchedInstructions,
          size: data?.sourceLot?.id,
          batchName: data?.sourceLot?.batchName,
          unit: data?.uom.name,
          quantity: data?.quantityUsed,
          uomId: data?.uom.id,
          chipName: data?.sourceCategory?.name,
          chipId: data?.sourceCategory?.id,
          categoryId: data?.sourceCategory?.id,
          readOnly: true,
        }))
      );

    getMix?.data?.data &&
      setInitialMixes(
        getMix?.data?.data?.map((data) => ({
          id: data?.id,
          comment: data?.sourceLot?.comments
            ? ` ${data?.sourceLot?.batch?.comments}, ${data?.sourceLot?.prefetchedInstructions}`
            : data?.sourceLot?.batch?.prefetchedInstructions,
          size: data?.sourceLot?.id,
          batchName: data?.sourceLot?.batchName,
          unit: data?.uom.name,
          quantity: data?.quantityUsed,
          uomId: data?.uom.id,
          chipName: data?.sourceCategory?.name,
          chipId: data?.sourceCategory?.id,
          categoryId: data?.sourceCategory?.id,
          readOnly: true,
        }))
      );
  };

  const handleClickYes = async () => {
    const data = [...chipData];

    data.splice(deletedId, 1);

    if (getId.id && chipData[deletedId].id != "") {
    getBatchTreeDataById(Number(getId?.id));
    // debugger;
      const updateMixBody = {
        mixMappingRequestDtoList: data
                                    .filter((item) => item.id != "")
                                    ?.map((material) => ({
                                      id: material.id,
                                      sourceLotId: material.size,
                                      quantityUsed: material.quantity,
                                      uomId: material.uomId,
                                    })),
        comments: null
      }
      const res = await updateMixes(
        getId.categoryId,

        getId.id,

        updateMixBody
      );
      if (res?.status === 200) {
        // setHideSaveButton(true);

        dispatchNotification("success", [
          "Material Details Updated Successfully",
        ]);
        setChipData(data);
     
        getBatchSizeLot(data);

        getBatchDetailLot(data);
        fetchData(data?.categoryId, 1);
        chipData.forEach((data) => {
          data.readOnly = true;
        });
      }
    } else {
      setChipData(data);

      getBatchSizeLot(data);

      getBatchDetailLot(data);
    }

    setOpen(false);
  };

  const handleClickSaveYes = async () => {
    getBatchSizeLot([...chipData]);
    getBatchDetailLot([...chipData]);
    let validated = checkValidations();
    if (getId.id && validated.length === 0) {
      const updateMixBody = {
        mixMappingRequestDtoList: chipData.map((material) => ({
                                    id: material.id,
                                    sourceLotId: material.size,
                                    quantityUsed: material.quantity,
                                    uomId: material.uomId,
                                  })),
        comments: instructions
      }
      const res = await updateMixes(
        getId.categoryId,
        getId.id,
        updateMixBody
      );

      if (res?.status === 200) {
        // setHideSaveButton(true);
        // setHideSaveButton(true);

        dispatchNotification("success", [
          "Material Details Updated Successfully",
        ]);
        // window.location.reload();
        getBatchTreeDataById(Number(getId?.id));
        updateCategoryDropDownData();
        setHideSaveButton(true);
        setCommentsChanged(false);
        setInitialComments(updateMixBody.comments)
        fetchSaveData();
        chipData.forEach((data) => {
          data.readOnly = true;
        });
      }
    }

    setOpen1(false);
  };

  return (
    <Box>
      <Box>
        <TableContainer sx={{ border: 1, borderColor: " #E0E0E0" }}>
          <Table
            sx={{ minWidth: 900 }}
            className="form-table"
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell
                  onMouseEnter={onHover}
                  onMouseLeave={onLeave}
                  className="table-head"
                  sx={{ border: 1, borderColor: " #E0E0E0", height: "20px" }}
                >
                  S.No
                </TableCell>
                <TableCell
                  className="table-head"
                  sx={{ border: 1, borderColor: " #E0E0E0", height: "20px" }}
                  align="left"
                >
                  {translate('lotNo-remQty')}
                </TableCell>
                <TableCell
                  className="table-head"
                  sx={{ border: 1, borderColor: " #E0E0E0", height: "20px" }}
                  align="left"
                >
                  {translate('quantity')}
                </TableCell>
                <TableCell
                  className="table-head"
                  sx={{ border: 1, borderColor: " #E0E0E0", height: "20px" }}
                  align="left"
                >
                  {translate('units')}
                </TableCell>

                {(!disableVendorData || !disableQuantityEdit) && (
                  <TableCell
                    className="table-head"
                    sx={{ border: 1, borderColor: " #E0E0E0", height: "20px" }}
                    align="left"
                  >
                    {translate('actions')}
                  </TableCell>
                )}
              </TableRow>
            </TableHead>

            <TableBody>
              {chipData &&
                chipData.map((data, idx) => (
                  <TableRow
                    // key={row.name}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 1,
                        borderColor: " #E0E0E0",
                      },
                    }}
                  >
                    <TableCell
                      sx={{
                        border: 1,
                        borderColor: " #E0E0E0",
                        height: "20px",
                      }}
                      component="th"
                      scope="row"
                    >
                      {idx + 1}
                      {/* {data.readOnly && !hover && <div style={{zIndex:1,display:"block"}}><p>Hello</p></div>} */}

                      <Chip
                        sx={{ ml: 2 }}
                        label={translate(data.chipName.toUpperCase())}
                        variant="outlined"
                      />
                      {data.comment && (
                        <Tooltip sx={{marginLeft: '3px'}} title={<p style={{ fontSize: "13px", margin: "0", padding: "0" 
                      }}>{data.comment}</p>}>
                          <InfoIcon />
                        </Tooltip>
                      )}
                    </TableCell>
                    <Tooltip
                      title={
                        ((data?.readOnly || chipList.includes(data?.size)) && !userDetails.isMonitor && !userDetails.isSuperAdmin && !disableVendorData)
                          ? "This Lot No. cannot be edited , you can only delete it"
                          : 
                          ""
                      }

                      placement="top"
                    >
                      <TableCell
                        align="left"
                        sx={{
                          border: 1,
                          borderColor: " #E0E0E0",
                          height: "20px",
                        }}
                      >
                        <Autocomplete
                          disabled={
                            data.readOnly ||
                            disableVendorData ||
                            chipList.includes(data?.size)||
                    isInspector || disableQuantityEdit

                          }
                          // disablePortal
                          id="combo-box-demo"
                          value={data.size}
                          getOptionLabel={(option) => {
                            if (typeof option == "object") return option?.label;
                            const dData = categoryDropdownData[
                              data?.categoryId
                            ]?.data?.filter(
                              (dropVal) => dropVal.id === data.size
                            )[0]
                            return (
                              data.size &&
                              `${dData?.batch?.batchName ? (dData?.batch?.batchName + '-') : 
                                dData?.batchName ? (dData?.batchName + '-') : ''}${
                                dData?.manufacturerLotNumber ? dData?.manufacturerLotNumber :
                                categoryDropdownData[
                                  data.categoryId
                                ]?.data?.filter(
                                  (dropVal) => dropVal.id === data.size
                                )[0]?.lotNo
                              }-${
                                categoryDropdownData[
                                  data?.categoryId
                                ]?.data?.filter(
                                  (dropVal) => dropVal.id === data.size
                                )[0]?.remainingQuantity
                              }-${
                                categoryDropdownData[
                                  data?.categoryId
                                ]?.data?.filter(
                                  (dropVal) => dropVal.id === data.size
                                )[0]?.uom?.name ||
                                categoryDropdownData[
                                  data?.categoryId
                                ]?.data?.filter(
                                  (dropVal) => dropVal.id === data.size
                                )[0]?.uom
                              }`
                            );
                          }}
                          isOptionEqualToValue={(option, value) =>
                            option.value === value
                          }
                          onInputChange={(e) => searchResults(e, data)}
                          onClose={() => {
                            setActiveRow(null);
                            searchResults("");
                          }}
                          onOpen={() => {
                            if(Object.keys(categoryDropdownData).length == 0){
                              return
                            }
                            setActiveRow(idx);
                            fetchData(data?.categoryId, 1);
                          }}
                          onChange={(___, target) => {
                            const { value, extra } = target || {};
                            !value && searchResults("", data);
                            // setShowButton(true);
                            chipData[idx]["size"] = value;
                            chipData[idx]['batchName'] = extra?.batch?.batchName
                            chipData[idx]["remaining"] =
                              extra?.remainingQuantity;
                            chipData[idx]["comment"] = getBatchComment(extra);
                            getBatchSizeLot([...chipData]);
                            getBatchDetailLot([...chipData]);
                          }}
                          options={
                            categoryDropdownData[data?.categoryId]?.data
                              ?.filter(
                                (drop, idx) =>
                                  !chipData
                                    ?.map((data) => data.size)
                                    .includes(drop?.id)
                              )
                              .map((drop) => ({
                                label: ` ${(drop?.batch?.batchName) ? (drop?.batch?.batchName + '-') : ''}${drop?.manufacturerLotNumber ? drop?.manufacturerLotNumber :  drop?.lotNo}- ${
                                  drop?.remainingQuantity
                                } ${drop?.uom?.name || drop?.uom}`,
                                value: drop?.id,
                                extra: drop,
                              })) || []
                          }
                          sx={{ width: 250 }}
                          ListboxProps={{
                            style: {
                              maxHeight: "unset",
                              overflow: "unset",
                              boxShadow: "none",
                              marginBottom: 5,
                            },
                            custom: categoryDropdownData[data?.categoryId],
                            fetchData: (fd) => {
                              fetchData(fd, pageNumber + 1);
                            },
                            categoryId: data?.categoryId,
                          }}
                          ListboxComponent={ListboxComponent}
                          PaperComponent={CustomPaperComponent}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="unit"
                              id="outlined-basic"
                              variant="standard"
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {sizeLoading && activeRow === idx ? (
                                      <CircularProgress
                                        color="inherit"
                                        size={20}
                                      />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                            />
                          )}
                        />
                      </TableCell>
                    </Tooltip>

                    <TableCell
                      sx={{
                        border: 1,
                        borderColor: " #E0E0E0",
                        height: "20px",
                      }}
                      align="left"
                    >
                      <TextField
                        disabled={disableVendorData || isInspector || disableQuantityEdit}
                        sx={{ width: "100%", backgroundColor: "#fff" }}
                        name="quantity"
                        id="outlined-basic"
                        value={data.quantity}
                        type="number"
                        variant="standard"
                        InputProps={{ inputProps: { min: 1 } }}
                        onChange={(event) => {
                          setMixUpdated({ quantity: event.target.value, idx });
                          setQuantity(event.target.value);
                          chipData[idx]["quantity"] = event.target.value;
                          // setShowButton(true);
                          getBatchSizeLot([...chipData]);
                          // if(initialMixes[idx] && initialMixes[idx]?.id!==""){
                          //   (  (event.target.value==initialMixes[idx]?.quantity)) ? setHideSaveButton(true):setHideSaveButton(false);

                          // }

                          getBatchDetailLot([...chipData]);
                        }}
                      />
                    </TableCell>

                    <TableCell
                      sx={{
                        border: 1,
                        borderColor: " #E0E0E0",
                        height: "20px",
                      }}
                      align="left"
                    >
                      <TextField
                        disabled={disableVendorData || isInspector || disableQuantityEdit}
                        sx={{ width: "100%", backgroundColor: "#fff" }}
                        name="unit"
                        id="outlined-basic"
                        value={data.unit}
                        variant="standard"
                        select
                        onChange={(event) => {
                          setUnit(event.target.value);
                          // setShowButton(true);
                          // if(initialMixes[idx] && initialMixes[idx].id!==""){
                          //   (  (event.target.value==initialMixes[idx]?.unit)) ? setHideSaveButton(true):setHideSaveButton(false);

                          // }
                          setMixUpdated({ quantity: event.target.value, idx });

                          chipData[idx]["unit"] = event.target.value;
                          chipData[idx]["uomId"] = uomData.filter(
                            (e) => e.name === event.target.value
                          )[0].id;
                          getBatchSizeLot([...chipData]);

                          getBatchDetailLot([...chipData]);
                        }}
                      >
                        {Array.isArray(uomData) &&
                          uomData?.map((uom, idx) => {
                            return (
                              <MenuItem value={uom.name}> {uom.name}</MenuItem>
                            );
                          })}
                      </TextField>
                    </TableCell>

                    {(!disableVendorData || !disableQuantityEdit) && (
                      <TableCell
                        sx={{
                          border: 1,
                          borderColor: " #E0E0E0",
                          height: "20px",
                        }}
                        align="left"
                      >
                        <Button
                          disabled={disableVendorData || isInspector || disableQuantityEdit}
                          sx={{ textDecoration: "underline" }}
                          onClick={() => {
                            handleDeleteRow(idx);
                          }}
                        >
                          {translate('delete')}
                        </Button>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        <div>
          <Dialog
            open={open}
            onClose={handleClickCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
            {translate('confirmationMsg')}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete this mix
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClickCloseDialog} variant="contained">
              {translate('no')}
              </Button>
              <Button
                type="submit"
                variant="contained"
                onClick={handleClickYes}
                autoFocus
              >
                {translate('yes')}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <div>
          <Dialog
            open={open1}
            onClose={handleClickSaveCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
            {translate('confirmationMsg')}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to save this mix
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClickSaveCloseDialog} variant="contained">
              {translate('no')}
              </Button>
              <Button
                type="submit"
                variant="contained"
                onClick={handleClickSaveYes}
                autoFocus
              >
                {translate('yes')}
              </Button>
            </DialogActions>
          </Dialog>
        </div>

        {data &&
          data.map((material) => {
            return (
              (!disableVendorData || !disableQuantityEdit) && (
                <Button
                  disabled={disableVendorData || isInspector || disableQuantityEdit}
                  sx={{ textDecoration: "underline" }}
                  onClick={() => handleAddItem(material.id, material.name)}
                >
                  <AddCircleOutlineIcon />
                  {translate('Add'+" "+ material.name)}
                </Button>
              )
            );
          })}
        {  saveButtonCondition && (
            <Button
              disabled={disableVendorData || isInspector || disableQuantityEdit}
              sx={{ textDecoration: "underline" }}
              onClick={() => handleSaveMixes()}
            >
              <AddCircleOutlineIcon />
              Save Materials
            </Button>
          )}
      </Box>

      <Stack direction="row" spacing={3} alignItems="baseline">
        {/* <p style={{ fontSize: "12px", width: "100px" }}>Instructions</p> */}

        <TextField
          disabled={disableVendorData || isInspector || disableQuantityEdit}
          sx={{ width: "80%", backgroundColor: "#fff", marginTop: 2 }}
          id="outlined-basic"
          multiline
          minRows={4}
          value={instructions}
          label={translate('instructions')}
          type="text"
          variant="outlined"
          onChange={(event) => {
            setInstructions(event.target.value);
            setComments(event.target.value);
            // setShowButton(true);
            // setHideSaveButton(false)
           
             getId.id && setCommentsChanged(event.target.value !== initialComments);
           
          }}
        />
      </Stack>
    </Box>
  );
}

function CustomPaperComponent(params) {
  const { className, ...rest } = params;
  return <Paper className={`${className} custom-paper`} {...rest} />;
}



export default StackComponent;
