import * as React from 'react';
import PropTypes from 'prop-types';
import { SimpleDialog } from './Dialog';
import DeletableChips from '../Chips/DeletableChip';
import { MILLER_UI_TEXT } from '../../textConstant';

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
};

export default function SimpleDialogDemo(props) {
    const { chipsState,open, onClose, filterData, setFilterData,activeFilters, handleSelectChange, fetchData, setFilters,
        stateId, setStateId,fetchStateList,options,filterStates, testTypeOptions, hideDateFilter, categories,isInspector
         ,manufactureOptions,targetmanufactureOptions, isLot,entityTypeOptions,hideState,state,district,setDistrict,getDistrictData,setSearchCriteria,searchCriteria,manufacturerTypeData,vendorTypeData} = props;
    return (
        <div>
            <br />
            <SimpleDialog
            chipsState={chipsState}
            filterStates={filterStates}
                open={open}
                onClose={onClose}
                filterData={filterData}
                setFilterData={setFilterData}
                activeFilters={activeFilters}
                setFilters={setFilters}
                handleSelectChange={handleSelectChange}
                fetchData={fetchData}
                stateId={stateId}
                setStateId={setStateId}
                fetchStateList={fetchStateList}
                // options={options}
                options={options?.map(item => ({
                    ...item,
                    label : item?.label.replace('MILLER',MILLER_UI_TEXT)
                }))
                }
                testTypeOptions={testTypeOptions}
                hideDateFilter={hideDateFilter}
                categories={categories?.map(item => ({
                    ...item,
                    label : item?.label.replace('MILLER',MILLER_UI_TEXT)
                }))
                }
                isInspector={isInspector}
                manufactureOptions={manufactureOptions}
                targetmanufactureOptions={targetmanufactureOptions}
                isLot={isLot}
                entityTypeOptions={entityTypeOptions}
                hideState={hideState}
                state={state}
                district={district}
                setDistrict={setDistrict}
    getDistrictData={()=>getDistrictData()}
    setSearchCriteria={setSearchCriteria}
searchCriteria={searchCriteria}
manufacturerTypeData={manufacturerTypeData}
                          vendorTypeData={vendorTypeData}
            />
        </div>
    );
}
