import * as React from "react";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import {useEffect} from "react"
import { MILLER_UI_TEXT } from "../../textConstant";
import useTranslatewrapper from "../useTranslateWrapper";

export default function DeletableChips(props) {
  const {translate} = useTranslatewrapper()

  let { chipKey, chipValue,deleteChip,options, categories , manufactureOptions,
    targetmanufactureOptions,state,district} = props;


 options = options?.map(item=>({
  ...item,
  label: item?.label.replace('MILLER',MILLER_UI_TEXT)
 }))

 categories = categories?.map(item=>({
  ...item,
  label: item?.label.replace('MILLER',MILLER_UI_TEXT)
 }))


if((chipValue && chipValue!=="" && chipValue!=="-") && chipKey === "dateOfManufacture"){
  chipValue= "Mfd. " + chipValue
}
if((chipValue && chipValue!=="" && chipValue!=="-") && chipKey === "dateOfExpiry"){
  chipValue= "Exp. " + chipValue
}
  
  const getChipNamebyId = (chipId, chipKey) =>{
    if(chipKey !== 'testTypes' && chipKey !== "entityTypes"){
      if(chipKey === 'stateIds'){

        if(options){
          const newValue = options?.filter((option)=>{
            return option.id==chipId
          })
         
         return newValue[0]?.label
        }
      }
      if(chipKey === 'categories'){

        if(options){
          const newValue = categories?.filter((option)=>{
            return option.id==chipId
          })
        //  return newValue[0]?.value
        return newValue[0]?.label || newValue[0]?.value
        }
      }
      if(chipKey === 'state'){
        if(chipId !== 'ALL'){

          return `Inspection ${chipId}`
        }
         return chipId
      }

      if(chipKey === 'categoryIds'){

        if(categories){
          const newValue = categories?.filter((option)=>{
            return option.id==chipId
          })
         
         return newValue[0]?.label
        }
      }
      if(chipKey === 'manufacturerIds'){

        if(manufactureOptions){
          const newValue = manufactureOptions?.filter((option)=>{
            return option.id==chipId
          })
         
         return newValue[0]?.label
        }
      }
      if(chipKey === 'targetManufacturerIds'){

        if(targetmanufactureOptions){
          const newValue = targetmanufactureOptions?.filter((option)=>{
            return option.id==chipId
          })
         
         return newValue[0]?.label
        }
      }
      if(chipKey === 'states'){

        if(state){
          const newValue = state?.filter((option)=>{
            return option.id==chipId
          })
         
         return newValue[0]?.name
        }
      }
      if(chipKey === 'districts'){

        if(district){
          const newValue = district?.filter((option)=>{
            return option.value==chipId
          })
         
         return newValue[0]?.label
        }
      }
     
      return ""
    } 
    else{
      return chipId
    }
   
  }
  
  
  return (
    <Stack direction="row" spacing={1} style={{ paddingRight: "5px", marginBottom:"5px", flexWrap:"wrap"}}>

      {(chipKey==="categories" || chipKey==="districts" || chipKey==="states" || chipKey==="stateIds" || chipKey==="testTypes" || chipKey==="categoryIds" || chipKey==="state" || chipKey==="manufacturerIds" || chipKey==="targetManufacturerIds" || chipKey==="entityTypes") ? (chipValue.length!=0 && chipValue[0]!=null) && chipValue?.map((chipId)=>
      getChipNamebyId(chipId, chipKey) && 
      <Chip
      label={translate(getChipNamebyId(chipId, chipKey).toLowerCase())}
      variant="outlined"
      color="primary"
      sx={{ height:"1.8rem !important",marginBottom:"5px !important" }}
      size="small"
      onDelete={()=> deleteChip(chipKey,chipId) }
    />
    ) :
    (chipValue && chipValue!=="" && chipValue!=="-") && <Chip
    label={chipValue}
    variant="outlined"
    sx={{ height:"1.8rem !important",marginBottom:"5px !important" }}
    size="small"
    color="primary"
    onDelete={() => deleteChip(chipKey,chipValue)  }
  />

      }
     
    {/* { (chipValue!=="" && chipValue && chipKey!=="stateIds") &&
      <Chip
      label={chipValue}
      variant="outlined"
      sx={{borderRadius:"0 !important" , height:"1.8rem !important" }}
      size="small"
      onDelete={handleDelete}
    />
    }
    { (chipValue!=="" && chipValue && chipKey==="stateIds" && chipValue[0]!=null) && 
    chipValue.map((chipId)=>
      <Chip
      label={chipId}
      variant="outlined"
      sx={{borderRadius:"0 !important" , height:"1.8rem !important" }}
      size="small"
      onDelete={handleDelete}
    />
    )

    } */}
    </Stack>
  );
}
