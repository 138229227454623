import React from "react";
import {
  Container,
  Section,
  Header,
  Logo,
  Heading,
  Text,
  SubHeading,
  List,
  ListItem,
} from "./AboutUsStyles";

const AboutUs = () => {
  return (
    <Container>
      <Header>
        <Logo src="/assets/images/logo1.png" alt="Company Logo" />
      </Header>

      <Section backgroundColor="#ffffff">
        <Text>
          <br />
          PATH is a global nonprofit organization dedicated to improving public
          health by driving innovation and partnerships that address the world's
          most pressing health challenges. Operating in over 70 countries, PATH
          focuses on areas such as infectious diseases, maternal and child
          health, and health systems strengthening. By combining science,
          technology, and collaboration, PATH develops and scales solutions that
          advance health equity, ensuring that people everywhere have the
          opportunity to live healthy, productive lives.
        </Text>
        <Text>
          For more information visit:{" "}
          <a href="https://www.path.org/" target="_blank">
            https://www.path.org/
          </a>
        </Text>
      </Section>

      <Section backgroundColor="#e6f7ff">
        <SubHeading>
          FFQMS: Fortified Food Quality Management System
        </SubHeading>
        <Text>
          This digital QA/QC platform is designed to enhance the quality,
          traceability, and transparency of fortified rice. PATH, in
          collaboration with key stakeholders including the National Government,
          private sector partners, and development agencies, is supporting the
          Government of India in scaling up its Rice Fortification project to
          ensure vulnerable populations have access to high-quality fortified
          rice.
        </Text>
      </Section>

      <Section backgroundColor="#ffffff">
        <SubHeading>Need for Digital QA/QC System</SubHeading>
        <Text>
          The rice fortification ecosystem faces several challenges that FFQMS
          addresses effectively:
        </Text>
        <List>
          <ListItem>
            <strong>Batch-wise Monitoring:</strong> Enables real-time tracking
            of production batches to ensure consistency and compliance.
          </ListItem>
          <ListItem>
            <strong>Compliance Challenges:</strong> Streamlines adherence to
            regulatory standards and reduces compliance gaps.
          </ListItem>
          <ListItem>
            <strong>Quality Assurance & Control:</strong> Provides robust
            mechanisms for ensuring and verifying product quality.
          </ListItem>
          <ListItem>
            <strong>Limited Traceability:</strong> Enhances traceability
            throughout the supply chain with advanced tracking features.
          </ListItem>
          <ListItem>
            <strong>Interoperability:</strong> Facilitates integration with
            other systems and stakeholders for cohesive operations.
          </ListItem>
          <ListItem>
            <strong>Transparency & Consumer Awareness:</strong> Improves
            transparency and informs consumers about product quality.
          </ListItem>
          <ListItem>
            <strong>Lack of Standardization:</strong> Establishes standardized
            procedures and practices across the industry.
          </ListItem>
          <ListItem>
            <strong>Data Fragmentation:</strong> Consolidates data into a
            unified system to avoid fragmentation.
          </ListItem>
          <ListItem>
            <strong>Manual Processes:</strong> Reduces reliance on manual
            processes through digital automation.
          </ListItem>
          <ListItem>
            <strong>Limited Stakeholder Collaboration:</strong> Fosters better
            collaboration among various stakeholders.
          </ListItem>
          <ListItem>
            <strong>Resource Constraints:</strong> Optimizes resource use and
            operational efficiency.
          </ListItem>
          <ListItem>
            <strong>Scalability and Sustainability:</strong> Supports scalable
            and sustainable practices for ongoing effectiveness.
          </ListItem>
        </List>
      </Section>

      <Section backgroundColor="#e6f7ff">
        <SubHeading>Key Features of FFQMS:</SubHeading>
        <List>
          <ListItem>
            <strong>Traceability:</strong> FFQMS provides meticulous tracking of
            fortified rice and its ingredients throughout the supply chain using
            unique identifiers like barcodes and QR codes. This ensures that
            every stage of the production and distribution process is
            transparent and accountable.
          </ListItem>
          <ListItem>
            <strong>Quality Control:</strong> The platform supports systematic
            sampling, coding, and rigorous testing of premix, fortified rice
            kernels (FRKs), and fortified rice to ensure they meet stringent
            quality standards. This includes quality assessments at various
            stages to maintain high-quality benchmarks.
          </ListItem>
          <ListItem>
            <strong>Quality Assurance:</strong> FFQMS facilitates the
            registration, verification, and assessment of all entities involved
            in the production and distribution of premix, FRKs, and fortified
            rice. This component ensures that all stakeholders adhere to
            required quality standards and operational protocols.
          </ListItem>
        </List>
      </Section>

      <Section backgroundColor="#ffffff">
        <SubHeading>Primary Outcomes of FFQMS:</SubHeading>
        <List>
          <ListItem>
            <strong>Institutionalized Monitoring:</strong> Implements efficient
            quality rating tools and accreditation mechanisms to improve
            fortified rice quality.
          </ListItem>
          <ListItem>
            <strong>Enhanced Capacity and Transparency:</strong> Provides
            improved capacity for monitoring and transparency in the quality of
            premix, FRKs, and fortified rice.
          </ListItem>
          <ListItem>
            <strong>Quality Assurance and Control:</strong> Ensures
            comprehensive quality assurance, control, and traceability for all
            stakeholders involved.
          </ListItem>
        </List>
      </Section>
    </Container>
  );
};

export default AboutUs;