const initial = {
  searchCriteriaList: [
    { key: "batchNo", value: undefined },
    { key: "dateOfManufacture", value: "" },
    { key: "dateOfExpiry", value: "" },
    { key: "stateIds", value: [null] },
    { key: "testTypes", value: [null] },
    { key: "categoryIds", value: [null] },
    { key: "state", value:[null]},
    { key: "manufacturerIds", value:[null]},
    { key: "targetManufacturerIds", value:[null]},
    { key: "entityTypes", value: [null] },

  ],
};
export default (state = initial, event) => {
  switch (event.type) {
    case "UPDATE_FILTER":
      return {
        ...state,
        searchCriteriaList: [...event.data],
      };

    default:
      return state;
  }
};
