import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export default function LinkRedirect({ params, target }) {
    const getId = useParams();
    const pathNameCapitalize = useLocation().pathname.split("/")[1];
    const currentPath = `/${pathNameCapitalize}/${getId.categoryId}/${target}`;
    const currentBatchPath = `/${(params?.batchName).toLowerCase()}/${params?.batchId}/${target}`;

    return (
        <>
            {target == "batch" ?
                <a href={`${currentBatchPath}/details/${JSON.stringify(params.id)}`}>
                    {params.batchNo}
                </a> :
                <a href={`${currentPath}/details/${JSON.stringify(params.id)}`}>

                    {params.lotNo}

                </a>
            }

        </>
    )
}