export const FILTER_BUTTON_TEXT="Filter";
export const CREATE_BUTTON_TEXT="Create New";
export const SEARCH_TEXT="Search..";
export const PREMIX_TEXT=" Data Elements";
export const SELECT_FILTER ="FILTER DATA";
export const MANF_DATE="Manufacturing Date";
export const DISPATCH_DATE="Dispatch Date";
export const EXP_DATE="Expiry Date";
export const STATUS="Stage"
export const TEST_TYPE="Test Type"
export const ENTITY_TYPE="Entity Type"


export const SUBMIT_BUTTON_TEXT = "Submit";
export const SAVE_BUTTON_TEXT = "Save Batch Details";
export const CREATE_NEW_BUTTON_TEXT = "Create";
export const APPLY_BUTTON_TEXT = "Apply";
export const CANCEL_BUTTON_TEXT = "Cancel";
export const DELETE_BUTTON_TEXT = "Delete";
export const EDIT_BUTTON_TEXT = "Edit";
export const ADD_BUTTON_TEXT = "Add Wastage";
export const MILLER_UI_TEXT = "MILLER(FR)"


export const VENDOR_DETAIL="Vendor Details";
export const BATCH_DETAIL="Batch Details";
export const BATCH_TEST_DETAIL="Batch Test Details";
export const LOT_TEST_DETAIL="Lot Test Details";
export const USAGE_DETAIL="Usage Details";
export const MATERIAL_DETIALS ="Material Details";
export const WASTAGE_DETAILS ="Wastage Details";
export const PACKAGING_INFO ="Packaging Info";
export const SOURCE_LOTS="Source Lots";
export const TARGET_LOTS="Target Lots";

export const BACK_BUTTON="Back to home";
export const DETAILS_PAGE=" DETAILS ";

export const MENU_DETAILS="menu details";
export const INVENTORY_TEXT="Inventory Data Elements";
export const INVENTORY="Inventory";
export const LOT_ELEMENTS="Lot Elements";
export const BATCH_ELEMENTS="Batch Elements";
export const PREMIX_GRID_TITLE="Premix Grid"
export const READ_TO_DISPATCH_STATE = "batchToDispatch";
export const HEADER_NAME = "Name";
export const HISTORY = "History";

export const BATCH_NUMBER ="Batch Number";
export const LOT_NUMBER ="Lot Number";
export const LOT_NAME = 'Lot Name';
export const BATCH_NAME = 'Batch Name'
export const TOTAL_QUANTITY ="Total Quantity";
export const QUANTITY_USED ="Quantity Used";

export const WASTAGE_QUANTITY ="Wastage Quantity";
export const DATE ="Wastage Date";
export const COMMENTS ="Comments*";

export const REMAINING_QUANTITY ="Remaining Quantity";

export const FILE_UPLOAD_ERROR = "size cannot be greater than 10MB";
export const UPLOAD_BUTTON_TEXT = "Upload";

export const DOWNLOAD ="Download";
export const REMOVE ="Remove";
export const RANGE ="Range";
export const TEST_NAME ="Test Name";
export const TEST_REFERENCE ="Test Reference";
export const REFERENCE_METHOD ="Reference Method";
export const RESULT ="Result";
export const TEST_METHOD_FOLLOWED ="Test Method Followed";

export const OTHER_BUTTON ="Additional Documents such as invoice etc.";

export const BATCH_HISTORY_TREE = "Batch History Tree";
export const DASHBORAD = "Dashboard";
export const NO_DATA = "No data found"

export const EMAIL = "Email";
export const NAME = "Name";
export const ROLE = "Role";
export const CATEGORY = "Category";
export const CLEAR_BUTTON = "Clear";




