export function formatTimestamp(inputTimestamp) {
  const currentDate = new Date().setHours(0, 0, 0, 0);

  const inputDatetime = new Date(inputTimestamp);
  inputDatetime.setHours(0, 0, 0, 0);

  if (inputDatetime.getTime() === currentDate) {
    return 'Today';
  }

  const yesterdayDatetime = new Date(currentDate);
  yesterdayDatetime.setDate(yesterdayDatetime.getDate() - 1);
  if (inputDatetime.getTime() === yesterdayDatetime.getTime()) {
    return 'Yesterday';
  }

  return inputDatetime.toLocaleDateString('en-GB');
}

