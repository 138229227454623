import React, { useEffect, useState } from 'react'
import Autocomplete from "@mui/material/Autocomplete";
import ClearIcon from "@mui/icons-material/Clear";
import {
    TextField,
} from "@mui/material";
import { ListboxComponent } from '../../../../../components/InfiniteScroll/ListboxComponent';
import { manufacturerList } from '../../../../../services/adminService';
import useTranslatewrapper from '../../../../../components/useTranslateWrapper';
function ManufacturerDropdown(props) {
  const { translate } = useTranslatewrapper();

    const { setManufacturerData,
        manufacturerDropDownId,
        id
    } = props
    const [manufacturer, setManufacture] = useState([])
    const [manufacturerCount, setManufacturerCount] = useState()
    const [searchString, setSearchString] = useState("");
    const [manufacturerId, setManufacturerId] = useState()
    const setQueryparam = (value) => {
        setSearchString(value);
    };
  const [pageNumber, setPageNumber] = useState(1);
  const pageSize = 10;

    const fetchManufacturers = async (pageNumber1) => {
        const fetchdata = await manufacturerList(pageNumber1, pageSize, searchString);
        setPageNumber(pageNumber1);
        if (fetchdata?.data) 
        {
          setManufacturerCount(fetchdata?.data?.count)
          const manufacturerCategoryNames = fetchdata?.data?.data?.map(
            (item) => {
              return {
                id: item.id,
                name: item.name,
              };
            }
          );
    
          setManufacture((d)=> pageNumber1!==1  ? [...d, ...manufacturerCategoryNames] : manufacturerCategoryNames);
        }

    }
    useEffect(() => {
        fetchManufacturers(1)
    }, [searchString]);
    return (
        <div>
            <Autocomplete
                disablePortal
                id="combo-box-demo"
                clearIcon={<ClearIcon fontSize="small" />}
                options={manufacturer.map((option) => ({
                    label: option.name,
                    value: option.id,
                }))}
                filterOptions={options => options}
                value={
                    manufacturer.filter(
                        (name) => name.id === manufacturerDropDownId
                    )[0]?.name
                }
                onInputChange={(e) => setQueryparam(e.target?.value)}
                onChange={(___, target) => {
                    const { value, extra } = target || {};
                    if(!id){
                    setManufacturerData(value);
                    }
                }}
                ListboxProps={{
                    style: {
                        maxHeight: "unset",
                        overflow: "unset",
                        boxShadow: "none",
                        marginBottom: 5,
                    },
                    custom: { data: manufacturer, count: manufacturerCount },
                    fetchData: () => {
                        fetchManufacturers(pageNumber + 1);
                    },
                }}
                ListboxComponent={ListboxComponent}
                onOpen={() => setQueryparam("")}
                required
                // error={submit && frkManufacturerId === "" && true}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        size="medium"
                        sx={{ width: 400 }}
                        variant="outlined"
                        label={translate('manufacturerName')}
                    />
                )}
            />

        </div>
    )
}

export default ManufacturerDropdown