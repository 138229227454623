import React, {
    useEffect,
    useState,
    useRef,
    useCallback,
} from "react";
import Box from "@mui/material/Box";
import "../../../../App.css";
import { CircularProgress, Grid, Stack, Typography } from "@mui/material";
import { useSelector, } from "react-redux";
import { useNavigate, } from "react-router-dom";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import _ from "lodash";
import {getAllStateLabAccess, getAllStatesManage } from "../../../../services/adminService";
import { MAIN_BLUE } from "../../../../color";
import GridItem from "../../../GridItem/GridItem";
import { PAGE_SIZE_ARRAY } from "../../../../constants";
import CreateButton from "../../../../components/CommonFilterCreateButton/CreateButton";
import FilterButton from "../../../../components/CommonFilterCreateButton/FilterButton";
import SimpleDialogDemo from "../../../../components/Filter/FilterPopup";
import SearchBar from "../../../../components/CommonSearchBar/Search";
import DeletableChips from "../../../../components/Chips/DeletableChip";
import { useAppDispatch } from "../../../../services/StoreHooks";
import { MILLER_UI_TEXT } from "../../../../textConstant";
import useTranslatewrapper from "../../../../components/useTranslateWrapper";

const StateGrid = () => {
  const {translate} = useTranslatewrapper()

    const [loader, setLoader] = React.useState(false);
    const [rowData, setRowData] = useState([]);
    const dispatch = useAppDispatch();
    const [gridApi, setGridApi] = useState(null);
    const [columnDefsUser, setColumnDefsUser] = React.useState([{
        field: "state",
        headerName: translate('states'),
        hide: false,
        filter: "agTextColumnFilter",
        floatingFilterComponentParams: {
            suppressFilterButton: true,
        },
        flex: 2,
        filterParams: {
            buttons: ["apply", "clear"],
        },

    },
    {
        field: "category",
        headerName: translate('category'),
        hide: false,
        filter: "agTextColumnFilter",
        floatingFilterComponentParams: {
            suppressFilterButton: true,
        },
        flex: 2,
        filterParams: {
            buttons: ["apply", "clear"],
        },
        valueFormatter: (params) => {
          return (params.data.category?.replace('MILLER',MILLER_UI_TEXT));
        },
    },
    {
        field: "entityType",
        headerName: "Entity Type",
        hide: false,
        filter: "agTextColumnFilter",
        floatingFilterComponentParams: {
            suppressFilterButton: true,
        },
        flex: 2,
        filterParams: {
            buttons: ["apply", "clear"],
        },
    },
    ]);
    const gridRef = useRef();
    const [openFilter, setOpenFilter] = React.useState(false);
    const [filterStates, setFilterStates] = React.useState([]);
    const [pageSize, setPageSize] = React.useState(12);
    const [pageNo, setPageNo] = React.useState(1);
    const [count, setCount] = React.useState(1);
    const RowSelectionType = "single";
    const filterDataRedux = useSelector((state) => state.filterData);
    const categoriesRedux = useSelector((state) => state.categoriesData);
    const [filterData, setFilterData] = React.useState({
        state: [],
        expStartDate: "",
        expEndDate: "",
        mnmfEndDate: "",
        mnfStartDate: "",
      });
    const [chipsState, setChipsState] = React.useState({});
    const [options, setOptions] = React.useState([]);
    const [entityTypeOptions, setEntityTypeOptions] = React.useState([]);
    const [chip, setChip] = useState("");
    const [clear, setclear] = useState(false);
    const [searchValue, setSearchValue] = React.useState("");
    let chipMap = {};
    const [selected, setSelected] = React.useState([]);
    useEffect(() => {
        updateChips()
        fetchAllStateData(1, pageSize);
    }, [filterDataRedux]);
    const navigate = useNavigate();
        const searchCriteriaList = [
      {
        "key": "search",
        "value": ""
      },
      {
        "key": "categoryIds",
        "value": []
      },
      {
         "key": "entityType",
         "value": []
     },
     ]
    const handleClickOpen = () => {
        setOpenFilter(true);
      };
      const handleClose = (value) => {
        setOpenFilter(false);
      };
      const setFiltersSearch = (data) => {
        var json = filterDataRedux.searchCriteriaList;
        var json1 = structuredClone(json);
    
        if (data == null) {
          json1[0].value = "";
        } else {
          json1[0].value = data.label;
          setChip(data.label);
        }
        dispatch({
          type: "UPDATE_FILTER",
          data: json1,
        });
      };
  
      const removeSearch = (data) => {
        var json = filterDataRedux.searchCriteriaList;
        var json1 = structuredClone(json);
        if (data != null) {
          json1[0].value = "";
          setChip("");
          setclear(true);
        }
    
        dispatch({
          type: "UPDATE_FILTER",
          data: json1,
        });
      };
      const setFilters = (filterData) => {
        var json = filterDataRedux.searchCriteriaList;
        var json1 = structuredClone(json);
        json1[0].value = searchValue;
        dispatch({
          type: "UPDATE_FILTER",
          data: json1,
        });
      };
  
  
  
    useEffect(() => {
        setLoader(true);
        // fetchAllStateData(1, pageSize);
        // setPageNo(1)
        setPage(1)
    }, [filterDataRedux]);

    const setPage = (page) => {
        setPageNo(page);
        fetchAllStateData(page, pageSize);
    };
    const counter = () => {
        return count % pageSize === 0
            ? Math.floor(count / pageSize)
            : Math.floor(count / pageSize) + 1;
    };
    useEffect(()=>{
        const options = []
        categoriesRedux?.categories?.forEach((category)=> {
          if(!category.independentBatch){
  
            options.push({
              value: category.name,
              label: category.name,
              id: category.id,
            });
          }
        });
        setOptions([...options]);
        const entityTypes = [
          {
            value: 'lot',
            label: 'lot',
            id: 'lot',
          },
          {
            value: 'batch',
            label: 'batch',
            id: 'batch',
          }
        ]
        setEntityTypeOptions(entityTypes)
      }, [categoriesRedux]);
    const fetchAllStateData = async (pageNo, pageSize) => {
      if(filterDataRedux.searchCriteriaList){
        if(filterDataRedux.searchCriteriaList[0].value){
          searchCriteriaList[0].value = filterDataRedux.searchCriteriaList[0].value
        }
        if(filterDataRedux.searchCriteriaList[3].value && filterDataRedux.searchCriteriaList[3].value.length>0){
          searchCriteriaList[1].value = filterDataRedux.searchCriteriaList[3].value
        }
        if(filterDataRedux.searchCriteriaList[9].value && filterDataRedux.searchCriteriaList[9].value.length>0){
          searchCriteriaList[2].value = filterDataRedux.searchCriteriaList[9].value
        }
      }
      const searchCriteria = {
        searchCriteriaList
      }
        const fetchData = await getAllStateLabAccess(searchCriteria,pageNo, pageSize);
        const arr = []
        const categoryMap = {}
        categoriesRedux?.categories?.forEach((category)=> {
        
        categoryMap[category.id] = category.name
      });
        if (fetchData?.data?.data) {
            setCount(fetchData?.data?.count);
            fetchData?.data?.data.map((data) =>
                arr.push({
                    state: data?.stateResponseDto?.name,
                    id:  data?.stateResponseDto.id,
                    category: categoryMap[data?.categoryId],
                    entityType: data?.entityType,
                    categoryId:data?.categoryId
                })
            )
            setRowData(arr)
        }
        setLoader(false)
    };

    function onGridReady(params) {
        setGridApi(params.api);
    }

    const onRowClicked = useCallback(() => {
        const selectedRows = gridRef.current.api.getSelectedRows();
        navigate(`/admin/iam/state/details/${selectedRows[0].categoryId}/${selectedRows[0].entityType}/${selectedRows[0].id}`)

        console.log(selectedRows)
    }, []);
    const handleSelectChange = (values) => {
        setSelected(values);
      };
  
      const updateChips = () => {
        filterDataRedux.searchCriteriaList.map((entry) => {
          switch (entry.key) {
            case "batchNo":
              if (entry.value) chipMap["batchNo"] = entry.value;
              break;
            case "dateOfManufacture":
              if (entry.value !== "" && entry.value)
                chipMap["dateOfManufacture"] = entry.value;
              break;
            case "dateOfExpiry":
              if (entry.value !== "" && entry.value)
                chipMap["dateOfExpiry"] = entry.value;
              break;
            case "stateIds":
              if (entry.value !== "" && entry.value)
                chipMap["stateIds"] = entry.value;
              break;
            case "entityTypes":
              if (entry.value !== "" && entry.value)
                chipMap["entityTypes"] = entry.value;
              break;
          }
        });
        setChipsState(chipMap);
      };
    const deleteChip = (chipKey, chipValue) => {
        var json = filterDataRedux.searchCriteriaList;
        var json1 = structuredClone(json);
    
        if (chipKey === "batchNo") {
          json1[0].value = "";
        }
        if (chipKey === "dateOfManufacture") {
          setFilterData({ ...filterData, mnfStartDate: "", mnfEndDate: "" });
    
          json1[1].value = "";
        }
        if (chipKey === "dateOfExpiry") {
          setFilterData({ ...filterData, expStartDate: "", expEndDate: "" });
    
          json1[2].value = "";
        }
        if (chipKey === "stateIds") {
          const index = json1[3].value.indexOf(chipValue);
          if (index > -1) {
            json1[3].value.splice(index, 1);
          }
          setFilterStates(json1[3].value);
        }
    
        if (json1[3].value.length == 0) json1[3].value = [null];
        if (chipKey === "entityTypes") {
          const index = json1[9].value.indexOf(chipValue);
          if (index > -1) {
            json1[9].value.splice(index, 1);
          }
          setFilterStates(json1[9].value);
        }
    
        if (json1[9].value.length == 0) json1[9].value = [null];
    
        dispatch({
          type: "UPDATE_FILTER",
          data: json1,
        });
      };
  
     
    return (
        <div>
            {loader ? (
                <Box className="main-content-overlay" sx={{ height: "100vh" }}>
                    <CircularProgress />
                </Box>
            ) : (
                <div>
                    <Box className="main-content-overlay">
                        <div className="main-content-div">
                            <Box m={2}>
                                <Grid container>
                                    <Grid item md={3} sd={12} p={1}>
                                        <Typography variant="h6" sx={{ color: MAIN_BLUE, mx: 1 }}>
                                        State Management
                                        </Typography>
                                    </Grid>
                                    <Grid item md={5}>
                      <Stack direction="row" sx={{ my: 1.5, flexWrap: "wrap" }}>
                        {Object.keys(chipsState).map((key) => (
                          <DeletableChips
                            chipValue={chipsState[key]}
                            chipKey={key}
                            deleteChip={deleteChip}
                            options={options}
                            sx={{ maxHeight: "2rem !important" }}
                          />
                        ))}
                      </Stack>
                    </Grid>
                    <Grid item md={4} sx={{ display: 'flex', alignItems: 'center' }}>
                    <SearchBar
                      onChange={(e) => {
                        if (e !== "") {
                          setFiltersSearch(e);
                        } else {
                          removeSearch();
                        }
                      }}
                    />
                    {openFilter ? (
                      <div>
                        <SimpleDialogDemo
                          chipsState={chipsState}
                          open={openFilter}
                          onClose={handleClose}
                          filterData={filterData}
                          setFilterData={setFilterData}
                          setFilters={setFilters}
                          filterStates={filterStates}
                          handleSelectChange={handleSelectChange}
                          options={options}
                          entityTypeOptions={entityTypeOptions}
                          hideDateFilter={true}
                          hideState={true}
                        />
                      </div>
                    ) : (
                      <FilterButton handleClickOpen={handleClickOpen} />
                    )}

                  </Grid>
                                    <Grid item md={4} sx={{ alignItems: 'center', display: 'flex', }}>
                                        <CreateButton
                                            onClick={() => {
                                                navigate(`/admin/iam/state/details`)
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Box sx={{ m: 1 }}>
                                    <GridItem
                                        onGridReady={onGridReady}
                                        rowData={rowData}
                                        gridRef={gridRef}
                                        rowSelection={RowSelectionType}
                                        columnDefs={columnDefsUser}
                                        pageSize={pageSize}
                                        pageSizeArray={PAGE_SIZE_ARRAY}
                                        count={counter()}
                                        setPage={setPage}
                                        page={pageNo}
                                        onSelectionChanged={onRowClicked}
                                        height="main"
                                    />
                                </Box>
                            </Box>
                        </div>
                    </Box>
                </div>
            )}
        </div>
    );
};

export default StateGrid;
