import PropTypes from 'prop-types';
import {Box, Icon, Typography} from '@mui/material';
import { StyledTreeItemRoot } from './InternalStyles';
import CircleIcon from '@mui/icons-material/Circle';


export default function StyledTreeItem(props) {
    const {
      bgColor,
      color,
      labelIcon: LabelIcon,
      labelInfo,
      labelText,
      iconName,
      ...other
    } = props;
  
    return (
      <StyledTreeItemRoot
        label={
          <Box sx={{ display: 'flex', alignItems: 'center', p: 0.1, pl: 0 }}>
            <Box  sx={{ mr: 1 }} />
            <CircleIcon sx={{fontSize:"7px",margin:"6px",color:"black"}}/>
            {/* <Icon sx={{mr: 1.5}}>
                <img alt="frkIcon" src={iconName ? `/assets/images/${iconName}.png` : "/assets/images/frk.png"} className='menu-item-icon-png' />
            </Icon> */}
            <Typography variant="body2" sx={{ fontWeight: '100', flexGrow: 1, lineHeight: "25px",color:"black",fontFamily:"outfit"}}>
              {labelText}
            </Typography>
            <Typography variant="caption" >
              {labelInfo}
            </Typography>
          </Box>
        }
        style={{
          '--tree-view-color': color,
          '--tree-view-bg-color': bgColor,
        }}
        {...other}
      />
    );
  }
  
  StyledTreeItem.propTypes = {
    bgColor: PropTypes.string,
    color: PropTypes.string,
    labelIcon: PropTypes.elementType.isRequired,
    labelInfo: PropTypes.string,
    labelText: PropTypes.string.isRequired,
  };