import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useMemo, useState } from "react";
import UnitConverterWeight from "../../utils/UnitConverter";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import DescriptionIcon from '@mui/icons-material/Description';

export default function DistrictGrid(props) {
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "S.No",
      valueGetter: "node.rowIndex + 1",
      width: 100,
    },
    {
      headerName: "District Name",
      field: "districtName",
      tooltipField: "districtName",
      filter: true
    },
    {
      headerName: "State Name",
      field: "stateName",
      tooltipField: "stateName",
      filter: true,
    },
    {
      headerName: "Total Demand",
      field: "totalDemand",
      filter: true,
      // valueFormatter: (params) => {
      //   return UnitConverterWeight(params.data.totalDemand);
      // },
    },
    {
      headerName: "Total Supply",
      field: "totalSupply",
      // valueFormatter: (params) => {
      //   return UnitConverterWeight(params.data.totalSupply);
      // },
    },
    {
      headerName: "Lot Approved",
      field: "lotApprovedQuantity",
      // valueFormatter: (params) => {
      //   return UnitConverterWeight(params.data.lotApprovedQuantity);
      // },
    },
    {
      headerName: "Lot Rejected",
      field: "lotRejectedQuantity",
      // valueFormatter: (params) => {
      //   return UnitConverterWeight(params.data.lotRejectedQuantity);
      // },
    },
    {
      headerName: "Lot Pending",
      field: "lotApprovalPending",
      // valueFormatter: (params) => {
      //   return UnitConverterWeight(params.data.lotApprovalPending);
      // },
    },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
    };
  }, []);

  const getRowStyle = (params) => {
    if (params.node.rowIndex % 2 != 0) {
      return { background: "#F8F8F8" };
    }
  };

  return (
    <>
      <div
        style={{ display: "flex", justifyContent: "end", marginRight: "10px" }}
      >
        <a onClick={props.handleDownloadClick} href="" download="districtExcel.xlsx">
          <span style={{ fontSize: "1rem" }}>Download</span>
          <FileDownloadOutlinedIcon
            sx={{ fontSize: "1.2rem"}}
            m={1}
          />
        </a>
      </div>
      <div className="ag-theme-alpine" style={{ height: 450 }}>
        <AgGridReact
          rowData={props.rowData}
          columnDefs={columnDefs}
          getRowStyle={getRowStyle}
          animateRows={true}
          // groupIncludeFooter={true}
          // groupIncludeTotalFooter={true}
          onRowClicked={props.onRowClick}
          defaultColDef={defaultColDef}
          tooltipShowDelay={0}
          tooltipHideDelay={2000}
          enableBrowserTooltips={true}
        />
      </div>
      {/* <a href={props.downloadUrl} download="districtExcel.xlsx">
        download excel
      </a> */}
    </>
  );
}
