import useTranslatewrapper from "../../../../components/useTranslateWrapper";

export default function useTestReferenceMethodSchemaFn() {
  const { translate } = useTranslatewrapper();
  return {
    properties: {
      Category: {
        title: translate('category'),
        enum: [],
      },
      TestMethod: {
        type: "string",
        title: translate('testName'),
      },
      TestType: {
        title: translate('testType'),
        enum: ["PHYSICAL", "MICRONUTRIENT"],
      },
      Mandatory: {
        type: "boolean",
        default: "false",
        title: "Mandatory",
      },
    },
    required: ["TestMethod", "Category", "TestType", "Mandatory"],
  };
}
