import React, { useState,useEffect } from "react";
import { Box, Button, Dialog, Fab, Tooltip, Typography } from "@mui/material";
import Chip from '@mui/material/Chip';
import LoaderComponent from "../Loader/LoaderComponent";
import "./ColumnChip.css"
import { ChipColorSelector } from "../../utils/ChipColorHandler";
import useTranslatewrapper from "../useTranslateWrapper";




const Columnchip = (props) => {
  useEffect(() => {
  //   {props.type=="lots"?
 
  // }
  

  }, [])
  const {translate} = useTranslatewrapper()
  const {label} =props
  const [loader, setLoader] = React.useState(false);
  //  created #ffe6ff
  //  #e6fffa
  const color = ChipColorSelector(props.data.state)
  
  const buttonStyles = {
    backgroundColor: `${color}`, padding: "2px 6px",
    margin: "8px", width: "80%",textalign:"center",
    maxHeight: "1.5rem", borderRadius: "20px",fonSize:"0.65 rem !important",
    color: "black", border: "none" , boxShadow: "none !important",
  }
//  #ffff99
//  #ffb780   
  return (
    <Box>
  
        <Chip label={translate(props.data.state.toLowerCase())} className="column-chip" fontSize="small" style={buttonStyles}/>
   </Box>
  );
};

export default Columnchip;
