export const SuccessMessageSelector = (status, type) => {
    switch (status) {
        case "Created":
          return [`${type} has been created`]
        case "Sample sent to Lab":
          return ["Sample has been sent to Lab for testing. Details will be updated once the tests are performed"]
        case "Sample in Lab":
           return ["Sample has been received in Lab"]
        case "Sample tested":
          return ["Sample Reports have been updated"];
        case "Rejected":
          return [`${type} is rejected`];
        case "Batch ready to be dispatched":
          return ["Batch is ready to be dispatched, create Lots for Shipment"];
        case "Lot dispatched":
          return ["Lot has been Dispatched"];
        case "Lot received":
            return ["Lot has been Received"]
        case "Self tested":
            return [`Self Tested the ${type} and uploaded the results`]
        case "Accepted":
            return [`${type} has been accepted and is available for use`];
        case  "Lot to send back":
            return ["Rejected Lot is ready to send back to the manufacturer"];
        case "Lot sent back":
            return ["Rejected Lot sent back to the manufacturer"];
        case  ["Rejected lot received"]:
            return ["Rejected Lot received by the manufacturer"];
        default:
          return ["Success"];
    }
}