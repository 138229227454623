import HttpService from "./HttpService";

let _axios
const IAM_URL = process.env.REACT_APP_BASE_IAM_URL;
const IMMUDB_URL = process.env.REACT_APP_BASE_IMMUDB_URL;
const IAM_URL_RICE = process.env.REACT_APP_BASE_IAM_URL_RICE;

// initialize the Axios instance to avoid error where axios call is happening late
export const getAxios = () => {
  _axios = HttpService.getAxiosClient();
}
export const firebaseREgistrationToken = async (body) => {
  return _axios
    .post(`${IAM_URL}registration/token`, body)
    .catch((error) => { });
};

export const notificationCount = async () => {
  return _axios.get(`${IMMUDB_URL}notification/count`).catch((error) => { });
};
export const notificationList = async (pageNumber, pageSize) => {
  return _axios
    .get(
      `${IMMUDB_URL}notification/list?pageNumber=${pageNumber}&pageSize=${pageSize}`
    )
    .catch((error) => { });
};
export const notificationLastSeen = async (body) => {
  return _axios
    .put(
      `${IAM_URL_RICE}user/notification/last-seen`,
      body
    )
    .catch((error) => { });
};
