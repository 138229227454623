import styled from "styled-components";

export const Container = styled.div`
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

export const Section = styled.div`
  width: 100%;
  padding: 20px;
  margin-bottom: 20px;
  background-color: ${(props) => props.backgroundColor};
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  padding-top: 20px;
`;

export const Logo = styled.img`
  padding-left: 20px;
  height: 70px;
`;

export const Heading = styled.h1`
  font-size: 2.5em;
  margin: 0;
  text-align: center;
  color: #444e62;
  flex-grow: 1;
  padding-left: 20px;
`;

export const Text = styled.p`
  font-size: 1.2em;
  line-height: 1.6;
  color: #333;
  margin-bottom: 20px;
`;

export const SubHeading = styled.h2`
  font-size: 1.5em;
  color: #0056b3;
  margin-top: 20px;
  margin-bottom: 10px;
`;

export const List = styled.ul`
  padding-left: 20px;
  margin-bottom: 20px;
`;

export const ListItem = styled.li`
  margin-bottom: 10px;
`;
