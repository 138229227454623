import HttpService from "./HttpService";
import { getadminMenu } from "./SuperAdminSideMenu";
const _axios = HttpService.getAxiosClient();
const FORTIFICATION_URL = process.env.REACT_APP_BASE_FORTIFICATION_URL;
const FORTIFICATION_URL_RICE = process.env.REACT_APP_BASE_FORTIFICATION_URL_RICE;

// var data = {
//     "roleCategoryIds": [
//     29,30
//   ],
//   "roleCategoryType": "MODULE"
//   }

export const getMenuItems = async (isSuperAdmin, isMonitor, userrole, categoryNameToIdMapRedux) => {
  if (isSuperAdmin || isMonitor) {
    return { data: getadminMenu(isSuperAdmin, isMonitor, userrole, categoryNameToIdMapRedux) };
  }
  return (
    _axios
      .get(`${FORTIFICATION_URL_RICE}menu/module`)
      // .get(`mock_json_data/formdata.json`)
      .catch((error) => {})
  );
};
