import React, { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { Button, TextField, MenuItem, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import Chip from "@mui/material/Chip";
import { getUomData } from "../../../services/FortificationService";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import useTranslatewrapper from "../../../components/useTranslateWrapper";

function StackComponent({
  setBatchSizeLot,
  lotSize,
  setShowButton,
  disableVendorData,
  submit
}) {
  const {translate} = useTranslatewrapper()
  const [uomData, setUomData] = useState();
  const [chipData, setChipData] = useState(lotSize?.map((data)=>({size:data?.size,unit:data?.uom?.name,quantity:data?.quantity,uomId:data?.uom?.id}) || []));
  const [fields, setFields] = React.useState({
    size: "",
    units: "",
    quantity: "",
  });
  const [size, setSize] = React.useState("");
  const [unit, setUnit] = React.useState("");
  const [quantity, setQuantity] = React.useState("");

  const fetchData = async () => {
    const SizeUnit = await getUomData();
    setUomData(SizeUnit.data.data);
  };
  useEffect(() => {
    
    fetchData();
  }, []);

 




  const handleAddItem = () => {

    if(chipData && chipData.length>0){
      setChipData([...chipData, { size: "", unit: "", quantity: "",uomId:"" }]);
    
    } 
    else{
      setChipData([{ size: "", unit: "", quantity: "" }]);

    }

  };

 

const handleDeleteRow=(idx)=>{
  if(chipData.length>0 ){
    const data = [...chipData];
  data.splice(idx, 1);
  setChipData(data);
  setBatchSizeLot(data);

  }
  }
  



  return (
    <Box sx={{ marginTop: "1rem !importatn" }}>
      <TableContainer  >
        <Table sx={{ width: "70vw" }} className="form-table" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="table-head" sx={{ border: 1,borderColor:" #E0E0E0"  }}>S.No</TableCell>
              <TableCell  className="table-head" sx={{ border: 1, borderColor:" #E0E0E0"}} align="left">
                {translate('bagSizes')}
              </TableCell>
              <TableCell className="table-head" sx={{ border: 1,borderColor:" #E0E0E0"  }} align="left">
              {translate('units')}
              </TableCell>
              <TableCell className="table-head" sx={{ border: 1,borderColor:" #E0E0E0"  }} align="left">
              {translate('quantity')}
              </TableCell>
              <TableCell  className="table-head" sx={{ border: 1,borderColor:" #E0E0E0"  }} align="left">
              {translate('actions')}
              </TableCell>
            </TableRow>
          </TableHead>
          
              <TableBody>
              {chipData &&
            chipData.map((data,idx) => (
                <TableRow
                  // key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 1,borderColor:" #E0E0E0" } }}
                >
                  <TableCell  sx={{ border: 1,borderColor:" #E0E0E0"   }} component="th" scope="row">
                    {idx+1}
                  </TableCell>
                  <TableCell align="left" sx={{ border: 1,borderColor:" #E0E0E0"   }}>
                    <TextField
                      disabled={disableVendorData}
                      sx={{ width: "100%", backgroundColor: "#fff" }}
                      name="size"
                      id="outlined-basic"
                      value={data.size}
                      type="number"
                      variant="standard"
              error={submit && data.size==="" && true}

                      onChange={(event) => {
                        chipData[idx]["size"]=event.target.value;
      setBatchSizeLot([...chipData]);

                        setSize(event.target.value);

                      }}
                    ></TextField>
                  </TableCell>
                  <TableCell  sx={{ border: 1,borderColor:" #E0E0E0"   }} align="left">
                    <TextField
                      disabled={disableVendorData}
                      sx={{ width: "100%", backgroundColor: "#fff" }}
                      name="unit"
                      id="outlined-basic"
                      value={data.unit}
                      variant="standard"
              error={submit &&data.unit==="" && true}

                      select
                      onChange={(event) => {
                        chipData[idx]["unit"]=event.target.value;
      setBatchSizeLot([...chipData]);

        
          chipData[idx]["uomId"]= uomData.filter((e) => e.name === event.target.value)[0].id;

                        setUnit(event.target.value)}
                      }

                    >
                      {Array.isArray(uomData) &&
                        uomData?.map((uom, idx) => {
                          return (
                            <MenuItem value={uom.name}> {uom.name}</MenuItem>
                          );
                        })}
                    </TextField>
                  </TableCell>
                  <TableCell  sx={{ border: 1,borderColor:" #E0E0E0"   }} align="left">
                    <TextField
                      disabled={disableVendorData}
                      sx={{ width: "100%", backgroundColor: "#fff" }}
                      name="quantity"
                      id="outlined-basic"
                      value={data.quantity}
                      type="number"
                      variant="standard"
              error={submit &&data.quantity==="" && true}

                      onChange={(event) => {
                        chipData[idx]["quantity"]=event.target.value;
      setBatchSizeLot([...chipData]);

                        
                        setQuantity(event.target.value)}}
                    />
                  </TableCell>
                  <TableCell  sx={{ border: 1,borderColor:" #E0E0E0"   }} align="left">
                    <Button 
                  disabled={disableVendorData}
      sx={{textDecoration:"underline"}}
                    
                    onClick={()=>handleDeleteRow(idx)}>{translate('delete')}</Button>
                  </TableCell>
                </TableRow>
            ))}

</TableBody>
        </Table>
      </TableContainer>
      <Button 
                  disabled={disableVendorData}
      sx={{textDecoration:"underline"}}
      onClick={() => handleAddItem()}>
        <AddCircleOutlineIcon />
        {translate('addItem')}
      </Button>
    </Box>
  );
}

export default StackComponent;
