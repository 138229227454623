import { Autocomplete, CircularProgress, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react'
import { getAllBatchesForSerach } from '../../services/LabService';
import { useNavigate, useParams } from 'react-router-dom';

const BatchSearch = () => {
    const routeParams = useParams();
    const navigate = useNavigate();
    const [value, setValue] = React.useState(null);
    const [inputValue, setInputValue] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const loading = open && options.length === 0;

    React.useEffect(() => {
        let active = true;
    
        if (inputValue === '') {
          setOptions(value ? [value] : []);
          return undefined;
        }

        (async () => {
        const response = await getAllBatchesForSerach(routeParams?.categoryId, inputValue)
    
        if (active) {
            setOptions(
                response?.data?.data
            );
        }
        })();
    
        return () => {
          active = false;
        };
      }, [value, inputValue]);

  return (
    <div style={{width: '100%'}}>
        <Autocomplete
        id="asynchronous-demo"
        size='small'
        fullWidth
        sx={{ minWidth: '20vw', paddingLeft: '5px', paddingBottom: '10px', fontSize: '20px' }}
        // filterOptions={(x) => x}
        isOptionEqualToValue={(option, value) => option.batchNo === value.batchNo}
        getOptionLabel={(option) => `${option.batchNo} ${option.manufacturerBatchNumber ? "(" + option.manufacturerBatchNumber + ")" : ""}`}
        options={options}
        onChange={(event, newValue) => {
            setOptions(newValue ? [newValue, ...options] : options);
            console.log('newValue', newValue)
            // setValue(newValue);
            
            navigate(`/${newValue.category.name.toLowerCase().split(' ').join('-')}/${newValue.category.id}/batch/details/${newValue.id}`);
        }}
        onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
        }}
        renderInput={(params) => (
            <TextField
            {...params}
            label="Search batch"
            InputProps={{
                ...params.InputProps,
                endAdornment: (
                <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                </React.Fragment>
                ),
            }}
            />
        )}
        />
    </div>
  )
}

export default BatchSearch