const initial = {
    showLoader: false
  };
export default function LoaderChangeStatus (state = initial, event) {
    switch (event.type) {
      case "SHOW_LOADER":
        return {
          ...state,
          showLoader:event.data.showLoader,

        };
      default:
        return state;
    }
  };