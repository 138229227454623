import { Box } from '@mui/material'
import React from 'react'
import SupplyDemandTable from '../../components/SupplyDemand/SupplyDemandTable'

function SupplyDemandPage() {
  return (
    <div>
        <Box>
            <SupplyDemandTable/>
        </Box>
    </div>
  )
}

export default SupplyDemandPage;