import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Box, TextField, Typography } from "@mui/material";

const PremixData = [
  { name: "AP", supply: "10000000", demand: "20000000" },
  { name: "Punjab", supply: "40000000", demand: "30000000" },
  { name: "Haryana", supply: "50000000", demand: "80000000" },
  { name: "Telangana", supply: "70000000", demand: "40000000" },
  { name: "TamilNadu", supply: "20000000", demand: "50000000" },
];
const FrkData = [
  { name: "AP", supply: "100000", demand: "200000" },
  { name: "Punjab", supply: "400000", demand: "300000" },
  { name: "Haryana", supply: "500000", demand: "800000" },
  { name: "Telangana", supply: "700000", demand: "400000" },
  { name: "TamilNadu", supply: "200000", demand: "500000" },
];
const MillerData = [
  { name: "AP", supply: "1000000", demand: "2000000" },
  { name: "Punjab", supply: "4000000", demand: "3000000" },
  { name: "Haryana", supply: "5000000", demand: "8000000" },
  { name: "Telangana", supply: "7000000", demand: "4000000" },
  { name: "TamilNadu", supply: "2000000", demand: "5000000" },
];

function SupplyDemandTable() {
  const [premixData, setPremixData] = useState(PremixData);
  const [frkData, setFrkData] = useState(FrkData);
  const [millerData, setMillerData] = useState(MillerData);

  const handlePremixDemandChange = (event, index) => {
    const data = [...premixData];
    data[index].demand = event.target.value;
    setPremixData(() => data);
  };

  const handleFrkDemandChange = (event, index) => {
    const data = [...frkData];
    data[index].demand = event.target.value;
    setFrkData(() => data);
  };
  const handleMillerDemandChange = (event, index) => {
    const data = [...millerData];
    data[index].demand = event.target.value;
    setMillerData(() => data);
  };

  return (
    <div style={{ padding: "2rem", backgroundColor: "white" }}>
      <Typography
        variant="h5"
        style={{ marginBottom: "2rem", textAlign: "center" }}
      >
        Supply-Demand
      </Typography>
      <TableContainer>
        <Typography variant="h6" sx={{ marginBottom: "5px" }}>
          Premix
        </Typography>
        <Table
          size="small"
          sx={{ width: "80%", border: "1px solid #ddd" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow style={{ height: "4rem" }}>
              <TableCell align="left" sx={{ fontWeight: "bolder" }}>
                Name
              </TableCell>
              <TableCell align="left" sx={{ fontWeight: "bolder" }}>
                Supply
              </TableCell>
              <TableCell align="left" sx={{ fontWeight: "bolder" }}>
                Demand
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {premixData.map((row, index) => (
              <TableRow key={row.name} sx={{ padding: "1px" }}>
                <TableCell align="left">{row.name}</TableCell>
                <TableCell align="left">{row.supply}</TableCell>
                <TableCell align="left" sx={{ maxWidth: "10ch" }}>
                  <TextField
                    id="standard-number"
                    type="number"
                    size="small"
                    value={row.demand}
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    onChange={(e) => {
                      handlePremixDemandChange(e, index);
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ height: "50px" }}></Box>

      <TableContainer>
        <Typography variant="h6" sx={{ marginBottom: "5px" }}>
          FRK
        </Typography>
        <Table
          size="small"
          sx={{ width: "80%", border: "1px solid #ddd", borderRadius: "10px" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow style={{ height: "4rem" }}>
              <TableCell align="left" sx={{ fontWeight: "bolder" }}>
                Name
              </TableCell>
              <TableCell align="left" sx={{ fontWeight: "bolder" }}>
                Supply
              </TableCell>
              <TableCell align="left" sx={{ fontWeight: "bolder" }}>
                Demand
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {frkData.map((row, index) => (
              <TableRow key={row.name}>
                <TableCell align="left">{row.name}</TableCell>
                <TableCell align="left">{row.supply}</TableCell>
                <TableCell align="left" sx={{ maxWidth: "10ch" }}>
                  <TextField
                    id="standard-number"
                    type="number"
                    size="small"
                    value={row.demand}
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    onChange={(e) => {
                      handleFrkDemandChange(e, index);
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ height: "50px" }}></Box>

      <TableContainer>
        <Typography variant="h6" sx={{ marginBottom: "5px" }}>
          ${`Miller(FR)`}
        </Typography>
        <Table
          size="small"
          sx={{ width: "80%", border: "1px solid #ddd", borderRadius: "10px" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow style={{ height: "4rem" }}>
              <TableCell align="left" sx={{ fontWeight: "bolder" }}>
                Name
              </TableCell>
              <TableCell align="left" sx={{ fontWeight: "bolder" }}>
                Supply
              </TableCell>
              <TableCell align="left" sx={{ fontWeight: "bolder" }}>
                Demand
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {millerData.map((row, index) => (
              <TableRow key={row.name}>
                <TableCell align="left">{row.name}</TableCell>
                <TableCell align="left">{row.supply}</TableCell>
                <TableCell align="left" sx={{ maxWidth: "10ch" }}>
                  <TextField
                    id="standard-number"
                    size="small"
                    type="number"
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    value={row.demand}
                    onChange={(e) => {
                      handleMillerDemandChange(e, index);
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default SupplyDemandTable;
