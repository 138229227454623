import React from "react";
import { useLocation, Navigate } from "react-router-dom";
import KeycloakService from "../services/KeycloakService";
import { useDispatch } from 'react-redux';
import { LOGIN_PATH_USING_TOKEN } from "../constants";

const ProtectedRoute = ({ children, menuDetails, isSuperAdmin, isMonitor,isInspector }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isLoggedIn = KeycloakService.isLoggedIn();

  const setIndependentBatch = (param) =>{
    dispatch(
      {
        type: "UPDATE_STATE",
        data: param
      }
    )
  }
  const currentCategoryId = location.pathname.split("/")[2];
  if(!isNaN(Number(currentCategoryId))) {
    const parent = menuDetails.find(item => item.entityStates[0].categoryId == currentCategoryId);
    if(parent?.independentBatch) setIndependentBatch(parent?.independentBatch || false);
  }
  switch (location.pathname) {
    case "/":
    case LOGIN_PATH_USING_TOKEN:
      if(isSuperAdmin || isMonitor){
        return <Navigate to={menuDetails[0].entityStates[0].states[0].route} />;
      }
      if(KeycloakService.getUserDetail().isInspector){
        // return <Navigate to={`inspect/${menuDetails[1].categoryName.toLowerCase().split(" ").join("-")}/${menuDetails[1]?.categoryId}/${menuDetails[1]?.entityStates[0]?.name.toLowerCase()}`} />;
        return <Navigate to={`inspect/manage`} />;
      }
      if (menuDetails && menuDetails.length)
        return <Navigate to={`/${menuDetails[0].categoryName.toLowerCase().split(" ").join("-")}/${menuDetails[0]?.categoryId}/${menuDetails[0]?.entityStates[0]?.name.toLowerCase()}`} />;
      return <Navigate to="/inventory" />
    default:
  }

  if(KeycloakService.loggedInThroughKeycloak == 'true'){//checking if init method is called
    if(KeycloakService.isLoggedIn()){//checking if token is present in keycloak or not
      return children;
    } else{
      return <Navigate to="/login" state={{ from: location }} replace />;
    }
  } else if(sessionStorage.getItem('react-token')){
    return children;
  } else{
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // if (!isLoggedIn && !sessionStorage.getItem('react-token')) {
  //   return <Navigate to="/login" state={{ from: location }} replace />;
  // } else {
  //   return children;
  // }
};

export default ProtectedRoute;
