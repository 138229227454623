import useTranslatewrapper from "../../../../../components/useTranslateWrapper";

export default function useUserDetailsSchemaFn() {
  const { translate } = useTranslatewrapper();
  return {
    properties: {
      FirstName: {
        type: "string",
        title: translate("firstName"),
      },
      LastName: {
        type: "string",
        title: translate("lastName"),
      },
      User: {
        type: "string",
        title: "User",
      },
      Password: {
        type: "string",
        title: "Password",
      },
      ManufacturerDropdown: {
        type: "string",
        title: "LabName",
      },
      RoleCategory: {
        type: "string",
        title: "Role category",
      },
      status: {
        type: "string",
        title: translate("status"),
      },
    },
    required: ["FirstName", "LastName"],
  };
}
