import React, { useEffect, useState } from "react";
import { Form } from "@rjsf/mui";
import validator from "@rjsf/validator-ajv8";
import {
  getManufacturerByID,
  adminCategoryDocDropDown,
  getLabDocTypeByID,
  getDocTypeFormDropdown,
  getLabDocTypeFormDropdown,
  createLabDocTypeByID,
  editLabDocTypeByID,
  createManufacturerGrid,
  editManufacturerGrid,
  createVillage,
  getVillageByDistrict,
  getIamStatus,
} from "../../../../services/adminService.js";
import { Box, Button, Typography } from "@mui/material";
import "../../../../../src/App.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { BACK_BUTTON } from "../../../../textConstant.js";
import { useParams } from "react-router-dom";
import { fromPairs } from "lodash";
import ManufacturingMapping from "./ManufacturingMapping.js";
import AddressWidget from "./AddressWidget.js";
import GetAllFiles from "../../../Form/GetAllFiles/GetAllFiles.js";
import { useAppDispatch } from "../../../../services/StoreHooks.js";
import { useSelector } from "react-redux";
import useTranslatewrapper from "../../../../components/useTranslateWrapper.js";

function ManufacturersForm() {
  const {translate} = useTranslatewrapper()

  const [dropdown, setDropdown] = useState([]);

  const [docDropdown, setDocDropdown] = useState([]);

  const navigate = useNavigate();
  const [formData, setFormData] = useState(
   

  );
  const [uploadData, setUploadData] = useState([]);
  const [statusData, setStatusData] = useState([]);


  const [widgetData, setWidgetData] = useState(null);
  const [docsData, setDocsData] = useState(null);
  const [fileUploadValidated, setFileUploadValidated] = useState(false);

  const [addressData, setAddressData] = useState({});
  const [villageId, setVillageId] = useState(null);

  const params = useParams();
  
  const fetchDocTypeDropdown = async () => {
    const dropdownDocData = await getLabDocTypeFormDropdown();
    dropdownDocData && setDocDropdown(dropdownDocData.data.data);
  };
  const dispatch = useAppDispatch();

  const dispatchNotification = (typ, msg) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      data: {
        open: true,
        type: typ,
        message: msg,
      },
    });

  };
const categoriesRedux = useSelector((state) => state.categoriesData);
 

  const GetCategoryName=(categoryId)=> {
    return categoriesRedux.categories?.filter((item)=>item.id===categoryId)[0].name;
  
}
   
  const fetchFormData = async () => {
    const formDataApi = await getManufacturerByID(params?.id);
    formDataApi && setFormData(formDataApi?.data);
    // formDataApi &&(formData["categoryMapping"] = formDataApi?.data?.manufacturerCategory || []);

    formDataApi &&
      setWidgetData({
        category: formDataApi?.data?.manufacturerCategory,
        targetManufacturers: formDataApi?.data?.targetManufacturers,
        docs: formDataApi?.data?.manufacturerDocs.map(
          ({ docName: name, docPath: path, ...rest }) => ({
            name,
            path,
            ...rest,
          })
        ),
      });
    formDataApi && setAddressData(formDataApi.data.address);
  };

  const fetchStatusData=async () =>{
    const statusApi=await getIamStatus();
    statusApi && setStatusData(statusApi?.data?.data?.filter(item=>item.name.toLowerCase()!=="testing"))
  }
  let arrId = [];
  let newArr;
  const removeDuplicates = (myArray) => {
    const uniqueArray = myArray.reduce((acc, obj) => {
      const found = acc.some(item => item.docType?.name === obj.docType?.name);
      if (!found) {
        acc.push(obj);
      }
      return acc;
    }, []);

    return uniqueArray;
  };



  const fileUploadValidation = () => {
// removeDuplicates(uploadData)
    uploadData
    ?.map((data) => {
      if (data.isMandatory) {
        if (
          docsData?.findIndex(
            (elem) => data?.id === elem.categoryDocId && elem.docPath !== ""
          ) !== -1
        ) {
          arrId.push({
            name: `${GetCategoryName(data.categoryId)}-${data.docType.name}`,
            value: true,
          });
        } else {
          arrId.push({
            name: `${GetCategoryName(data.categoryId)}-${data.docType.name}`,

            value: false,
          });
        }
      }
    });

    newArr = arrId.filter((ele) => ele.value == false);

    if (arrId.length === 0 || newArr.length == 0) {
      setFileUploadValidated(true);
      return true;
    } else {
      setFileUploadValidated(false);
      return false;
    }
  };

  function manufactureValidation(formData, errors) {
    let arrErr = [];
    if (widgetData?.filter((item)=>item.isEnabled===true && item.categoryValId)?.length < 1) {
      errors?.addError("Please add categories");
      arrErr.push("Please add categories");
    }

    if (!fileUploadValidation()) {
      errors?.addError("File Upload is not selected");

      newArr.map((ele) => arrErr.push(`${ele.name} is not selected`));

      // dispatchNotification("error", `${newArr[0].name} is not selected`);
      // setErrMsg(errors?.ExpiryDate?.__errors[0]);
    }

    let msg = "";
    arrErr.map((err) => (msg += err + "\n"));
    if (arrErr && arrErr.length > 0) {
      dispatchNotification("error", arrErr);
    }

    setFormData(formData);
    return errors;
  }

  useEffect(() => {
    fetchDocTypeDropdown();
    fetchStatusData();
  }, []);
  useEffect(() => {
    params.id && fetchFormData();
  }, [params]);

  const ManufactureMappingWidget = (e) => {
    return (
      <ManufacturingMapping
        widgetData={widgetData}
        setWidgetData={setWidgetData}
        setDocsData={setDocsData}
        setUploadData={setUploadData}
      />
    );
  };

  const AddressMapWidget = () => {
    return (
      <AddressWidget
        addressData={addressData}
        setAddressData={setAddressData}
      />
    );
  };

  const widgets = [ManufactureMappingWidget, AddressMapWidget];

  const createVillageData = async (data) => {
    const VillageData = await createVillage(data);

    return VillageData.data;
  };
  const getVillageData = async (data) => {
    const getVillageDataDist = await getVillageByDistrict(data.districtId);
    // getVillageDataDist && handleVillageData(villageIds);

    return getVillageDataDist?.data?.data?.filter(
      (item) => item.name === data.name
    )?.length > 0
      ? getVillageDataDist?.data.data.filter(
          (item) => item.name === data.name
        )[0].id
      : null;
  };
  const createForm = async (data) => {
   
 const manufacturerCategory =[];
   
    const targetManufacturers = [];

    for (let i = 0; i < widgetData?.length; i++) {
      widgetData[i].categoryValId &&  manufacturerCategory.push({
        categoryId: widgetData[i].categoryValId,
        isEnabled:widgetData[i].isEnabled,
        canSkipRawMaterials:widgetData[i].canSkipRawMaterials,
      });


    
  
      

      for (let j = 0; j < widgetData[i].selectedManufacturers?.length; j++) {
        targetManufacturers.push(widgetData[i]?.selectedManufacturers[j]?.id);
      }
    }

    const payload = {
      id: Number(params.id) || "",
      name: data.name,
      completeAddress: data.completeAddress,
      type: data.type,
      accreditedByAgency: data.accreditedByAgency,
      vendorType: data.vendorType,
      agencyName: data.agencyName,
      licenseNumber: data.licenseNumber,
      targetManufacturers: targetManufacturers,
      manufacturerDocs: [],
      statusId: data.status?.id,

      address: {
        laneOne: addressData.laneOne,
        laneTwo: addressData.laneTwo,
        villageId:
          (await getVillageData({
            name: addressData.villageName || addressData.village.name,
            districtId:
              addressData.districtId || addressData?.village?.district?.id,
          })) ||
          (await createVillageData({
            name: addressData.villageName,
            districtId: addressData.districtId,
          })),

        pinCode: addressData.pinCode,
        longitude: Number(addressData.longitude),
        latitude: Number(addressData.latitude),
      },
      manufacturerDocs: docsData,

      manufacturerCategory: manufacturerCategory,
      manufacturerType: data.manufacturerType,
    };

    let dataa;
    if (params.id) {
      dataa = await editManufacturerGrid(params.id, payload);
    } else {
      dataa = await createManufacturerGrid(payload);
    }

    if (dataa?.status == 200 || dataa?.status == 201) {
      dispatchNotification("success", [
        params.id ? "Manufacturer updated Successfully" : "Manufacturer created successfully",
      ]);

      navigate(-1);
    }
    // }
  };

  const DocTypeSchema = {
    properties: {
      name: {
        type: "string",
        title: translate('name'),
      },
      licenseNumber: {
        type: "string",
        title: translate('licenseNo'),
      },

      agencyName: {
        title: "Agency Name",
        type: "string",
      },

      vendorType: {
        title: translate('vendorType'),
        enum: ["Manufacturer", "Trader", "Broker"],
        enumNames: [translate("manufacturer"), translate("trader"), translate("broker")],
      },
      type: {
        title: "Type",
        enum: ["CENTRAL_LICENSE", "STATE_LICENSE"],
      },
      manufacturerType: {
        type: "string",
        enum: ["PUBLIC", "PRIVATE"],
        enumNames:[translate('public'),translate('private')],
        title: translate('manufacturerType')
      },
      status: {
        enumNames: statusData?.map((item) => item.name),
        enum: statusData?.map((item) => ({id:item.id,name:item.name})),
        default: statusData?.map((item) => ({id:item.id,name:item.name})).filter(item=>item.name==="Active")[0],
        title: translate('status'),

        // default: statusData?.filter(item=>item.name=='Active')[0],
      },
      accreditedByAgency: {
        type: "boolean",
        default: false,
        title: translate('manufAccreditedByAgency')
      },

      //  "completeAddress":{
      //     type:"string",

      //  },

      addressData: {
        type: "string",
      },

      categoryMapping: {
        type: "string",
      },
     
    },
    required: [
      "name",
      "licenseNumber",
      "agencyName",
      "vendorType",
      "type",
      "manufacturerType",
      "accreditedByAgency",
    ],
  };
  const CategoryDocTypeUiSchema = {
    categoryMapping: {
      "ui:widget": ManufactureMappingWidget,
    },
    addressData: {
      "ui:widget": AddressMapWidget,
    },
    licenseNumber: params.id ? {
      "ui:disabled": true,
    } : {}
  };
  const CategoryDocTypeData = {
    // name: formData?.name,
  };

  return (
    <div>
      <div className="main-content-overlay">
        <Box className="main-content-div" style={{ position: "relative" }}>
          <Box id="VendorDetails" className="form-div ">
            <Box p={2} id="rjsf">
              <Button
                onClick={(e) => {
                  navigate(-1);
                }}
              >
                <ArrowBackIcon />
                <Typography
                  sx={{
                    fontSize: "13px",
                    textDecoration: "underline",
                    fontWeight: "600",
                    fontSize: "12px",
                    lineHeight: "15px",
                    color: "#0075FF",
                  }}
                >
                  {BACK_BUTTON}
                </Typography>
              </Button>
              {(params.id ? widgetData : true)&& statusData.length>0 && (
                <Form
                  schema={DocTypeSchema}
                  validator={validator}
                  uiSchema={CategoryDocTypeUiSchema}
                  formData={formData}
                  widgets={widgets}
                  className="batch-form-styling"
                  customValidate={manufactureValidation}
                  onChange={(e) => {
                    setFormData(e.formData);
                  }}
                  onSubmit={(e) => {
                    !(addressData.pinCode < 100000 || addressData.pinCode > 999999) && createForm(e.formData);
                  }}
                ></Form>
              )}
            </Box>
          </Box>
        </Box>
      </div>
    </div>
  );
}

export default ManufacturersForm;
