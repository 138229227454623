import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import KeycloakService from "./services/KeycloakService";
import HttpService from "./services/HttpService";
import { BrowserRouter, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import MainTheme from "./MainTheme";
import { Provider } from "react-redux";
import store from "./services/StoreService";
import { AxiosInterceptor } from "./services/HttpService";
import BatchAndVendorInfo from "./pages/PremixDetails/BatchAndVendorInfo";
import AppUnprotected from "./AppUnprotected";
import axios from "axios";
import { LOGIN_PATH_USING_TOKEN } from "./constants";
const container = document.getElementById("root");
const root = createRoot(container);
const IAM_URL = process.env.REACT_APP_BASE_IAM_URL;
export const renderApp = () => {
  return root.render(
    <React.StrictMode>
      <BrowserRouter>
        <ThemeProvider theme={MainTheme}>
          <Provider store={store}>
            <App />
            <AxiosInterceptor></AxiosInterceptor>
          </Provider>
        </ThemeProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
};

if(window.location.pathname.includes(LOGIN_PATH_USING_TOKEN) || ( (KeycloakService.loggedInThroughKeycloak=='false') && !!sessionStorage.getItem('react-token'))) {
  await KeycloakService.setConfigurationOnLoggingInWithToken();
  renderApp()
} else if (!window.location.pathname.includes("/code/") && !window.location.pathname.includes("/about-us") && !window.location.pathname.includes(LOGIN_PATH_USING_TOKEN)) {
  HttpService.configure();
  KeycloakService.initKeycloak(renderApp);
  reportWebVitals();
} else if(window.location.pathname.includes(LOGIN_PATH_USING_TOKEN)) {
  await KeycloakService.setConfigurationOnLoggingInWithToken();
  renderApp()
} else {
  HttpService.configure();
   root.render(
    <React.StrictMode>
      <BrowserRouter>
        <AppUnprotected />
      </BrowserRouter>
    </React.StrictMode>
  );
}
