import { createSlice } from "@reduxjs/toolkit";

export const menuSlice = createSlice({
  name: "menuDetails",
  initialState: { data: []
  
  },
  reducers: {
    storeMenuDetails: (state,action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes

      state.data=action.payload;
    },

    
    
  },
});

// Action creators are generated for each case reducer function
export const { storeMenuDetails } = menuSlice.actions;
export const saveMenuDetails = (amount) => (dispatch) => { dispatch(storeMenuDetails(amount))}
export const getMenuDetails=(state)=>state.menuDetails.data;


export default menuSlice.reducer;
