import InfiniteScroll from "react-infinite-scroll-component";
import Paper from "@mui/material/Paper";

export function ListboxComponent(props) {
    const { custom, fetchData, categoryId, className, ...param } = props;
    return (
      <InfiniteScroll
        height={200}
        dataLength={custom?.data?.length || 0}
        next={() => {
          custom?.data?.length < custom?.count && fetchData(categoryId);
        }}
        hasMore={custom?.data?.length < custom?.count}
        loader={
          <p
            style={{
              textAlign: "center",
              backgroundColor: "#eef6ff",
            }}
          >
            <b>Loading...</b>
          </p>
        }
        role="listbox"
      >
        <Paper className={`${className} raw-material-list-box`} {...param} />
      </InfiniteScroll>
    );
  }