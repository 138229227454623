import React from "react";
import BarChartIcon from "@mui/icons-material/BarChart";
import HighchartPie from "../HighCharts/HighCharts";
import UnitConverterWeight from "../../utils/UnitConverter";
import  CategoryNameMapping   from "../../utils/CategoryNameMapping";
import useTranslatewrapper from "../useTranslateWrapper";

//added console

const getRejectedQuantity = (data)=>{
  return data?.hasOwnProperty('lotRejected') ? data.lotRejected : data.rejectedQuantity;
}
function DashBoardStats(obj) {
  const {translate} = useTranslatewrapper()
  if (obj?.type === "batch" && obj?.isIndependentBatch === true) {
    const data = [
      {
        name: translate('quantity'),
        y: obj?.totalQuantity,
        quan:UnitConverterWeight(obj?.totalQuantity),

      },
      {
        name: translate('remainingQuantity'),
        y: obj?.remainingQuantity,
        quan:UnitConverterWeight(obj?.remainingQuantity),

      },
    ];
    return (
      <div className="stats">
        <div
          className="innerbox"
          style={{
            backgroundImage: "linear-gradient(to right, #FC9365 , #FEB89A)",
          }}
        >
          <div className="textbox">
            <div>
              <div className="dashboard-card-text">
                {UnitConverterWeight(obj.totalQuantity)}
              </div>
              <div
                className="dashboard-card-subtext"
              >
                {translate(CategoryNameMapping(obj.categoryName))} {translate('aquired')}
              </div>
            </div>
            <div>
              <BarChartIcon fontSize="large"></BarChartIcon>
            </div>
          </div>
        </div>
        <div
          className="innerbox"
          style={{
            backgroundImage: "linear-gradient(to right, #00C382 , #00F4A2)",
          }}
        >
          <div className="textbox">
            <div>
              <div className="dashboard-card-text">
                {UnitConverterWeight(obj.remainingQuantity)}
              </div>
              <div
                className="dashboard-card-subtext"
              >
                {translate(CategoryNameMapping(obj.categoryName))} {translate('available')}
              </div>
            </div>
            <div>
              <BarChartIcon fontSize="large"></BarChartIcon>
            </div>
          </div>
        </div>
        {HighchartPie(data)}
      </div>
    );
  }
  if (obj.type === "lot" && obj.isIndependentBatch === true) {
    const data = [
      {
        name: translate('quantity'),
        y: obj?.totalQuantity,
        quan:UnitConverterWeight(obj?.totalQuantity),
        color: '#FDA883'

      },
      {
        name: translate('remainingQuantity'),
        y: obj?.remainingQuantity,
        quan:UnitConverterWeight(obj?.remainingQuantity),
        color: '#00DA91'
      },
    ];
    return (
      <div className="stats">
        <div
        className="twobox"
          
        >
          <div
            className="innerbox"
            style={{
              backgroundImage: "linear-gradient(to right, #FC9365 , #FEB89A)",
              padding: "1rem 0",
            }}
          >
            <div className="textbox">
              <div>
                <div className="dashboard-card-text">
                  {UnitConverterWeight(obj.totalQuantity)}
                </div>
                <div
                className="dashboard-card-subtext"
                >
                  {translate(CategoryNameMapping(obj.categoryName))} {translate('aquired')}
                </div>
              </div>
              <div>
                <BarChartIcon fontSize="large"></BarChartIcon>
              </div>
            </div>
          </div>
          <div
            className="innerbox"
            style={{
              backgroundImage: "linear-gradient(to right, #00C382 , #00F4A2)",
              padding: "1rem 0",
            }}
          >
            <div className="textbox">
              <div>
                <div className="dashboard-card-text">
                  {UnitConverterWeight(obj.remainingQuantity)}
                </div>
                <div
                 className="dashboard-card-subtext"
                >
                  {translate(CategoryNameMapping(obj.categoryName))} {translate('available')}
                </div>
              </div>
              <div>
                <BarChartIcon fontSize="large"></BarChartIcon>
              </div>
            </div>
          </div>
        </div>
        <div style={{flexGrow: 1}}>
        {HighchartPie(data)}

        </div>
      </div>
    );
  }
  if (obj.type === "batch" && obj.isIndependentBatch === false) {
    const data = [
      {
        name: translate('inProduction'),
        y: obj?.inProductionQuantity,
        quan:UnitConverterWeight(obj?.inProductionQuantity),
        color: '#FDAC89'
      },
      {
        name: translate('produced'),
        y: obj?.producedQuantity,
        quan:UnitConverterWeight(obj?.producedQuantity),
        color: '#00E598'

      },
      {
        name: translate('inTransit'),
        y: obj?.inTransitQuantity,
        quan:UnitConverterWeight(obj?.inTransitQuantity),
        color: '#0AC7CA'
      },
      {
        name: translate('dispatched'),
        y: obj?.receivedQuantity,
        quan:UnitConverterWeight(obj?.receivedQuantity),
        color: '#01B1B3'
      },
      {
        name: translate('approved'),
        y: obj?.approvedQuantity,
        quan:UnitConverterWeight(obj?.approvedQuantity),

      },
      {
        name: translate('sampleInTransitQuantity'),
        y: obj?.batchSampleInTransitQuantity,
        quan:UnitConverterWeight(obj?.batchSampleInTransitQuantity),
        color: '#8a8a5c'
      },
    ];
    return (
      <div className="stats" >
        <div 
        
        className="fourbox"
       
           >
          <div
            className="innerbox"
            style={{
              backgroundImage: "linear-gradient(to right, #FC9365 , #FEB89A)",
              
            }}
          >
            <div className="textbox">
              <div>
                <div className="dashboard-card-text">
                  {UnitConverterWeight(obj.inProductionQuantity)}
                </div>
                <div
                className="dashboard-card-subtext"
                >
                 {translate(CategoryNameMapping(obj.categoryName))}  {translate('inProduction')}
                </div>
              </div>
              <div>
                <BarChartIcon fontSize="large"></BarChartIcon>
              </div>
            </div>
          </div>
          <div
            className="innerbox"
            style={{
              backgroundImage: "linear-gradient(to right, #00C382 , #00F4A2)",
             
            }}
          >
            <div className="textbox">
              <div>
                <div className="dashboard-card-text">
                  {UnitConverterWeight(obj.producedQuantity)}
                </div>
                <div
                 className="dashboard-card-subtext"
                >
                 {translate(CategoryNameMapping(obj.categoryName))} {translate('produced')}
                </div>
              </div>
              <div>
                <BarChartIcon fontSize="large"></BarChartIcon>
              </div>
            </div>
          </div>
        </div>
        <div 
        className="fourbox"
>          <div
            className="innerbox"
            style={{
              backgroundImage: "linear-gradient(to right, #FF5E70 , #FD8E99)",
           
            }}
          >
            <div className="textbox">
              <div>
                <div className="dashboard-card-text">
                  {UnitConverterWeight(obj.batchTestedQuantity)}
                </div>
                <div
                 className="dashboard-card-subtext"
                >
                 {translate(CategoryNameMapping(obj.categoryName))} {translate('tested')}
                </div>
              </div>
              <div>
                <BarChartIcon fontSize="large"></BarChartIcon>
              </div>
            </div>
          </div>
          <div
            className="innerbox"
            style={{
              backgroundImage: "linear-gradient(to right, #00AFB1 , #11D8DC)",
              
            }}
          >
            <div className="textbox">
              <div>
                <div className="dashboard-card-text">
                  {UnitConverterWeight(obj.inTransitQuantity + obj.receivedQuantity)}

                </div>
                <div
                 className="dashboard-card-subtext"
                >
{translate(CategoryNameMapping(obj.categoryName))} {translate('dispatched')}
                </div>
              </div>
              <div>
                <BarChartIcon fontSize="large"></BarChartIcon>
              </div>
            </div>
            {/* <hr style={{ border: "0.5px solid white", margin: 0 }}></hr>
                <div
                  style={{
                    color: "white",
                    padding: "15px",
                    fontSize: "large",
                    fontWeight: 500,
                  }}
                >
                  {obj.label}
                </div> */}
          </div>
        </div>

        <div style={{marginTop:"0px auto", flexGrow: 1}}>
        {HighchartPie(data)}

        </div>
      </div>
    );
  }
  if (obj.type === "lot" && obj.isIndependentBatch === false) {
    const data = [
      {
        name: translate('inTransit'),
        y: obj?.inTransitQuantity,
        quan:UnitConverterWeight(obj?.inTransitQuantity),
        color: '#FC9466'
      },
      {
        name: translate('received'),
        y: obj?.receivedQuantity,
        quan:UnitConverterWeight(obj?.receivedQuantity),
        color: '#FF6274'
      },
      {
        name: translate('approved'),
        y: obj?.approvedQuantity,
        quan:UnitConverterWeight(obj?.approvedQuantity),
        color: '#00C583'

      },
      {
        name: translate('rejected'),
        y: getRejectedQuantity(obj),
        quan:UnitConverterWeight(getRejectedQuantity(obj)),
        color: '#01B2B4'

      },
      {
        name: translate('sampleInTransitQuantity'),
        y: obj?.sampleInTransitQuantity,
        quan:UnitConverterWeight(obj?.sampleInTransitQuantity),

      },
    ];
    return (
      <div className="stats" >
        <div 
        className="fourbox"
        
        >
          <div
            className="innerbox"
            style={{
              backgroundImage: "linear-gradient(to right, #FC9365 , #FEB89A)",
             
            }}
          >
            <div className="textbox">
              <div>
                <div className="dashboard-card-text">
                  {UnitConverterWeight(obj.inTransitQuantity)}
                </div>
                <div
                  className="dashboard-card-subtext"
                >
                  {translate(CategoryNameMapping(obj.categoryName))} {translate('inTransit')}
                </div>
              </div>
              <div>
                <BarChartIcon fontSize="large"></BarChartIcon>
              </div>
            </div>
          </div>

          <div
            className="innerbox"
            style={{
              backgroundImage: "linear-gradient(to right, #FF5E70 , #FD8E99)",
           
            }}
          >
            <div className="textbox">
              <div>
                <div className="dashboard-card-text">
                  {UnitConverterWeight(obj.receivedQuantity)}
                </div>
                <div
                 className="dashboard-card-subtext"
                >
                 {translate(CategoryNameMapping(obj.categoryName))} {translate('received')}
                </div>
              </div>
              <div>
                <BarChartIcon fontSize="large"></BarChartIcon>
              </div>
            </div>
          </div>
        </div>
        <div 
        className="fourbox"

        
           >
          <div
            className="innerbox"
            style={{
              backgroundImage: "linear-gradient(to right, #00C382 , #00F4A2)",
             
            }}
          >
            <div className="textbox">
              <div>
                <div className="dashboard-card-text">
                  {UnitConverterWeight(obj.approvedQuantity)}
                </div>
                <div
                 className="dashboard-card-subtext"
                >
                 {translate(CategoryNameMapping(obj.categoryName))} {translate('approved')}
                </div>
              </div>
              <div>
                <BarChartIcon fontSize="large"></BarChartIcon>
              </div>
            </div>
          </div>
          <div
            className="innerbox"
            style={{
              backgroundImage: "linear-gradient(to right, #00AFB1 , #11D8DC)",
              
            }}
          >
            <div className="textbox">
              <div>
                <div className="dashboard-card-text">
                  {UnitConverterWeight(getRejectedQuantity(obj))}
                </div>
                <div
                 className="dashboard-card-subtext"
                >
                 {translate(CategoryNameMapping(obj.categoryName))}  {translate('rejected')}
                </div>
              </div>
              <div>
                <BarChartIcon fontSize="large"></BarChartIcon>
              </div>
            </div>

            {/* <hr style={{ border: "0.5px solid white", margin: 0 }}></hr>
                <div
                  style={{
                    color: "white",
                    padding: "15px",
                    fontSize: "large",
                    fontWeight: 500,
                  }}
                >
                  {obj.label}
                </div> */}
          </div>
        </div>
        <div style={{flexGrow: 1}}>
        {HighchartPie(data)}

        </div>
      </div>
    );
  }
}

export default DashBoardStats;
