import React, { useEffect, useMemo, useState } from "react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import UnitConverterWeight from "../../utils/UnitConverter";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import DescriptionIcon from "@mui/icons-material/Description";
import { useParams } from "react-router-dom";
import LinkRedirect from "../../pages/Form/UsageGrid/LinkRedirect";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import { useAppSelector } from "../../services/StoreHooks";
export default function AgencyGrid(props) {

  const categoryNameToIdSelectorRedux = useAppSelector((state) =>
    state?.categoriesData?.categories
  );

  const categoryNameToIdMapRedux = useMemo(()=>{
    return (
      categoryNameToIdSelectorRedux.reduce(
      (acc, current) => {
        acc[current.name] = current.id.toString();
        return acc;
      },
      {}
    ))
  }, [categoryNameToIdSelectorRedux])

  const routeParams = useParams();
  const [columnDefs, setColumnDefs] = useState([]);
  const [query, setQuery] = useState(props.query);

  const customAggregation = (values) => {
    const numericValues = values.filter((value) => typeof value === "number");

    console.log(numericValues, "numeric values");
    return numericValues.reduce((sum, value) => sum + value, 0);
  };

  useEffect(() => {
    if(routeParams.reportType === "lab"){
      if(props.query["type"] === "labs-all"){
      setColumnDefs([
        {
          headerName: "S.No",
          valueGetter: "node.rowIndex + 1",
          width: 100,
        },
        {
          headerName: "Lab Name",
          field: "name",
          tooltipField: "name",
          width: "auto",
        },
        {
          headerName: routeParams.reportType === "lab" ? "TC Number" : "License Number",
          field: "nablCertificate",
          tooltipField: "nablCertificate",
          width: "auto",
        },
        {
          headerName: "State",
          field: "state",
          tooltipField: "state",
          width: "auto",
        },
        {
          headerName: "District",
          field: "district",
          tooltipField: "district",
          width: "auto",
        },
        {
          headerName: "Samples To Receive",
          field: "samplesToReceive",
          width: "auto",
        },
        {
          headerName: "Samples Received at Lab",
          field: "samplesReceived",
          width: "auto",
          // cellRendererFramework: (params) => <a href ={'javascript:void(0)'} style>{params.data.samplesReceived}</a> 
        },
        {
          headerName: "Samples in Lab",
          field: "samplesInLab",
          width: "auto",
        },
        {
          headerName: "No. of Tests Performed",
          field: "testsDone",
          width: "auto",
        },
        {
          headerName: "Samples Rejected Without Testing",
          field: "samplesRejected",
          width: "auto",
        },
        

      ]);
    } else if(props.query["type"] === "labsForParticularData"){
      setColumnDefs([
        {
          headerName: "S.No",
          valueGetter: "node.rowIndex + 1",
          width: 100,
        },
        {
          headerName: "Shipment No.",
          field: "batchNo",
          tooltipField: "batchNo",
          width: "auto",
          valueFormatter: (params) => {
            return (params.data.batchNo ? params.data.batchNo : params.data.lotNo);
          },
        },
        {
          headerName: "Sample Receiving Date",
          field: "receivedDate",
          width: "auto",
        },
        {
          headerName: "Sample Test Date",
          field: "testDate",
          width: "auto",
        },
        {
          headerName: "status",
          field: "state.displayName",
          width: "auto",
        },
        {
          headerName: "Lab Name",
          field: "lab.name",
          width: "auto",
        },
        {
          headerName: "TC Number",
          field: "lab.certificateNo",
          width: "auto",
        },
      ]);
    }
      return;
    }
    if (["PREMIX", "FRK", "MILLER"].map(key=>categoryNameToIdMapRedux[key]).includes(routeParams.categoryId)) {
      if (props.query["type"].includes("target")) {
        //setting columns for target manufacturers grid
        setColumnDefs([
          {
            headerName: "S.No",
            valueGetter: "node.rowIndex + 1",
            width: 100,
          },
          {
            headerName: "Agency Name",
            field: "agencyName",
            tooltipField: "agencyName",
            width: "auto",
          },
          {
            headerName: routeParams.reportType === "lab" ? "TC Number" : "License Number",
            field: "licenseNumber",
            tooltipField: "licenseNumber",
            width: "auto",
          },
          {
            headerName: "State",
            field: "stateName",
            tooltipField: "stateName",
            width: "auto",
          },
          {
            headerName: "District",
            field: "districtName",
            tooltipField: "districtName",
            width: "auto",
          },
          // {
          //   headerName: "Total Supply",
          //   field: "totalSupply",
          //   width: "auto",
          //   valueFormatter: (params) => {
          //     return UnitConverterWeight(params.data.totalSupply);
          //   },
          //   // aggFunc: customAggregation,
          // },
          {
            headerName: "Lot Approved Quantity",
            field: "lotApprovedQuantity",
            width: "auto",
            valueFormatter: (params) => {
              return UnitConverterWeight(params.data.lotApprovedQuantity);
            },
            // aggFunc: customAggregation,
          },
          {
            headerName: "Lot Rejected Quantity",
            field: "lotRejectedQuantity",
            width: "auto",
            valueFormatter: (params) => {
              return UnitConverterWeight(params.data.lotRejectedQuantity);
            },
            // aggFunc: customAggregation,
          },
          {
            headerName: "Lot Pending For Approval",
            field: "lotApprovalPending",
            width: "auto",
            valueFormatter: (params) => {
              return UnitConverterWeight(params.data.lotApprovalPending);
            },
            // aggFunc: customAggregation,
          },

        ]);
      } 
      else {
        setColumnDefs([
          //columns for source manufacturer grid
          {
            headerName: "S.No",
            valueGetter: "node.rowIndex + 1",
            width: 100,
          },
          {
            headerName: "Agency Name",
            field: "agencyName",
            tooltipField: "agencyName",
            width: "auto",
          },
          {
            headerName: routeParams.reportType === "lab" ? "TC Number" : "License Number",
            field: "licenseNumber",
            tooltipField: "licenseNumber",
            width: "auto",
          },
          {
            headerName: "State",
            field: "stateName",
            tooltipField: "stateName",
            width: "auto",
          },
          {
            headerName: "District",
            field: "districtName",
            tooltipField: "districtName",
            width: "auto",
          },
          {
            headerName: "No. Of Batches",
            field: "numberOfBatches",
            width: "auto",
            hide: !(props.query["type"].includes("agency-all")),
            cellRendererFramework: (params) => (params.data.numberOfBatches === 0) ? params.data.numberOfBatches : <a href={'javascript:void(0)'} style={{ textDecoration: 'none' }}>{params.data.numberOfBatches}</a>
          },
          {
            headerName: "Total Production",
            field: "totalProduction",
            width: "auto",
            valueFormatter: (params) => {
              return UnitConverterWeight(params.data.totalProduction);
            },
            // aggFunc: customAggregation,
          },
          {
            headerName: "Batch Test Approved",
            field: "batchTestApproved",
            width: "auto",
            valueFormatter: (params) => {
              return UnitConverterWeight(params.data.batchTestApproved);
            },
            // aggFunc: customAggregation,
          },
          {
            headerName: "Batch Test Rejected",
            field: "batchTestRejected",
            width: "auto",
            valueFormatter: (params) => {
              return UnitConverterWeight(params.data.batchTestRejected);
            },
            // aggFunc: customAggregation,
          },
          {
            headerName: "Not Tested Quantity",
            field: "notTestedQuantity",
            width: "auto",
            valueFormatter: (params) => {
              return UnitConverterWeight(params.data.notTestedQuantity);
            },
            // aggFunc: customAggregation,
          },
          // {
          //   headerName: "Total Demand",
          //   field: "totalDemand",
          //   width: "auto",
          //   valueFormatter: (params) => {
          //     return UnitConverterWeight(params.data.totalDemand);
          //   },
          //   hide: !["4"].includes(routeParams.categoryId)
          //   // aggFunc: customAggregation,
          // },
          // {
          //   headerName: "Total Supply",
          //   field: "totalSupply",
          //   width: "auto",
          //   valueFormatter: (params) => {
          //     return UnitConverterWeight(params.data.totalSupply);
          //   },
          //   // aggFunc: customAggregation,
          // },
          {
            headerName: "Lot Approved Quantity",
            field: "lotApprovedQuantity",
            width: "auto",
            valueFormatter: (params) => {
              return UnitConverterWeight(params.data.lotApprovedQuantity);
            },
            // aggFunc: customAggregation,
          },
          {
            headerName: "Lot Rejected Quantity",
            field: "lotRejectedQuantity",
            width: "auto",
            valueFormatter: (params) => {
              return UnitConverterWeight(params.data.lotRejectedQuantity);
            },
            // aggFunc: customAggregation,
          },
          {
            headerName: "Lot Pending For Approval",
            field: "lotApprovalPending",
            width: "auto",
            valueFormatter: (params) => {
              return UnitConverterWeight(params.data.lotApprovalPending);
            },
            // aggFunc: customAggregation,
          },
          {
            headerName: "Tested Available Quantity",
            field: "availableTested",
            width: "auto",
            valueFormatter: (params) => {
              return UnitConverterWeight(params.data.availableTested);
            },
            // aggFunc: customAggregation,
          },

        ]);
      }
    } else {//here it is warehouse

      setColumnDefs([
        {
          headerName: "S.No",
          valueGetter: "node.rowIndex + 1",
          width: 100,
        },
        {
          headerName: "Agency Name",
          field: "agencyName",
          tooltipField: "agencyName",
          width: "auto",
        },
        {
          headerName: routeParams.reportType === "lab" ? "TC Number" : "License Number",
          field: "licenseNumber",
          tooltipField: "licenseNumber",
          width: "auto",
        },
        {
          headerName: "State",
          field: "stateName",
          tooltipField: "stateName",
          width: "auto",
        },
        {
          headerName: "District",
          field: "districtName",
          tooltipField: "districtName",
          width: "auto",
        },
        // {
        //   headerName: "Total Supply",
        //   field: "totalSupply",
        //   width: "auto",
        //   valueFormatter: (params) => {
        //     return UnitConverterWeight(params.data.totalSupply);
        //   },
        //   // aggFunc: customAggregation,
        // },
        {
          headerName: "Lot Approved Quantity",
          field: "lotApprovedQuantity",
          width: "auto",
          valueFormatter: (params) => {
            return UnitConverterWeight(params.data.lotApprovedQuantity);
          },
          // aggFunc: customAggregation,
        },
        {
          headerName: "Lot Rejected Quantity",
          field: "lotRejectedQuantity",
          width: "auto",
          valueFormatter: (params) => {
            return UnitConverterWeight(params.data.lotRejectedQuantity);
          },
          // aggFunc: customAggregation,
        },
        {
          headerName: "Lot Pending For Approval",
          field: "lotApprovalPending",
          width: "auto",
          valueFormatter: (params) => {
            return UnitConverterWeight(params.data.lotApprovalPending);
          },
          // aggFunc: customAggregation,
        },
        {
          headerName: "Dispatched Quantity",
          field: "totalDispatched",
          width: "auto",
          hide: !props.query["type"].includes("warehouse-all"),
          valueFormatter: (params) => {
            return UnitConverterWeight(params.data.totalDispatched);
          },
          // aggFunc: customAggregation,
        },
        {
          headerName: "Tested Available Quantity",
          field: "totalAvailable",
          width: 200,
          valueFormatter: (params) => {
            return UnitConverterWeight(params.data.totalAvailable);
          },
          
          // aggFunc: customAggregation,
        },
        {
          headerName: "Link to view Lot Wise",
          field: "lotDetailsLink",
          width: 200,
          hide: props.query["type"].includes("warehouse-all"),
          // valueFormatter: (params) => {
          //   return "Lot Wise Details";
          // },
          cellRendererFramework: (params) => <a href ={'javascript:void(0)'}>{'Lot Wise Details'}</a> 
          
          // aggFunc: customAggregation,
        },
      ]);
    }
  }, [routeParams.categoryId, routeParams.reportType, props.query]);

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
    };
  }, []);

  const getRowStyle = (params) => {
    if (params.node.rowIndex % 2 != 0) {
      return { background: "#F8F8F8" };
    }
  };

  let overlayNoRowsTemplate = (props.query['level']!='country') ? '<span style="padding: 10px; display: block; text-align: center;">No Data to show</span>' : '<span style="padding: 10px; display: block; text-align: center;">Please apply filters to view data.</span>';

  const gridOptions = {
    overlayNoRowsTemplate: overlayNoRowsTemplate,
  };

  return (
    <>
      <div
        style={{ display: "flex", justifyContent: "space-between", margin:"10px", alignItems:"center" }}
      >
        <div style={{width:"40%"}}>
          <TextField
            id="outlined-required"
            label="Search by name"
            value={props.searchKeyWord}
            onChange={(e)=>{props.setSearchKeyWord(e.target.value)}}
            InputProps={{
              style: {
                borderRadius: 40,
                height: '45px',
                textAlign:"center"
              },
              endAdornment: 
                (<InputAdornment position='end'>
                  <IconButton onClick={()=>{props.setSearchKeyWord("")}}>
                    <ClearIcon/>
                  </IconButton>
                </InputAdornment>)
            }}
          />
        </div>
        <a
          onClick={props.handleDownloadClick}
          href="javascript:void(0)"
          hidden={['labs-all','labsForParticularData'].includes(props.query['type'])}
        >
          <span style={{ fontSize: "1rem" }}>Download</span>
          <FileDownloadOutlinedIcon sx={{ fontSize: "1.2rem" }} m={1} />
        </a>
      </div>
      <div className="ag-theme-alpine" style={{ height: 430 }}>
        <AgGridReact
          rowData={props?.rowData?.filter(item=>(item?.agencyName?.toLowerCase().includes(props.searchKeyWord.toLowerCase()) || (item?.name?.toLowerCase().includes(props.searchKeyWord.toLowerCase()) ) || (props.query['type'] == 'labsForParticularData')))}
          // domLayout="autoHeight"
          columnDefs={columnDefs}
          animateRows={true}
          getRowStyle={getRowStyle}
          // groupIncludeFooter={false}
          // groupIncludeTotalFooter={true}
          onRowClicked={props.onRowClick}
          onCellClicked={props.onCellClicked}
          defaultColDef={defaultColDef}
          tooltipShowDelay={0}
          // enableCellTextSelection
          tooltipHideDelay={2000}
          enableBrowserTooltips={true}
          gridOptions={gridOptions}
          // suppressAggFuncInHeader
        />
      </div>
    </>
  );
}
