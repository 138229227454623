import { COMMENTS, DATE, EXP_DATE,  MANF_DATE,  TOTAL_QUANTITY, WASTAGE_QUANTITY } from "../../../textConstant";
import moment from "moment";
import UnitConverterWeight from "../../../utils/UnitConverter";
export const LISTING_WASTAGE_HEADER = (translate) => [
  {
    field: "wastageQuantity",
    headerName: translate('wastageQuantity'),
    // maxWidth: 280,
    flex:1,
    hide: false,
    valueFormatter: (params) => {
      return UnitConverterWeight(params.data.wastageQuantity);
  },
  },
  {
    field: "comments",
    headerName: translate('comments'),
    maxWidth: 270,
    hide: false,
    flex:3,
  },
  {
    field: "reportedDate",
    headerName: translate('wastageDate'),
    maxWidth: 270,
    hide: false,
    valueFormatter: (params) => {
      return moment.utc(params.data.reportedDate).format("DD-MMM-YYYY");
    },
    flex:3,

  },

];
