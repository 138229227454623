import React, { useState, } from "react";
import Box from "@mui/material/Box";
import { Button, TextField, } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Tooltip from '@mui/material/Tooltip';

function TruckDetailComponent({
    setTruckDetail,
    submit,
}) {
    const [chipData, setChipData] = useState([]);

    const handleAddItem = () => {
        setChipData([
            ...chipData,
            {
                vehicle: "",
                driverName: "",
                contactNumber: "",
                licenseNumber: "",
                adhaarNumber: "",
                size: "", quantity: "",
            },
        ]);
    };

    const handleDeleteRow = (idx) => {
        if (chipData.length > 0) {
            const data = [...chipData];
            data.splice(idx, 1);
            setChipData(data);
            setTruckDetail(data);
        }
    };
    const [contactNumberErrors, setContactNumberErrors] = useState([]);
    const handleContactNumberChange = (event, idx) => {
        const updatedErrors = [...contactNumberErrors];
        if (/^\d{10}$/.test(event)) {
            updatedErrors[idx] = false;
        } else {
            updatedErrors[idx] = true;
        }
        setContactNumberErrors(updatedErrors);
    };
    const [aadhaarNumberErrors, setAadhaarNumberErrors] = useState([]);
    const handleAadhaarNumberChange = (event, idx) => {
        const updatedErrors = [...aadhaarNumberErrors];
        if (/^\d{12}$/.test(event)) {
            updatedErrors[idx] = false;
        } else {
            updatedErrors[idx] = true;
        }
        setAadhaarNumberErrors(updatedErrors);
    };

    const [licenseNumberErrors, setLicenseNumberErrors] = useState([]);
    const handleLicenseNumberChange = (event, idx) => {
        const updatedErrors = [...licenseNumberErrors];
        if (/^.{1,20}$/.test(event)) {
            updatedErrors[idx] = false;
        } else {
            updatedErrors[idx] = true;
        }
        setLicenseNumberErrors(updatedErrors);
    };

    const [vehicleNumberErrors, setVehicleNumberErrors] = useState([]);
    const handleVehicleNumberChange = (event, idx) => {
        const updatedErrors = [...vehicleNumberErrors];
        if (/^.{1,12}$/.test(event)) {
            updatedErrors[idx] = false;
        } else {
            updatedErrors[idx] = true;
        }
        setVehicleNumberErrors(updatedErrors);
    };

    return (
        <Box sx={{ marginTop: "1rem !important" }}>
            <TableContainer>
                <Table
                    sx={{ width: 800 }}
                    className="form-table"
                    aria-label="simple table"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell
                                className="table-head"
                                sx={{ border: 1, borderColor: "black" }}
                            >
                                S.No
                            </TableCell>
                            <TableCell
                                className="table-head"
                                sx={{ border: 1, borderColor: " black" }}
                                align="left"
                            >
                                Vehicle No.
                            </TableCell>
                            <TableCell
                                className="table-head"
                                sx={{ border: 1, borderColor: " black" }}
                                align="left"
                            >
                                Driver Name
                            </TableCell>
                            <TableCell
                                className="table-head"
                                sx={{ border: 1, borderColor: " black" }}
                                align="left"
                            >
                                Mobile number
                            </TableCell>
                            <TableCell
                                className="table-head"
                                sx={{ border: 1, borderColor: " black" }}
                                align="left"
                            >
                                License Number
                            </TableCell>
                            <TableCell
                                className="table-head"
                                sx={{ border: 1, borderColor: " black" }}
                                align="left"
                            >
                                Adhaar Number
                            </TableCell>
                            <TableCell
                                className="table-head"
                                sx={{ border: 1, borderColor: " black" }}
                                align="left"
                            >
                                Total Bags
                            </TableCell>
                            <TableCell
                                className="table-head"
                                sx={{ border: 1, borderColor: " black" }}
                                align="left"
                            >
                                Quantity
                            </TableCell>
                            <TableCell
                                className="table-head"
                                sx={{ border: 1, borderColor: " black" }}
                                align="left"
                            >
                                Actions
                            </TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {chipData &&
                            chipData.map((data, idx) => (
                                <TableRow
                                    sx={{
                                        "&:last-child td, &:last-child th": {
                                            border: 1,
                                            borderColor: " black",
                                        },
                                    }}
                                >
                                    <TableCell
                                        sx={{ border: 1, borderColor: " black" }}
                                        component="th"
                                        scope="row"
                                    >
                                        {idx + 1}
                                    </TableCell>
                                    <TableCell
                                        sx={{ border: 1, borderColor: " black" }}
                                        align="left"
                                    >
                                        <Tooltip
                                            title={vehicleNumberErrors[idx] ? "Please enter a valid vehicle number" : ""}
                                            placement="top"
                                            open={vehicleNumberErrors[idx]}
                                        >
                                            <TextField
                                                sx={{ width: "100%", backgroundColor: "#fff" }}
                                                name="vehicle"
                                                id="outlined-basic"
                                                value={data.vehicle}
                                                variant="standard"
                                                error={submit && data.vehicle === "" && true}
                                                onChange={(event) => {
                                                    chipData[idx]["vehicle"] = event.target.value;
                                                    setTruckDetail([...chipData]);
                                                    handleVehicleNumberChange(event.target.value, idx);
                                                }}
                                            />
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell
                                        sx={{ border: 1, borderColor: " black" }}
                                        align="left"
                                    >
                                        <TextField
                                            sx={{ width: "100%", backgroundColor: "#fff" }}
                                            name="driverName"
                                            id="outlined-basic"
                                            value={data.driverName}
                                            type="text"
                                            variant="standard"
                                            error={submit && data.driverName === "" && true}
                                            onChange={(event) => {
                                                chipData[idx]["driverName"] = event.target.value;
                                                setTruckDetail([...chipData]);
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell
                                        sx={{ border: 1, borderColor: " black" }}
                                        align="left"
                                    >
                                        <Tooltip
                                            title={contactNumberErrors[idx] ? "Please enter a valid 10-digit number" : ""}
                                            placement="top"
                                            open={contactNumberErrors[idx]}
                                        >
                                            <TextField
                                                sx={{ width: "100%", backgroundColor: "#fff" }}
                                                name="contactNumber"
                                                id="outlined-basic"
                                                value={data.contactNumber}
                                                type="text"
                                                variant="standard"
                                                error={submit && data.contactNumber === "" && true}
                                                onChange={(event) => {
                                                    chipData[idx]["contactNumber"] = event.target.value;
                                                    setTruckDetail([...chipData]);
                                                    handleContactNumberChange(event.target.value, idx);
                                                }}
                                            />
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell
                                        sx={{ border: 1, borderColor: " black" }}
                                        align="left"
                                    >
                                        <Tooltip
                                            title={licenseNumberErrors[idx] ? "Please enter a valid license number" : ""}
                                            placement="top"
                                            open={licenseNumberErrors[idx]}
                                        >
                                            <TextField
                                                sx={{ width: "100%", backgroundColor: "#fff" }}
                                                name="licenseNumber"
                                                id="outlined-basic"
                                                value={data.licenseNumber}
                                                type="text"
                                                variant="standard"
                                                error={submit && data.licenseNumber === "" && true}
                                                onChange={(event) => {
                                                    chipData[idx]["licenseNumber"] = event.target.value;
                                                    setTruckDetail([...chipData]);
                                                    handleLicenseNumberChange(event.target.value, idx);
                                                }}
                                            />
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell
                                        sx={{ border: 1, borderColor: " black" }}
                                        align="left"
                                    >
                                        <Tooltip
                                            title={aadhaarNumberErrors[idx] ? "Please enter a valid 12-digit Aadhaar number" : ""}
                                            placement="top"
                                            open={aadhaarNumberErrors[idx]}
                                        >
                                            <TextField
                                                sx={{ width: "100%", backgroundColor: "#fff" }}
                                                name="adhaarNumber"
                                                id="outlined-basic"
                                                value={data.adhaarNumber}
                                                type="text"
                                                variant="standard"
                                                error={submit && data.adhaarNumber === "" && true}
                                                onChange={(event) => {
                                                    chipData[idx]["adhaarNumber"] = event.target.value;
                                                    setTruckDetail([...chipData]);
                                                    handleAadhaarNumberChange(event.target.value, idx);
                                                }}
                                            />
                                        </Tooltip>
                                    </TableCell>

                                    <TableCell
                                        sx={{ border: 1, borderColor: " black" }}
                                        align="left"
                                    >
                                        <TextField
                                            sx={{ width: "100%", backgroundColor: "#fff" }}
                                            name="size"
                                            id="outlined-basic"
                                            value={data.size}
                                            type="number"
                                            variant="standard"
                                            error={submit && data.size === "" && true}
                                            onChange={(event) => {
                                                chipData[idx]["size"] = event.target.value;
                                                setTruckDetail([...chipData]);
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell
                                        sx={{ border: 1, borderColor: " black" }}
                                        align="left"
                                    >
                                        <TextField
                                            sx={{ width: "100%", backgroundColor: "#fff" }}
                                            name="quantity"
                                            id="outlined-basic"
                                            value={data.quantity}
                                            type="number"
                                            variant="standard"
                                            error={submit && data.quantity === "" && true}
                                            onChange={(event) => {
                                                chipData[idx]["quantity"] = event.target.value;
                                                setTruckDetail([...chipData]);
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell
                                        sx={{ border: 1, borderColor: " black" }}
                                        align="left"
                                    >
                                        <Button
                                            sx={{ textDecoration: "underline" }}
                                            onClick={() => handleDeleteRow(idx)}
                                        >
                                            Delete
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Button
                sx={{ textDecoration: "underline" }}
                onClick={() => handleAddItem()}
            >
                <AddCircleOutlineIcon />
                Add item
            </Button>
        </Box>
    );
}

export default TruckDetailComponent;
