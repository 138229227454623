import React, { useEffect, useState, useRef, useCallback } from "react";
import Box from "@mui/material/Box";
import "../../../../App.css";
import { CircularProgress, Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import _ from "lodash";
import { MAIN_BLUE } from "../../../../color";
import GridItem from "../../../GridItem/GridItem";
import CreateButton from "../../../../components/CommonFilterCreateButton/CreateButton";
import { PAGE_SIZE_ARRAY } from "../../../../constants";
import { getUomData } from "../../../../services/FortificationService";
import useTranslatewrapper from "../../../../components/useTranslateWrapper";

const UomGrid = () => {
  const {translate} = useTranslatewrapper()

  const [loader, setLoader] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [columnDefsUser, setColumnDefsUser] = useState([
    {
      field: "name",
      headerName: translate("name"),
      hide: false,
      filter: "agTextColumnFilter",
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      flex: 2,
      filterParams: {
        buttons: ["apply", "clear"],
      },
    },
    {
      field: "conversionFactorKg",
      headerName: "Conversion Factor to Kg",
      hide: false,
      filter: "agTextColumnFilter",
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      flex: 2,
      filterParams: {
        buttons: ["apply", "clear"],
      },
    },
  ]);
  const gridRef = useRef();

  const [pageSize, setPageSize] = useState(12);
  const [pageNo, setPageNo] = useState(1);
  const [count, setCount] = useState(1);
  const RowSelectionType = "single";
  const filterDataRedux = useSelector((state) => state.filterUserData);
  const navigate = useNavigate();

  useEffect(() => {
    setLoader(true);
    fetchUomList();
  }, [filterDataRedux]);

  const setPage = (page) => {
    setPageNo(page);
    fetchUomList();
  };
  const counter = () => {
    return count % pageSize === 0
      ? Math.floor(count / pageSize)
      : Math.floor(count / pageSize) + 1;
  };

  const fetchUomList = async () => {
    const fetchData = await getUomData();
    const arr = [];
    if (fetchData?.data?.data) {
      setCount(fetchData?.data?.count);
      fetchData?.data?.data.map((data) =>
        arr.push({
          name: data.name,
          id: data?.id,
          categoryId: data?.categoryId,
          conversionFactorKg: data?.conversionFactorKg,
        })
      );
      setRowData(arr);
    }
    setLoader(false);
  };

  function onGridReady(params) {
    setGridApi(params.api);
  }

  const onRowClicked = useCallback(() => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    navigate(`details/${selectedRows[0].id}`);
  }, []);

  return (
    <div>
      {loader ? (
        <Box className="main-content-overlay" sx={{ height: "100vh" }}>
          <CircularProgress />
        </Box>
      ) : (
        <div>
          <Box className="main-content-overlay">
            <div className="main-content-div">
              <Box m={2}>
                <Grid container>
                  <Grid item md={3} sd={12} p={1}>
                    <Typography variant="h6" sx={{ color: MAIN_BLUE, mx: 1 }}>
                      Fortification - Unit of Measurement
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={4}
                    sx={{ alignItems: "center", display: "flex" }}
                  >
                    <CreateButton
                      onClick={() => {
                        navigate(`/admin/fortification/uom/details`);
                      }}
                    />
                  </Grid>
                </Grid>
                <Box sx={{ m: 1 }}>
                  <GridItem
                    onGridReady={onGridReady}
                    rowData={rowData}
                    gridRef={gridRef}
                    rowSelection={RowSelectionType}
                    columnDefs={columnDefsUser}
                    pageSize={pageSize}
                    pageSizeArray={PAGE_SIZE_ARRAY}
                    count={counter()}
                    setPage={setPage}
                    page={pageNo}
                    onSelectionChanged={onRowClicked}
                    height="main"
                  />
                </Box>
              </Box>
            </div>
          </Box>
        </div>
      )}
    </div>
  );
};

export default UomGrid;
