import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Button, MenuItem, Paper, TextField, Tooltip, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Form } from "@rjsf/mui";
import validator from "@rjsf/validator-ajv8";
import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { MAIN_BLUE } from '../../../../color';
import { adminCategoryDocDropDown, createTestType, deleteRefMethod, getLabTestType, updateTestType, createRefMethod, updateRefMethod } from '../../../../services/adminService';
import { BACK_BUTTON, MILLER_UI_TEXT } from '../../../../textConstant';
// import TestReferenceMethodSchema from './TestReferenceMethodSchema.json';
import useTestReferenceMethodSchemaFn from "./TestReferenceMethodSchema";
import TestReferenceMethodUISchema from './TestReferenceMethodUISchema.json';
import { useAppDispatch } from "../../../../services/StoreHooks";
import './TestType.css';
import useTranslatewrapper from "../../../../components/useTranslateWrapper";
function AddEditTesTypes() {
    const {translate} = useTranslatewrapper()
    let TestReferenceMethodSchema = useTestReferenceMethodSchemaFn()
    const dispatch = useAppDispatch();
    const params = useParams();
    const [uiSchema, setUiSchema] = useState(TestReferenceMethodUISchema);
    const [testReferenceMethodSchema, setTestReferenceMethodSchema] = useState(TestReferenceMethodSchema);
    const [referenceMethods, setReferenceMethods] = useState([]);
    const [testMethodName, setTestMethodName] = useState("");
    const [isExpanded1, setIsExpanded1] = useState(true)
    const [formData, setFormData] = useState({ Category: '', TestMethod: '', TestType: '', Mandatory: true })
    const [methodTypes, setMethodTypes] = useState([{ id: 'range', value: 'Range' }, { id: 'present', value: 'Presence' }]);
    const navigate = useNavigate();
    const addReference = () => {
        const refMethod = {
            name: '',
            methodType: 'range',
            minValue: null,
            maxValue: null,
            defaultPresent: null,
            referenceValue: null,
            uom: null,
            id: null,
            options: []
        }
        params.id && (refMethod.labTestTypeId = params.id)

        setReferenceMethods(data => [...data, refMethod]);
    }
    // useEffect(() => {
    //     console.log(referenceMethods)
    // }, [referenceMethods])

    useEffect(() => {
        if (params.id) {
            fetchLabTest(params.id)
        }
        fetchCategories();
    }, []);
    const fetchLabTest = async (id) => {
        const testDetails = await getLabTestType(id);
        if (testDetails.status === 200) {
            if (testDetails.data) {

                setFormData(d => {
                    d.TestMethod = testDetails.data.name
                    d.TestType = testDetails.data.type
                    d.Mandatory = testDetails.data.isMandatory
                    d.Category = testDetails.data.categoryId
                    return { ...d }
                });

                const refMethods = testDetails.data.labTestReferenceMethods.map(labRefMethod => {
                    const refMethod = {
                        name: labRefMethod.name,
                        methodType: labRefMethod.defaultPresent ? 'present' : 'range',
                        minValue: labRefMethod.minValue,
                        maxValue: labRefMethod.maxValue,
                        defaultPresent: labRefMethod.defaultPresent,
                        referenceValue: labRefMethod.referenceValue,
                        uom: labRefMethod.uom,
                        id: labRefMethod.id,
                        options: []
                    }
                    if (params.id) refMethod.labTestTypeId = parseInt(params.id)
                    refMethod.options = (refMethod.referenceValue && refMethod.referenceValue.split(',')) || [];
                    return refMethod
                })
                setReferenceMethods(refMethods)
            }
        }
    }

    const deleteReferenceMethod = async (index) => {
        if (!referenceMethods[index].id) {
            setReferenceMethods((d) => d.filter((eD, i) => i !== index));
        } else {
            const data = await deleteRefMethod(referenceMethods[index].id);
            if (data.status === 200) {
                setReferenceMethods((d) => d.filter((eD, i) => i !== index));
                dispatchNotification("success", ["Reference method deleted successfully"]);
            }
        }
    }

    const fetchCategories = async () => {
        const categories = await adminCategoryDocDropDown();
        if (categories.status === 200) {
            if (categories.data.data) {
                dispatch({
                    type: "STORE_CATEGORIES",
                    data: categories.data.data,
                });
                const independentCat = categories.data.data.filter(cat => !cat.isIndependentBatch);
                const refSchema = cloneDeep(TestReferenceMethodSchema);

                refSchema.properties.Category.enum = independentCat.map(indpCategory => indpCategory.id);
                refSchema.properties.Category.enumNames = independentCat.map(indpCategory => indpCategory.name.replace('MILLER',MILLER_UI_TEXT));
                setTestReferenceMethodSchema(refSchema);
            }
        }
    }

    const addTestType = async (formData) => {
        const reqBody = prepareDataForCreate(formData);
        if (reqBody.id) {
            const data = await updateTestType(reqBody, reqBody.id);
            if (data.status === 200) {
                dispatchNotification("success", ["Test type method updated successfully"]);
            }
            fetchLabTest(reqBody.id)
        } else {
            const data = await createTestType(reqBody);
            if (data.status === 201) {
                dispatchNotification("success", ["Test type method created successfully"]);
            }
            navigate(-1)
        }
    }
    const prepareDataForCreate = (formData) => {
        const reqBody = {};
        reqBody.name = formData.TestMethod;
        reqBody.type = formData.TestType;
        reqBody.isMandatory = formData.Mandatory;
        reqBody.categoryId = formData.Category;
        reqBody.labTestReferenceMethods = referenceMethods.map((refM => {
            return {
                name: refM.name,
                minValue: refM.minValue,
                maxValue: refM.maxValue,
                defaultPresent: refM.defaultPresent,
                referenceValue: refM.referenceValue,
                uom: refM.uom,
                id: refM.id,
            }
        }));
        if (params.id) {
            reqBody.id = parseInt(params.id);
            // let valid = true;
            // reqBody.labTestReferenceMethods.forEach(labTestRefMethod=> {
            //     valid = valid && (labTestRefMethod.name && labTestRefMethod.minValue && labTestRefMethod.maxValue && labTestRefMethod.uom)
            // });
            // if(!valid){
            //     dispatchNotification("error", "please fill all mandatory fields")
            // }
        }
        return reqBody
    }
    const dispatchNotification = (typ, msg) => {
        dispatch({
            type: "SHOW_NOTIFICATION",
            data: {
                open: true,
                type: typ,
                message: msg,
            },
        });
    };
    const saveRefMethod = async (refMethod) => {
        const errArr = [];
        if (!refMethod.name || (refMethod.methodType === 'range' && (!refMethod.minValue || !refMethod.maxValue || !refMethod.uom)) || (refMethod.methodType === 'present' && (!refMethod.referenceValue || !refMethod.defaultPresent))) {
            errArr.push("please fill mandatory fields")
        }

        if (errArr.length > 0) {
            dispatchNotification('error',errArr);
        } else {
            if (refMethod.id) {
                const data = await updateRefMethod(refMethod, refMethod.id);
                if (data.status === 200) {
                    dispatchNotification("success", ["Test methods updated successfully"])
                }
            } else {
                const data = await createRefMethod(refMethod);
                if (data.status === 200) {
                    dispatchNotification("success", ["Test refernce method created successfully"])
                }
            }
        }
    }

    return (
        <div className="main-content-overlay">

            <Typography
                align="center"
                mt={2}
                variant="h6"
                sx={{ color: MAIN_BLUE, fontWeight: "600" }}
            >
                Add Test Types
            </Typography>

            <Box marginLeft={3} className="main-content-div" style={{ position: "relative", marginLeft: "0px" }}>
                <Button
                    onClick={(e) => {
                        navigate(-1);
                    }}
                >
                    <ArrowBackIcon />
                    <Typography
                        sx={{
                            fontSize: "13px",
                            textDecoration: "underline",
                            fontWeight: "600",
                            fontSize: "12px",
                            lineHeight: "15px",
                            color: "#0075FF",
                        }}
                    >
                        {BACK_BUTTON}
                    </Typography>
                </Button>
                <Accordion
                    onChange={(e, expanded) => {
                        setIsExpanded1(expanded);
                    }}
                    defaultExpanded={true}
                >
                    <AccordionSummary
                        expandIcon={
                            <ExpandMoreIcon
                                sx={{ color: "#fff", background: "#0075FF", height: "35px" }}
                            />
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Box className="formTitle">
                            <Typography className="formBox-text"> Test Methods</Typography>
                            {isExpanded1 ? (
                                <Typography className="formBox-helperText">
                                    {translate('clickToCollapse')}
                                </Typography>
                            ) : (
                                <Typography className="formBox-helperText">
                                    {translate('clickToExpand')}
                                </Typography>
                            )}
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>


                        <Form
                            schema={testReferenceMethodSchema}
                            validator={validator}
                            uiSchema={uiSchema}
                            formData={formData}
                            className='test-type-form px-3'
                            onChange={(e) => {
                                console.log('e', e)
                                // setTestMethodName(e.formData.TestMethod)
                                setFormData({ ...e.formData })
                            }}
                            onSubmit={(e) => {
                                addTestType(e.formData);
                            }}>

                            <TableContainer component={Paper} sx={{ marginLeft: "0px" }} className="lab-details-table">
                                <Table sx={{ width: "70vw" }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left" width={"5%"}>S.No</TableCell>
                                            <TableCell align="left" width={"20%"}>Reference Name</TableCell>
                                            <TableCell align="left" width={"10%"}>Reference Type</TableCell>
                                            <TableCell align="left" width={"55%"}>Reference Values</TableCell>
                                            <TableCell align="left" width={"10%"}>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {referenceMethods.map((refMethod, refMethodIndex) =>

                                        (
                                            <TableRow>
                                                <TableCell align="left" width={"5%"}>{refMethodIndex + 1}</TableCell>
                                                <TableCell align="left" width={"10%"}>
                                                    <TextField
                                                        variant="outlined"
                                                        
                                                        value={refMethod.name}
                                                        sx={{ width: "20ch", paddingRight: "10px", lineHeight:"13px" }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                            }}
                                                        label="Reference Name"
                                                        onChange={(e) => {
                                                            refMethod.name = e.target.value
                                                            setReferenceMethods(d => [...d])

                                                        }}
                                                        required="true"
                                                    />
                                                </TableCell>

                                                <TableCell align="left" width={"20%"}>
                                                    <TextField
                                                        sx={{ width: "100%", backgroundColor: "#fff" }}
                                                        name="category"
                                                        id="outlined-basic"
                                                        variant="outlined"
                                                        value={refMethod.methodType}
                                                        select
                                                        required="true"
                                                        onChange={(event, refMethodIndex) => {
                                                            refMethod.methodType = event.target.value;
                                                            if (refMethod.methodType === 'range') {
                                                                refMethod.defaultPresent = null;
                                                                refMethod.referenceValue = null;
                                                                refMethod.options = []
                                                            } else if (refMethod.methodType === 'present') {
                                                                refMethod.minValue = null;
                                                                refMethod.maxValue = null;
                                                                refMethod.uom = null;
                                                            }
                                                            setReferenceMethods(d => [...d])

                                                        }}
                                                    >
                                                        {methodTypes.map((method, index) => (

                                                            <MenuItem key={index} value={method.id}>
                                                                {method.value}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </TableCell>
                                                <TableCell  sx={{display: "flex", width:"100%"}} align="left" width={"55%"}>
                                                    {refMethod.methodType && refMethod.methodType === 'range' && (
                                                        <>
                                                            <TextField
                                                                variant="outlined"
                                                                
                                                                value={refMethod.minValue}
                                                                sx={{ width: "20ch", paddingRight: "10px" }}
                                                                type="number"
                                                                InputLabelProps={{
                                                                shrink: true,
                                                                }}
                                                                onChange={(e) => {
                                                                    refMethod.minValue = e.target.value;
                                                                    setReferenceMethods(d => [...d])
                                                                }}
                                                                label="minimum value"
                                                                required="true"
                                                            />
                                                            <TextField
                                                                variant="outlined"
                                                                
                                                                value={refMethod.maxValue}
                                                                sx={{ width: "20ch", paddingRight: "10px" }}
                                                                type="number"
                                                                InputLabelProps={{
                                                                shrink: true,
                                                                }}
                                                                label="Maximum Value"
                                                                onChange={(e) => {
                                                                    refMethod.maxValue = e.target.value
                                                                    setReferenceMethods(d => [...d])

                                                                }}
                                                                required="true"
                                                            />
                                                            <TextField
                                                                variant="outlined"
                                                                
                                                                value={refMethod.uom}
                                                                sx={{ width: "20ch", paddingRight: "10px" }}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                    }}
                                                                label="uom"
                                                                onChange={(e) => {
                                                                    refMethod.uom = e.target.value
                                                                    setReferenceMethods(d => [...d])

                                                                }}
                                                                required="true"
                                                            />
                                                        </>

                                                    )}
                                                    {refMethod.methodType && refMethod.methodType === 'present' && (
                                                        <div style={{ display: "flex" , width: "100%"}}>
                                                           <Tooltip title="Enter values coma seperated">

                                                            <TextField
                                                                inputProps={{"aria-autocomplete": false}}
                                                                variant="outlined"
                                                                value={refMethod.referenceValue}
                                                                sx={{ width: "20ch", paddingRight: "10px" }}
                                                                
                                                                onChange={(e) => {
                                                                    refMethod.referenceValue = e.target.value
                                                                    refMethod.options = e.target.value.split(',');
                                                                    setReferenceMethods(d => [...d])
                                                                }}
                                                                label="Enter possible values"
                                                                required="true"
                                                                

                                                            />
                                                           </Tooltip>
                                                            <TextField
                                                                sx={{ width: "50%", marginLeft: '10px', backgroundColor: "#fff" }}
                                                                name="abc"
                                                                id="outlined"
                                                                variant="outlined"
                                                                value={refMethod.defaultPresent}
                                                                label="select reference value"
                                                                select
                                                                // disabled={row.roleCategoryId ? true : false}
                                                                onChange={(event) => {
                                                                    refMethod.defaultPresent = event.target.value
                                                                    setReferenceMethods(d => [...d])

                                                                }}
                                                                required="true"
                                                            >
                                                                {refMethod.options.map((option, index) => (

                                                                    <MenuItem key={index} value={option}>
                                                                        {option}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>

                                                        </div>

                                                    )}

                                                </TableCell>
                                                <TableCell align="left" width={"20%"}>
                                                    <Button onClick={() => {
                                                        deleteReferenceMethod(refMethodIndex);
                                                    }}>{translate('delete')}</Button>
                                                    {/* {
                                                        params.id && (<Button onClick={() => {
                                                            saveRefMethod(refMethod);
                                                        }}>Save</Button>
                                                        )
                                                    } */}

                                                </TableCell>
                                            </TableRow>
                                        )
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Button
                                onClick={addReference}
                                sx={{
                                    mt: 1,
                                    color: "#0075FF !important",
                                    textDecoration: "underline",
                                    ml: 2
                                }}

                            >
                                <AddCircleOutlineIcon />
                                Add Reference Method
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                            >
                                {params.id ?  translate('saveChanges'): translate('addTestType')}
                            </Button>
                        </Form>



                    </AccordionDetails>
                </Accordion>
            </Box>
        </div>
    )
}

export default AddEditTesTypes