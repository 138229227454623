import React, { useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import PremixGrid from "../Dashboard/PremixGrid";

function RawMaterialsList() {

    const routeParams = useParams();

    return (
        <PremixGrid />
    )
}

export default RawMaterialsList;