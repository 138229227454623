import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Paper } from "@mui/material";
import useTranslatewrapper from "../useTranslateWrapper";

const BasicStepper = (props) => {
  const {translate} = useTranslatewrapper()
  const { steps } = props;
  let activestep = steps.length;
  return (
    <Box sx={{ padding: "20px", width:"50%"}} component={Paper} className="form-div ">
      <Stepper
        activeStep={activestep}
        alternativeLabel={props.orientation == "horizontal" ? true : false}
        orientation={props.orientation}
      >
        {steps.map((step) => (
          <Step key={step.label}>
            <StepLabel>
              <div>
                <div>{translate(step?.label?.toLowerCase())}</div>
                <div style={{paddingBottom:"5px"}}>{step.description}</div>
                {step.manufacturerName && step.manufacturerName != "" && (
                  <div style={{ fontSize: "smaller" }}>
                    {step.manufacturerName}
                  </div>
                )}
                {step.licenseNumber && step.licenseNumber != "" && (
                  <div style={{ fontSize: "smaller" }}>
                    {`${translate('licenseNo')}: ${step.licenseNumber}`}
                  </div>
                )}
                {step?.comments && step?.comments !== "" && step?.comments !== "null" && (
                  <div style={{ fontSize: "smaller" }}>
                    {step.comments}
                  </div>
                )}
                {
                  step.toManufacturerName && (
                  
                  <div style={{ fontSize: "smaller", marginTop: '3px' }}>
                    <b>To: </b>{`${step.toManufacturerName}`}
                  </div>)
                }

                {step.toManufacturerLicenseNo && 

                  (<div style={{ fontSize: "smaller" }}>
                      {`${translate('licenseNo')}: ${step?.toManufacturerLicenseNo}`}
                  </div>
                  )
                }
              </div>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default BasicStepper;
