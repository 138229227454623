import { UserType } from "../constants";

const initial =  false
  //const initial = []
export default (state = initial, event) => {
  switch (event.type) {
    case "UPDATE_STATE":
      return event.data;
    
        
        
      
    default:
      return state;
  }
};
