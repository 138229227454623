import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import "../../../../App.css";
import { CircularProgress, Grid, Typography } from "@mui/material";
import { useSelector, } from "react-redux";
import { useNavigate, } from "react-router-dom";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import _ from "lodash";
import { getManufacturerGrid, getState } from "../../../../services/adminService";
import {
  getAllSubSections,
  getStateList,
  getDashboardCount,
  getAllLotsOrBatches,
  getAllInspectionSubSections,
  getManufacturerFilterData


} from "../../../../services/FortificationService";
import { MAIN_BLUE } from "../../../../color";
import GridItem from "../../../GridItem/GridItem";
import CreateButton from "../../../../components/CommonFilterCreateButton/CreateButton";
import { PAGE_SIZE_ARRAY } from "../../../../constants";
import DeletableChips from "../../../../components/Chips/DeletableChip";
import SimpleDialogDemo from "../../../../components/Filter/FilterPopup";
import SearchBar from "../../../../components/CommonSearchBar/Search";
import { useAppDispatch } from "../../../../services/StoreHooks";
import { useParams } from "react-router-dom";
import FilterButton from "../../../../components/CommonFilterCreateButton/FilterButton";
import moment from "moment";
import KeycloakService from "../../../../services/KeycloakService";




const ManufacturerPageGrid = () => {
  const routeParams = useParams();
  const dispatch = useAppDispatch();
  const [loader, setLoader] = React.useState(false);
  const [gridApi, setGridApi] = useState(null);
  const [chipsState, setChipsState] = React.useState({});
  const [chip, setChip] = useState("");
  const [searchValue, setSearchValue] = React.useState("");
  const [stateId, setStateId] = React.useState([]);
  const [state, setState] = useState([])
  const [district, setDistrict] = useState([])
  const [manufacturerId, setmanufacturerId] = React.useState([]);
  const [tagetManufacturerId, setTargetManufacturerId] = React.useState([]);


  const [columnDefsUser, setColumnDefsUser] = React.useState([
    {
      field: "name",
      headerName: "name",
      hide: false,
      filter: "agTextColumnFilter",
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      flex: 2,
      filterParams: {
        buttons: ["apply", "clear"],
      },
    },
    {
      field: "isMandate",
      headerName: "License Number",
      hide: false,
      filter: "agTextColumnFilter",
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      flex: 2,
      filterParams: {
        buttons: ["apply", "clear"],
      },
    },
    {
      field: "categoryId",
      headerName: "Vendor Type",
      hide: false,
      filter: "agTextColumnFilter",
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      flex: 2,
      filterParams: {
        buttons: ["apply", "clear"],
      },
    },
  ]);
  const gridRef = useRef();
  const [rowData, setRowData] = useState([]);
  const [pageSize, setPageSize] = React.useState(12);
  const [pageNo, setPageNo] = React.useState(1);
  const [count, setCount] = React.useState(1);
  const [clear, setclear] = useState(false);
  const [openFilter, setOpenFilter] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [filterData, setFilterData] = React.useState({
    state: [],
    manufacturerIds: [],
    targetManufacturerIds: [],
  });
  const [filterStates, setFilterStates] = React.useState([]);
  const [filterManufacturer, setFilterManufacturer] = React.useState([]);
  const [filterTargetManuf, setFilterTargetManuf] = React.useState([]);
  const [categories, setCategories] = useState([])
  const [manufactureOptions, setManufacturerOptions] = React.useState([]);
  const [targetmanufactureOptions, setTagretManufacturerOptions] = React.useState([]);
  const [activeFilters, setActiveFilters] = React.useState({});
  const [vendorTypeData, setVendorTypeData] = React.useState([
    {id:"1",label:"Manufacturer",value:"Manufacturer"},
    {id:"2",label:"Trader",value:"Trader"},
    {id:"3",label:"Broker",value:"Broker"},






]);
  const [manufacturerTypeData, setManufacturerTypeData] = React.useState([
    {id:"1",label:"Public",value:"Public"},
    {id:"1",label:"Private",value:"Private"},



  ]);



  const [searchCriteria, setSearchCriteria] = useState(
    { searchCriteriaList: [{ key: "stateId", value: "" }, { key: "districtId", value: '' }, { key: "categories", value: "" }, { key: "search", value: chip }] }
    )


  const RowSelectionType = "single";

  const filterDataRedux = useSelector((state) => state.filterData);

  const navigate = useNavigate();

  useEffect(() => {
    // setLoader(true);
    fetchIamDocTypeData(pageNo, pageSize,
    filterDataRedux
    );

  }, [pageNo,pageSize]);
  // }, [filterDataRedux,pageNo,pageSize]);


  useEffect(() => {
    setLoader(true);
    setPage(1)
    fetchIamDocTypeData(1, pageSize,
    filterDataRedux
    );

  }, [filterDataRedux]);

  useEffect(() => {
    setLoader(true);
    setCount(0);
    setRowData([]);
    updateChips();

    // fetchBatchOrLotData(pageNo, pageSize);
    setLoader(false);

  }, [filterDataRedux, routeParams]);
  const userdetails = KeycloakService.getUserDetail();
  const isInspector = userdetails?.isInspector;
  const setPage = (page) => {
    setPageNo(page);
    // fetchIamDocTypeData(page, pageSize,"");
  };
  const counter = () => {
    return count % pageSize === 0
      ? Math.floor(count / pageSize)
      : Math.floor(count / pageSize) + 1;
  };

  const fetchIamDocTypeData = async (pageNo, pageSize, searchCriteria) => {
    const fetchData = await getManufacturerFilterData(searchCriteria, pageNo, pageSize);
    const arr = []
    if (fetchData?.data?.data) {
      setCount(fetchData?.data?.count);
      fetchData?.data?.data.map((data) =>
        arr.push({
          name: data?.name,
          id: data?.id,
          categoryId: data?.vendorType,
          isMandate: data?.licenseNumber
        })
      )
      setRowData(arr)
    }
    setLoader(false)
  };
  function onGridReady(params) {
    setGridApi(params.api);
  }

  const onRowClicked = useCallback(() => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    navigate(`/inspect/manage/manufacturer/details/${selectedRows[0].id}`)

  }, []);
  const setFiltersSearch = (data) => {
    var json = filterDataRedux.searchCriteriaList;
    var json1 = structuredClone(json);
    json1[0].key = "search";

    if (data == null) {
      json1[0].value = "";
    } else {

      json1[0].value = data.label;
      setChip(data.label);
    }
    dispatch({
      type: "UPDATE_FILTER",
      data: json1,
    });
  };

  const removeSearch = (data) => {
    var json = filterDataRedux.searchCriteriaList;
    var json1 = structuredClone(json);
    if (data != null) {
      json1[0].value = "";
      setChip("");
      setclear(true);
    }

    dispatch({
      type: "UPDATE_FILTER",
      data: json1,
    });
  };
  let chipMap = {};
  const updateChips = () => {
   filterDataRedux.searchCriteriaList.map((entry) => {
      switch (entry.key) {
        case "search":
          if (entry.value) {
            chipMap["search"] = entry.value;
            // onlyStateChangeVar=false;
          }
          break;
          case "categories":
            if (entry.value !== "" && entry.value)
              chipMap["categories"] = entry.value;
  
  
            break;


        case "states":
          if (entry.value !== "" && entry.value)
            chipMap["states"] = entry.value;


          break;
          case "districts":
          if (entry.value !== "" && entry.value)
            chipMap["districts"] = entry.value;


          break;
        case "categories":
          if (entry.value !== "" && entry.value) {
            chipMap["categories"] = entry.value;
            
            // onlyStateChangeVar=false;

          }



          break;
          case "manufacturerType":
            if (entry.value !== "" && entry.value) {
                chipMap["manufacturerType"] = entry.value;
                
                // onlyStateChangeVar=false;
    
              }
    
    
    
              break;
              case "vendorType":
                if (entry.value !== "" && entry.value) {
                    chipMap["vendorType"] = entry.value;
                    
                    // onlyStateChangeVar=false;
        
                  }
        
        
        
                  break;


      }
    });

    setChipsState(chipMap);
  };


  const deleteChip = (chipKey, chipValue) => {

    var json = filterDataRedux.searchCriteriaList;
    var json1 = structuredClone(json);
   
      if (chipKey === "categories") {
        const index = json1[1].value.indexOf(chipValue);
        if (index > -1) {
          json1[1].value.splice(index, 1);
        }
        // setFilterManufacturer(json1[1].value);
      }
    if (chipKey === "search") {
      json1[0].value = "";
    }
    if (chipKey === "states") {
      json1[2].value = "";
    }
    if (chipKey === "manufacturerType") {
      json1[4].value = "";

       
      }
      if (chipKey === "vendorType") {
      json1[5].value = "";

       
      }
    if (chipKey === "districts") {
        const index = json1[3].value.indexOf(chipValue);
        if (index > -1) {
          json1[3].value.splice(index, 1);
        }
        // setFilterManufacturer(json1[1].value);
      }
   
   
    if (json1[6].value.length == 0) json1[6].value = [null];
    if (json1[3].value.length == 0) json1[3].value = [null];
    if (json1[7].value.length == 0) json1[7].value = [null];
    if (json1[8].value.length == 0) json1[8].value = [null];
    dispatch({
      type: "UPDATE_FILTER",
      data: json1,
    });
  };
  const handleClickOpen = () => {
    setOpenFilter(true);
  };
  const handleClose = (value) => {
    setOpenFilter(false);
  };
  let arrFilterStates = [];

  const handleSelectChange = (values) => {

    if (arrFilterStates.length == 0) {
      values &&
        values.length != 0 &&
        values?.map((value) => {
          arrFilterStates.push(value.id);
          // arr1.push
        });
      setFilterStates(arrFilterStates);
    }

    //  setStatesDefault()
    // setSelected(values);
  };

  const getStateData = async () => {
    const StateData = await getState(1);
    StateData && setState(StateData.data.data);
  };


  useEffect(() => {
    getStateData();
  }, [])


  const categoriesData = useSelector((state) => state.categoriesData);


  useEffect(() => {
    setCategories(categoriesData?.categories?.filter(item=>!item.independentBatch)?.map(cat => { return { id: cat.id, value: cat.name, label: cat.name } }))
  }, [categoriesData])
  const dateValue = (date) => {
    if (date) {
      return moment.utc(date).local().format("yyyy/MM/DD");
    } else {
      return "";
    }
  };
  const setFilters = (filterData) => {

    var json = filterDataRedux.searchCriteriaList;

    var json1 = structuredClone(json);
    if (filterStates.length != 0) {
      setFilterData({ ...filterData, state: filterStates });
      json1[3].value = filterStates;
      json1[6].value = filterStates;
    }
    else {
      json1[3].value = [null];
      json1[6].value = [null];
    }
    if (filterManufacturer.length != 0) {
      setFilterData({ ...filterData, manufacturerIds: filterManufacturer });
      json1[7].value = filterManufacturer;
    } else {
      json1[7].value = [null];
    }
    if (filterTargetManuf.length != 0) {
      setFilterData({ ...filterData, targetManufacturerIds: filterTargetManuf });
      json1[7].value = filterTargetManuf;
    } else {
      json1[8].value = [null];
    }

    json1[0].value = searchValue;
    json1[1].value = `${dateValue(filterData.mnfStartDate)}${"-"}${dateValue(
      filterData.mnfEndDate
    )}`;
    json1[2].value = `${dateValue(filterData.expStartDate)}${"-"}${dateValue(
      filterData.expEndDate
    )}`;
    dispatch({
      type: "UPDATE_FILTER",
      data: json1,
    });
  };
  return (
    <div>
      {loader ? (
        <Box className="main-content-overlay" sx={{ height: "100vh" }}>
          <CircularProgress />
        </Box>
      ) : (
        <div>
          <Box className="main-content-overlay">
            <div className="main-content-div">
              <Box m={2}>
                <Grid container>


                  <Grid item md={3} sd={12} p={1}>
                    <Typography variant="h6" sx={{ color: MAIN_BLUE, mx: 1 }}>
                      Manufacturers
                    </Typography>
                  </Grid>


                  <Grid item md={5}>
                    <Stack direction="row" sx={{ my: 1.5, flexWrap: "wrap" }}>
                      {Object.keys(chipsState).map((key) => (
                        <DeletableChips
                          chipValue={chipsState[key]}
                          chipKey={key}
                          deleteChip={deleteChip}
                          options={options}
                          categories={categories}
                          manufactureOptions={manufactureOptions}
                          targetmanufactureOptions={targetmanufactureOptions}
                          sx={{ maxHeight: "2rem !important" }}
                          state={state}
                          district={district}
                        />
                      ))}
                      {/*                
                      {[...chipsState.keys()].map(data=>
                       data.map((value)=>(
                        <DeletableChips
                        chipValue={value}
                        chipKey={2}
                        deleteChip={deleteChip}
                        sx={{ maxHeight: "2rem !important" }}
                      />
                       ))
                        
                      )
                 } */}
                    </Stack>
                  </Grid>


                  <Grid item md={4} sx={{ display: 'flex', alignItems: 'center' }}>
                    <SearchBar
                      onChange={(e) => {
                        if (e !== "") {
                          setFiltersSearch(e);
                        } else {
                          removeSearch();
                        }
                      }}
                    />

                    {
                      !isInspector &&
                      <CreateButton
                      onClick={() => {
                        routeParams.categoryId === "1"
                        ? navigate(
                          `${localStorage.getItem("currentPath")}/details`
                        )
                        : navigate(
                          `${localStorage.getItem("currentPath")}/details`
                        );
                      }}
                      />
                    }

                    {openFilter ? (
                      <div>
                        <SimpleDialogDemo
                          chipsState={chipsState}
                          open={openFilter}
                          onClose={handleClose}
                          filterData={filterData}
                          setFilterData={setFilterData}
                          setFilters={setFilters}
                          filterStates={filterStates}
                          setActiveFilters={setActiveFilters}
                          activeFilters={activeFilters}
                          stateId={stateId}
                          setStateId={setStateId}
                          handleSelectChange={handleSelectChange}
                          options={options}
                          categories={categories}
                          isInspector={isInspector}
                          manufacturerId={manufacturerId}
                          tagetManufacturerId={tagetManufacturerId}
                          manufactureOptions={manufactureOptions}
                          targetmanufactureOptions={targetmanufactureOptions}
                          state={state}
                          district={district}
                          setDistrict={setDistrict}
                          hideDateFilter={true}
                          setSearchCriteria={setSearchCriteria}
                          searchCriteria={searchCriteria}
                          manufacturerTypeData={manufacturerTypeData}
                          vendorTypeData={vendorTypeData}

                        />
                      </div>
                    ) : (
                      <FilterButton handleClickOpen={handleClickOpen} />
                    )}
                  </Grid>

                </Grid>




                {/* <Grid item md={4} sx={{ alignItems: 'center', display: 'flex', }}>
                                        <CreateButton
                                            onClick={() => {
                                                navigate(`/admin/iam/manufacturer/details`)
                                            }}
                                        />
                                    </Grid> */}

                <Box sx={{ m: 1 }}>
                  <GridItem
                    onGridReady={onGridReady}
                    rowData={rowData}
                    gridRef={gridRef}
                    rowSelection={RowSelectionType}
                    columnDefs={columnDefsUser}
                    pageSize={pageSize}
                    pageSizeArray={PAGE_SIZE_ARRAY}
                    count={counter()}
                    setPage={setPage}
                    page={pageNo}
                    onSelectionChanged={onRowClicked}
                    height="main"
                  />
                </Box>
              </Box>
            </div>
          </Box>
        </div>
      )}
    </div>
  );
};

export default ManufacturerPageGrid;
