import React, {
    useEffect,
    useState,
    useRef,
    useCallback,
  } from "react";
  import Box from "@mui/material/Box";
  import Stack from "@mui/material/Stack";
  import "../../../../App.css";
  import { CircularProgress, Grid, Typography } from "@mui/material";
  import { useSelector, useDispatch } from "react-redux";
  import { useNavigate, useParams,  } from "react-router-dom";
  import "ag-grid-community/dist/styles/ag-grid.css";
  import "ag-grid-community/dist/styles/ag-theme-alpine.css";
  import "./../../../GridItem/AgGrid.css";
  import GridItem from "../../../GridItem/GridItem";
import { MAIN_BLUE } from "../../../../color";
  import { PAGE_SIZE_ARRAY } from "../../../../constants";
  import FilterButton from "../../../../components/CommonFilterCreateButton/FilterButton";
  import CreateButton from "../../../../components/CommonFilterCreateButton/CreateButton";
  import SearchBar from "../../../../components/CommonSearchBar/Search";
  import { USER_LISTING_HEADER } from "./ColumnHeader";
  import { getAllCategory, getAllStatus } from "../../../../services/Manage_service";
  import { FilterUser } from "../../../../components/Filter/Filteruser";
  import FilterChips from "../../../../components/Chips/ManagePageChip";
  import _ from "lodash";
import { getAllUser } from "../../../../services/adminService";
import { MILLER_UI_TEXT } from "../../../../textConstant";
import useTranslatewrapper from "../../../../components/useTranslateWrapper";
  
  const InspectionGrid = () => {
    const {translate} = useTranslatewrapper()
    const routeParams = useParams();
    const [loader, setLoader] = React.useState(false);
    const [hideRoleFilter,setHideRoleFilter]=useState(true);
    const [gridApi, setGridApi] = useState(null);
    const [columnDefsUser, setColumnDefsUser] = React.useState(USER_LISTING_HEADER(translate));
    const gridRef = useRef();
    const [rowData, setRowData] = useState([]);
    const [pageSize, setPageSize] = React.useState(12);
    const [pageNo, setPageNo] = React.useState(1);
    const [count, setCount] = React.useState(1);
    const [searchValue, setSearchValue] = React.useState("");
    const [stateId, setStateId] = React.useState([]);
    const [clear, setclear] = useState(false);
    const RowSelectionType = "single";
    const [chip, setChip] = useState("");
    const [categoryoptions, setcategoryOptions] = React.useState([]);
    const [roleoptions, setroleOpitons] = React.useState([]);
    const [status,setStatus]= useState([])
    let options = []
    let chipMap = {};
    const [activeFilters, setActiveFilters] = React.useState({});
    const filterDataRedux = useSelector((state) => state.filterUserData);
    const [chipsState, setChipsState] = React.useState({});
    const [filterCategory, setFilterCategory] = React.useState([]);
    const [filterRole, setFilterRole] = React.useState([]);
    const [filterStatus, setFilterStatus] = React.useState([]);
    const [filterData, setFilterData] = React.useState({
      search: "",
      categories: [],
      roles: [],
      status:[],
    });
    const [openFilter, setOpenFilter] = React.useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
  
    useEffect(() => {
      setLoader(true);
      // fetchUserData(pageNo, pageSize);
      // setPageNo(1);
      setPage(1)
      fetchCategoryRole();
      fetchStatus();
      updateChips();
    }, [filterDataRedux]);
  
    const handleClickOpen = () => {
      setOpenFilter(true);
    };
    const handleClose = (value) => {
      setOpenFilter(false);
    };
    const setPage = (page) => {
      setPageNo(page);
      fetchUserData(page, pageSize);
    };
    const counter = () => {
      return count % pageSize === 0
        ? Math.floor(count / pageSize)
        : Math.floor(count / pageSize) + 1;
    };
  
    const fetchUserData = async (pageNo, pageSize) => {
      const fetchData = await getAllUser("INSPECTION",pageNo, pageSize, filterDataRedux);
      
      if (fetchData?.data?.data) {
        setCount(fetchData?.data?.count);
        setRowData(fetchData?.data?.data)
      }
      setLoader(false)
    };
  
    const fetchCategoryRole = async () => {
      const categoryListData = await getAllCategory()
      const arrayCat = Object.keys(categoryListData?.data)
      let arrRole = []
      arrayCat.map((key) => arrRole.push(...categoryListData?.data[key]?.roles))
      const getCategoryList = arrayCat.map((list) => {
        return {
          value: list,
          label: list.replace('MILLER',MILLER_UI_TEXT),
        };
      });
      setcategoryOptions(getCategoryList)
      options.push(getCategoryList)
      const getRoleList = _.uniq(arrRole).map((list) => {
        return {
          value: list,
          label: list,
        };
      });
      setroleOpitons(getRoleList)
      options.push(getRoleList)
    }
    const fetchStatus = async () => {
      const statusListData = await getAllStatus()
      const arrayStat = statusListData?.data?.data.filter(item=>item.name.toLowerCase()!=="testing").map((data)=>data.name)
         const getStatusList = arrayStat.map((list) => {
        return {
          value: list,
          label: list,
        };
      });
      setStatus(getStatusList)
      options.push(getStatusList)
    }
  
    const setFilters = (filterData) => {
      var json = filterDataRedux.searchCriteriaList;
      if (filterCategory.length != 0) {
        setFilterData({ ...filterData, categories: filterCategory });
        json1[1].value = filterCategory;
  
      } else {
        json1[1].value = [null];
      }
      if (filterRole.length != 0) {
        setFilterData({ ...filterData, roles: filterRole });
        json1[2].value = filterRole;
  
      } else {
        json1[2].value = [null];
      }
      if (filterStatus.length != 0) {
        setFilterData({ ...filterData, status: filterStatus });
        json1[3].value = filterStatus;
  
      } else {
        json1[3].value = [null];
      }
      var json1 = structuredClone(json);
      json1[0].value = searchValue;
  
      dispatch({
        type: "UPDATE_USER_FILTER",
        data: json1,
      });
    };
  
    function onGridReady(params) {
      setGridApi(params.api);
    }
  
    const onRowClicked = useCallback(() => {
      const selectedRows = gridRef.current.api.getSelectedRows();
      navigate(`/admin/iam/inspectionusers/details/${selectedRows[0].id}`)
  
    }, []);
  
    const setFiltersSearch = (data) => {
      var json = filterDataRedux.searchCriteriaList;
      var json1 = structuredClone(json);
      if (data === null) {
        json1[0].value = "";
      } else {
        json1[0].value = data.label;
        setChip(data.label);
      }
      dispatch({
        type: "UPDATE_USER_FILTER",
        data: json1,
      });
    };
  
    const removeSearch = (data) => {
      var json = filterDataRedux.searchCriteriaList;
      var json1 = structuredClone(json);
      if (data != null) {
        json1[0].value = "";
        setChip("");
        setclear(true);
      }
  
      dispatch({
        type: "UPDATE_USER_FILTER",
        data: json1,
      });
    };
  
  
    const updateChips = () => {
      filterDataRedux.searchCriteriaList.map((entry) => {
        switch (entry.key) {
          case "search":
            if (entry.value)
              chipMap["search"] = entry.value;
            break;
          case "categories":
            if (entry.value !== "" && entry.value)
              chipMap["categories"] = entry.value;
            break;
          case "roles":
            if (entry.value !== "" && entry.value)
              chipMap["roles"] = entry.value;
            break;
          case "status":
            if (entry.value !== "" && entry.value)
            chipMap["status"] = entry.value;
            break;  
        }
      });
      setChipsState(chipMap);
    };
  
    const deleteChip = (chipKey, chipValue) => {
  
      var json = filterDataRedux.searchCriteriaList;
      var json1 = structuredClone(json);
  
      if (chipKey === "search") {
        json1[0].value = "";
      }
      if (chipKey === "categories") {
        const index = json1[1].value.indexOf(chipValue);
        if (index > -1) {
          json1[1].value.splice(index, 1);
        }
        setFilterCategory(json1[1].value)
      }
      if (chipKey === "roles") {
        const index = json1[2].value.indexOf(chipValue);
        if (index > -1) {
          json1[2].value.splice(index, 1);
        }
        setFilterRole(json1[2].value)
      }
      if (chipKey === "status") {
        const index = json1[3].value.indexOf(chipValue);
        if (index > -1) {
          json1[3].value.splice(index, 1);
        }
        setFilterStatus(json1[3].value)
      }
      if (json1[1].value.length === 0) json1[1].value = [null];
      if (json1[2].value.length === 0) json1[2].value = [null];
      if (json1[3].value.length === 0) json1[3].value = [null];
  
      dispatch({
        type: "UPDATE_USER_FILTER",
        data: json1,
      });
    };
    return (
      <div>
        {loader ? (
          <Box className="main-content-overlay" sx={{ height: "100vh" }}>
            <CircularProgress />
          </Box>
        ) : (
          <div>
            <Box className="main-content-overlay">
              <div className="main-content-div">
                <Box m={2}>
                  <Grid container>
                    <Grid item md={3} sd={12} p={1}>
                      <Typography variant="h6" sx={{ color: MAIN_BLUE, mx: 1 }}>
                        Inspection Users
                      </Typography>
                    </Grid>
                    <Grid item md={5}>
                      <Stack direction="row" sx={{ my: 1.5 }}>
                        {Object.keys(chipsState).map((key) => (
                          <FilterChips
                            chipValue={chipsState[key]}
                            chipKey={key}
                            deleteChip={deleteChip}
                            options={options}
                            sx={{ maxHeight: "2rem !important" }}
                          />
                        ))}
                      </Stack>
                    </Grid>
                    <Grid item md={4} sx={{ alignItems: 'center', display: 'flex', }}>
                      <SearchBar
                        onChange={(e) => {
                          if (e !== "") {
                            setFiltersSearch(e);
                          } else {
                            removeSearch();
                          }
                        }}
                      />
                      <CreateButton
                        onClick={() => {
                          navigate(`/admin/iam/inspectionusers/details`)
                        }}
                      />
  
                      {openFilter ? (
                        <div>
                          <FilterUser
                            chipsState={chipsState}
                            open={openFilter}
                            onClose={handleClose}
                            filterData={filterData}
                            setFilterData={setFilterData}
                            setFilters={setFilters}
                            filterRole={filterRole}
                            filterCategory={filterCategory}
                            setActiveFilters={setActiveFilters}
                            activeFilters={activeFilters}
                            stateId={stateId}
                            setStateId={setStateId}
                            roleoptions={roleoptions}
                            categoryoptions={categoryoptions}
                            status={status}
                            hideRoleFilter={hideRoleFilter}
                          />
                        </div>
                      ) : (
                        <FilterButton handleClickOpen={handleClickOpen} />
                      )}
                    </Grid>
  
                  </Grid>
                  <Box sx={{ m: 1 }}>
                    <GridItem
                      onGridReady={onGridReady}
                      rowData={rowData}
                      gridRef={gridRef}
                      rowSelection={RowSelectionType}
                      columnDefs={columnDefsUser}
                      pageSize={pageSize}
                      pageSizeArray={PAGE_SIZE_ARRAY}
                      count={counter()}
                      setPage={setPage}
                      page={pageNo}
                      onSelectionChanged={onRowClicked}
                      height="main"
                    />
                  </Box>
                </Box>
              </div>
            </Box>
          </div>
        )}
      </div>
    );
  };
  
  export default InspectionGrid;
  