import HttpService from "./HttpService";
const _axios = HttpService.getAxiosClient();
const FORTIFICATIONURL = process.env.REACT_APP_BASE_FORTIFICATION_URL;
const IAMURL = process.env.REACT_APP_BASE_IAM_URL;
const LABURL = process.env.REACT_APP_BASE_LAB_URL;
const FORTIFICATION_URL_RICE = process.env.REACT_APP_BASE_FORTIFICATION_URL_RICE;

export const getMapData = async (filter1, selectedCategoryId, type, geoId, categoryname, year, 
  // formattedfromDate, formattedtoDate, 
    selectedTab
  , selectedDates
  ) => {
    let urlpath = "";
    if (filter1 === "masters") {
      if(categoryname.toLowerCase() === 'labs'){
        urlpath = `${LABURL}dashboard/labs/count?type=${type}&geoId=${geoId}`
      }else{
        let empanelStr = ''
        empanelStr = (selectedTab == '1') ? 'manufacturers' : 'empanel'
        urlpath = `${IAMURL}${empanelStr}/count?categoryId=${selectedCategoryId}&type=${type}&geoId=${geoId}`;
      }
    } else if (filter1 === "production" || filter1 === "testing") {
      // debugger;
      let body = {
        "fromDate" : selectedDates.startDate,
        "toDate": selectedDates.endDate ,
        'categoryId': selectedCategoryId,
        'type': type,
        'geoId': geoId
      }

      if(categoryname==="WAREHOUSE"){

        let empanelUrlStr = (selectedTab == '2') ? 'empanel' : 'geography'

        urlpath = `${FORTIFICATION_URL_RICE}dashboard/${empanelUrlStr}/quantity/warehouse?`;
        let idKey = (type == 'country') ? 'targetCountryGeoId' : (type == 'state') ? 'targetStateGeoId' : 'geoId'
        body = {
          "fromDate" : selectedDates.startDate,
          "toDate": selectedDates.endDate ,
          'categoryId': selectedCategoryId,
          'type': type,
          [idKey]: geoId,
          // 'geoId': 1
        }
      } else{
        if(selectedTab == '2'){
          // urlpath = `${FORTIFICATIONURL}dashboard/empanel/aggregate/${filter1}`;
          urlpath = `${FORTIFICATION_URL_RICE}dashboard/empanel/aggregate/testing`;
        } else{
          urlpath = `${FORTIFICATION_URL_RICE}dashboard/geography/quantity/${filter1}?`;
        }
      }

      return _axios.post(urlpath, body).catch((error) => {
        console.log(error);
      });
    } else if (filter1 === "samples") {
      urlpath = `${LABURL}dashboard/geography/count/samples?categoryId=${selectedCategoryId}&type=${type}&geoId=${geoId}&year=${year || null}`;
    } else {
      urlpath = "";
    }

    return _axios.get(urlpath).catch((error) => {
      console.log(error);
    });
};

export const getDashboardDataForIndividualCell = (categoryId, cellType, level, geoId, dataType, formattedfromDate, formattedtoDate) =>{

  const body={
    'fromDate' : formattedfromDate,
    'toDate':formattedtoDate,
    'categoryId':categoryId,
    'type':level,
    'geoId':geoId

  }


  return _axios
  .post(`${FORTIFICATION_URL_RICE}dashboard/geography/aggregate/${dataType}/${cellType} ` ,body)
  .catch((error) => {
    console.log(error);
  });
}

export const getDashboardEmpaneledDataForIndividualCell = (categoryId, cellType, formattedfromDate, formattedtoDate) =>{

  const body={
    'fromDate' : formattedfromDate,
    'toDate':formattedtoDate,
    'categoryId':categoryId,
    // 'type':level,
    // 'geoId':geoId

  }


  return _axios
  .post(`${FORTIFICATION_URL_RICE}dashboard/empanel/aggregate/testing/${cellType} ` ,body)
  .catch((error) => {
    console.log(error);
  });
}

export const getCategories = async (independentBatch) => {
    return _axios
      .get(`${FORTIFICATIONURL}category?independentBatch=${independentBatch}`)
      .catch((error) => {
        console.log(error);
      });
}

export const getGridData = async (responseType, categoryId, type, geoId, pageNumber, pageSize,search) => {
  let urlpath = "";
  let path = `?categoryId=${categoryId}&type=${type}&geoId=${geoId}&pageNumber=${pageNumber}&pageSize=${pageSize}&search=${search ? search : ''}`;
  if (responseType === "masters") {
    urlpath = `${IAMURL}manufacturers/list${path}`;
  } else if (responseType === "production" || responseType === "testing") {
    urlpath = `${FORTIFICATIONURL}dashboard/geography/manufacturers/${responseType}${path}`;
    const body = {
      "fromDate" : null,
      "toDate": null 
    }
    return _axios.post(urlpath, body).catch((error) => {
      console.log(error);
    });
  } else if (responseType === "samples") {
    urlpath = `${LABURL}dashboard/geography/labs${path}`;
  } else {
    urlpath = "";
  }

  return _axios.get(urlpath).catch((error) => {
    console.log(error);
  });
};
