const initial = {
    searchCriteriaList: [
      { key: "search", value: undefined },
      { key: "categories", value: [null] },
      { key: "roles", value: [null] },
      { key: "status", value: [null] },
    ],
  };
  export default (state = initial, event) => {
    switch (event.type) {
      case "UPDATE_USER_FILTER":
        return {
          ...state,
          searchCriteriaList: [...event.data],
        };
  
      default:
        return state;
    }
  };
  