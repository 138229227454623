import { UserType } from "../constants";

const initial =  {name:""}
export default (state = initial, event) => {
  switch (event.type) {
    case "FETCH_IMAGE":
       
      return {
       ...state,
        name:event.data.name
      }          
      
    default:
      return state;
  }
};
