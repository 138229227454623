import React, { useEffect, useState } from "react";
import { withTheme } from "@rjsf/core";
import Theme from "@rjsf/mui";
import validator from "@rjsf/validator-ajv8";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector,useDispatch} from "react-redux";
import { Button } from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import ManufacturerDropdown from "../../../pages/Admin/labService/Users/Manufacturer Dropdown/ManufacturerDropdown.js"
import "../../../App.css";
import {
  ACTION_STATE_DATE_LABELS,
  ACTION_CONFIRMATION_MESSAGES,
} from "../GenericForm/ActionModalConstants";
import {postInspection} from "../../../services/FortificationService.js"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  InputLabel
} from "@mui/material";
import { useAppDispatch } from "../../../services/StoreHooks";
import moment from "moment";
import useTranslatewrapper from "../../useTranslateWrapper.js";
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
const handleDisableRow=()=>{

}
const uischema = {
  // SampleSentToLabDate: {
  //   'ui:placeholder': moment().local()
  //   },
  SampleSentToLabDate: {
    "ui:options": {
      "yearsRange": [
        2020,
        2023
      ]
    }
    
  },
  Comments: { "ui:widget": "textarea" },
  
};

function InspectModal(props) {
  const { handleClose, configuration, categoryId, actions, updateActionState,requestBody,lastActionDate,inspectModalData,getTestResultStatus } =
    props;

  const {translate} = useTranslatewrapper()


  const getId = useParams();
  const navigate = useNavigate();
console.log(inspectModalData);
  const dispatchNotification = (typ, msg) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      data: {
        open: true,
        type: typ,
        message: msg,
      },
    });
  };
  const event = moment().local();
  
  const currentAction = useSelector((state) => state.actionState);
  const Form = withTheme(Theme);

  const [schema, setSchema] = useState();
  const [error, setError] = useState(false);

  const [formData, setFormData] = useState({SampleSentToLabDate: event.format("yyyy-MM-DD")});
  const [open, setOpen] = React.useState(false);
  const [dateValidated, setDateValidated] = React.useState(false);
  const [globalErr, setGlobalErr] = React.useState([]);
  const [randomLab,setRandomLab]=React.useState(false);
  const [modalDate, setModalDate] = React.useState(moment().local());
  const [data, setData] = useState({});
const [requestedBy,setRequestedBy]=useState("");
const [block,setBlock]=useState(false);
const [manufacturerData,setManufacturerData]=useState()
  const [SampleSentToLabDate, setSampleSentToLabDate] = useState();
  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const dispatch = useAppDispatch();
  const activeLab = true;
  const handleClickOpenDialog = (e) => {
    if (e) {
      //debugger
      setData({
        SampleSentToLabDate: modalDate,
        Comments: e.formData?.Comments
      });
      setOpen(true);
    } 
  };

  const handleClickCloseDialog = () => {
    setOpen(false);
  };
  const dispatchShowLoader = (showLoader) => {
    dispatch({
      type: "SHOW_LOADER",
      data: {
        showLoader
      },
    });
  };

 
  useEffect(() => {
   
   
    setSchema({
      type: "object",
      properties: {
        
        Comments: {
          type: "string",
          title: "Comments",
        },
      },
      required: ["SampleSentToLabDate","Comments"],
    });
  }, []);

  // const GetDateWidget = () => {
  //   return (
  //     <DatePickerComponentModal
        
  //     />
  //   );
  // };
  // const widgets = {
    
  //   GetDateWidget,
  //   // ReqCatergoryWidget
  // };

  const handleClickYes = async () => {
    dispatchShowLoader(true);
   



const StatusUpdate=    {
        "requestedBy": requestedBy,
        "comments": data?.Comments || "",
        "labSample": {
          "batchId":  getId.type === "batch"?Number(getId?.id):null,
          "manufacturerId": getId.type === "batch" ? inspectModalData?.manufacturerId : inspectModalData?.batch?.manufacturerId,
          "batchNo": getId.type === "batch"?inspectModalData?.batchNo:null,
          "lotNo": getId.type === "lot"? inspectModalData?.lotNo: null,
          "lotId": getId.type === "lot"?Number(getId?.id):null,
          "sampleSentDate": modalDate,
          "labId": randomLab?null:Number(manufacturerData),
          "categoryId": Number(getId?.categoryId),
        },
        "isBlocking":block
      }




   
    const updateResult = await postInspection(StatusUpdate);
    dispatchShowLoader(false);

    if (updateResult?.data) {
      dispatchNotification("success",["Sample sent for inspection"]);
      getTestResultStatus(getId?.id,getId?.type);

      handleClose(false);
      handleClickCloseDialog();
     
    }
  };

  
  const validateDate =()=>{
    let arrErr =[];
    if(randomLab== false && !manufacturerData){
      arrErr.push(`Select a lab to continue`)
    }
   
       
      
    let msg = "";
    arrErr.map((err) => (msg += err + "\n"));

    if (arrErr && arrErr.length > 0) {
      // debugger;
    // debugger

    //  setGlobalErr([...arrErr])
      dispatchNotification("error", arrErr);
      return false;
    } else {
      // setGlobalErr([])
      return true;
    }

  }
  const manufacturerId = getId.type === "batch" ? inspectModalData?.manufacturerId : inspectModalData?.batch?.manufacturerId;

  return (
    <div>
      {schema && (
        <>
         <div style={{display:"flex",flexDirection:"column"}}>
         <Typography style={{ fontWeight: "400", fontFamily: "outfit", marginBottom: "1rem" }}>{ACTION_STATE_DATE_LABELS[currentAction.action]}</Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDatePicker
                  value={modalDate}
                  disableFuture="true"
                  minDate={new Date(inspectModalData?.dateOfManufacture)}
                  label={ACTION_STATE_DATE_LABELS[currentAction.action] || "Date"}
                  inputFormat="DD/MM/YYYY"
                  renderInput={(params) => <TextField {...params} inputProps={{...params.inputProps, readOnly: true}}  />}
                  onChange={(date)=>{
                    
                    setModalDate(date.$d);
                  }}
                  className="input-date-picker"
                  sx={{ mb: "1", mt: "1",width: "97%" }}
                />
              </LocalizationProvider>



              <TextField id="outlined-basic" 
              error={error}
              helperText={error? "Requested By cannot be empty":""}
              label="Requested By" sx={{mt:3}} variant="outlined" 
              onChange={(event) => {
                event.target.value!="" && setError(false)
    setRequestedBy(event.target.value);
          }} />







              <FormControlLabel
              sx={{width:"45%"}}
          value="start"
          control={<Checkbox />}
          label="Assign to random Lab"
          labelPlacement="end"
          onChange={(event) => {
            setRandomLab(event.target.checked);
            setManufacturerData(null)
          }}
        />




<ManufacturerDropdown
        // manufacturerDropDownId={manufacturerDropDownId}
        // manufacturer={manufacturer}
        // id={params.id}
        // getmanufacture={getmanufacture}
        setManufacturerData={setManufacturerData }
        // setQueryparam={setQueryparam}
        // manufacturerCount={manufacturerCount}
        isActiveLab={activeLab}
        disableDropdown={randomLab}
        categoryId={categoryId}
        manufacturerId={manufacturerId}
        />
 
        
        </div>
        <Form
          schema={schema}
          validator={validator}
          uiSchema={uischema}
        
          formData={formData}
         
          onSubmit={(e) => {
           const data= validateDate();
            setFormData(e.formData)
           if(requestedBy!=""){

            data && handleClickOpenDialog(e) 

           }
           else{
            setError(true)

           }
           
          }}
        >
         
          <div className="action-buttons-container">
            <Button variant="contained" type="submit">
              {currentAction?.title}
            </Button>
          </div>
        </Form>
        </>
        
      )}
      <Dialog
        open={open}
        onClose={handleClickCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{translate('confirmationMsg')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to {ACTION_CONFIRMATION_MESSAGES[currentAction.action]}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickCloseDialog} variant="contained"  sx={{ backgroundColor: 'grey' }}>
          {translate('no')}
          </Button>
          <Button
            type="submit"
            variant="contained"
            onClick={handleClickYes}

            autoFocus
          >
            {translate('yes')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default InspectModal;
