import { makeStyles } from '@mui/styles';

const drawerWidth = 240;

export const useStyles = makeStyles((Paper) => ({
  permanentDrawer: {
    width: drawerWidth,
    flexShrink: 0,
    "& .MuiDrawer-paper": {
      height: "auto",
      width: drawerWidth,
      boxSizing: "border-box",
      marginLeft: "0%",
      borderRadius: 0,
      backgroundColor: "white",
      top: "6em", bottom: "1em",
      width: "18%",
      borderTopRightRadius: "20px",
      borderBottomRightRadius:"20px"
    },
  },
}));