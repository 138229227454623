import moment from "moment";
import { EXP_DATE, HEADER_NAME, MANF_DATE, STATUS, TOTAL_QUANTITY, BATCH_NUMBER, BATCH_NAME } from "../../textConstant";
import Columnchip from "../../components/Table/Columnchip";
import UnitConverterWeight from "../../utils/UnitConverter";

export const LISTING_BATCHES_HEADER = (translate) => [
  {
    field: "batchNo",
    headerName:  translate('batchNumber'),
    minWidth: 170,
    hide: false,
    filter: "agTextColumnFilter",
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    filterParams: {
      buttons: ["apply", "clear"],
    },
  },
  {
    field: "name",
    headerName:  translate('batchName'),
    minWidth: 170,
    hide: false,
    filter: "agTextColumnFilter",
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    filterParams: {
      buttons: ["apply", "clear"],
    },
  },
  {
    field: "manufacturerBatchNumber",
    headerName:  translate('manufacturerBatchNo'),
    tooltipField: "manufacturerBatchNumber",
    minWidth: 170,
    hide: false,
    filter: "agTextColumnFilter",
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    filterParams: {
      buttons: ["apply", "clear"],
    },
  },
  {
    field: "dateOfManufacture",
    headerName: translate('manufacturingDate'),
    minWidth: 150,
    hide: false,
    filter: "agTextColumnFilter",
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    filterParams: {
      buttons: ["apply", "clear"],
    },
    valueFormatter: (params) => {
      if(params.data.dateOfManufacture){
        return moment.utc(params.data.dateOfManufacture).format("DD-MMM-YYYY");
      } else{
          return ""
      }
    },
  },
  {
    field: "dateOfExpiry",
    headerName: translate('expiryDate'),
    minWidth: 150,
    hide: false,
    filter: "agTextColumnFilter",
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    filterParams: {
      buttons: ["apply", "clear"],
    },

    valueFormatter: (params) => {
      if(params.data.dateOfExpiry){
        return moment.utc(params.data.dateOfExpiry).format("DD-MMM-YYYY");
      } else {
          return ""
      }
    },
  },
  {
    field: "totalQuantity",
    headerName: translate('totalQuantity'),
    minWidth: 170,
    hide: false,
    filter: "agTextColumnFilter",
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    filterParams: {
      buttons: ["apply", "clear"],
    },
    valueFormatter: (params) => {
      return UnitConverterWeight(params.data.totalQuantity);
  },
  },
  {
    field: "state",
    headerName: translate('stage'),
    minWidth: 170,
    hide: false,
    filter: "agTextColumnFilter",
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    filterParams: {
      buttons: ["apply", "clear"],
    },
    cellRendererFramework: (params) => (
      <Columnchip data={params.data} type="batches" />
    ),
  },
  {
    field: "labName",
    headerName:  translate('labName'),
    tooltipField: "labName",
    minWidth: 200,
    hide: false,
    filter: "agTextColumnFilter",
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    filterParams: {
      buttons: ["apply", "clear"],
    },
  },
  {
    field: "labCertificateNumber",
    headerName:  translate('tcNumber'),
    tooltipField: "labCertificateNumber",
    minWidth: 200,
    hide: false,
    filter: "agTextColumnFilter",
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    filterParams: {
      buttons: ["apply", "clear"],
    },
  },
  {
    field: "createdBy",
    headerName:  translate('createdBy'),
    tooltipField: "createdBy",
    minWidth: 200,
    hide: false,
    filter: "agTextColumnFilter",
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    filterParams: {
      buttons: ["apply", "clear"],
    }
  },
];
