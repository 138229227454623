import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import "../../App.css";
import { CircularProgress, Grid, Typography, Card } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Notification from "../../components/NotificationComponent";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { LISTING_BATCHES_HEADER } from "./ColumnHeaderBatches";
import "./../GridItem/AgGrid.css";
import GridItem from "../GridItem/GridItem";
import moment from "moment";

import { MAIN_BLUE } from "../../color";
import { PAGE_SIZE_ARRAY } from "../../constants";
import DeletableChips from "../../components/Chips/DeletableChip";
import SimpleDialogDemo from "../../components/Filter/FilterPopup";
import {
  getAllSubSections,
  getStateList,
  getDashboardCount,
  getAllLotsOrBatches,
  getAllInspectionSubSections,

} from "../../services/FortificationService";
import { getState, getDistrict } from "../../services/adminService";
import FilterButton from "../../components/CommonFilterCreateButton/FilterButton";
import CreateButton from "../../components/CommonFilterCreateButton/CreateButton";
import SearchBar from "../../components/CommonSearchBar/Search";
import { PREMIX_TEXT } from "../../textConstant";
import { LISTING_LOTS_HEADER } from "./ColumnHeaderLots";
import { getMenuDetails } from "../../modules/menuSlice";
import DashBoardStats from "../../components/DashboardCards/Cards";
import KeycloakService from "../../services/KeycloakService";
import { manufacturerListFilter } from "../../services/adminService";
import { getAllLots, getBatchesByManufacturerForMonitor } from "../../services/Manage_service";
import useTranslatewrapper from "../../components/useTranslateWrapper";
const PremixGrid = (props) => {
  const { translate, language } = useTranslatewrapper();

  const [showBatchesOrLotsForMonitor, setShowBatchesOrLotsForMonitor] = useState(props?.showBatchesOrLotsForMonitor)
  const routeParams = useParams();
  if(showBatchesOrLotsForMonitor){

    routeParams.type = props.isBatchList ? 'batch' : props.isLotList ? "lot" : 'lot'
  }
  const stateData = useSelector((state) => {
    return state.stateData
  });


  const pathNameCapitalize = useLocation().pathname.split("/")[1];
  const pathNameCapitalizeInspect = useLocation().pathname.split("/")[2];

  let isSuperAdmin = false;
  if ((pathNameCapitalize === 'admin') && !showBatchesOrLotsForMonitor) {
    isSuperAdmin = true
  }
  const categoriesData = useSelector(state => state.categoriesData);
  const [categories, setCategories] = useState([])
  const [state, setState] = useState([])
  const [district, setDistrict] = useState([])

  const userdetails = KeycloakService.getUserDetail();
  const isInspector = userdetails?.isInspector;

  useEffect(() => {
    if (isSuperAdmin) {
      setCategories(categoriesData?.categories?.map(cat => { return { id: cat.id, value: cat.name, label: cat.name } }))
    }
  }, [categoriesData])
  const currentPath = isInspector
    ? `/${pathNameCapitalize}/${pathNameCapitalizeInspect}/${routeParams.categoryId}/${routeParams.type}`
    : showBatchesOrLotsForMonitor
    ? `/${pathNameCapitalize}/${props.query['categoryId']}/${routeParams.type}`
    : !isSuperAdmin
    ? `/${pathNameCapitalize}/${routeParams.categoryId}/${routeParams.type}`
    : `/${pathNameCapitalize}/fortification/${routeParams.type}`;
  localStorage.setItem(
    "currentPath",
    currentPath
  );
  const PAGE_HEADER_TITLE =
    // showBatchesOrLotsForMonitor ? '' :
    pathNameCapitalize[0]?.toUpperCase() + pathNameCapitalize.replace('miller', 'miller(FR)').slice(1);
  const PAGE_HEADER_TYPE = 
  // showBatchesOrLotsForMonitor ? '' :
    routeParams.type == "batch" ?
      routeParams.type[0]?.toUpperCase() + routeParams.type.slice(1) + "es" :
      routeParams.type[0]?.toUpperCase() + routeParams.type.slice(1) + "s"
  const [open, setOpen] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [dataMessage, setDataMessage] = React.useState("");
  const [type, setType] = React.useState("");
  const [gridApi, setGridApi] = useState(null);
  const [independentBatch, setIndependentBatch] = useState(false);

  const [updatedColumnDefsLots, setUpdatedColumnDefsLots] = useState([]);
  const gridRef = useRef();
  const [rowData, setRowData] = useState([]);
  const [pageSize, setPageSize] = React.useState(12);
  const [pageNo, setPageNo] = React.useState(1);
  const [count, setCount] = React.useState(1);
  const [onlyStateChange, setOnlyStateChange] = React.useState(true);
  let onlyStateChangeVar = true;
  const [searchValue, setSearchValue] = React.useState("");
  const [stateId, setStateId] = React.useState([]);
  const [manufacturerId, setmanufacturerId] = React.useState([]);
  const [tagetManufacturerId, setTargetManufacturerId] = React.useState([]);
  const [clear, setclear] = useState(false);
  const RowSelectionType = "single";
  const [chip, setChip] = useState("");
  const [options, setOptions] = React.useState([]);
  const [manufactureOptions, setManufacturerOptions] = React.useState([]);
  const [targetmanufactureOptions, setTagretManufacturerOptions] = React.useState([]);
  const [activeFilters, setActiveFilters] = React.useState({});
  const filterDataRedux = useSelector((state) => state.filterData);
  const [chipsState, setChipsState] = React.useState({});
  const [filterStates, setFilterStates] = React.useState([]);
  const [filterManufacturer, setFilterManufacturer] = React.useState([]);
  const [filterTargetManuf, setFilterTargetManuf] = React.useState([]);
  const [statesDefault, setStatesDefault] = React.useState([]);

  const [dashboardCount, setDashBoardCount] = useState(false);
  const [customLocaleText,setCustomLocaleText]=useState({
    noRowsToShow : onlyStateChangeVar?'please do search or apply filters to get batch/lot list': translate('noRowsToShow')

  });

  const lotColumnDefs = LISTING_LOTS_HEADER(translate, independentBatch)
  const batchColumnDefs = LISTING_BATCHES_HEADER(translate)

  const [columnDefsBatches, setColumnDefsBatches] = React.useState(
    batchColumnDefs
  );
  const [columnDefsLots, setColumnDefsLots] =
    React.useState(lotColumnDefs);

  useEffect(()=>{
    setColumnDefsBatches(batchColumnDefs)
    setColumnDefsLots(lotColumnDefs)
  }, [language])

  const [filterData, setFilterData] = React.useState({
    state: [],
    stateId: [],
    expStartDate: "",
    expEndDate: "",
    mnmfEndDate: "",
    mnfStartDate: "",
    manufacturerIds: [],
    targetManufacturerIds: [],
  });
  const previousDataRef = useRef();
  
  useEffect(() => {
    // Update the previousDataRef whenever the data changes
    previousDataRef.current = filterData;

  }, [filterData]);

  const [openFilter, setOpenFilter] = React.useState(false);

  const handleClickOpen = () => {
    setOpenFilter(true);
  };
  const handleClose = (value) => {
    setOpenFilter(false);
  };
  // Function to check if only the "state" property is changed in the searchCriteriaList array
  const isOnlyStateChanged = () => {
    const myObject = filterDataRedux.searchCriteriaList

    myObject.forEach((data) => {
      if (data.key !== "state") {
        const value = data.value;
        if (!(value === null || value === undefined || (Array.isArray(value) && (value.length === 0 || value.includes(null))) || (typeof value === 'string' && value.trim() === ''))) {

          // setOnlyStateChange( false);


        }

      }
    }
    )
  }

  // useEffect=(()=>{
  // isOnlyStateChanged();
  // },[filterDataRedux])


  const setPage = (page) => {
    setPageNo(page);
    fetchBatchOrLotData(page, pageSize);
  };
  const counter = () => {
    return count % pageSize === 0
      ? Math.floor(count / pageSize)
      : Math.floor(count / pageSize) + 1;
  };

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 170,
      maxWidth: 250,
      floatingFilter: false,
      // enableRowGroup: true,
      // enablePivot: true,
      // enableValue: true,
      resizable: true,
    };
  }, []);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let arrFilterStates = [];
  let arr1 = [];

  const handleSelectChange = (values) => {

    if (arrFilterStates.length == 0) {
      values &&
        values.length != 0 &&
        values?.map((value) => {
          arrFilterStates.push(value.id);
          // arr1.push
        });
      setFilterStates(arrFilterStates);
    }

    //  setStatesDefault()
    // setSelected(values);
  };
  useEffect(() => {
    setLoader(true);
    setCount(0);
    setRowData([]);
    setPageNo(1)
    isOnlyStateChanged();
    updateChips();

   isInspector?( !onlyStateChangeVar && fetchBatchOrLotData(1, pageSize)):fetchBatchOrLotData(1, pageSize);
  //  debugger;
   setCustomLocaleText({
    noRowsToShow : onlyStateChangeVar?'please do search or apply filters to get batch/lot list':translate('noRowsToShow')
   });
    setLoader(false);

    fetchStateList();
    fetchDashboardData();
    fetchManufacturers();

    let updatedColumnDefsBatches;

    updatedColumnDefsBatches = batchColumnDefs
    setColumnDefsBatches(updatedColumnDefsBatches)


  }, [filterDataRedux, routeParams]);
  const dateValue = (date) => {
    if (date) {
      return moment.utc(date).local().format("yyyy/MM/DD");
    } else {
      return "";
    }
  };
  const setFilters = (filterData) => {

    var json = filterDataRedux.searchCriteriaList;

    var json1 = structuredClone(json);
    if (filterStates.length != 0) {
      setFilterData({ ...filterData, state: filterStates });
      json1[3].value = filterStates;
      json1[6].value = filterStates;
    }
    else {
      json1[3].value = [null];
      json1[6].value = [null];
    }
    if (filterManufacturer.length != 0) {
      setFilterData({ ...filterData, manufacturerIds: filterManufacturer });
      json1[7].value = filterManufacturer;
    } else {
      json1[7].value = [null];
    }
    if (filterTargetManuf.length != 0) {
      setFilterData({ ...filterData, targetManufacturerIds: filterTargetManuf });
      json1[7].value = filterTargetManuf;
    } else {
      json1[8].value = [null];
    }

    json1[0].value = searchValue;
    json1[1].value = `${dateValue(filterData.mnfStartDate)}${"-"}${dateValue(
      filterData.mnfEndDate
    )}`;
    json1[2].value = `${dateValue(filterData.expStartDate)}${"-"}${dateValue(
      filterData.expEndDate
    )}`;
    dispatch({
      type: "UPDATE_FILTER",
      data: json1,
    });
  };
  const currentActionNew = useSelector(getMenuDetails);
  const location = useLocation();
  // let isIndBatch = false;
  useEffect(() => {
    const loc = location.pathname.split("/")[1];
    if (loc) {
      if(loc === 'raw-material' || loc === 'rice' || loc === 'rice-mix') {
        setUpdatedColumnDefsLots(columnDefsLots.filter(els => els.field !== 'labName' && els.field !== 'labCertificateNumber'));
      }
      else {
        setUpdatedColumnDefsLots(columnDefsLots);
      }
      const isIndBatch = currentActionNew?.filter(
        (d) => d.categoryName.toLowerCase().split(" ").join("-") === loc
      )[0]?.independentBatch;
      // debugger;
      setIndependentBatch(isIndBatch);
    }
  }, [location, currentActionNew, columnDefsLots]);

  useEffect(()=>{
    let updatedColumnDefs;

    updatedColumnDefs = lotColumnDefs
    // .map((item) =>
    //   item.headerName === translate('lotName') ? { ...item, hide: !independentBatch } : (item.headerName === 'Lab' ? { ...item, hide: (independentBatch) } : item)

    // );
    setColumnDefsLots(updatedColumnDefs)
  }, [independentBatch])

  const fetchBatchOrLotData = async (pageNo, pageSize) => {

    if (props.showBatchesOrLotsForMonitor) {

      let fetchData;

      if(props.isLotList){
        fetchData = await getAllLots(
          props.query['districtGeoId'],
          props.query['sourceManufacturerId'],
          props.query['targetManufacturerId'],
          {
            fromDate:props.query['fromDate'],
            toDate:props.query['toDate'],
            categoryId: props.query['categoryId'],
            type: 'country',
            geoId:'1'
          }
        )
        if (fetchData?.data) {
          setRowData(fetchData?.data);
        }

      } else if(props.isBatchList){
        fetchData = await getBatchesByManufacturerForMonitor(
          props.query['categoryId'],
          props.query['fromDate'].toString(),
          props.query['toDate'].toString(),
          props.query['sourceManufacturerId'],
          props.query['cellType']
        )

        if (fetchData?.data?.data) {
          setRowData(fetchData?.data?.data);
        }

      }
    }

    else if (isInspector) {
      const fetchData = await getAllInspectionSubSections(
        routeParams.categoryId,
        filterDataRedux,
        routeParams.type || "batch",
        pageNo,
        pageSize
      );
      if (fetchData?.data?.data) {
        setCount(fetchData?.data?.count);
        setRowData(fetchData?.data?.data);

      }
    }
    else if (!isSuperAdmin) {
      const fetchData = await getAllSubSections(
        routeParams.categoryId,
        filterDataRedux,
        routeParams.type || "batch",
        pageNo,
        pageSize
      );
      if (fetchData?.data?.data) {
        setCount(fetchData?.data?.count);
        setRowData(fetchData?.data?.data);
        // console.log(fetchData.data.data, 'fetchData.data.data')
      }
    } else {
      const fetchData = await getAllLotsOrBatches(filterDataRedux, routeParams.type === 'lot' ? 'lots' : 'batches', pageNo, pageSize);
      if (fetchData?.data?.data) {
        setCount(fetchData?.data?.count);
        setRowData(fetchData?.data?.data);
        // console.log(fetchData.data.data, 'fetchData.data.data')

      }
    }
    setLoader(false);
  };

  // const handleDeleteActiveFilter = async (key) => {
  //   var json = filterDataRedux.searchCriteriaList;
  //   var json1 = structuredClone(json);
  //   if (key === "expStartDate") {
  //     setFilterData({ ...filterData, expStartDate: "" });
  //     json1[2].value = `${" "}${"-"}${dateValue(filterData.expEndDate)}`;
  //     dispatch({
  //       type: "UPDATE_FILTER",
  //       data: json1,
  //     });
  //   } else if (key === "expEndDate") {
  //     setFilterData({ ...filterData, expEndDate: "" });
  //     json1[2].value = `${dateValue(filterData.expStartDate)}${"-"}${" "}`;
  //     dispatch({
  //       type: "UPDATE_FILTER",
  //       data: json1,
  //     });
  //   } else if (key === "mnfStartDate") {
  //     setFilterData({ ...filterData, mnfStartDate: "" });
  //     json1[1].value = `${" "}${"-"}${dateValue(filterData.expEndDate)}`;
  //     dispatch({
  //       type: "UPDATE_FILTER",
  //       data: json1,
  //     });
  //   } else if (key === "mnfEndDate") {
  //     setFilterData({ ...filterData, mnfEndDate: "" });
  //     json1[1].value = `${dateValue(filterData.mnfStartDate)}${"-"}${" "}`;
  //     dispatch({
  //       type: "UPDATE_FILTER",
  //       data: json1,
  //     });
  //   }
  //   fetchBatchOrLotData()
  //   // fetchLotData()
  // };

  function onGridReady(params) {
    setGridApi(params.api);
  }

  const onRowClicked = useCallback(() => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    if(selectedRows.length == 0){
      return
    }
    const categoryId = !isSuperAdmin ? routeParams.type : selectedRows[0].category.id;
    // if (routeParams.categoryId === "1")
    //   navigate(
    //     `/raw-material/${categoryId}/${routeParams.type}/details/${selectedRows[0].id}`
    //   );
    // else {
    const routeToNavigate = !isSuperAdmin ? `${localStorage.getItem("currentPath")}/details/${selectedRows[0]?.id}` : `${localStorage.getItem("currentPath")}/${categoryId}/details/${selectedRows[0]?.id}`

    if(props?.showBatchesOrLotsForMonitor){
      window.open(routeToNavigate,'_blank')
      gridRef.current.api.deselectAll()
//
    } else{
      navigate(
        routeToNavigate
      );
    }




    // }
  }, []);

  const setFiltersSearch = (data) => {
    var json = filterDataRedux.searchCriteriaList;
    var json1 = structuredClone(json);
    json1[0].key = "batchNo";
    if (data == null) {
      json1[0].value = "";
    } else {
      json1[0].value = data.label;
      setChip(data.label);
    }
    dispatch({
      type: "UPDATE_FILTER",
      data: json1,
    });
  };

  const removeSearch = (data) => {
    var json = filterDataRedux.searchCriteriaList;
    var json1 = structuredClone(json);
    if (data != null) {
      json1[0].value = "";
      setChip("");
      setclear(true);
    }

    dispatch({
      type: "UPDATE_FILTER",
      data: json1,
    });
  };

  let chipMap = {};
  const updateChips = () => {
    // console.log(filterDataRedux, "filterdataredux in updatechips")
    filterDataRedux.searchCriteriaList.map((entry) => {
      switch (entry.key) {
        case "batchNo":
          if (entry.value) {

            chipMap["batchNo"] = entry.value;
            setOnlyStateChange(false);
            onlyStateChangeVar = false;
          }


          break;
        case "dateOfManufacture":
          if (entry.value !== "" && entry.value) {
            chipMap["dateOfManufacture"] = entry.value;
            onlyStateChangeVar = false;
          }
          break;
        case "dateOfExpiry":
          if (entry.value !== "" && entry.value) {
            chipMap["dateOfExpiry"] = entry.value;
            onlyStateChangeVar = false;

          }
          break;
        case "stateIds":
          if (entry.value !== "" && entry.value) {
            chipMap["stateIds"] = entry.value;
            if ((entry.value).filter((item) => item != null).length > 0) {
              onlyStateChangeVar = false;

            }

          }

          break;
        case "categoryIds":
          if (entry.value !== "" && entry.value)
            chipMap["categoryIds"] = entry.value;


          break;
        case "state":
          if (entry.value !== "" && entry.value)
            chipMap["state"] = entry.value;


          break;
        case "manufacturerIds":
          if (entry.value !== "" && entry.value) {
            chipMap["manufacturerIds"] = entry.value;
            if ((entry.value).filter((item) => item != null).length > 0) {
              onlyStateChangeVar = false;

            }
            // onlyStateChangeVar=false;

          }



          break;
          case "targetManufacturerIds":
            if (entry.value !== "" && entry.value) {
              chipMap["targetManufacturerIds"] = entry.value;
              if ((entry.value).filter((item) => item != null).length > 0) {
                onlyStateChangeVar = false;
  
              }
              // onlyStateChangeVar=false;
  
            }
  
  
  
            break;

        case "stateData":
          if (entry.value !== "" && entry.value)
            chipMap["stateData"] = entry.value;
          break;
        case "districtData":
          if (entry.value !== "" && entry.value)
            chipMap["districtData"] = entry.value;
          break;
      }
    });
    const isAnythingElseEmpty = Object.keys(chipMap).some((key) => {

      if (key !== "state") {
        if (!(chipMap[key] === null || chipMap[key] === undefined || chipMap[key]?.trim === "" || ((key === "dateOfExpiry" || key === "dateOfManufacture") && chipMap[key] === "-") || (Array.isArray(chipMap[key]) && chipMap[key]?.filter((item) => item != null).length === 0) || (Array.isArray(chipMap[key]) && chipMap[key]?.filter((item) => item != '').length === 0))) {
          return true;

        }
      }
      return false;
    });


    onlyStateChangeVar = !isAnythingElseEmpty;

    setChipsState(chipMap);
  };

  const deleteChip = (chipKey, chipValue) => {

    var json = filterDataRedux.searchCriteriaList;
    var json1 = structuredClone(json);

    if (chipKey === "batchNo") {
      json1[0].value = "";
    }
    if (chipKey === "dateOfManufacture") {
      setFilterData({ ...filterData, mnfStartDate: "", mnfEndDate: "" });

      json1[1].value = "";
    }
    if (chipKey === "dateOfExpiry") {
      setFilterData({ ...filterData, expStartDate: "", expEndDate: "" });

      json1[2].value = "";
    }
    if (chipKey === "stateIds") {
      const index = json1[3].value.indexOf(chipValue);
      if (index > -1) {
        json1[3].value.splice(index, 1);
      }
      setFilterStates(json1[3].value);
    }
    if (chipKey === "categoryIds") {
      const index = json1[5].value.indexOf(chipValue);
      if (index > -1) {
        json1[5].value.splice(index, 1);
      }
      // setFilterStates(json1[3].value);
    }
    if (chipKey === "state") {
      const index = json1[6].value.indexOf(chipValue);
      if (index > -1) {
        json1[6].value.splice(index, 1);
      }
      setFilterStates(json1[6].value);
    }
    if (chipKey === "manufacturerIds") {
      const index = json1[7].value.indexOf(chipValue);
      if (index > -1) {
        json1[7].value.splice(index, 1);
      }
      setFilterManufacturer(json1[7].value);
    }
    if (chipKey === "targetManufacturerIds") {
      const index = json1[8].value.indexOf(chipValue);
      if (index > -1) {
        json1[8].value.splice(index, 1);
      }
      setFilterTargetManuf(json1[8].value);
    }
    if (json1[5].value.length == 0) json1[5].value = [null];
    if (json1[6].value.length == 0) json1[6].value = [null];
    if (json1[3].value.length == 0) json1[3].value = [null];
    if (json1[7].value.length == 0) json1[7].value = [null];
    if (json1[8].value.length == 0) json1[8].value = [null];
    dispatch({
      type: "UPDATE_FILTER",
      data: json1,
    });
  };

  useEffect(() => {
    fetchDashboardData();
  }, [])


  const fetchDashboardData = async () => {
    const dashBoardData = await getDashboardCount();

    dashBoardData && setDashBoardCount(dashBoardData.data)
  }


  const fetchStateList = async () => {
    const stateList = await getStateList(routeParams?.type.toUpperCase());

    const getList = stateList.data.data.map((list) => {
      return {
        value: list.name,
        label: list.displayName,
        id: list.id,
      };
    });
    const getListId = stateList.data.data.map((list) => {
      return {
        id: list.id,
      };
    });
    setOptions(getList);
    setStateId(getListId);
  };
  const getStateData = async () => {
    const StateData = await getState(1);
    StateData && setState(StateData.data.data);
  };


  useEffect(() => {
    getStateData();
  }, [])

  const fetchManufacturers = async () => {
    const list = await manufacturerListFilter();
    const getList = list?.data?.data?.map((list) => {
      return {
        value: list.name,
        label: list.name,
        id: list.id,
      };
    });
    const getListId = list?.data?.data?.map((list) => {
      return {
        id: list.id,
      };
    });
    setManufacturerOptions(getList);
    setmanufacturerId(getListId);
    setTagretManufacturerOptions(getList);
    setTargetManufacturerId(getListId)
  };
  const dashboardStatProps = (a) => {
    if (!a || a.length === 0) {
      return [];
    }

    let filteredData = a.filter((item) => (routeParams.categoryId == item.categoryId && routeParams.type === item.type));
    let dashboardData;
    if (filteredData.length === 0) {

      filteredData = a.filter((item) => (routeParams.categoryId == item.categoryId));
      if (filteredData[0]) {
        dashboardData = JSON.parse(JSON.stringify(filteredData[0]))
        dashboardData.type = "lot"
      }

      // filteredData[0].type="lot"
    }
    else {
      dashboardData = filteredData[0];
    }
    return dashboardData || [];
  }



  return (
    <div>
      {loader ? (
        <Box className="main-content-overlay" sx={{ height: "100vh" }}>
          <CircularProgress />
        </Box>
      ) : (
        <div>
          <Box className="main-content-overlay" style={props?.showBatchesOrLotsForMonitor ? { marginBottom: "-1.5rem", marginTop: '0px' } : {marginBottom: "-1.5rem"}}>
            {DashBoardStats(dashboardStatProps(dashboardCount))}
          </Box>
          <Box className="main-content-overlay">
            <div className="main-content-div" style={props?.showBatchesOrLotsForMonitor ? {marginTop: '0px' } : {}}>
              <Box m={2}>
                <Grid container>



                  {
                    !props.showBatchesOrLotsForMonitor && 
                    <Grid item md={3} sd={12} p={1}>
                    <Typography variant="h6" sx={{ color: MAIN_BLUE, mx: 1 }}>
                      {translate(PAGE_HEADER_TITLE)} {translate(PAGE_HEADER_TYPE)}
                    </Typography>
                  </Grid>
                  }

                  {
                    !props.showBatchesOrLotsForMonitor && 
                  
                  <Grid item md={5}>
                    <Stack direction="row" sx={{ my: 1.5, flexWrap: "wrap" }}>
                      {Object.keys(chipsState).map((key) => (
                        <DeletableChips
                          chipValue={chipsState[key]}
                          chipKey={key}
                          deleteChip={deleteChip}
                          options={options}
                          categories={categories}
                          manufactureOptions={manufactureOptions}
                          targetmanufactureOptions={targetmanufactureOptions}
                          sx={{ maxHeight: "2rem !important" }}
                        />
                      ))}
                      {/*                
                      {[...chipsState.keys()].map(data=>
                       data.map((value)=>(
                        <DeletableChips
                        chipValue={value}
                        chipKey={2}
                        deleteChip={deleteChip}
                        sx={{ maxHeight: "2rem !important" }}
                      />
                       ))
                        
                      )
                 } */}
                    </Stack>
                  </Grid>
                  }
                  


                 {!props.showBatchesOrLotsForMonitor && 
                  <Grid item md={4} sx={{ display: 'flex', alignItems: 'center' }}>
                    <SearchBar
                      onChange={(e) => {
                        if (e !== "") {
                          setFiltersSearch(e);
                        } else {
                          removeSearch();
                        }
                      }}
                    />
                    {((routeParams?.type !== "lot" ||
                      (routeParams?.type === "lot" && independentBatch)) && !isInspector && !isSuperAdmin) && (
                        <CreateButton
                          onClick={() => {
                            routeParams.categoryId === "1"
                              ? navigate(
                                `${localStorage.getItem("currentPath")}/details`
                              )
                              : navigate(
                                `${localStorage.getItem("currentPath")}/details`
                              );
                          }}
                        />
                      )}
                    {openFilter ? (
                      <div>
                        <SimpleDialogDemo
                          chipsState={chipsState}
                          open={openFilter}
                          onClose={handleClose}
                          filterData={filterData}
                          setFilterData={setFilterData}
                          setFilters={setFilters}
                          filterStates={filterStates}
                          setActiveFilters={setActiveFilters}
                          activeFilters={activeFilters}
                          stateId={stateId}
                          setStateId={setStateId}
                          handleSelectChange={handleSelectChange}
                          options={options}
                          fetchStateList={fetchStateList}
                          categories={categories}
                          isInspector={isInspector}
                          manufacturerId={manufacturerId}
                          tagetManufacturerId={tagetManufacturerId}
                          manufactureOptions={manufactureOptions}
                          targetmanufactureOptions={targetmanufactureOptions}
                          isLot={routeParams.type === 'lot'}
                          state={state}
                          district={district}
                          setDistrict={setDistrict}

                        />
                      </div>
                    ) : (
                      <FilterButton handleClickOpen={handleClickOpen} />
                    )}
                  </Grid>
                 }

                </Grid>
                <Box sx={{ m: 1 }}>



                  <GridItem
                    onGridReady={onGridReady}
                    rowData={rowData}
                    gridRef={gridRef}
                    rowSelection={RowSelectionType}
                    columnDefs={
                      routeParams?.type == "lot"
                        ? updatedColumnDefsLots
                        : columnDefsBatches
                    }
                    defaultColDef={defaultColDef}
                    pageSize={pageSize}
                    pageSizeArray={PAGE_SIZE_ARRAY}
                    count={counter()}
                    setPage={setPage}
                    page={pageNo}
                    localeText={isInspector ? customLocaleText : {noRowsToShow : translate('noRowsToShow')}}
                    onSelectionChanged={onRowClicked}
                    height="main"
                    showDownloadButton={true}
                    title={PAGE_HEADER_TITLE + " " +  PAGE_HEADER_TYPE+"_Page"+pageNo+"of"+counter()}//
                    showUiSearchBar={showBatchesOrLotsForMonitor}
                    isBatchList={props.isBatchList}
                    isLotList={props.isLotList}
                    showBatchesOrLotsForMonitor={showBatchesOrLotsForMonitor}
                    query={props.query}
                  />



                </Box>
              </Box>
              <Notification
                open={open}
                type={type}
                message={dataMessage}
                setOpen={setOpen}
              />
            </div>
          </Box>
        </div>
      )}
    </div>
  );
};

export default PremixGrid;
