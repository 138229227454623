import React, { useEffect } from "react";
import Notification from "./components/NotificationComponent";
import { useAppSelector, useAppDispatch } from "./services/StoreHooks";

export default function NotificationSelector({ children }) {
  const notificationStatus = useAppSelector((state) => {
    return state.notificationAlert});
  const dispatch = useAppDispatch();

  useEffect(() => {
  }, [])

  return (
    <>
      <Notification
        open={notificationStatus.open}
        type={notificationStatus.type}
        message={notificationStatus.message}
        setOpen={() => dispatch({type: "SHOW_NOTIFICATION", data: {open: false},})}
      />
      {children}
    </>
  );
}
