export const CLOSE_RED = '#E8EAFA';
export const WHITE_TEXT = '#FFFFFF';
export const MAIN_BLUE = '#0075ff';
export const DARK_BLUE = '#30374C';
export const LIGHT_ORANGE = '#FFA500';
export const DARK_RED = '#C30011';
export const LIGHT_GREY = '#EAEAF0';
export const NAVY_BLUE = '#00008B';
export const SKELETON_FOREGROUND = "#ffffff";
export const SKELETON_BACKGROUND = "#dcddde";
export const MAIN_GREEN = '#00C15D';
export const LIGHT_YELLOW = '#F5BC2F';
export const BORDER_RED = '#FBFBFB';
export const COOL_RED = '#EF4444';