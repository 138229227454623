import * as React from "react";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { MILLER_UI_TEXT } from "../../textConstant";
import useTranslatewrapper from "../useTranslateWrapper";

export default function FilterChips(props) {
    const {translate} = useTranslatewrapper()
    let { chipKey, chipValue, deleteChip, options } = props;

    return (
        <Stack direction="row" spacing={1} style={{ paddingRight: "5px" }}>

            {(chipKey === "categories" || chipKey === "roles" ||chipKey === "status") ? (chipValue.length != 0 && chipValue[0] != null) &&
                chipValue.map((data, id) =>
                    <Chip
                        label={translate(data.replace('MILLER',MILLER_UI_TEXT))}
                        variant="outlined"
                        color="primary"
                        sx={{ height: "1.8rem !important" }}
                        size="small"
                        onDelete={() => deleteChip(chipKey, data)}
                    />
                ) : (chipValue && chipValue !== "" && chipValue !== [null]) &&
            <Chip
                label={translate(chipValue)}
                variant="outlined"
                color="primary"
                sx={{ height: "1.8rem !important" }}
                size="small"
                onDelete={() => deleteChip(chipKey, chipValue)}
            />

            }
        </Stack>
    );
}
