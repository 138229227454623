import React from "react";
import {
  Box, Stack, Modal, Button
} from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import SampleSentToLabForm from "../Sample Sent to Lab/SampleSentToLabForm";
import SelfTestedForm from "../Self Tested/SelfTestedForm";
import "../../../App.css";
import InspectModal from "../Inspect Modal/InspectModal";
import useTranslatewrapper from "../../useTranslateWrapper";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  padding: 2,
  "max-height": "70%",
  "overflow-y": "auto"
};
const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  padding: 2,
};

function ActionsModal(props) {
  const {translate} = useTranslatewrapper()
  const {
    batchId, actions, categoryId,
    batchData, updateActionState,
    showButton, requestBody, lastActionDate, showButtonAction, canInspect,inspectModalData,
    remainingQuantity, inspectButton, getTestResultStatus,showLabDropDown,disbaleVendorData,saveButtonCondition
    } = props;

  const dispatch = useDispatch();
  const currentAction = useSelector((state) => state.actionState);

  const actionNames = actions?.map((action) => action.name);

  const [openSelfTested, setOpenSelfTested] = React.useState(false);
  const [openInspectModal, setOpenInspectModal] = React.useState(false);

  
  const [openGenericDialog, setOpenGenericDialog] = React.useState(false);

  const clickHandler = (action, actionText, display) => {
    dispatchCurrentAction({actionText, action, display});
    if (action === "sentBatchSampleToLabTest") {
      setOpenGenericDialog(true);
    } else if (action === "selfTestedBatch") {
      setOpenSelfTested(true);
    } else if (action === "batchToDispatch") {
      setOpenGenericDialog(true);
      //drop down is required
    } else if (action === "lotReceived") {
      setOpenGenericDialog(true);
    } else if (action === "approved") {
      setOpenGenericDialog(true);
    } else if (action === "toSendBackRejected") {
      setOpenGenericDialog(true);
    } else if (action === "sentLotSampleToLabTest") {
      setOpenGenericDialog(true);
    } else if (action === "selfTestedLot") {
      setOpenSelfTested(true);
    } else if (action === "rejected") {
      setOpenGenericDialog(true);
    } else if (action === "toSendBackRejected") {
      setOpenGenericDialog(true);
    } else if (action === "sentBackRejected") {
      setOpenGenericDialog(true);
    } else if (action === "receivedRejected") {
      setOpenGenericDialog(true);
    }
    else if(action ==="sendSampleForInspection"){
      setOpenInspectModal(true);
    }
  };

  const dispatchCurrentAction = (actionState) => {
    dispatch({
      type: "UPDATE_ACTION_STATE",
      data: {
        title: actionState.actionText,
        action: actionState.action,
        display: actionState.display
      },
    });
  };
  const showInspectButton =()=>{
    if(canInspect && remainingQuantity>0 && inspectButton){
      return true
    }
    else{
      return false
    }
  }
  return (
    <div className="action-buttons-container">
      {showInspectButton() ?
      <>
        <Stack spacing={1} direction="row">
          {
            <Button variant="contained"
            onClick={() => clickHandler("sendSampleForInspection", "Send Sample For Inspection", "Send Sample For Inspection")}
            >

              Send Sample For Inspection
            </Button>
          }
        </Stack>
         <Modal
         open={openInspectModal}
         onClose={() => setOpenInspectModal(false)}
       >
         <Box sx={style} style={{width:"26%"}}>
           <InspectModal
             handleClose={() => setOpenInspectModal(false)}
             updateActionState={updateActionState}
             configuration={actionNames}
             actions={actions}
             batchId={batchId}
             categoryId={categoryId}
             batchData={batchData}
             lastActionDate={lastActionDate}
             inspectModalData={inspectModalData}
            getTestResultStatus={getTestResultStatus}

           />
         </Box>
       </Modal>
      </>

        
        :
        <>
        <Stack spacing={1} direction="row">
          {actions?.map((element, index) => (
            <Button variant="contained" disabled={showButton || showButtonAction || saveButtonCondition }
              onClick={() => clickHandler(element.name, element.actionName, element.displayName)}
              >
              {translate(element.actionName)}
            </Button>
          ))}
        </Stack>

          <Modal
            open={openGenericDialog}
            onClose={() => setOpenGenericDialog(false)}
          >
            <Box sx={style1}>
              <SampleSentToLabForm
                handleClose={() => setOpenGenericDialog(false)}
                updateActionState={updateActionState}
                configuration={actionNames}
                categoryId={categoryId}
                actions={actions}
                batchId={batchId}
                requestBody={requestBody}
                lastActionDate={lastActionDate}
                showLabDropDown={showLabDropDown}
                inspectModalData={inspectModalData}
              />
            </Box>
          </Modal>

          <Modal
            open={openSelfTested}
            onClose={() => setOpenSelfTested(false)}
          >
            <Box sx={style}>
              <SelfTestedForm
                handleClose={() => setOpenSelfTested(false)}
                updateActionState={updateActionState}
                configuration={actionNames}
                actions={actions}
                batchId={batchId}
                categoryId={categoryId}
                batchData={batchData}
                lastActionDate={lastActionDate}
              />
            </Box>
          </Modal>
          <Modal
            open={openInspectModal}
            onClose={() => setOpenInspectModal(false)}
          >
            <Box sx={style}>
              <InspectModal
                handleClose={() => setOpenInspectModal(false)}
                updateActionState={updateActionState}
                configuration={actionNames}
                actions={actions}
                batchId={batchId}
                categoryId={categoryId}
                batchData={batchData}
                lastActionDate={lastActionDate}
                inspectModalData={inspectModalData}
              />
            </Box>
          </Modal>
          </>
      }


      {/* <Modal
        open={openDispatchBatch}
        onClose={() => setOpenDispatchBatch(false)}
      >
        <Box sx={style1}>
          <SampleSentToLabForm
            handleClose={() => setOpenDispatchBatch(false)}
            updateActionState={updateActionState}
            configuration={actionNames}
            // name="batchToDispatch"
            categoryId={categoryId}
            actions={actions}
            batchId={batchId}
          />
        </Box>
      </Modal> */}

      {/* <Modal
        open={openLotReceived}
        onClose={handleCloseLotReceived}
      >
        <Box sx={style1}>
          <SampleSentToLabForm
            handleClose={OnLotReceivedSubmit}
            configuration={actionNames}
            name="lotReceived"
            actions={actions}
            batchId={batchId}
            categoryId={categoryId}
          />
        </Box>
      </Modal>
      <Modal
        open={openGenericDialog}
        onClose={() => setOpenGenericDialog(false)}
      >
        <Box sx={style1}>
          <SampleSentToLabForm
            handleClose={() => setOpenGenericDialog(false)}
            configuration={actionNames}
            // name="approved"
            actions={actions}
            batchId={batchId}
            categoryId={categoryId}
          />
        </Box>
      </Modal>
      <Modal
        open={openGenericDialog}
        onClose={() => setOpenGenericDialog(false)}
      >
        <Box sx={style1}>
          <SampleSentToLabForm
            handleClose={() => setOpenGenericDialog(false)}
            configuration={actionNames}
            // name="sentLotSampleToLabTest"
            formNames="Sent Sample Date"
            actions={actions}
            batchId={batchId}
            categoryId={categoryId}
          />
        </Box>
      </Modal>
      <Modal
        open={openGenericDialog}
        onClose={() => setOpenGenericDialog(false)}
      >
        <Box sx={style1}>
          <SampleSentToLabForm
            handleClose={() => setOpenGenericDialog(false)}
            configuration={actionNames}
            // name="toSendBackRejected"
            formNames="Rejected Sample Date"
            actions={actions}
            batchId={batchId}
            categoryId={categoryId}
          />
        </Box>
      </Modal>
       <Modal
        open={openGenericDialog}
        onClose={() => setOpenGenericDialog(false)}
      >
        <Box sx={style1}>
          <SampleSentToLabForm
            handleClose={() => setOpenGenericDialog(false)}
            configuration={actionNames}
            // name="rejected"
            formNames="Rejected Sample Date"
            actions={actions}
            batchId={batchId}
            categoryId={categoryId}
          />
        </Box>
      </Modal> */}
    </div>
  );
}

export default ActionsModal;
