import React from 'react'

function CategoryNameMapping(props) {
    // debugger;
if(!props){
    return('N/A')
}
    if(props==="PREMIX"){
    return (
        "Premix"
        )
}
if(props==="FRK"){
    return (
        "FRK"
        )
}
if(props==="MILLER"){
    return (
        "Fortified Rice"
        )
}

  
}

export default CategoryNameMapping