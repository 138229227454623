import { Box, Button, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { saveAs } from "file-saver";
import { downloadQRCode } from "../../../services/FortificationService";
import DownloadIcon from "@mui/icons-material/Download";
import { useParams } from "react-router-dom";
import useTranslatewrapper from "../../../components/useTranslateWrapper";

function QrImage({ qrData,props }) {
  const details = useParams();
  const {translate} = useTranslatewrapper()

  const HandleDownloadQr = async () => {
    const qr = await downloadQRCode(
      qrData,
      `FORTIFICATION_${details?.type.toUpperCase()}_QR_CODE`
    );
    qr && saveAs(qr.data, qrData);
  };

  return ((props?.value) ? (
    <Box sx={{ flex: "unset" }}>
      <p style={{ fontSize: "12px" }}>{translate('code')}</p>

      <img
        style={{ width: "80px" }}
        src={props?.value}
        onClick={() => HandleDownloadQr()}
        alt="qr"
      />

      <Tooltip title="Download QR Code" placement="right" arrow>
        <span onClick={() => HandleDownloadQr()}>
          <DownloadIcon />
        </span>
      </Tooltip>
    </Box>
  ) : (
    <div style={{ display: "hidden" }}>
    </div>
  ))
}

export default QrImage;
