import React, { useEffect, useState } from 'react'
import { Form } from '@rjsf/mui'
import validator from "@rjsf/validator-ajv8";
import { getIamDocTypeByID, getDocTypeFormDropdown, adminCategoryDocDropDown, editIamCatDocTypeByID, createIamCatDoc, getIamCategoryDocTypeByID, getFortifiacationDocTypeFormDropdown, getFortificationCategoryDocTypeByID, editFortificationCatDocTypeByID, createFortificationCatDoc } from "../../../../services/adminService.js"
import { Box, Button, Typography } from '@mui/material';
import "../../../../../src/App.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from 'react-router-dom';
import { BACK_BUTTON, MILLER_UI_TEXT } from '../../../../textConstant.js';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../../services/StoreHooks.js';
import "../../../../App.css";
import useTranslatewrapper from '../../../../components/useTranslateWrapper.js';

function CategoryFortificationDoctypeForm() {
  const {translate} = useTranslatewrapper()
  
  const [catDropDown, setCatDropDown] = useState([]);
  const [docDropdown, setDocDropdown] = useState([]);

  const navigate = useNavigate()
  const [formData, setFormData] = useState({});
  const params = useParams()
  const fetchDropdown = async () => {
    const dropdownData = await adminCategoryDocDropDown();
    dropdownData && setCatDropDown(dropdownData.data.data);
  }
  const fetchDocTypeDropdown = async () => {
    const dropdownDocData = await getFortifiacationDocTypeFormDropdown();
    dropdownDocData && setDocDropdown(dropdownDocData.data.data);
  }
  const fetchFormData = async () => {
    const formDataApi = await getFortificationCategoryDocTypeByID(params?.categoryId,params?.id);
    formDataApi && setFormData(formDataApi?.data)
  }
  useEffect(() => {
    fetchDropdown();
    fetchDocTypeDropdown();
  }, [])
  useEffect(() => {
    params.id && fetchFormData();
  }, [params])

  const dispatch = useAppDispatch();
  const dispatchNotification = (typ, msg) => {
      dispatch({
          type: "SHOW_NOTIFICATION",
          data: {
              open: true,
              type: typ,
              message: msg,
          },
      });
  };

  const createForm = async (data) => {
    let Response;
    if (formData.id) {
      Response = await editFortificationCatDocTypeByID(data.categoryId,formData.id, {
        id: Number(formData.id),
        categoryId: data.categoryId,
        docTypeId: data.docType.id,
        isMandatory: data.isMandatory,
        isEnabled:data.isEnabled,
      });
    } else {
      Response = await createFortificationCatDoc(data.categoryId,{
        categoryId: data.categoryId,
        docTypeId: data.docType.id,
        isMandatory: data?.isMandatory ? data.isMandatory:false,
        isEnabled:data?.isEnabled ? data.isEnabled :false,
      });
    }

    if (Response?.status === 201) {
      navigate(-1);
      dispatchNotification("success", ["Created Successfully"]);
  }
  if (Response?.status === 202 || Response?.status === 200) {
      navigate(-1);
      dispatchNotification("success", ["Updated Successfully"]);
  }
  };

  const DocTypeSchema = {
    properties: {
      docType: {
        title: "Doc Name",
        enumNames: docDropdown.map((item) => item?.name),
        enum: docDropdown,
      },

      categoryId: {
        title: translate('category'),
        enumNames: catDropDown.map((item) => item.name.replace('MILLER',MILLER_UI_TEXT)),
        enum: catDropDown?.map((item) => item.id),
      },

      isMandatory: {
        type: "boolean",
        default: "false",
        title: "Required ?",
      },
      isEnabled: {
        type: "boolean",
        default: "false",
        title: "Enabled ?",
      },
    },
    required: ["docType", "categoryId"],
  };
  const CategoryDocTypeUiSchema = {};
  const CategoryDocTypeData = {
    docType: formData?.docType,
    categoryId: formData?.categoryId,
    isMandatory: formData?.isMandatory,
    isEnabled:formData?.isEnabled,
  };
  return (
    <div>
      <div className="main-content-overlay">
      Fortification category doctype
        <Box className="main-content-div" style={{ position: "relative" }}>

          <Box id="VendorDetails" className="form-div ">
            <Box p={2} id="rjsf">
              <Button
                onClick={(e) => {
                  navigate(-1);
                }}
              >
                <ArrowBackIcon />
                <Typography
                  sx={{
                    fontSize: "13px",
                    textDecoration: "underline",
                    fontWeight: "600",
                    fontSize: "12px",
                    lineHeight: "15px",
                    color: "#0075FF",
                  }}
                >
                  {BACK_BUTTON}
                </Typography>
              </Button>
              {catDropDown && <Form
                schema={DocTypeSchema}
                validator={validator}
                uiSchema={CategoryDocTypeUiSchema}
                formData={CategoryDocTypeData}
                className="batch-form-styling"
                onChange={(e) => {
                  console.log("e", e)
                }}
                onSubmit={(e) => {
                  createForm(e.formData);
                }}
              ></Form>
              }

            </Box>
          </Box>
        </Box>
      </div>


    </div>
  )
}

export default CategoryFortificationDoctypeForm