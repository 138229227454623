import React, {
    useEffect,
    useState,
    useRef,
    useCallback,
} from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import "../../../../App.css";
import { CircularProgress, Grid, Typography } from "@mui/material";
import { useSelector, } from "react-redux";
import { useNavigate, } from "react-router-dom";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import _ from "lodash";
import { getLabDocTypeGrid } from "../../../../services/adminService";
import { MAIN_BLUE } from "../../../../color";
import GridItem from "../../../GridItem/GridItem";
import CreateButton from "../../../../components/CommonFilterCreateButton/CreateButton";
import { PAGE_SIZE_ARRAY } from "../../../../constants";
import { MILLER_UI_TEXT } from "../../../../textConstant";
import useTranslatewrapper from "../../../../components/useTranslateWrapper";

const CategoryDocTypeGrid = () => {
    const {translate} = useTranslatewrapper()
    const [loader, setLoader] = React.useState(false);
    const [gridApi, setGridApi] = useState(null);
    const [columnDefsUser, setColumnDefsUser] = React.useState([
        {
        field: "name",
        headerName: translate('name'),
        hide: false,
        filter: "agTextColumnFilter",
        floatingFilterComponentParams: {
            suppressFilterButton: true,
        },
        flex: 2,
        filterParams: {
            buttons: ["apply", "clear"],
        },
        },
        {
            field: "isMandatory",
            headerName: "Is Mandatory",
            hide: false,
            filter: "agTextColumnFilter",
            floatingFilterComponentParams: {
                suppressFilterButton: true,
            },
            flex: 2,
            filterParams: {
                buttons: ["apply", "clear"],
            },
            },
            {
                field: "categoryId",
                headerName: translate('category'),
                hide: false,
                filter: "agTextColumnFilter",
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
                flex: 2,
                filterParams: {
                    buttons: ["apply", "clear"],
                },
                valueFormatter: (params) => {
                    return (params.data?.categoryId?.replace('MILLER',MILLER_UI_TEXT));
                },
                },
                {
                    field: "isEnabled",
                    headerName: "Is Enabled",
                    hide: false,
                    filter: "agTextColumnFilter",
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                    },
                    flex: 2,
                    filterParams: {
                        buttons: ["apply", "clear"],
                    },
                    },    
]);
    const gridRef = useRef();
    const [rowData, setRowData] = useState([]);
    const [pageSize, setPageSize] = React.useState(12);
    const [pageNo, setPageNo] = React.useState(1);
    const [count, setCount] = React.useState(1);
    const RowSelectionType = "single";
    const filterDataRedux = useSelector((state) => state.filterUserData);
    const categoriesRedux = useSelector((state) => state.categoriesData);
    const navigate = useNavigate();

    useEffect(() => {
        setLoader(true);
        fetchIamDocTypeData(pageNo, pageSize);
    }, [filterDataRedux]);

    const setPage = (page) => {
        setPageNo(page);
        fetchIamDocTypeData(page, pageSize);
    };
    const counter = () => {
        return count % pageSize === 0
            ? Math.floor(count / pageSize)
            : Math.floor(count / pageSize) + 1;
    };
    const categoryDocRequirementType = "lab"
    const fetchIamDocTypeData = async (pageNo, pageSize) => {
        const fetchData = await getLabDocTypeGrid(categoryDocRequirementType,pageNo, pageSize);
        const arr = []
        const categoryMap = {}
        categoriesRedux?.categories?.forEach((category)=> {
        
        categoryMap[category.id] = category.name
      });
        if (fetchData?.data?.data) {
            setCount(fetchData?.data?.count);
            fetchData?.data?.data.map((data) => 
            arr.push({
                name : data?.docType?.name,
                id :data?.id ,
                categoryId: categoryMap[data?.categoryId],
                isMandatory: data?.isMandatory,
                isEnabled: data?.isEnabled,
            })
            )
            setRowData(arr)
        }
        setLoader(false)
    };
    function onGridReady(params) {
        setGridApi(params.api);
    }

    const onRowClicked = useCallback(() => {
        const selectedRows = gridRef.current.api.getSelectedRows();
        navigate(`/admin/lab/categorydoc/details/${categoryDocRequirementType}/${selectedRows[0].id}`)

    }, []);

    return (
        <div>
            {loader ? (
                <Box className="main-content-overlay" sx={{ height: "100vh" }}>
                    <CircularProgress />
                </Box>
            ) : (
                <div>
                    <Box className="main-content-overlay">
                        <div className="main-content-div">
                            <Box m={2}>
                                <Grid container>
                                    <Grid item md={3} sd={12} p={1}>
                                        <Typography variant="h6" sx={{ color: MAIN_BLUE, mx: 1 }}>
                                            Lab Documents Requirements 
                                        </Typography>
                                    </Grid>
                                    <Grid item md={4} sx={{ alignItems: 'center', display: 'flex', }}>
                                        <CreateButton
                                            onClick={() => {
                                                navigate(`/admin/lab/categorydoc/details/${categoryDocRequirementType}`)
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Box sx={{ m: 1 }}>
                                    <GridItem
                                        onGridReady={onGridReady}
                                        rowData={rowData}
                                        gridRef={gridRef}
                                        rowSelection={RowSelectionType}
                                        columnDefs={columnDefsUser}
                                        pageSize={pageSize}
                                        pageSizeArray={PAGE_SIZE_ARRAY}
                                        count={counter()}
                                        setPage={setPage}
                                        page={pageNo}
                                        onSelectionChanged={onRowClicked}
                                        height="main"
                                    />
                                </Box>
                            </Box>
                        </div>
                    </Box>
                </div>
            )}
        </div>
    );
};

export default CategoryDocTypeGrid;
