import SearchIcon from "@mui/icons-material/Search";
import { FormControl, InputAdornment, Input, IconButton ,OutlinedInput} from "@mui/material";
import * as React from "react";
import "./search.css"
import useTranslatewrapper from "../useTranslateWrapper";

export default function SearchBar(props) {
  const { translate } = useTranslatewrapper();

  const { onChange } = props;
  const [value, setValue] = React.useState("");

  return (
    <FormControl  variant="outlined" sx={{ minheight: "1rem", width:"80%" ,margin:"8px"}}>
      <OutlinedInput
        id="search-input-box"
        onChange={(e) => {
          setValue(e.target.value);
        }}
        onKeyDown={(e)=> {
          if(e.key === 'Enter'){
            onChange({ label: value });
            setValue("") ;
          } 
        }}
        placeholder={`${translate('search')}...`}
        sx={{padding:"0px 15px" ,borderRadius:"50px"}}
        value={value}
        endAdornment={
          <InputAdornment position="end">
            <IconButton m={1} onClick={() => {onChange({ label: value }) ; setValue("") }} edge="end">
              <SearchIcon sx={{color:"#0075ff"}}/>
            </IconButton>
          </InputAdornment>
        }
         variant="outlined" 
      />
    </FormControl>
  );
}
