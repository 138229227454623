import axios from "axios";
import KeycloakService from "./KeycloakService";
import { useAppDispatch } from "./StoreHooks";
import { useEffect } from 'react'
import { getAxios } from "./NotifcationServices";
import { useNavigate } from "react-router-dom";
const HttpMethods = {
  GET: "GET",
  POST: "POST",
  DELETE: "DELETE",
  PUT: "PUT",
};

const _axios = axios.create();

const configure = () => {
  _axios.interceptors.request.use(async (config) => {
    if (KeycloakService.loggedInThroughKeycloak == 'true') {

      if(KeycloakService.isLoggedIn()){

        
        const cb = () => {
          sessionStorage.setItem(
            process.env.REACT_APP_TOKEN_REFERENCE || "react-token",
            KeycloakService.getToken() || "no-token"
            );
            if (config && config.headers) {
              config.headers.Authorization = `Bearer ${KeycloakService.getToken()}`;
              return Promise.resolve(config);
            }
          };
          const updatedConfig = await KeycloakService.updateToken(cb);
          getAxios()
          //config.headers["Access-Control-Allow-Origin"] = "*";
          return config;
        }
    } else{
      const token  = sessionStorage.getItem('react-token') || ''
      const cb = () => {
        if (config && config.headers) {
          config.headers.Authorization = `Bearer ${token}`;
          return Promise.resolve(config);
        }
      };

      await cb();
      getAxios()
      //config.headers["Access-Control-Allow-Origin"] = "*";
      return config;
    }
  });


};


const AxiosInterceptor = ({ children }) => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const dispatchNotification = (typ, msg) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      data: {
        open: true,
        type: typ,
        message: msg,
      },
    });
  };
  useEffect(() => {

    const resInterceptor = response => {
      if(response.status === 204){
        navigate('/error')
      }
      return response
    };

    const errInterceptor = error => {
      if (error.response.status === 401) {
        KeycloakService.doLogout();
      }else if (error.response.status === 204){
        navigate('/error')
      }else {
        const message = error.response?.data?.message
        if(message){
          dispatchNotification('error', [message]);
        }
        return Promise.reject(error);
      }
    }

    const interceptor = _axios.interceptors.response.use(resInterceptor, errInterceptor);

    return () => _axios.interceptors.response.eject(interceptor);

  }, [])

  return children;
}

const getAxiosClient = () => _axios;

const HttpService = {
  HttpMethods,
  configure,
  getAxiosClient,
};

export default HttpService;
export { AxiosInterceptor }
