const initial = {
    title: "",
    action: "",
    display: ""
};

const stateUpdateFunction = (state = initial, event) => {
  switch (event.type) {
    case "UPDATE_ACTION_STATE":
      return event.data;
    default:
      return state;
  }
};
export default stateUpdateFunction;