import React from "react";
import PackagingInfoGrid from "./PackagingInfoGrid";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/material";


function SorceCategory({
  categoryId,
  showButton,
  setShowButton,
  disableVendorData,
  rowInfoData,
  remainingQuantity,
 refreshPackagingInfo,
 isInspector,
 setIsDispatchDisable,
 disablePackageInfo
}) {
  return (
    <Box   className="form-div sourceCategory">
        <PackagingInfoGrid
          rowInfoData={rowInfoData}
          categoryId={categoryId}
          showButton={showButton}
          setShowButton={setShowButton}
          disableVendorData={disableVendorData}
          remainingQuantity={remainingQuantity}
          refreshPackagingInfo={refreshPackagingInfo}
          isInspector={isInspector}
          setIsDispatchDisable={setIsDispatchDisable}
          disablePackageInfo={disablePackageInfo}
        />
    </Box>
  );
}

export default SorceCategory;
