import React, { useEffect } from "react";
import {
  Drawer,
  AppBar,
  Box
} from "@mui/material";
import MenuListComponent from "../MenuListComponentNew/MenuListComponentNew";
import { WHITE_TEXT } from "../../color";
import { useStyles } from "./InternalStyle";
import ToolbarCompnent from "../Toolbar/ToolbarComponent";

export default function BarsOverlay({menuDetails, isSuperAdmin, isMonitor , isInspector , searchAdmin, language, setLanguage}) {
   const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {}, []);

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        position="fixed"
        sx={{ width: `100%`, background: WHITE_TEXT , borderBottom:"2px solid #0075ff"}}
      >
        <ToolbarCompnent open={mobileOpen} setOpen={setMobileOpen} isSuperAdmin={isSuperAdmin} isMonitor={isMonitor} isInspector={isInspector} language={language} setLanguage={setLanguage} />
      </AppBar>
      {(<><Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { marginTop: "4em", width: isMonitor ? "12%" : "18%" },
          }}
          className={classes.permanentDrawer}
        >
          <MenuListComponent hideMenu={setMobileOpen}  menuDetails={menuDetails} isSuperAdmin = {isSuperAdmin} isMonitor={isMonitor} isInspector={isInspector} searchAdmin={searchAdmin}/>
      </Drawer>
      <Drawer
        sx={{
          display: { xs: 'none', sm: 'block' },
          "& .MuiDrawer-paper": {  marginLeft:"0% !important", width: isMonitor ? "12%" : "18%"}
        }}
        className={classes.permanentDrawer}
        variant="permanent"
        anchor="left"
      >
        <MenuListComponent menuDetails={menuDetails} isSuperAdmin = {isSuperAdmin} isMonitor={isMonitor} isInspector={isInspector} searchAdmin={searchAdmin} />
      </Drawer></>)}
    </Box>
  );
}
