const initial = {
    count: 0
  };
  
  export default function NotificationCountChange (state = initial, event) {
    switch (event.type) {

      case "SHOW_COUNT":
        return {
          ...state,
        count:event.data
        };
      default:
        return state;
    }
  };
  