import React  from 'react';
import {  Chip } from '@mui/material';
import { ChipColorSelector } from "../../utils/ChipColorHandler";

export  const renderNotificationTitleWithChips = (notificationTitle) => {
    const parts = notificationTitle.split(/({[^}]+})/g);
    return (
      <>
        {parts.map((part, index) => {
          if (part.startsWith("{") && part.endsWith("}")) {
            const categoryName = part.substring(1, part.length - 1).trim();
            return (
              <Chip
                key={index}
                label={categoryName}
                size="small"
                sx={{
                  backgroundColor: ChipColorSelector(categoryName),
                  m: 1,
                }}
              />
            );
          }
          return part;
        })}
      </>
    );
  };
