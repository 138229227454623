import { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TextField, IconButton, MenuItem,Button, Select,FormControl,Typography} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { RANGE, REFERENCE_METHOD, RESULT, TEST_METHOD_FOLLOWED, TEST_NAME } from "../../../textConstant";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

function LabTestDataTableEdit({ testDetailsData, updateTableData }) {

  const [temporaryTableData, setTemporaryTableData] = useState(testDetailsData);
  
  const handleValueChange = (event, index, key) => {
    const details = [...testDetailsData];
    details[index][key] = event.target.value;
    
    setTemporaryTableData((item) => details)
    updateTableData((item) => details);
  };

  const addRows = () => {
    const refData = [...testDetailsData];
    refData.push({
      name: "",
      selectionList: [],
      result: "",
      ref:[],
      testMethodFollowed: ""
    });
    setTemporaryTableData((item) => refData)
    updateTableData((item) => refData);
  };

  const removeRows = (index) => {
    let rows = [...testDetailsData];
    if (rows.length > 1) {
      rows.splice(index, 1);
      setTemporaryTableData((item) => rows)
      updateTableData((data) => rows);
    }
  };
  const dropDownArray =(data)=>{
    return  data!= null && 
    data.split(",")
  }
  const handleReferenceSelection = (event, index) => {
    const refData = [...testDetailsData];
    refData[index].reference = event.target.value;
    const selectIndex = refData[index].labTestReferenceMethod.findIndex(
      (selection) => selection.name === event.target.value
    );
    refData[index].labTestReferenceMethodId =
      refData[index].labTestReferenceMethod[selectIndex].id;
    refData[index].uom = refData[index].labTestReferenceMethod[selectIndex].uom;
    refData[
      index
    ].range = refData[index].labTestReferenceMethod[selectIndex].defaultPresent ? refData[index].labTestReferenceMethod[selectIndex].defaultPresent
    :`${refData[index].labTestReferenceMethod[selectIndex].minValue} - ${refData[index].labTestReferenceMethod[selectIndex].maxValue}  ${refData[index].labTestReferenceMethod[selectIndex].uom}` ;
    refData[index].referenceValueList = dropDownArray(refData[index].labTestReferenceMethod[selectIndex].referenceValue)

    setTemporaryTableData((item) => refData);
    updateTableData((data) => refData);
  };

  return (
    <div>
    <TableContainer
      id="table"
      sx={{
        border: "1px solid rgba(224, 224, 224, 1)",
        width: "95%",
        display: "flex",
        backgroundColor: "white",
      }}
    >
      <Table aria-label="simple table">
        <TableHead>
          <TableRow
            sx={{ fontSize: "12px", fontWeight: "bold", color: "#333" }}
          >
            <TableCell align="left">{TEST_NAME}</TableCell>
            <TableCell align="left">{REFERENCE_METHOD}</TableCell>
            <TableCell align="left">{RESULT}</TableCell>
            <TableCell align="left">
              {RANGE}
            </TableCell>
            <TableCell align="left">{TEST_METHOD_FOLLOWED}</TableCell>
          </TableRow>
        </TableHead>
        {
          temporaryTableData && temporaryTableData?.length &&
          <TableBody>
            {temporaryTableData?.map((item, index) => (
              <TableRow sx={{ fontSize: "12px", color: "#333" }}>
                <TableCell>
                  {
                    item.labTestReferenceMethodId ? <>{item.name}{item.isMandatory && "*"}</> :
                      <TextField
                        variant="outlined"
                        size="small"
                        value={item.name}
                        sx={{ width: "20ch" }}
                        // autoFocus={true}
                        onChange={(e) => handleValueChange(e, index, "name")}
                      />
                  }
                </TableCell>
                <TableCell align="left">
                  {item.labTestReferenceMethod?.length > 0 ?
                    (<FormControl fullWidth variant="standard">
                      <Select
                        sx={{
                          fontSize: "12px",
                          color: "black",
                        }}
                        value={item.reference}
                        onChange={(e) => handleReferenceSelection(e, index)}
                      >
                        {item.labTestReferenceMethod.map((menuItem) => (
                          <MenuItem
                            sx={{
                              fontSize: "12px",
                              color: "black",
                            }}
                            key={menuItem.name}
                            value={menuItem.name}
                          >
                            {menuItem.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>) : (<Typography
                      sx={{
                        width: "25ch",
                        fontSize: "12px",
                        color: "black",
                      }}
                    >
                      Not Applicable
                    </Typography>)
                  }

                </TableCell>
                <TableCell align="left">
                 
                  {item.referenceValueList  ?

                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      value={item.result}
                      sx={{ width: "20ch" }}
                      // autoFocus={true}
                      select
                      onChange={(e) => handleValueChange(e, index, "result")}
                    >
                      {Array.isArray(item.referenceValueList) &&
                        item.referenceValueList?.map((data, idx) => {
                          return <MenuItem value={data}> {data}</MenuItem>;
                        })}
                    </TextField>
                    :
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      value={item.result}
                      sx={{ width: "20ch" }}
                      onChange={(e) => handleValueChange(e, index, "result")}
                    />
                  }

                </TableCell>
                <TableCell align="left">
                  {
                    item?.range ? (
                      item?.range ): (
                      <IconButton
                        onClick={() => removeRows(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )
                  }
                </TableCell>
                <TableCell>

                  <TextField
                    variant="outlined"
                    size="small"
                    value={item.testMethodFollowed}
                    sx={{ width: "20ch" }}
                    // autoFocus={true}
                    onChange={(e) => handleValueChange(e, index, "testMethodFollowed")}
                  />

                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        }
      </Table>
    </TableContainer>
    <Button
    onClick={addRows}
    sx={{mt:1, color: "#0075FF !important",width:"11%",textDecoration:"underline",fontSize:"13px"}}
  >
   <AddCircleOutlineIcon/>
    Add Test
  </Button>
  </div>
  );
}

export default LabTestDataTableEdit;
