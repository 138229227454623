import { Box, Modal, Paper, Typography } from "@mui/material";
import React from "react";
import BasicStepper from "../Timeline/BasicStepper";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow:"scroll",
  height:"90%"
};
const StepperModal = (props) => {
  return (
    <Modal
      open={props.batchNo != null}
      onClose={props.closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {props.stepperData == 0 ? (
          <Typography style={{ textAlign: "center" }}>
            No Records Found for {props.batchNo}
          </Typography>
        ) : (
          <>
            <Typography style={{ textAlign: "center" }}>
              Batch History of {props.batchNo}
            </Typography>
            <BasicStepper
              steps={props.stepperData}
              orientation={"vertical"}
            ></BasicStepper>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default StepperModal;