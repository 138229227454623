import React, { useEffect, useState } from 'react';
import { FormControlLabel, Radio, RadioGroup, TextField, FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const RatingTable = ({ key,item,Val,handleSetValue,clicked,toEdit }) => {

  const [value, setValue] = useState(item.value || "");
  const [compliance, setCompliance] = useState(item.compliance || "");
  const handleChange = (event) => {
    item.value  = event.target.value ||"";
    item.compliance = item.type ==='SCORE'? compliance:'C';
    handleSetValue({value:event.target.value,compliance:item.type ==='SCORE'? compliance:'C'})
    setValue(event.target.value);
  };

  return (
    <div style={{height:"60px",marginBottom:"5px",display:"flex",alignItems:"center"}} key={key}>
      {item.type === 'BOOLEAN' && (

        <RadioGroup value={value} 
        required={true} onChange={handleChange} sx={{marginLeft:"10px"}}>
          <FormControlLabel  disabled={!toEdit} value="1"  control={<Radio sx={ (clicked && !(value=="1" || value=="0"))?{ color:'red' }:{}}   />} label={<span style={ (clicked && !(value=="1" || value=="0"))?{ color:'red' }:{}}>Yes</span>} />
          <FormControlLabel disabled={!toEdit} value="0" control={<Radio sx={ (clicked && !(value=="1" || value=="0"))?{ color:'red' }:{}}  />} label={<span style={ (clicked && !(value=="1" || value=="0"))?{ color:'red' }:{}}>No</span>} />
        </RadioGroup>
      )}

      {item.type === 'TEXT' && (
        <TextField value={value} onChange={handleChange}  label="Value"  error={!!value=="" && clicked  }
        // helperText={value === "" ? 'Value cannot be empty!' : ' '} 
        disabled={!toEdit}
        
        required={true} />

      )}

      {item.type === 'SCORE' && (
        <div style={{display:"flex",gap:"20px"}}>

          <FormControl  style={{ width: '150px' }} error={!!compliance=="" && clicked  }
        helperText={value === "" ? 'Value cannot be empty!' : ' '} required={true}>
            <InputLabel sx={{backgroundColor:"#fff",padding:"0px 5px"}}>Compliance</InputLabel>
            <Select value={compliance} 
        disabled={!toEdit}
            // label="Compliance"
            onChange={(e)=>
                (
                setCompliance(e.target.value),
                handleSetValue({value:value ,compliance:item.type ==='SCORE'? e.target.value:'C'})
                

                )
    }>
              <MenuItem value="C">C</MenuItem>
              <MenuItem value="NC">NC</MenuItem>
              <MenuItem value="NA">NA</MenuItem>

            </Select>
          </FormControl>
          <FormControl  style={{ width: '150px' }} error={!!value=="" && clicked  }
        helperText={value === "" ? 'Value cannot be empty!' : ' '} required={true}>
            <InputLabel sx={{backgroundColor:"#fff",padding:"0px 5px"}}>Score</InputLabel>
            <Select value={value} 
        disabled={!toEdit}
            
            onChange={handleChange}>
            {[...Array(item.totalStars+1).keys()]
            .map((item)=>(
              <MenuItem value={String(item)}>{item}</MenuItem>

            ))
            }
            </Select>
          </FormControl>
        </div>
      )}
    </div>
  );
};

export default RatingTable;