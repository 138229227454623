import React, { useState, useRef } from 'react'
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { Box, Rating, Typography, Button, CircularProgress, CircularProgressWithLabel, Modal } from "@mui/material"
import { ITEMS_KEY } from '@rjsf/utils';
import { MAIN_BLUE } from "../../../../color";
import RatingComponent from '../../../../components/RatingComponent/RatingComponent';
import RatingTable from './RatingTable';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "80%",

  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 5,
  maxHeight: "80%",
  overflowY: "auto"
};


const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(1),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));




function ModalEditRating({ data, open, isEdit, handleClose, setManufacturerRatings, setClicked, clicked, editFormRating }) {
  const [expanded, setExpanded] = React.useState('panel');
  const [ratings, setRatings] = useState(data)
  const [value, setValue] = useState();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };


  const handleSetValue = (itemName, newValue) => {
    setRatings(prevData => {
      const updatedData = prevData.map(item => {
        const updatedItems = item.items.map(innerItem => {
          if (innerItem.id === itemName.id) {
            return {
              ...innerItem,
              value: newValue?.value,
              compliance:newValue?.compliance
            };
          }
          return innerItem;
        });
        return {
          ...item,
          items: updatedItems
        };
      });
      return updatedData;
    });
  };





  return (
    <Box
      sx={style}


    >



      <Typography
        align="center"
        mt={2}
        variant="h6"
        sx={{ color: MAIN_BLUE, fontWeight: "600" }}
      >
        {isEdit ? "Edit Ratings" : "Add ratings"}
      </Typography>




      {ratings?.map((item) => (
        <div key={item?.id}>
          <Typography className='text-capitalize mb-2 ' variant='h6'>{item?.categoryName}</Typography>

          <ul style={{ paddingInlineStart: '20px' }}>
            {item?.items?.map((innerItem) => (
              <div id={innerItem.id} style={{ display: "flex", gap: "20px", paddingRight: "20px", alignItems: "center" }}>
                <div style={{ width: '70%' }}>
                  <li>
                    <h6 className='text-capitalize'>{innerItem.name}</h6>

                  </li>

                </div>
                <div style={{ width: '30%' }}>

                  <RatingTable item={innerItem} Val={innerItem.value || ""} handleSetValue={(newValue) => handleSetValue(innerItem, newValue)} clicked={clicked} toEdit={true} />
                </div>

              </div>
            ))}
          </ul>
        </div>
      ))}
      <Typography align='center'>

        <Button 
          variant="contained"
          onClick={() => {
            // setManufacturerRatings(ratings)

            if (ratings.length > 0) {
              editFormRating(ratings)

            }
            setClicked(true)
          }}
        >Submit</Button>
      </Typography>




    </Box>

  )
}

export default ModalEditRating