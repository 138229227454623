export const SUCCESS_KEY = "success";
export const WARNING_KEY = "warning";
export const ERROR_KEY = "error";

export const UserType = {
  ADMIN: "admin",
  MANAGER: "manager",
  HR: "hr",
  EMPLOYEE: "employee",
};
export const IN_TEXT="IN";
export const OUT_TEXT="OUT";

export const LeaveStatusType = {
  PENDING: "Pending",
  ACCEPTED: "Accepted",
  REJECTED: "Rejected",
};
export const SideDrawerHeader = {
  DATE: "Date : ",
  IN_TIME: "In Time : ",
  OUT_TIME: "Out Time : ",
}
export const SummaryHeadings={
  TYPE:"Type",
  HOURS:"Hours"
}
export const CLICK_SUMMRAY_TEXT="Click to view Summary ...";
export const FORM_SUBMISSION_SUCCESS = "Details are successfully submitted";
export const ACTIVE_STATUS = "active";
export const COMPLETED_STATUS = "completed";
export const IMAGE_UPLOAD_ERROR =
  "Something went wrong in image upload, please try again";
export const FILE_SIZE_MSG = "Size: 2MB, pdf.";
export const NUMBER_ONLY_REGEX = /^[0-9\b]+$/;
export const LETTERS_ONLY_REGEX = /^[a-zA-Z \b]+$/;
export const DOB = new RegExp(
  "^(3[01]|[12][0-9]|0[1-9])/(1[0-2]|0[1-9])/[0-9]{4}$"
);
export const PAGE_SIZE_ARRAY = [10, 15, 20, 25, 50, 100];
export const emailValidation = (email) => {
  const regex = new RegExp(
    "([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\t -Z^-~]*])"
  );
  return !(!email || regex.test(email) === false);
};
export const specialCharValidation = (input) => {
  const regex = /^[a-zA-Z \b]+$/;
  return !input || !regex.test(input) === false;
};

export const VERSION = `v1/`
export const USER_PATH = `${VERSION}users`;
export const PROJECT_PATH = `${VERSION}projects`;
export const TASK_PATH = `${VERSION}tasks`;
export const TIMINGS_PATH = `${VERSION}timings`;
export const MANAGER_PATH = `${VERSION}manager`;
export const ADMIN_PATH = `${VERSION}admin`;

export const ACTIVE_PROJECT = "ACTIVE";
export const SELECT_MONTH = "Select Month/Year";

export const NEXT_BUTTON_TEXT = "Next month";
export const PREVIOUS_BUTTON_TEXT = "Previous month";
export const DATEPICKER_MIN_DATE = "2020-01-01";
export const DATEPICKER_MAX_DATE = "2031-01-01";

export const SNACKBAR_400_MSG ="Bad Request – Error 400";
export const SNACKBAR_401_MSG ="HTTP 401 Error – Unauthorized";
export const SNACKBAR_403_MSG ="You are not authorized to view this page";
export const SNACKBAR_404_MSG ="We can’t find the page you’re looking for";
export const SNACKBAR_409_MSG ="Request could not be processed";
export const SNACKBAR_500_MSG ="Internal Server Error";
export const SNCACKBAR_DEFAULT_MSG="Sorry Something went wrong please try again!";
export const SNACKBAR_SUCCESS_MSG ="Successfully Processed Request";
export const SNACKBAR_201_MSG ="Successfully created";
export const SNACKBAR_204_MSG ="Successfully deleted";

export const SNACKBAR_NODATA_MSG ="Sorry No data for this date";
export const TASK_EDIT="TASK_EDIT";
export const TASK_EDIT_CLOSE="TASK_EDIT_CLOSE";
export const TASK_DELETE ="TASK_DELETE";
export const TASK_DELETE_CLOSE="TASK_DELETE_CLOSE";
export const TASK_ROW="TASK_ROW";
export const EXPAND_TEXT="Click To expand";

export const DESCRIPTION_TEXT="Description :-"

export const TaskFieldError={
  NUMBER_GREATER_THAN:"Number of hours can not be greater than ",
  REQUIRED:"*Required"

}
export const TasksText ={
  TASK:"Tasks",
  APPRAISAL:"Mark For Appraisal",
  PROJECT:"Project",
  TASK_TYPE:"Task Type",
  DURATION:"Duration",
  TASK_TILE:"Task Title",
  TASK_DESCRIPTION:"Task Description",
  WFH:"Work From Home",
  WFO:"Office Hours",
  EXTERNAL:"External Hours",
  APPRIASAL_STATUS:"Is Appraisable",
  DATE:"Date",
  ADD_TASK:"Add Task",
  NO_TASK:"No Tasks..."
  
}
export const CONTEST_ABOUT_EMPLOYER = "991289466351534080";
export const ALL_KEY = "All";
export const COLUMNS_TEXT = "Columns";


export const CONTEST_DETAILS = "991273137863147520";
export const CONFIRMATION_BOX_BUTTON = ["Yes", "Cancel"];
export const DRAFT_STATUS = "draft";
export const HOLD_STATUS = "hold";
export const COLLECTION_STATUS = "Collection";
export const CONTEST_PROCESS_ID = "Process_6oen7t6";
export const EMPLOYER_PROCESS_ID = "Process_66hru2c";
export const BROADCAST_PROCESS_ID = "Process_9fcw6s1";
export const BROADCAST_UPLOAD_FORM = "1019885401876742144";
export const BROADCAST_MESSAGES = "/form-runtime/v1/form-data?formId=";
export const RECRUITMENT_COMPANY_PROCESS_ID = "Process_vd0s71b";
export const FREELANCE_PROCESS_ID = "Process_f5ja9ue";

export const DELETE_DOCUMENT = "/dms/v1/documents?id=";
export const DOWNLOAD_DOCUMENT = "/dms/v1/documents/download?id=";

export const RECRUITMENT_COMPANY = "Recruitment Company";
export const FREELANCE_RECRUITER = "Freelance Recruiter";

export const GET_CONTEST_DETAILS = "/form-runtime/v1/form-data?filter=id:";
export const PATCH_CONTEST_DETAILS = "/form-runtime/v1/form-data";
export const FILTER_CONTEST_DETAILS_RELATION =
  "/form-runtime/v1/form-data?relations=";

export const CONTESTSETTINGS_EDIT = "999548019411533824";
export const GET_CONTEST_SETTINGS =
  "/form-runtime/v1/form-data?filter=formData.contestId:";
export const HH_NOTIFICATIONS = "1006209950805409792";
export const HH_VISA_PASSPORT = "1008996873286467584";
export const HOLD_ACTION = "hold";
export const UNHOLD_ACTION = "activate";

export const PROCEED_BTN_TEXT = "Proceed";

export const NEW_SUB_STATUS = "new";
export const DRAFT_GROUPBY = "currentDraftStep";
export const GENERIC_GROUPBY = "subStatus";
export const COUNT_OPERATION = "count";
export const TIMEZONE_SUFFIX = "%2B05:30";
export const BACK_BTN_TEXT = "Go Back";
export const INTERNAL_GROUP = "HH_COMPANY_RECRUITER";
export const FREELANCE_GROUP = "HH_FREELANCE_RECRUITER";
export const TEMPLATE_TITLE = "Questions From Templates";
export const CREATE_CONTEST_TEXT = "Create New Contest";
export const CONTEST_LIST_PAGE_TITLE = "Contest Listing Page";
export const FAQ_ALL_CATEGORY_BUTTON = "Add Questions From All Categories";
export const BOOKMARK_STATUS = "Bookmarked";
export const ARCHIVED_STATUS = "Archived";

export const FAQ_SELECTED_QUESTIONS = "Add Selected Questions";
export const CONTEST_STATUS_LIST = [
  { name: "Pre mix", status: "" },
  // { name: "Collection", status: COLLECTION_STATUS },
  { name: "Frk", status: ACTIVE_STATUS },
  { name: "Miller", status: HOLD_STATUS },
  // { name: "Completed", status: COMPLETED_STATUS },
  { name: "Labs", status: BOOKMARK_STATUS },
  // { name: "Archived", status: ARCHIVED_STATUS },
  // { name: "Drafts", status: DRAFT_STATUS },
];

export const CONTEST_SUB_STATUS = [
  { id: "submission", label: "Submission" },
  { id: "shortlisting", label: "Shortlisting" },
  { id: "evaluation", label: "Evaluation" },
  { id: "phasehr", label: "Phase - HR" },
  { id: "offerrolled", label: "Offer Rolled" },
];

export const CONTEST_DRAFT_LIST = [
  { id: "1", label: "Step 1", tooltip: "Details" },
  { id: "2", label: "Step 2", tooltip: "Job Description" },
  { id: "3", label: "Step 3", tooltip: "About Employer" },
  { id: "4", label: "Step 4", tooltip: "Partner" },
  { id: "5", label: "Step 5", tooltip: "Rewards" },
  { id: "6", label: "Step 6", tooltip: "FAQ" },
  { id: "7", label: "Step 7", tooltip: "T&C" },
];


// export interface FaqReferenceData {
//   answer: string;
//   category: string;
//   checked: boolean;
//   createdById: string;
//   createdByName: string;
//   createdOn: string;
//   dropdown: boolean;
//   formMetadata: any;
//   id: string;
//   question: string;
//   relatedTo: string;
//   updatedById: string;
//   updatedByName: string;
//   updatedOn: string;
//   version: string;
// }
// export interface ReferenceData {
//   type: string;
//   displayText: string;
// }

export const CONFIRMATION_BOX_HOLD={
  title: "Hold",
  message: "Are you sure to put the contest on hold ?",
  multiple: "Are you sure to put multiple contests on hold ?",
};
export const CONFIRMATION_BOX_UNHOLD = {
  title: "Unhold",
  message: "Are you sure to unhold the contest?",
  multiple: "Are you sure to unhold these multiple contests?",
};
export const FAQ_TEMPLATE_DATA = "997375789751615488";
export  const SOMETHING_WENT_WRONG = "Opps! something went wrong"
export const LOGIN_PATH_USING_TOKEN = '/auth/redirect'