import useTranslatewrapper from "../../../../../components/useTranslateWrapper";

export default function useUserDetailsEditSchemaFn() {
  const { translate } = useTranslatewrapper();
  return {
    properties: {
      FirstName: {
        type: "string",
        title: translate('firstName'),
      },
      LastName: {
        type: "string",
        title: translate('lastName'),
      },
      User: {
        type: "string",
        title: "User",
      },
      RoleCategory: {
        type: "string",
        title: "Role category",
      },
      status: {
        type: "string",
        title: translate('status'),
      },
    },
    required: ["FirstName", "LastName"],
  };
}
