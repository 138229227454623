import React from "react";
import { 
  CircularProgress,
  LinearProgress,
  Stack
} from "@mui/material";

const LoaderComponent = (props) => {

    const { loaderType } = props;

    switch(loaderType) {
        case 'linear':
          return <LinearProgress />
        case 'circular':
          return (
            <Stack alignItems="center">
                <CircularProgress />
            </Stack>
          )
        case 'spinner':
          return (
            <>
            <Stack alignItems="center" className="spinner">
                <CircularProgress />
                <div>Please Wait</div>
            </Stack>
            </>
          )
        default:
          return <></>
    }
};

export default LoaderComponent;