import React, { useState } from "react";
import { checkEmail, checkUserName } from "../../../services/Manage_service";
import {
  Box,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
} from "@mui/material";
import { useParams } from "react-router-dom";
import useTranslatewrapper from "../../../components/useTranslateWrapper";

function UserExistCheck(props) {
  const { translate } = useTranslatewrapper();
          const { setUserNameData, autoPopulateData, setEmailData,setUserValidation } = props;
  const [userName, setUserName] = useState(autoPopulateData.UserName);
  const [emailId, setEmailId] = useState(autoPopulateData.EmailId);
  const params = useParams();

  let msg = "";

  const focusOutHandlerEmail = async () => {
    const emailExpression =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!emailId.match(emailExpression)) {
      document.getElementById("email-validate-message").innerHTML =
        "Invalid Email Id ";
      document.getElementById("email-validate-message").style.color = "red";
    } else {
      if (emailId !== autoPopulateData.EmailId) {
        const checkUserDetails = await checkEmail(emailId);
        if (checkUserDetails.data === true) {
          document.getElementById("email-validate-message").innerHTML =
            "Email Id already exists";
          document.getElementById("email-validate-message").style.color = "red";
          setUserValidation(true)

        } else {
          document.getElementById("email-validate-message").innerHTML = "";
          setEmailData(emailId);
        }
      } else {
        setEmailData(autoPopulateData.EmailId);
      }
    }
  };

  const focusOutHandlerUserName = async () => {
    if (userName.trim().length === 0) {
      document.getElementById("userName-validate-message").innerHTML =
        "Username can not be Empty";
      document.getElementById("userName-validate-message").style.color = "red";
    } else {
      if (userName !== autoPopulateData.UserName) {
        const checkUserDetails = await checkUserName(userName);
        if (checkUserDetails.data === true) {
          document.getElementById("userName-validate-message").innerHTML =
            "Username already exists";
          document.getElementById("userName-validate-message").style.color =
            "red";
          setUserValidation(true)

        } else {
          document.getElementById("userName-validate-message").innerHTML = "";
          setUserNameData(userName);
        }
      } else {
        setUserNameData(autoPopulateData.UserName);
      }
    }
  };

  return (
    <Box sx={{ display: "flex", gap: "2.5%", width: "50vw" }}>
      <FormControl sx={{ width: "46.5%" }} variant="outlined">
        <InputLabel htmlFor="outlined-adornment-password">{translate('userName')}*</InputLabel>
        <OutlinedInput
          onBlur={() => {
            focusOutHandlerUserName();
          }}
          id="outlined-adornment-password"
          type="text"
          required
          disabled={params?.id ? true: false}
          label={translate('userName')}
          value={userName}
          onChange={(e) => {
            setUserName(e.target.value);
          }}
        />
        <FormHelperText id="userName-validate-message">{msg}</FormHelperText>
      </FormControl>

      <FormControl sx={{ width: "46.5%" }} variant="outlined">
        <InputLabel htmlFor="outlined-adornment-password">{translate('emailId')}*</InputLabel>
        <OutlinedInput
          onBlur={() => {
            focusOutHandlerEmail();
          }}
          id="outlined-adornment-password"
          type="text"
          label={translate('emailId')}
          required
          disabled={params?.id ? true: false}
          value={emailId}
          onChange={(e) => {
            setEmailId(e.target.value);
          }}
        />
        <FormHelperText id="email-validate-message">{msg}</FormHelperText>
      </FormControl>
    </Box>
  );
}

export default UserExistCheck;
