
import * as React from "react";
import AddIcon from '@mui/icons-material/Add';
import { Button } from "@mui/material";
import { buttonStyles1 } from "./buttonstyle";

export default function AddButton(props) {
  const { onClick ,text ,disabled} = props;
  return (
    <>
      {
        <Button
          variant="contained"
          style={buttonStyles1}
          onClick={onClick}
          disabled={disabled}
        >
          {text}
          <AddIcon />
        </Button>
      }
    </>
  );
}