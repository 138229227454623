import React, { useState, useEffect } from "react";
import {
  updateBatchStatus,
  UpdateLabSamples,
} from "../../../services/FortificationService";
import { useNavigate } from "react-router-dom";
import LoaderComponent from "../../../components/Loader/LoaderComponent";
import { selfTestedMethods } from "../../../services/LabService";
import LabTestDataTableEdit from "./LabTestTable";
import GetAllFiles from "../../../pages/Form/GetAllFiles/GetAllFiles";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "@mui/material/TextField";
import DatePicker from "react-datepicker";
import { Box, Button, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { groupBy } from "lodash";
import { ACTION_CONFIRMATION_MESSAGES } from "../GenericForm/ActionModalConstants";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../src/services/StoreHooks";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import moment from "moment";
import useTranslatewrapper from "../../useTranslateWrapper";

function SelfTestedForm(props) {
  const {translate} = useTranslatewrapper()

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentAction = useSelector((state) => state.actionState);

  const {
    updateActionState,
    handleClose,
    batchData,
    configuration,
    categoryId,
    actions,
    batchId,
    lastActionDate
  } = props;
  const getId = useParams();

  const [tableData, setTableData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [fileBody, setFileBody] = React.useState([]);
  const [removedNames, setRemovedNames] = useState([]);
  const [deleteFiles, setDeleteFiles] = React.useState([]);
  const [fileUploadValidated, setFileUploadValidated] = useState(false);
  const [tableDataValidated, setTableDataValidated] = useState(false);
  const [createFileUploadFields, setCreateFileUploadFields] = React.useState(
    []
  );
  const [selfTestDate, setSelfTestDate] = React.useState(moment().local());
  const [open, setOpen] = React.useState(false);

  const dispatchNotification = (typ, msg) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      data: {
        open: true,
        type: typ,
        message: msg,
      },
    });
  };

  const dispatchShowLoader = (showLoader) => {
    dispatch({
      type: "SHOW_LOADER",
      data: {
        showLoader
      },
    });
  };

let newArr;
  const fileUploadValidation = () => {
    let arrId = [];

    

    let fileUploadData = removeDeletedFiles();
    createFileUploadFields?.map((data) => {
      if (data.isMandatory == true || data.isMandatory == null) {
        if (
          fileUploadData.findIndex(
            (elem) => data.id === elem.categoryDocId && elem.path !== ""
          ) !== -1
        ) {
          arrId.push({
            name: data.name,
            value: true,
          });
        } else {
          arrId.push({
            name: data.name,
            value: false,
          });
        }
      }
    });

    newArr = arrId.filter((ele) => ele.value == false);
    if (arrId.length == 0 || newArr.length == 0) {
      setFileUploadValidated(true);
      return true;
    } else {
      return false;
    }
  };

  const handleClickOpenDialog = () => {
    let arrErr=[]
    if (selfTestDate == "") {
      arrErr.push("Date is not selected")
    } else if (!fileUploadValidation()) {
      newArr.map((ele) => arrErr.push(`${ele.name} is not selected`));
     // alert("NO docs");
    } else if(!validateTableData()){

      arrVal.map((ele) => 
      (
        (ele.value=="Result Invalid") ? arrErr.push(`${ele.name}: Result cannot be negative`) :
      arrErr.push(`${ele.name}:${ele.value} is not selected`)
      )
      );
    }

    else{
      setOpen(true);
    }
    let msg = "";
    arrErr.map((err) => (msg += err + "\n"));
    
    arrErr.length >0 && dispatchNotification("error", arrErr);
  };

  const handleClickCloseDialog = () => {
    setOpen(false);
  };

  useEffect(() => {
  
    setLoader(true);
    getSelfTested();
  }, []);
  const getSelfTested = async () => {
    const apiResponse = await selfTestedMethods(categoryId);
    const labMethod = groupBy(apiResponse?.data?.labTestReferenceMethods, labMethod => {
      return labMethod.labTestType.id
      });
    const array = Object.values(labMethod).map(data=> {
    return {refs: data,...data[0], reference: data[0].name}}
    );
    const selfTests = array.map(
      (item) => {
        return {
          name: item.labTestType.name,
          isMandatory: item.labTestType.isMandatory,
          result: item.result,
          referenceValueList:item.defaultPresent == null ? null : item?.referenceValue?.split(","),
          range:
            item.minValue != null && item.maxValue !=null
              ? `${item.minValue}-${item.maxValue} ${item.uom}`
              : item.minValue == null && item.maxValue == null && item.defaultPresent == null
              ? '-'
              : item.defaultPresent,
          labTestReferenceMethod: item.refs,
          reference:item.name,
          testMethodFollowed: item.testMethodFollowed,
          labTestReferenceMethodId: item.id,
          uom: item.uom,
        };
      }
    );
    const documents = apiResponse?.data?.categoryDocumentRequirements?.map(
      (item) => {
        return {
          name: item.docType?.name,
          id: item?.id,
          isMandatory: item.isMandatory,
        };
      }
    );
    setTableData(() => selfTests);
    setCreateFileUploadFields(() => documents);
    setLoader(false);
  };

  const updateStateStatus = async (result) => {
    const currentPayload = {
      stateId: actions.find((element) => element.name === currentAction.action)
        .id,
      comments: "comments",
      dateOfAction: moment(selfTestDate.toString()).format('yyyy-MM-DD'),
      currentAction,
    };
    getId.type === "batch" && (currentPayload["batchId"] = Number(getId.id));
    getId.type === "lot" && (currentPayload["lotId"] = Number(getId.id));
    const res = await updateActionState(currentPayload, result);
    dispatchShowLoader(false);

    if (res?.data) {
      handleClose(false);
      // if (categoryId === "1")
      //   navigate(`/raw-material/${categoryId}/${getId.type}`);
      // else if (categoryId === "2")
      //   navigate(`/premix/${categoryId}/${getId.type}`);
      // else {
      //   navigate(`/frk/${categoryId}/${getId.type}`);
      // }
    }
  };
  // const message="Batch as Self Tested Batch?"


  useEffect(() => {}, [fileUploadValidated]);

  let arrVal = [];
  const validateTableData = () => {
    arrVal = []
    tableData.map((item) => {
      if(item.isMandatory){
        if (!item.result) {
          arrVal.push({
            name: item.name,
            value: "Result",
          });
        }
        if(item.result && !isNaN(parseFloat(item.result.trim())) && parseFloat(item.result.trim()) <0){
          arrVal.push({
            name: item.name,
            value: "Result Invalid",
          });
        }
        // if (!item.testMethodFollowed) {
        //   arrVal.push({
        //     name: item.labTestReferenceMethod,
        //     value: "Test Method Followed",
        //   });
        // }
      }
    });

    if (arrVal.length === 0) {
      setTableDataValidated(true);
      return true;
    } else {
      setTableDataValidated(false);
      return false;
    }
  };

  const sendFormData = async (e) => {
    
    const data = tableData.map((item) => {
      let maxValue = null
      let minValue = null;
      let referenceMethod = null;
      let defaultPresent = null;
      referenceMethod = item.name ? (item.name) : null;
      
      item?.labTestReferenceMethod?.forEach((method) => {
        if(method.id == item.labTestReferenceMethodId){
          maxValue = (method.maxValue != null) ? (method.maxValue) : null ;
          minValue = method.minValue!= null ? (method.minValue) : null ;
          defaultPresent = method.defaultPresent !=null ? (method.defaultPresent) : null
        }
      })

      return {
        labTestReferenceMethodId: item.labTestReferenceMethodId,
        testName: item.reference ? item.reference :item.name,
        defaultPresent: item.defaultPresent,
        value: item.result,
        testDate: moment(selfTestDate.toString()).format('yyyy-MM-DD'),
        performedBy: "",
        requestStateId: 0,
        uom: item.uom,
        testMethodFollowed: item.testMethodFollowed,
        maxValue: maxValue,
        minValue: minValue,
        referenceMethod : referenceMethod,
        defaultPresent : defaultPresent
      };
    });
    
    const LabData = {
    
      batchNo: batchData?.BatchNoOfPremixManufracturer || batchData.batchNo,
      testDate: moment(selfTestDate.toString()).format('yyyy-MM-DD'),
      sampleSentDate: batchData?.sampleSentDate || "",
      receivedDate: batchData?.receivedDate || "",
      performedBy: "",
      labId: 17,
      requestStatusId: 0,
      categoryId,
      sampleProperties: batchData?.sampleProperties,
      labTests: data,
      sampleTestDocuments: removeDeletedFiles(),
      stateId: 3,
      manufacturerId: batchData.manufacturerId || batchData.targetManufacturerId,
      lotNo: batchData.lotNo
    };
    getId.type === "batch" && (LabData["batchId"] = Number(getId.id));
    getId.type === "lot" && (LabData["lotId"] = Number(getId.id));

    const response = await UpdateLabSamples(LabData);
    if (response?.status === 201) {
      updateStateStatus(response.data.result);
      handleClickCloseDialog();
    }
  };

  const removeDeletedFiles = () => {
    let tempFileBody = [...fileBody];
    removedNames.forEach((removedName) => {
      const deleteFileIndexesArray = fileBody.filter(
        (file) => !file?.id && file.name === removedName
      );
      deleteFileIndexesArray.forEach((deleteItem) => {
        const deleteFileIndex = fileBody.findIndex(
          (file) => file.name === deleteItem.name
        );
        if (deleteFileIndex !== -1) {
          tempFileBody.splice(deleteFileIndex, 1);
        }
      });
    });
    deleteFiles.forEach((deleteItem) => {
      const deleteFileIndex = fileBody.findIndex(
        (file) =>
          file.path === deleteItem.path &&
          file.name === deleteItem.name &&
          file.categoryDocId === deleteItem.categoryDocId
      );
      if (deleteFileIndex !== -1) {
        tempFileBody.splice(deleteFileIndex, 1);
      }
    });
    return tempFileBody;
  };
  const handleClickYes = () => {

    dispatchShowLoader(true);
    sendFormData();
  };

  return (
    <div className="modalForm">
      {loader ? (
        <LoaderComponent />
      ) : (
        <div>
          <div className="self-test-picker">
            <Typography>Self Test Date</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDatePicker
                  value={selfTestDate}
                  disableFuture="true"
                  minDate={new Date(lastActionDate)}
                  
                  inputFormat="DD/MM/YYYY"
                  renderInput={(params) => <TextField {...params}  inputProps={{...params.inputProps, readOnly: true}} />}
                  onChange={(date)=>{
                    
                    setSelfTestDate(date)
                  }}
                  className="input-date-picker"
                  sx={{ mb: "1", mt: "1",width: "97%" }}
                />
              </LocalizationProvider>
            {/* <DatePicker
              selected={selfTestDate}
              dateFormat="dd/MM/yyyy"
              onChange={(date) => setSelfTestDate(date)}
              placeholderText="Self Tested Date"
            /> */}
          </div>
          <LabTestDataTableEdit
            testDetailsData={tableData}
            updateTableData={setTableData}
          />
          <GetAllFiles
            createFileUploadFields={createFileUploadFields}
            getFileData={setFileBody}
            handleFileDelete={setDeleteFiles}
            setRemovedNames={setRemovedNames}
            getId={getId}
          />
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button variant="contained" onClick={handleClickOpenDialog}>
              Self Certified
            </Button>
          </Box>
          <div>
            <Dialog
              open={open}
              onClose={handleClickCloseDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
              {translate('confirmationMsg')}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to{" "}{ACTION_CONFIRMATION_MESSAGES[currentAction.action]}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClickCloseDialog} variant="contained" sx={{ backgroundColor: 'grey' }}>
                {translate('no')}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  onClick={handleClickYes}
                  autoFocus
                >
                  {translate('yes')}
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      )}
    </div>
  );
}

export default SelfTestedForm;
