import * as React from "react";
import ListItemIcon from "@mui/material/ListItemIcon";
export default function FileIcon({ filename, iconOnly }) {

  return (
    <>
      {`${filename}`.includes('.pdf') ? (
        <ListItemIcon sx={{ p: 0, minWidth: "0px !important", m: 1 }}>
          <img src={`/assets/images/pdf.png`} alt="logo2" width={15} height={15} />
        </ListItemIcon>
      ) : `${filename}`.includes('.doc') || `${filename}`.includes('.docx') ? (
        <ListItemIcon sx={{ p: 0, minWidth: "0px !important", m: 1 }}>
          <img src={`/assets/images/docs.png`} alt="logo2" width={15} height={15} />
        </ListItemIcon>
      ) : `${filename}`.includes('.xml') || `${filename}`.includes('.xlsx') || `${filename}`.includes('.xls') ? (
        <ListItemIcon sx={{ p: 0, minWidth: "0px !important", m: 1 }}>
          <img src={`/assets/images/xml.png`} alt="logo2" width={15} height={15} />
        </ListItemIcon>
      ) : (
        <ListItemIcon sx={{ p: 0, minWidth: "0px !important", m: 1 }}>
          <img src={`/assets/images/docs.png`} alt="logo2" width={15} height={15} />
        </ListItemIcon>
      )}

    </>
  );
}

