import * as React from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import {SOMETHING_WENT_WRONG} from '../constants'

const Alert = React.forwardRef(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Notification({ open, type, duration, message, setOpen }) {
  
  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      {(type) ? 
     ( <Snackbar
        open={open}
        bodyStyle={{ height: 'auto', lineHeight: '28px', padding: 24, whiteSpace: 'pre-line' }} 
        autoHideDuration={duration || 3000}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity={type} sx={{ width: "100%" }} onClose={() => setOpen(false)}>
          {(message && message?.length!=0) ? message?.map((data)=><li>{data}</li>): SOMETHING_WENT_WRONG}
        </Alert>
      </Snackbar>): ''}
    </Stack>
  );
}
