import React, { useState, useEffect } from "react";
import { TextField, MenuItem } from "@mui/material";
import { useLocation } from "react-router-dom";
import {
  getCountry,
  getDistrict,
  getState,
  getVillage,
  createVillage,
  getLabCountry,
  getLabDistrict,
  getLabState,
  getLabVillage,
  createLabVillage,
} from "../../../../services/adminService.js";
import useTranslatewrapper from "../../../../components/useTranslateWrapper.js";

function AddressWidget({ addressData, setAddressData, hidelatlong = false, setIsValidPinCode, showMobileNumber = false, setIsMobileNumberValid }) {
  const {translate} = useTranslatewrapper()
  const location=useLocation();
  const pageHeading=location.pathname.split("/")[2]==="lab";

    const getCountryData = async () => {
        const countryData = pageHeading? await getLabCountry(): await getCountry();
        // const countryData =await getCountry();
        countryData && setCountry(countryData.data.data);
      };
      const getStateData = async (id) => {
        if(id !== 'other'){
          const StateData = pageHeading? await getLabState(id): await getState(id);
          StateData && setState(StateData.data.data);
        }else{
          setState([]);
        }
      };
    
      const getDistrictData = async (id) => {
        const DistrictData = pageHeading? await getLabDistrict(id): await getDistrict(id);
        DistrictData && setDistrict(DistrictData.data.data);
      };
    
      const getVillageData = async (id) => {
        const VillageData = pageHeading? await getLabVillage(id): await getVillage(id);
        VillageData && setVillage(VillageData.data.data);
      };


  const [country, setCountry] = useState([]);
  const [countryVal, setCountryVal] = useState(()=>{ addressData?.village?.district?.state?.country?.id && getStateData(addressData?.village?.district?.state?.country?.id); return addressData?.village?.district?.state?.country?.id || ''});
  const [otherCountryVal, setOtherCountryVal] = useState(null);
  const [isOtherCountry, setOtherCountry]= useState(false);
  const [addressVal, setAddressVal] = useState(
    {
      ...addressData,
      villageName: addressData?.village?.name,
      districtId: addressData?.village?.district?.id,
    } || {}
  );
  const [pinCodeError, setPinCodeError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [state, setState] = useState([]);
  const [stateVal, setStateVal] = useState(()=> {addressData?.village?.district?.state?.id && getDistrictData(addressData?.village?.district?.state?.id); return addressData?.village?.district?.state?.id || ''});

  const [district, setDistrict] = useState([]);
  const [districtVal, setDistrictVal] = useState(addressData?.village?.district?.id || '');

  const [village, setVillage] = useState("");
  const [villageVal, setVillageVal] = useState({});
  const [laneOneVal, setLaneOneVal] = useState({
    laneOne: "string",
    laneTwo: "string",
  });
  const [laneTwoVal, setLaneTwoVal] = useState("");
  const [pinCode, setPinCode] = useState("");

  
  const createVillageData = async (data) => {
    const VillageData = pageHeading? await createLabVillage(data): await createVillage(data);
    
  };




  useEffect(() => {
    getCountryData();
  }, []);

  useEffect(() => {
    setAddressData(addressVal);
  }, [addressVal, setAddressData]);

  return (
    <div
      style={{ display: "flex", gap: "20px", flexWrap: "wrap", width: "75vw" }}
    >
      <TextField
        sx={{ width: "31%", backgroundColor: "#fff" }}
        name="category"
        id="outlined-basic"
        value={addressVal.laneOne}
        label={translate('addressLaneOne')}
        required
        variant="outlined"
        onChange={(event) => {
          setAddressVal({ ...addressVal, laneOne: event.target.value });
          // setAddressData({...addressData,laneOne:event.target.value});
        }}
      />

      <TextField
        sx={{ width: "31%", backgroundColor: "#fff" }}
        name="category"
        id="outlined-basic"
        value={addressVal.laneTwo}
        label={translate('addressLaneTwo')}
        variant="outlined"

        onChange={(event) => {
          // setLaneTwoVal(event.target.value)
          setAddressVal({ ...addressVal, laneTwo: event.target.value });

          // setAddressData({...addressData,laneTwo:event.target.value});
        }}
      />
 

      <TextField
        sx={{ width: "31%", backgroundColor: "#fff" }}
        name="category"
        id="outlined-basic"
        value={countryVal}
        variant="outlined"
        label={translate('country')}
        required

        select
        onChange={(event) => {
          const isOther = event.target.value === 'other';
          setOtherCountry(()=> isOther)
          setCountryVal(event.target.value);
          setOtherCountryVal(null);
          setStateVal("");
          setDistrictVal("");
          if(!isOther){

            getStateData(event.target.value);
            setDistrict([])
            setAddressVal({ ...addressVal,isNotOther: true, countryName:country.find(i=>i.id==event.target.value)?.name, villageName: "", stateName:"", districtName: "", pinCode: "", mobileNumber: ""});
          }else{
            setAddressVal({ ...addressVal, countryName: event.target.value, villageName: "", stateId: "", districtId: "", district: "", country: "", pinCode: "", mobileNumber: ""});
            
          }
          if(setIsValidPinCode){
            setIsValidPinCode(true)
          }
          if(setIsMobileNumberValid){
            setIsMobileNumberValid(true);
          }
          setPinCodeError("");
          setMobileError("");
        }}
      >
        {country?.map((option, idx) => (
          <MenuItem key={idx} value={option.id}>
            {option.name}
          </MenuItem>
        ))}

          <MenuItem key={'other'} value={'other'}>
            Other
          </MenuItem>
      </TextField>
      
      {isOtherCountry && <TextField
        sx={{ width: "31%", backgroundColor: "#fff" }}
        name="category"
        id="outlined-basic"
        value={otherCountryVal}
        variant="outlined"
        label="Country Name"
        required

        onChange={(event) => { 
          setOtherCountryVal(event.target.value)
          setAddressVal({ ...addressVal, countryName: event.target.value});
        }}
      >
      </TextField>}

      <TextField
        sx={{ width: "31%", backgroundColor: "#fff" }}
        name="category"
        id="outlined-basic"
        value={stateVal}
        label={translate('states')}
        variant="outlined"
        required
        select={!isOtherCountry && state?.length>0}
        onChange={(event) => {
          setStateVal(event.target.value);
          if(!isOtherCountry && state?.length>0){
            getDistrictData(event.target.value);
            setAddressVal({ ...addressVal, stateName: state.find(i=>i.id==event.target?.value)?.name });
          }else{
            setAddressVal({ ...addressVal, stateName: event.target.value });
          }
        }}
      >
        {!isOtherCountry && state?.map((option, idx) => (
          <MenuItem key={idx} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        sx={{ width: "31%", backgroundColor: "#fff" }}
        name="category"
        id="outlined-basic"
        value={districtVal}
        variant="outlined"
        required
        select={!isOtherCountry && district?.length > 0}
        label={translate('districts')}
        onChange={(event) => {
          setDistrictVal(event.target.value);
          if(!isOtherCountry && district?.length > 0){
            setAddressVal({ ...addressVal, districtId: event.target.value ,districtName: district.find(i=>i.id==event.target.value)?.name });
          }else{
            setAddressVal({ ...addressVal,districtName: district.find(i=>i.id==event.target.value)?.name || event.target.value });
          }
        }}
      >
        { !isOtherCountry && district?.map((option, idx) => (
          <MenuItem key={idx} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        sx={{ width: "31%", backgroundColor: "#fff" }}
        name="category"
        id="outlined-basic"
        value={addressVal.villageName}
        label={translate('villageOrCity')}
        required

        variant="outlined"
        onChange={(event) => {
          setAddressVal({ ...addressVal, villageName: event.target.value });
        }}
      />
           <TextField
        sx={{ width: "31%", backgroundColor: "#fff" }}
        name="category"
        id="outlined-basic"
        label={translate('pinCode')}
        required
        type="text"
        InputProps={{
          // inputProps: { min: 100000, max: 999999 },

        }}

        value={addressVal.pinCode}
        variant="outlined"
        onChange={(event) => {
          // if((/^[0-9]*$/.test(event.target.value))){

            const pinCode = event.target.value;
            const numericValue = pinCode.replace(/[^0-9]/g, '');
            setAddressVal({ ...addressVal, pinCode: numericValue });
            if (addressVal.countryName === 'India' && ( numericValue < 100000 || numericValue > 999999)) {
              if(setIsValidPinCode){
                setIsValidPinCode(false)
              }
              setPinCodeError("Please enter valid pin code");
            } else {
              if(setIsValidPinCode){
                setIsValidPinCode(true)
              }
              setPinCodeError("");
            }
          // }
        }}
        error={!!pinCodeError}
  helperText={pinCodeError}
      />
      {showMobileNumber&& <TextField
        sx={{ width: "31%", backgroundColor: "#fff", mb: 2 }}
        name="category"
        id="outlined-basic"
        value={addressVal.mobileNumber}
        label={translate('mobileNo')}
        variant="outlined"
        type="text"
        required
        onChange={(event) => {
          // if((/^[0-9]*$/.test(event.target.value))){
            const number = event.target.value;
            const numericValue = number.replace(/[^0-9]/g, '');
            setAddressVal({ ...addressVal, mobileNumber: numericValue });
            if (
              (numericValue >= 9999999999 || numericValue <= 1000000000) &&
              numericValue !== "" &&
              addressVal.countryName === "India"
              ) {
                if(setIsMobileNumberValid){
                setIsMobileNumberValid(false);
                }
                setMobileError("Please enter valid 10 digit mobile number");
              } else {
                if(setIsMobileNumberValid){
                setIsMobileNumberValid(true);
                }
                setMobileError("");
              }
          // }
        }}
        // InputLabelProps={{
        //   shrink: true
        // }}
        error={mobileError}
        helperText={mobileError}
      />}
      {(!hidelatlong)&&<TextField
        sx={{ width: "31%", backgroundColor: "#fff" }}
        name="category"
        id="outlined-basic"
        value={addressVal.longitude}
        label="Longitude"
        required
        type="number"

        variant="outlined"
        onChange={(event) => {
          setAddressVal({ ...addressVal, longitude: event.target.value });
          
        }}
      />}
      {(!hidelatlong)&&<TextField
        sx={{ width: "31%", backgroundColor: "#fff" }}
        name="category"
        id="outlined-basic"
        value={addressVal.latitude}
        required
        type="number"
        label="Latitude"
        variant="outlined"
        onChange={(event) => {
          setAddressVal({ ...addressVal, latitude: event.target.value });
          
        }}
      />}
    </div>
  );
}

export default AddressWidget;
