export const convert = (size,quant, conversionFactorKg, ) => {
    let result = ""
    

    result =  (typeof size === "string" ? parseInt(size,10) : size) * 
    (typeof quant === "string" ? parseInt(quant,10) : quant)*
    (conversionFactorKg)
    return result
  }
export  function sumArray(array) {
    let sum = 0;

    array.forEach(item => {
      sum += item;
    });

    return sum;
  }
