import React from "react";
import SourceComponent from "./SourceComponent";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/material";


function SorceCategory({
  sourceCategoryData,
  getBatchSizeLot,
  setComments,
  comments,
  MaterialData,
  categoryId,
  showButton,
  setShowButton,
  disableVendorData,
  getBatchDetailLot,
  mixComments,
  sendFormData,
  setHandleMixDelete,
  chipList,
  setShowButtonAction,
  isInspector,
  setSaveButtonCondition,
  getBatchTreeDataById
}) {

  return (
    <Box   className="form-div sourceCategory">
      {/* {sourceCategoryData?.sourceCategories?.map((data, idx) => ( */}
        <SourceComponent
          data={sourceCategoryData?.sourceCategories}
          // idx={data?.id}
          getBatchSizeLot={getBatchSizeLot}
          comments={comments}
          setComments={setComments}
          chipList={chipList}
          mixComments={mixComments}
          MaterialData={MaterialData}
          categoryId={categoryId}
          showButton={showButton}
          setShowButton={setShowButton}
          disableVendorData={disableVendorData}
          getBatchDetailLot={getBatchDetailLot}
          sendFormData={sendFormData}
          setHandleMixDelete={setHandleMixDelete}
          setShowButtonAction={setShowButtonAction}
          isInspector={isInspector}
          setSaveButtonCondition={setSaveButtonCondition}
          getBatchTreeDataById={(id)=>getBatchTreeDataById(id)}


        />
      {/* ))} */}
    </Box>
  );
}

export default SorceCategory;
