import { Box, Modal, Typography } from "@mui/material";
import { Form } from "@rjsf/mui";
import React, { useState } from "react";
import "./VendorDetails.css";
import validator from "@rjsf/validator-ajv8";
import AddressWidget from "../../Admin/iamService/manufacturers/AddressWidget";
import {
  createVillage,
  getVillageByDistrict,
} from "../../../services/adminService";
import { addnewVendor } from "../../../services/FortificationService";
import { useAppDispatch } from "../../../services/StoreHooks";
import { MAIN_BLUE } from "../../../color";
import useTranslatewrapper from "../../../components/useTranslateWrapper";

function VendorDetailsForm(props) {
  const {translate} = useTranslatewrapper()
  const [addressData, setAddressData] = useState({});
  const [formData, setFormData] = useState({});
  const [isValidPinCode, setIsValidPinCode] = useState(false)
  const [isMobileNumberValid, setIsMobileNumberValid] = useState(true);
  const dispatch = useAppDispatch();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    overflow: "scroll",
    width: "80%",
  };
  const AddressMapWidget = () => {
    return (
      <AddressWidget
        addressData={addressData}
        setAddressData={setAddressData}
        hidelatlong={true}
        setIsValidPinCode={setIsValidPinCode}
        setIsMobileNumberValid={setIsMobileNumberValid}
        showMobileNumber={true}
      />
    );
  };
  const widgets = [AddressMapWidget];
  const schema = {
    properties: {
      name: {
        type: "string",
        title: translate('nameOfVendor'),
      },
      vendorType: {
        title: translate('vendorType'),
        enum: ["Manufacturer", "Trader", "Broker"],
        enumNames: [translate('manufacturer'),translate('trader'),translate('broker')]
      },
      emailId: {
        title: translate('emailId'),
        type: "string",
        pattern: "(^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$|^$)"
      },
      licenseNumber: {
        title: translate('licenseNo'),
        type: "string",
      },
      addressData: {
        type: "string",
      },
    },
    required: ["name", "vendorType"],
  };
  const uiSchema = {
    addressData: {
      "ui:widget": AddressMapWidget,
    },
    "ui:submitButtonOptions": {
      "submitText": translate('submit'),
    },
    emailId: {
      "ui:options": {
        "patternMessage": "Please enter a valid email id"
      }
    }
  };
  const getcompleteAddress = (addressData) => {
    let { laneOne, laneTwo, country, state, district, villageName, pinCode } =
      addressData;
    return (
      (laneOne ? `${laneOne}, ` : "") +
      (laneTwo ? `${laneTwo}, ` : "") +
      (villageName ? `${villageName}, ` : "") +
      (district?.name
        ? `${district?.name}, `
        : district
        ? `${district}, `
        : "") +
      (state?.name ? `${state?.name}, ` : state ? `${state}, ` : "") +
      (country?.name ? `${country?.name}, ` : country ? `${country}, ` : "") +
      (pinCode || "")
    );
  };
  const createVillageData = async (data) => {
    const VillageData = await createVillage(data);
    return VillageData?.data;
  };
  const getVillageData = async (data) => {
    const getVillageDataDist = await getVillageByDistrict(data.districtId);
    return getVillageDataDist?.data?.data?.filter(
      (item) => item.name === data.name
    )?.length > 0
      ? getVillageDataDist?.data.data.filter(
          (item) => item.name === data.name
        )[0].id
      : null;
  };
  const dispatchNotification = (typ, msg) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      data: {
        open: true,
        type: typ,
        message: msg,
      },
    });
  };

  const transformErrors = (errors) => {
    return errors.map(error => {
      if (error.name === 'pattern' && error.property === '.emailId') {
        error.message = 'Please enter a valid email id';
        error.stack = 'Please enter a valid email id'
      }
      return error;
    });
  };

  const submitForm = async (data) => {
    if(!isValidPinCode || !isMobileNumberValid){
      return;
    }

    const vId = ((addressData.isNotOther && addressData.districtId) && await getVillageId()) || null;
    let payload = {
      name: data.name,
      vendorType: data.vendorType,
      licenseNumber: data.licenseNumber,
      address: {
        laneOne: addressData.laneOne,
        laneTwo: addressData.laneTwo,
        villageId: vId,
        villageName: addressData.villageName,
        countryName: addressData.countryName,
        stateName: addressData.stateName,
        districtName: addressData.districtName,
        pinCode: addressData.pinCode,
        // longitude: 0,
        // latitude: 0,
      },
      // mobileNumber: data.mobileNumber,
      // emailId: data.emailId,
      manufacturerProperties:[
        {
          name: 'mobileNumber',
          value: addressData.mobileNumber
        },
        {
          name: 'emailId',
          value: data.emailId
        }
      ],
      // {
      //   mobileNumber: data.mobileNumber,
      //   emailId: data.emailId
      // },
      manufacturerDocs: [],
      manufacturerCategory: [
        {
          categoryId: props.categoryId,
        },
      ],
      manufacturerType: "PRIVATE",
    };
    let res = await addnewVendor(payload);
    if (res.status === 201) {
      props.fetchVendorList();
      dispatchNotification("success", ["Added to vendor list"]);
      setFormData({});
      setAddressData({});
      props.setaddnewVendor(false);
    }
  };
  const getVillageId = async ()=>{
      return (await getVillageData({
        name: addressData?.villageName || addressData?.village?.name,
        districtId:
          addressData?.districtId || addressData?.village?.district?.id,
      })) ||
      (await createVillageData({
        name: addressData?.villageName,
        districtId: addressData?.districtId,
      }))
    
  }
  return (
    <Modal
      open={props.open}
      onClose={() => {
        setFormData({});
        setAddressData({});
        props.setaddnewVendor(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          align="left"
          mt={2}
          variant="h6"
          sx={{ color: MAIN_BLUE, fontWeight: "600" }}
        >
          {translate('addNewVendor')}
        </Typography>
        <Form
          schema={schema}
          validator={validator}
          uiSchema={uiSchema}
          formData={formData}
          onSubmit={(e) => {
            submitForm(e.formData);
          }}
          onChange={(e) => {

            setFormData({
              ...e.formData, 
              licenseNumber : e.formData?.licenseNumber?.replace(/[^0-9]/g, '')
            })

          }}
          transformErrors={transformErrors}
          widgets={widgets}
          className="batch-form-styling"
        ></Form>
      </Box>
    </Modal>
  );
}

export default VendorDetailsForm;
